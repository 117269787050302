import { Component, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { authorIdBuilder } from '@shared/idBuilder';
import { buildCenterMock, TCenter } from '@shared/interfaces/TCenter';
import { buildHCPMock, THCP } from '@shared/interfaces/THCP';

import { isNullOrEmpty } from '@shared/utils/isNullOrEmpty';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { newGuid } from '@shared/utils/newGuild';
import { ROUTES_PATHS } from '../app-routing.module';

import { HCPsUtils } from 'src/utils/hcpsUtils';
import {
  ConfirmationDialogComponent,
  ConfirmDialogData,
} from '../confirmation-dialog/confirmation-dialog.component';
import { TDataMaintenanceFooterButtonParams } from '../data-mainterance-fotter-button/data-maintenance-footer-button.component';
import {
  TComponentParent,
  TComponentType,
  THCPParams,
} from '../interfaces/TComponentParams';
import { TProjectReviewParams } from '../project-review/project-review.component';
import { TReportParams } from '../reports-review/reports-review.component';
import { CategoriesService } from '../services/CategoriesService';
import { CentersService } from '../services/CentersService';
import { ClinicalTrialsService } from '../services/ClinicalTrialsService';
import { DataProcessorService } from '../services/DataProcessorService';
import { HCPService } from '../services/HCPService';
import { LoadingService } from '../services/LoadingService';

export const NEW_HCP_ID = `new_hcp`;

@Component({
  selector: 'app-hcp',
  templateUrl: './hcp.component.html',
  styleUrls: ['./hcp.component.scss'],
})
export class HcpComponent implements OnDestroy {
  public hcpData: THCP = {
    ...buildHCPMock({}),
  };
  public centerData: TCenter = buildCenterMock({});
  public studiesCount: number = 0;

  public footerButtonsData: TDataMaintenanceFooterButtonParams = {
    backCallback: () => this._backCallback(),
    backLabel: `Back`,
    deleteCallback: () => this._deleteCallback(),
    deleteLabel: `Delete`,
    saveCallback: () => this._saveCallback(),
    saveLabel: `Save`,
    nextCallback: () => this._nextCallback(),
    nextLabel: `Next`,
    previousCallback: () => this._previousCallback(),
    previousLabel: `Previous`,
  };
  public hcpParams: THCPParams = {
    __type: TComponentType.hcp,
    hcpId: ``,
    categoryId: ``,
    projectId: ``,
  };
  @ViewChild('tabs') public tabGroup: MatTabGroup;
  public isNewFromOtherProject: boolean = false;

  private _subs: Subscription[] = [];

  @HostListener('window:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this._saveCallback();
      event.preventDefault();
    }
  }

  constructor(
    public deleteConfirmationDialog: MatDialog,
    private _HCPsService: HCPService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _centersService: CentersService,
    private _loadingService: LoadingService,
    private _categoriesService: CategoriesService,
    private _clinicalTrialsService: ClinicalTrialsService,
    private _dataProcessorService: DataProcessorService
  ) {
    this._subs.push(
      this._route.params.subscribe((params) => {
        this.hcpParams = params as THCPParams;
        this.setHCPInfo();
      })
    );
    this._subs.push(
      this._centersService.getCentersObservable().subscribe((centers) => {
        const center: TCenter = centers.slice()[0];
        if (center !== undefined) {
          this.centerData = center;
        }
      })
    );
    this._subs.push(
      this._HCPsService.getExtendedHCPsObservable().subscribe((hcps) => {
        if (hcps.length === 1) {
          this.hcpData = hcps[0];
          if (hcps[0].center.length > 0) {
            this._centersService.setExtendedCenters(
              [hcps[0].center],
              this.hcpParams.categoryId,
              this.hcpParams.projectId
            );
          }
          if (
            !isNullOrUndefined(this.hcpData) &&
            !isNullOrEmpty(this.hcpData.first_name) &&
            !isNullOrEmpty(this.hcpData.last_name)
          ) {
            if (this.hcpData.studies.length > 0) {
              this._clinicalTrialsService.setClinicalTrialsByIDs(
                this.hcpData.studies.map((s) => s.id),
                this.hcpParams.projectId
              );
            } else {
              this._clinicalTrialsService.resetClinicalTrials();
            }
          }
        }
      })
    );
    this._subs.push(
      this._clinicalTrialsService
        .getClinicalTrialsObservable()
        .subscribe((ctData) => {
          this.studiesCount = ctData.length;
        })
    );
  }

  public ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
    this._centersService.resetCenters();
    this.centerData = buildCenterMock({});
    this.hcpData = {
      ...buildHCPMock({}),
    };
  }

  public tabChanged(event: MatTabChangeEvent): void {
    const publicationsTab: MatTab | undefined = this.tabGroup._tabs.get(1);
    const studiesTab: MatTab | undefined = this.tabGroup._tabs.get(2);
    if (!isNullOrUndefined(publicationsTab)) {
      if (event.index === 1) {
        publicationsTab.textLabel = `Publications (${
          this._HCPsService.getProjectData(
            this.hcpData,
            this.hcpParams.projectId
          ).history_publications.length
        })`;
      } else {
        publicationsTab.textLabel = `Publications`;
      }
    }
    if (!isNullOrUndefined(studiesTab)) {
      if (event.index === 2) {
        studiesTab.textLabel = `Studies (${this.studiesCount})`;
      } else {
        studiesTab.textLabel = `Studies`;
      }
    }
  }

  public onHCPSelectCallback(hcp: THCP, reset: boolean): void {
    if (reset) {
      this.hcpData = hcp;
      this.hcpParams = {
        ...this.hcpParams,
        hcpId: NEW_HCP_ID,
      };
    } else {
      this.hcpData = hcp;
      const clonedParams = structuredClone(this.hcpParams);
      clonedParams.hcpId = hcp.id;
      this.hcpParams = clonedParams;
      this.isNewFromOtherProject = true;
      if (!isNullOrEmpty(hcp.center))
        this._centersService.setExtendedCenters(
          [hcp.center],
          this.hcpParams.categoryId,
          this.hcpParams.projectId
        );
    }
  }

  private _backCallback(): void {
    if (isNullOrEmpty(this.hcpParams.projectId)) {
      this._router.navigate([`/${ROUTES_PATHS.dashboard}`]);
    } else if (this.hcpParams.parent === TComponentParent.report) {
      const reportsParams: TReportParams = {
        data: ``,
        projectId: this.hcpParams.projectId,
        loadFromCache: true,
      };
      this._router.navigate([`/${ROUTES_PATHS.report}`, reportsParams], {
        relativeTo: this._route,
      });
    } else {
      const projectReviewParams: TProjectReviewParams = {
        projectId: this.hcpParams.projectId ?? ``,
        categoryId: this.hcpParams.categoryId ?? ``,
        hcpsVisible: true,
        centersVisible: false,
      };
      this._router.navigate([`/${ROUTES_PATHS.project}`, projectReviewParams]);
    }
  }

  private isEmailValid(email: string): boolean {
    if (isNullOrEmpty(email)) {
      return true;
    }
    const re: RegExp = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  private _saveCallback(): void {
    const isNew: boolean = this.hcpParams.hcpId === NEW_HCP_ID;
    let newHCPId = ``;
    if (isNew) {
      newHCPId = `${authorIdBuilder(
        this.hcpData.first_name,
        this.hcpData.last_name
      )}-${newGuid()}`;
      this.hcpData.id = newHCPId;
      this.hcpData.projects = [this.hcpParams.projectId];
    }
    const isDataValid: boolean =
      (!isNew && this.isEmailValid(this.hcpData.primary_email_address)) ||
      (isNew &&
        this.hcpData.first_name.length > 0 &&
        this.hcpData.last_name.length > 0 &&
        this.isEmailValid(this.hcpData.primary_email_address));
    if (isDataValid) {
      if (this.centerData.c_id !== `` && this.hcpParams.projectId !== ``) {
        this._centersService.updateCenter(
          this.centerData,
          this.hcpParams.projectId,
          () => {
            this._updateHCP(isNew, newHCPId);
          }
        );
      } else {
        this._updateHCP(isNew, newHCPId);
      }
    } else {
      const errorMessage: string[] = [];
      if (this.hcpData.first_name.length === 0) {
        errorMessage.push(`First name`);
      }
      if (this.hcpData.last_name.length === 0) {
        errorMessage.push(`Last name`);
      }
      if (errorMessage.length > 1) {
        this._loadingService.triggerErrorMessage(
          `Please fill ${errorMessage.join(`, `)} field${
            errorMessage.length > 1 ? `s` : ``
          }`,
          4000
        );
      }
      if (!this.isEmailValid(this.hcpData.primary_email_address)) {
        this._loadingService.triggerErrorMessage(
          `Please provide valid email address`,
          4000
        );
      }
    }
  }

  private _updateHCP(isNew: boolean, newHCPId: string): void {
    let hcpForUpdate: THCP = HCPsUtils.getHCPDataForUpdate(this.hcpData);
    this._HCPsService.updateHCP(
      hcpForUpdate,
      this.hcpParams.categoryId,
      this.hcpParams.projectId,
      () => {
        if (isNew) {
          const hcpParams: THCPParams = {
            hcpId: newHCPId,
            categoryId: this.hcpParams.categoryId ?? ``,
            projectId: this.hcpParams.projectId ?? ``,
            __type: TComponentType.hcp,
          };
          this._router.navigate([`/${ROUTES_PATHS.hcp}`, hcpParams], {
            relativeTo: this._route,
          });
        }
        if (this.isNewFromOtherProject || isNew) {
          this._dataProcessorService.updateProjectCounts(
            this.hcpParams.projectId
          );
        }
      }
    );
  }

  private _deleteCallback(): void {
    const message = `Are you sure you want to delete ${this.hcpData.first_name} ${this.hcpData.last_name}?`;
    const dialogData: ConfirmDialogData = {
      title: `Delete HCP`,
      message,
    };
    const dialogRef = this.deleteConfirmationDialog.open(
      ConfirmationDialogComponent,
      {
        maxWidth: `400px`,
        data: dialogData,
      }
    );

    dialogRef.afterClosed().subscribe((dialogResult: boolean) => {
      if (dialogResult) {
        this._HCPsService.deleteHCPs(
          {
            categoryId: this.hcpParams.categoryId,
            projectId: this.hcpParams.projectId,
            ids: [this.hcpData.id],
          },
          () => {
            this._categoriesService.setCategories(() => {
              this._backCallback();
            });
          }
        );
      }
    });
  }

  private setHCPInfo(): void {
    if (this.hcpParams.hcpId !== NEW_HCP_ID) {
      this._HCPsService.setHCPs({
        categoryId: this.hcpParams.categoryId,
        ids: [this.hcpParams.hcpId],
        projectId: this.hcpParams.projectId,
      });
      if (!isNullOrUndefined(this.tabGroup)) {
        this.tabGroup.selectedIndex = 0;
      }
      if (
        !isNullOrUndefined(this.hcpParams.hcpId) &&
        isNullOrEmpty(this.hcpParams.projectId)
      ) {
        this._loadingService.triggerInfoMessage(
          `Viewing HCP outside of the project will not contains project specific data`,
          10000,
          `OK`
        );
      }
    }
  }

  private _nextCallback(): void {
    this._HCPsService.handleNextHCP(
      this.hcpData.id,
      this.hcpParams.projectId,
      (hcpId: string) => {
        const hcpParams: THCPParams = {
          hcpId,
          categoryId: this.hcpParams.categoryId,
          projectId: this.hcpParams.projectId,
          __type: TComponentType.hcp,
          parent: this.hcpParams.parent,
        };
        this._router.navigate([`/${ROUTES_PATHS.hcp}`, hcpParams], {
          relativeTo: this._route,
        });
      }
    );
  }

  private _previousCallback(): void {
    this._HCPsService.handlePreviousHCP(
      this.hcpData.id,
      this.hcpParams.projectId,
      (hcpId: string) => {
        const hcpParams: THCPParams = {
          hcpId,
          categoryId: this.hcpParams.categoryId,
          projectId: this.hcpParams.projectId,
          __type: TComponentType.hcp,
          parent: this.hcpParams.parent,
        };
        this._router.navigate([`/${ROUTES_PATHS.hcp}`, hcpParams], {
          relativeTo: this._route,
        });
      }
    );
  }
}
