<div class="mt-5">
  <div class="mt-4">
    <div>
      <h3 class="mat-h3">Aliases</h3>
      <div *ngFor="let alias of hcpData.alias" class="flex gap-2 items-center">
        <div class="flex">
          <!-- <mat-icon>person</mat-icon> -->
          <div mat-line class="text-sm ml-4">
            <span class="text-sm">{{ alias }}</span>
          </div>
        </div>
        <button color="error" class="mr-4" mat-icon-button>
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <div *ngIf="!aliasesVisible()">
        <div class="flex">
          <mat-icon>person</mat-icon>
          <div mat-line class="text-sm ml-4">
            <span class="text-sm">No aliases</span>
          </div>
        </div>
      </div>
      <mat-divider class="py-2"></mat-divider>
      <h3 class="mat-h3">Center history</h3>
      <div *ngFor="let center of hcpData.history_centers">
        <div class="flex">
          <div mat-line class="text-sm ml-4 mb-1">
            <span class="text-sm">{{ center.name }}</span>
            -
            <span class="text-sm">{{ center.year }}</span>
          </div>
        </div>
      </div>
      <mat-divider class="py-2"></mat-divider>
    </div>
  </div>
</div>