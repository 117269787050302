import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints, ScoreEndpoints } from '@shared/Endpoints';
import { THCPScoreQuery } from '@shared/interfaces/THCPScoreQuery';
import { TScoreItem } from '@shared/interfaces/TProject';
import { environment } from 'src/environments/environment';
import { TBaseScoreItem } from '../project-review/project-settings/scoring-settings/scoring-settings.component';
import { LoadingService, TLoadingItem } from './LoadingService';

interface TScoreUpdaterParams {
  selectedFields: TBaseScoreItem[];
  categoryId: string;
  projectId: string;
  logMessage: string;
  endpoint: ScoreEndpoints;
}
//NOT USED CURRENTLY
@Injectable()
export class ScoringService {
  constructor(
    private _http: HttpClient,
    private _loadingService: LoadingService
  ) {}

  public scoresUpdater(params: TScoreUpdaterParams): void {
    const loadingItem: TLoadingItem = this._loadingService.buildLoadingItem(
      params.logMessage
    );
    this._loadingService.addLoadingItem(loadingItem);
    const weights: TScoreItem[] = params.selectedFields.map((item) => {
      return {
        fieldId: item.fieldId,
        operator: item.operator,
        score: item.score,
      };
    });
    const url: string = Endpoints.BUILD_URL('score', {
      base: environment.apiUrl,
      endpoint: params.endpoint,
      params: ``,
    });
    const payload: THCPScoreQuery = {
      categoryId: params.categoryId,
      projectId: params.projectId,
      weights,
    };
    const response = this._http.post<void>(url, payload);
    response.subscribe(() => {
      this._loadingService.removeItem(loadingItem);
    });
  }
}
