<ng-container *ngIf="isLogged">
  <app-settings-initializator></app-settings-initializator>
  <app-loading></app-loading>
  <app-header></app-header>
  <app-toolbar></app-toolbar>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</ng-container>
<ng-container *ngIf="!isLogged">
  <router-outlet>
  </router-outlet>
</ng-container>