import { Injectable } from '@angular/core';
import { COUNTRIES } from '@shared/geonames.consts';
import { BehaviorSubject, Observable } from 'rxjs';
import { SettingsService } from './SettingsService';

export interface TRegion {
  value: string;
  viewValue: string;
}

export const WORLD_REGION: TRegion = { value: `World`, viewValue: `World` };
const mockRegions: TRegion[] = [
  WORLD_REGION,
  ...COUNTRIES.map((c) => {
    return { value: c, viewValue: c };
  }),
];

@Injectable({
  providedIn: 'root',
})
export class RegionsService {
  private _selectedCountries: BehaviorSubject<TRegion[]> = new BehaviorSubject<
    TRegion[]
  >([WORLD_REGION]);
  private _countries: BehaviorSubject<TRegion[]> = new BehaviorSubject<
    TRegion[]
  >([]);
  private _allCountries: BehaviorSubject<TRegion[]> = new BehaviorSubject<
    TRegion[]
  >(mockRegions);
  private countriesOnly: TRegion[] = COUNTRIES.map((c) => {
    return { value: c, viewValue: c };
  });

  private regionSelectorValues: BehaviorSubject<TRegion[]> =
    new BehaviorSubject<TRegion[]>([]);

  constructor(private settingsService: SettingsService) {
    this.settingsService.getAppSettingsObservable().subscribe((s) => {
      const customRegionLabels = s.customRegions.map((r) => {
        return { value: r.name, viewValue: r.name };
      });

      this.regionSelectorValues.next([
        WORLD_REGION,
        ...customRegionLabels,
        ...COUNTRIES.map((c) => {
          return { value: c, viewValue: c };
        }),
      ]);
    });
  }

  public getSelectedCountriesObservable(): Observable<TRegion[]> {
    return this._selectedCountries.asObservable();
  }

  public getSelectedCountries(): TRegion[] {
    return this._selectedCountries.value;
  }

  public getCountriesObservable(): Observable<TRegion[]> {
    return this._countries.asObservable();
  }

  public getCountries(): TRegion[] {
    return this._countries.value;
  }

  public getCountriesOnly(): TRegion[] {
    return this.countriesOnly;
  }

  public setSelectedCountries(region: TRegion[]): void {
    this._selectedCountries.next(region);
  }

  public getRegionSelectorValues(): Observable<TRegion[]> {
    return this.regionSelectorValues.asObservable();
  }

  public setCountries(allowedCountries: string[] | undefined): void {
    let countries: TRegion[] = [];
    if (
      (allowedCountries?.length ?? 0) > 0 &&
      !allowedCountries?.includes(WORLD_REGION.value)
    ) {
      countries = this.regionSelectorValues.value.filter((c) =>
        allowedCountries?.includes(c.value)
      );
    } else {
      countries = this.regionSelectorValues.value;
    }
    if (
      (allowedCountries?.length ?? 0) > 0 &&
      !allowedCountries?.includes(WORLD_REGION.value)
    ) {
      //countries.splice(0, 1);
      this.setSelectedCountries(countries);
    }
    this._countries.next(countries);
  }

  public getAllCountries(): TRegion[] {
    return this._allCountries.value;
  }
}
