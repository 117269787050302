import { Component, Input, OnInit } from '@angular/core';
import { TTierItem } from '@shared/interfaces/TProject';
import { isNullOrEmpty } from '@shared/utils/isNullOrEmpty';

@Component({
  selector: 'app-tier-item',
  templateUrl: './tier-item.component.html',
  styleUrls: ['./tier-item.component.scss'],
})
export class TierItemComponent implements OnInit {
  @Input() public tierItem: TTierItem;
  @Input() public addNewTier: (tierItem: TTierItem) => void = () => void 0;
  @Input() public isNew: boolean = false;
  @Input() public deleteTier: (tierItem: TTierItem) => void = () => void 0;
  @Input() public selectedTierItems: TTierItem[] = [];

  constructor() {}

  ngOnInit(): void {}

  public addNewTierErrors(): string {
    const nameExists: boolean = this.selectedTierItems
      .map((item) => item.name)
      .includes(this.tierItem.name);
    if (this.isNew && nameExists) {
      return `Tier name must be unique`;
    }
    if (isNullOrEmpty(this.tierItem.name)) {
      return `Tier name cannot be empty`;
    }
    return ``;
  }

  public addNewTierDisabled(): boolean {
    return this.addNewTierErrors().length > 0;
  }
}
