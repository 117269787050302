export interface TUser {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  countries: string[];
  projects: string[];
  categories: string[];
  level: UserLevel;
  status: UserStatus;
}

export enum UserStatus {
  Active,
  Inactive
}

export enum UserLevel {
  Admin,
  User
}
