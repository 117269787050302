import { Component, OnInit } from '@angular/core';
import {
  DEFAULT_APP_SETTINGS,
  TSettings,
  TSupportDoc,
} from '@shared/interfaces/TSettings';
import { Subscription } from 'rxjs';
import { LoadingService, TLoadingItem } from '../../services/LoadingService';
import { SettingsService } from '../../services/SettingsService';
import { SupportDocumentsService } from '../../services/SupportDocumentsService';

@Component({
  selector: 'app-support-docs',
  templateUrl: './support-docs.component.html',
  styleUrls: ['./support-docs.component.scss'],
})
export class SupportDocsComponent implements OnInit {
  public appSettings: TSettings = structuredClone(DEFAULT_APP_SETTINGS);
  private _subs: Subscription[] = [];
  public supportDocs: TSupportDoc[] = [];
  public supportDocUrls: string[] = [];
  public newDocLabel: string = ``;
  public pageAvailable: boolean = false;

  constructor(
    private _settingsService: SettingsService,
    private _loadingService: LoadingService,
    private _supportDocsService: SupportDocumentsService
  ) {}

  ngOnInit(): void {
    this._supportDocsService.getIsAvailable();

    this._subs.push(
      this._supportDocsService
        .getPageAvailableObservable()
        .subscribe((available) => {
          this.pageAvailable = available;
          if (available) {
            this._supportDocsService.getFiles();
          }
        })
    );

    this._subs.push(
      this._supportDocsService
        .getSupportDocsUrlsObservable()
        .subscribe((urls) => {
          this.supportDocUrls = urls;
        })
    );

    this._subs.push(
      this._settingsService.getAppSettingsObservable().subscribe((settings) => {
        this.appSettings = settings;
        this.supportDocs = settings.supportDocs;
      })
    );
  }

  public uploadDocument(e: Event): void {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    const files: FileList | null = target.files;
    const docFile: File = files![0];
    if (docFile.size === 0) {
      this._loadingService.triggerErrorMessage(`Invalid file`);
    } else if (docFile.size > 2000000) {
      this._loadingService.triggerErrorMessage(`File to large (max 2 MB)`);
    } else if (docFile.type !== `application/pdf`) {
      this._loadingService.triggerErrorMessage(
        `Invalid file type, only PDF allowed`
      );
    } else if (this.newDocLabel === ``) {
      this._loadingService.triggerErrorMessage(
        `Please enter a label for the document`
      );
    } else {
      const loadingItem: TLoadingItem =
        this._loadingService.buildLoadingItem(`Uploading file...`);
      this._loadingService.addLoadingItem(loadingItem);
      this._supportDocsService.uploadFile(docFile).subscribe({
        next: (response: { url: string }) => {
          const newDoc: TSupportDoc = {
            fileName: docFile.name,
            label: this.newDocLabel,
            fileUrl: response.url,
          };
          this.supportDocs.push(newDoc);
          this.updateSettings([...this.supportDocs]);
          this._loadingService.removeItem(loadingItem);
        },
        error: (err) => {
          console.error(err);
          this._loadingService.removeItem(loadingItem);
        },
      });
    }
  }

  public deleteFile(doc: TSupportDoc): void {
    if (this.supportDocUrls.includes(doc.fileUrl)) {
      this._supportDocsService.deleteFile(doc.fileUrl).subscribe({
        next: () => {
          // delete document info from settings
          this.supportDocs = this.supportDocs.filter(
            (d) => d.fileUrl !== doc.fileUrl
          );
          this.updateSettings([...this.supportDocs]);
        },
        error: (err) => {
          console.error(err);
        },
      });
    } else {
      console.error(`File not found`);
    }
  }

  private updateSettings(supportDocs: TSupportDoc[]): void {
    const newSettings: TSettings = {
      ...this.appSettings,
      supportDocs: supportDocs,
    };
    this._settingsService.updateSettings(newSettings);
    this._supportDocsService.getFiles();
  }
}
