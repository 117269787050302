<div class="engagement-plan mb-4">
  <h4 class="flex items-center mb-1">
    Plan
    <button
      mat-icon-button
      color="primary"
      (click)="addNewEngagement()"
      [matTooltip]="'Add new engagement plan'"
    >
      <mat-icon color="primary">playlist_add</mat-icon>
    </button>
  </h4>
  <app-score-view
    class="score-view-wrapper"
    [score]="engagementScore"
    [projectId]="params.projectId"
    [area]="'hcpEngagement'"
  ></app-score-view>

  <div class="engagement-plan-table-wrapper">
    <table class="engagement-plan-table relative">
      <thead class="sticky top-0">
        <tr>
          <td class="project">{{ headers.project }}</td>
          <td class="date">{{ headers.date }}</td>
          <td class="description">{{ headers.description }}</td>
          <td class="quantity"></td>
          <td class="tags">{{ headers.tags }}</td>
          <td class="person">{{ headers.person }}</td>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let engagement of getSortedEngagements()"
          engagement-plan-tr
          [engagementPlanItem]="engagement"
          [params]="params"
          [project]="getProjectFromEngagement(engagement).description"
          [itemProjectId]="getProjectFromEngagement(engagement).id"
          [deleteCallback]="deleteCallback.bind(this)"
        ></tr>
      </tbody>
    </table>
  </div>
</div>
