import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PdfViewerComponent } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-sales-data-wrapper',
  templateUrl: './sales-data-wrapper.component.html',
  styleUrls: ['./sales-data-wrapper.component.scss'],
})
export class SalesDataWrapperComponent implements OnInit {
  @Input()
  public pdfSrc: Uint8Array = new Uint8Array([]);
  @ViewChild(PdfViewerComponent)
  private pdfComponent: PdfViewerComponent;
  constructor() {}

  ngOnInit(): void {}

  public pageRendered() {
    const viewerContainer: HTMLDivElement =
      this.pdfComponent.pdfViewerContainer.nativeElement;
    const viewerElement: HTMLDivElement | null =
      viewerContainer.querySelector(`.pdfViewer`);
    if (viewerElement !== null && viewerContainer.parentElement !== null) {
      viewerContainer.parentElement.style.height = `${
        viewerElement.clientHeight + 10
      }px`;
    }
  }
}
