export const OptOutStatuses = [
  {
    label: 'Not informed',
    value: 0,
  },
  {
    label: 'Informed and accepted',
    value: 1,
  },
  {
    label: 'Declined',
    value: 2,
  },
]

export const OptInStatuses = [
  {
    label: 'Not contacted',
    value: 0,
  },
  {
    label: 'Contacted, no response',
    value: 1,
  },
  {
    label: 'Accepted',
    value: 2,
  },
  {
    label: 'Declined',
    value: 3,
  },
]
