<div class="settings-modal-content">
  <h2 class="my-4 mat-h2">Tier definitions</h2>
  <div class="flex gap-4">
    <div>
      <h3 class="my-4 mat-h3">Center fields</h3>
      <div>
        <mat-form-field>
          <mat-label>Available tier fields</mat-label>
          <mat-select [(value)]="selectedCenterField">
            <mat-option *ngFor="let field of availableCenterFields" [value]="field.fieldId">
              {{ field.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="tierListVisible('center')">
        <div class="flex gap-4 items-baseline">
          <h3 class="my-4 mat-h3">
            Defined tiers for field {{ getFieldName("center") }}
          </h3>
          <button mat-flat-button color="primary" (click)="addFieldTier('center')">
            <span>Add new</span>
          </button>
        </div>
        <ng-container *ngIf="getTiers('center').length === 0">
          <label>No tiers defined</label>
        </ng-container>
        <ng-container *ngIf="getTiers('center').length > 0">
          <div *ngFor="let tier of getTiers('center')" class="flex gap-2">
            <mat-form-field appearance="fill">
              <mat-label>Name</mat-label>
              <input matInput [(ngModel)]="tier.name" />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Value</mat-label>
              <input matInput [(ngModel)]="tier.value" />
            </mat-form-field>
            <button mat-icon-button (click)="deleteTier(tier, 'center')" aria-label="Delete" color="error">
              <mat-icon>delete_forever</mat-icon>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
    <div>
      <h3 class="my-4 mat-h3">HCP fields</h3>
      <div>
        <mat-form-field>
          <mat-label>Available tier fields</mat-label>
          <mat-select [(value)]="selectedHCPField">
            <mat-option *ngFor="let field of availableHCPFields" [value]="field.fieldId">
              {{ field.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="tierListVisible('hcp')">
        <div class="flex gap-4 items-baseline">
          <h3 class="my-4 mat-h3">
            Defined tiers for field {{ getFieldName("hcp") }}
          </h3>
          <button mat-flat-button color="primary" (click)="addFieldTier('hcp')">
            <span>Add new</span>
          </button>
        </div>
        <ng-container *ngIf="getTiers('hcp').length === 0">
          <label>No tiers defined</label>
        </ng-container>
        <ng-container *ngIf="getTiers('hcp').length > 0">
          <div *ngFor="let tier of getTiers('hcp')" class="flex gap-2">
            <mat-form-field appearance="fill">
              <mat-label>Name</mat-label>
              <input matInput [(ngModel)]="tier.name" />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Value</mat-label>
              <input matInput [(ngModel)]="tier.value" />
            </mat-form-field>
            <button mat-icon-button (click)="deleteTier(tier, 'hcp')" aria-label="Delete" color="error">
              <mat-icon>delete_forever</mat-icon>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>