import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SharedConstants } from '@shared/Constants';
import { SearchArea } from '@shared/enums/SearchArea';

import { ROUTES_PATHS } from '../app-routing.module';

import { TCenterParams, TComponentType, THCPParams } from '../interfaces/TComponentParams';

@Injectable()
export class CommonService {
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
  ) {}

  navigateToSearchedItem(searchArea: SearchArea, itemId: string, categoryId: string, projectId: string): void {
    switch (searchArea) {
      case SearchArea.Articles:
        window.open(`${SharedConstants.PUBMED_URL}${itemId}`, '_blank')?.focus();
        break;
      case SearchArea.Centers:
        const centerParams: TCenterParams = {
          centerId: itemId,
          categoryId,
          projectId,
          __type: TComponentType.center,
        };
        this._router.navigate([`/${ROUTES_PATHS.center}`, centerParams], {
          relativeTo: this._route,
        });
        break;
      case SearchArea.HCP:
        const hcpParams: THCPParams = {
          hcpId: itemId,
          categoryId,
          projectId,
          __type: TComponentType.hcp,
        };
        this._router.navigate([`/${ROUTES_PATHS.hcp}`, hcpParams], {
          relativeTo: this._route,
        });
        break;
      default:
        console.log(`/404`);
    }
  }
}
