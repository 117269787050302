import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { AuthService, TCurrentUser } from '../services/auth/auth.service';

export interface IPaginationParams {
  pageIndex: number;
  length: number;
  pageSize: number;
  pageSizeOptions: number[];
  handlePageCallback: (args: PageEvent) => void;
}
@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnDestroy {
  @Input()
  public paginationParams: IPaginationParams = {
    handlePageCallback: () => void 0,
    length: 1000,
    pageIndex: 1,
    pageSize: 3,
    pageSizeOptions: [3, 10, 25, 99],
  };
  public currentUser: TCurrentUser | undefined = undefined;
  private _subs: Subscription[] = [];

  @ViewChild('paginator') paginator: MatPaginator;

  public pageEvent: PageEvent;
  constructor(
    public importDialog: MatDialog,
    private _authService: AuthService
  ) {}

  ngOnInit(): void {
    this._subs.push(
      this._authService.getCurrentUserObservable().subscribe((user) => {
        this.currentUser = user;
      })
    );
  }

  public ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
  }

  public handlePage(e: PageEvent) {
    this.paginationParams.handlePageCallback(e);
  }

  public goToFirstPage(): void {
    this.paginator.firstPage();
  }

  public paginationVisible(): boolean {
    //return this.paginationParams.length >= this.paginationParams.pageSize;
    return true;
  }
}
