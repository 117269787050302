<div class="settings-modal-content relative">
  <h2 class="mat-h2 mt-2">{{ data.name }}</h2>
  <div class="text-sm">
    <div *ngIf="data.showCutoff">
      <h3 class="my-4">Add start year</h3>
      <mat-form-field appearance="fill">
        <mat-label>Year</mat-label>
        <input type="number" required matInput [(ngModel)]="cutoff" />
      </mat-form-field>
    </div>
    <div>
      <h3 class="my-4">Add new field</h3>
      <app-scoring-item
        [isNew]="true"
        [selectedField]="addNewSelectedValue"
        [operator]="addNewOperator"
        [scoreValue]="addNewScoreValue"
        [addNewField]="addNewField.bind(this)"
        [availableFields]="availableFields"
        [project]="params.selectedProject"
      >
      </app-scoring-item>
    </div>
    <div *ngIf="selectedItems.length > 0">
      <h3 class="my-4">Selected fields</h3>
      <div *ngFor="let field of selectedItems">
        <app-scoring-item
          [isNew]="false"
          [selectedField]="field"
          [operator]="field.operator"
          [scoreValue]="getScoreValue(field)"
          [deleteField]="deleteField.bind(this)"
          [availableFields]="availableFields"
          [project]="params.selectedProject"
        >
        </app-scoring-item>
      </div>
    </div>
  </div>
  <app-tier-settings
    *ngIf="tiersAvailable"
    [params]="params"
    [scoreArea]="scoreArea"
  ></app-tier-settings>
  <div mat-dialog-actions class="actions">
    <button mat-flat-button color="primary" [mat-dialog-close]="cutoff">
      Close
    </button>
  </div>
</div>
