import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Endpoints } from '@shared/Endpoints';
import {
  ResponseStatus,
  TResponseStatusMessage,
} from '@shared/interfaces/TResponseStatusMessage';
import { TUser, UserLevel } from '@shared/interfaces/TUser';
import { BehaviorSubject, Observable } from 'rxjs';
import { ROUTES_PATHS } from 'src/app/app-routing.module';
import { environment } from 'src/environments/environment';
import { LoadingService } from '../LoadingService';

export const ID_TOKEN_NAME = 'id_token';

export type TCurrentUser = Partial<TUser> | undefined;

@Injectable()
export class AuthService {
  private _isLogged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private _path: BehaviorSubject<string> = new BehaviorSubject<string>(``);
  private currentUser: BehaviorSubject<TCurrentUser> =
    new BehaviorSubject<TCurrentUser>(undefined);

  private allUsers: BehaviorSubject<TUser[]> = new BehaviorSubject<TUser[]>([]);
  constructor(
    private _http: HttpClient,
    private _jwtHelper: JwtHelperService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _loadingService: LoadingService
  ) {}

  public getCurrentUserObservable(): Observable<TCurrentUser> {
    return this.currentUser;
  }

  public getCurrentUser(): TCurrentUser {
    return this.currentUser.value;
  }

  public getAllUsersObservable(): Observable<TUser[]> {
    return this.allUsers;
  }

  public setPath(path: string): void {
    this._path.next(path);
  }

  public getIsLoggedObservable(): Observable<boolean> {
    return this._isLogged;
  }

  public getToken(): string {
    return localStorage.getItem(ID_TOKEN_NAME) || '';
  }

  public logout(): void {
    localStorage.removeItem(ID_TOKEN_NAME);
    this._isLogged.next(false);
    this._router.navigate([`/${ROUTES_PATHS.login}`], {
      relativeTo: this._route,
    });
  }

  public setAllUsers(): void {
    const url: string = Endpoints.BUILD_URL('users', {
      base: environment.apiUrl,
      endpoint: 'getAll',
      params: ``,
    });
    this._http.get<TUser[]>(url).subscribe({
      error: (err) => {
        console.error(err);
        //this._loadingService.onError();
      },
      next: (results) => {
        this.allUsers.next(results);
      },
    });
  }

  public setCurrentUserData(email: string): void {
    const url: string = Endpoints.BUILD_URL('users', {
      base: environment.apiUrl,
      endpoint: 'findOne',
      params: `?email=${email}`,
    });
    this._http.get<Partial<TUser>>(url).subscribe({
      error: (err) => {
        console.error(err);
        //this._loadingService.onError();
      },
      next: (results) => {
        this.currentUser.next(results);
        if (results?.level === UserLevel.Admin) {
          this.setAllUsers();
        }
      },
    });
  }

  public setUserByToken(): void {
    const url: string = Endpoints.BUILD_URL('auth', {
      base: environment.apiUrl,
      endpoint: 'getByToken',
      params: `?token=${this.getToken()}`,
    });
    this._http.get<Partial<TUser>>(url).subscribe({
      error: (err) => {
        console.error(err);
      },
      next: (results) => {
        this.currentUser.next(results);
        this.setAllUsers();
      },
    });
  }

  public login(
    email: string,
    password: string,
    successCallback: () => void,
    errorCallback: () => void
  ): void {
    const url: string = Endpoints.BUILD_URL('auth', {
      base: environment.apiUrl,
      endpoint: 'login',
      params: ``,
    });
    const response = this._http.post<{
      access_token: string;
    }>(url, {
      email,
      password,
    });
    response.subscribe({
      error: () => {
        errorCallback();
      },
      next: (response) => {
        localStorage.setItem(ID_TOKEN_NAME, response.access_token);
        this.setCurrentUserData(email);
        if (this._path.value.length > 0) {
          this._router.navigateByUrl(this._path.value);
        } else {
          this._router.navigate([`/${ROUTES_PATHS.root}`], {
            relativeTo: this._route,
          });
        }
        successCallback();
      },
    });
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();
    const authenticationResults: boolean =
      !this._jwtHelper.isTokenExpired(token);
    this._isLogged.next(authenticationResults);
    return authenticationResults;
  }

  public signup(userData: TUser): void {
    const url: string = Endpoints.BUILD_URL('users', {
      base: environment.apiUrl,
      endpoint: 'create',
      params: ``,
    });
    const response = this._http.post<TResponseStatusMessage<void>>(
      url,
      userData
    );
    response.subscribe({
      error: (err) => {
        console.error(err);
      },
      next: (response) => {
        if (response.status === ResponseStatus.AlreadyExists) {
          this._loadingService.triggerErrorMessage(`User already exists`);
        }
        if (response.status === ResponseStatus.Error) {
          this._loadingService.triggerErrorMessage(response.message);
        }
        if (response.status === ResponseStatus.OK) {
          this._loadingService.triggerOkMessage(`User added successfully`);
          this.setAllUsers();
        }
      },
    });
  }

  public updateUser(userData: TUser): void {
    const url: string = Endpoints.BUILD_URL('users', {
      base: environment.apiUrl,
      endpoint: 'update',
      params: ``,
    });
    const response = this._http.patch<TResponseStatusMessage<void>>(
      url,
      userData
    );
    response.subscribe({
      error: (err) => {
        console.error(err);
      },
      next: (response) => {
        if (response.status === ResponseStatus.OK) {
          this._loadingService.triggerOkMessage(
            response.message ?? `User updated`
          );
        }
      },
    });
  }

  public deleteUser(userData: Partial<TUser>): void {
    const url: string = Endpoints.BUILD_URL('users', {
      base: environment.apiUrl,
      endpoint: 'delete',
      params: ``,
    });
    const response = this._http.post<TResponseStatusMessage<void>>(
      url,
      userData
    );
    response.subscribe({
      error: (err) => {
        console.error(err);
      },
      next: (response) => {
        if (response.status === ResponseStatus.OK) {
          this._loadingService.triggerOkMessage(
            response.message ?? `User deleted`
          );
          this.setAllUsers();
        }
      },
    });
  }
}
