export interface TURLParts {
  base: string;
  endpoint:
    | NCBIEndpoints
    | RiseEndpoints
    | AuthEndpoints
    | CTEndpoints
    | GeonamesEndpoints
    | ExportEndpoints
    | ImportEndpoints
    | ScoreEndpoints
    | SettingsEndpoints
    | ReportEndpoints
    | MailerEndpoints
    | ProximityEndpoints
    | TwitterEndpoints
    | UsersEndpoints
    | SupportDocsEndpoints;
  params: string;
}
export type EndpointNamespace =
  | `ncbi`
  | `rise`
  | 'auth'
  | 'ct'
  | 'geonames'
  | 'export'
  | 'import'
  | 'score'
  | 'settings'
  | 'mailer'
  | 'proximity'
  | 'twitter'
  | 'report'
  | 'users'
  | 'support';
export type NCBIEndpoints = `metadata` | `dataSize` | `ids` | `citations`;
export type CTEndpoints =
  | `hcpData`
  | `hcpLocalData`
  | `hcpLocalDataByIDs`
  | `migrateCTDates`
  | `migrateCTContent`
  | `migrateCTAllContent`;
export type GeonamesEndpoints = `cityData`;
export type ExportEndpoints =
  | `centers`
  | `hcps`
  | `generateExcelSSE`
  | `hcpsReport`
  | `centersReport`;
export type SettingsEndpoints = `base` | `app` | `update`;
export type ReportEndpoints = `hcps` | `centers`;
export type MailerEndpoints = `optIn` | `optOut`;
export type ProximityEndpoints = `getData`;
export type TwitterEndpoints = `userNameData` | `userTwitterData` | `twitterProximity`;
export type ImportEndpoints =
  | `centers`
  | `hcps`
  | `centersProductsSales`
  | `headers`
  | `pdf`
  | `validateCenters`
  | `validateHCPs`
  | `validateProductSales`
  | `engagement`;
export type ScoreEndpoints =
  | `hcpInfluenceWeights`
  | 'hcpEngagementWeights'
  | 'hcpValueWeights'
  | 'centerValueWeights'
  | 'risingStarValueWeights'
  | 'updateCentersWithScore'
  | 'updateSingleCenterWithScore'
  | 'updateSingleHCPWithScore'
  | `updateHCPsWithScore`
  | 'weights';
export type RiseEndpoints =
  | `getCategories`
  | `createCategory`
  | `updateCategory`
  | `updateCategories`
  | `getHCPS`
  | `migrateHCPs`
  | `migrateCenters`
  | `getHCPsForCenter`
  | `getArticles`
  | `getArticlesForAuthor`
  | `getArticlesByIDs`
  | `getArticlesForCenter`
  | `removeProject`
  | `removeCategory`
  | `getCenters`
  | `getCentersByName`
  | `getCenterByName`
  | `getCentersById`
  | `getCompanies`
  | `processArticles`
  | `processArticlesSSE`
  | `processArticlesStatusEvent`
  | `processInstitutionsName`
  | `processMissingHCPsData`
  | `removeCentresDuplicates`
  | `removeHCPDuplicates`
  | `processCTData`
  | `searchAll`
  | `searchHcps`
  | `searchCenters`
  | `searchArticles`
  | `mergeHCPs`
  | `mergeCenters`
  | `updateProjectCounts`
  | `hcpBatchUpdate`
  | `centerBatchUpdate`
  | `deleteCenters`
  | `deleteHCPs`
  | `batchUpdateCenters`
  | `batchUpdateHCPs`
  | `getCTForProject`
  | `confirmOptIn`
  | `getHCPsByName`
  | `getECRProjectArticles`
  | `getECRProjectClinicalTrials`
  | `getECRProjectClinicalTrialsWithCountries`
  | `migrateArticlesDates`
  | 'updateArticlesCitations'
  | 'processArticlesBatchData'
  | 'updateProjectData'
  | 'getAutoImportProjects';

export type AuthEndpoints = 'currentUser' | 'login' | 'getByToken';
export type UsersEndpoints = 'create' | 'update' | 'getAll' | 'delete' | 'massUpdate' | 'findOne';
export type SupportDocsEndpoints = 'upload' | 'deleteDocument' | 'list' | 'isAvailable';

export type EndpointParams = {
  ncbi: {
    base: string;
    endpoint: NCBIEndpoints;
    params: string;
  };
  rise: {
    base: string;
    endpoint: RiseEndpoints;
    params: string;
  };
  auth: {
    base: string;
    endpoint: AuthEndpoints;
    params: string;
  };
  ct: {
    base: string;
    endpoint: CTEndpoints;
    params: string;
  };
  geonames: {
    base: string;
    endpoint: GeonamesEndpoints;
    params: string;
  };
  export: {
    base: string;
    endpoint: ExportEndpoints;
    params: string;
  };
  import: {
    base: string;
    endpoint: ImportEndpoints;
    params: string;
  };
  score: {
    base: string;
    endpoint: ScoreEndpoints;
    params: string;
  };
  settings: {
    base: string;
    endpoint: SettingsEndpoints;
    params: string;
  };
  report: {
    base: string;
    endpoint: ReportEndpoints;
    params: string;
  };
  mailer: {
    base: string;
    endpoint: MailerEndpoints;
    params: string;
  };
  proximity: {
    base: string;
    endpoint: ProximityEndpoints;
    params: string;
  };
  twitter: {
    base: string;
    endpoint: TwitterEndpoints;
    params: string;
  };
  users: {
    base: string;
    endpoint: UsersEndpoints;
    params: string;
  };
  support: {
    base: string;
    endpoint: SupportDocsEndpoints;
    params: string;
  };
};

export interface TBaseEndpoints {
  NAMESPACE: EndpointNamespace;
}

export interface TNCBIEndpoints extends TBaseEndpoints {
  NAMESPACE: `ncbi`;
  DATA_SIZE: NCBIEndpoints;
  METADATA: NCBIEndpoints;
  IDS: NCBIEndpoints;
  CITATIONS: NCBIEndpoints;
}

export interface TRiseEndpoints extends TBaseEndpoints {
  NAMESPACE: `rise`;
  GET_CATEGORIES: RiseEndpoints;
  CREATE_CATEGORY: RiseEndpoints;
  UPDATE_CATEGORY: RiseEndpoints;
  UPDATE_CATEGORIES: RiseEndpoints;
  REMOVE_PROJECT: RiseEndpoints;
  GET_HCPS: RiseEndpoints;
  MIGRATE_HCPS: RiseEndpoints;
  MIGRATE_CENTERS: RiseEndpoints;
  GET_ARTICLES: RiseEndpoints;
  GET_ARTICLES_BY_IDS: RiseEndpoints;
  GET_ARTICLES_FOR_AUTHOR: RiseEndpoints;
  GET_ARTICLES_FOR_CENTER: RiseEndpoints;
  REMOVE_CATEGORY: RiseEndpoints;
  GET_HCPS_FOR_QUERY: RiseEndpoints;
  GET_CENTERS: RiseEndpoints;
  GET_CENTERS_BY_NAME: RiseEndpoints;
  GET_CENTER_BY_NAME: RiseEndpoints;
  GET_CENTERS_BY_ID: RiseEndpoints;
  GET_COMPANIES: RiseEndpoints;
  PROCESS_ARTICLES: RiseEndpoints;
  PROCESS_ARTICLES_SSE: RiseEndpoints;
  PROCESS_ARTICLES_STATUS_EVENT: RiseEndpoints;
  PROCESS_INSTITUTIONS_NAME: RiseEndpoints;
  PROCESS_MISSING_HCP_DATA: RiseEndpoints;
  REMOVE_HCP_DUPLICATES: RiseEndpoints;
  REMOVE_CENTRES_DUPLICATES: RiseEndpoints;
  PROCESS_CT_DATA: RiseEndpoints;
  SEARCH_ALL: RiseEndpoints;
  SEARCH_HCPS: RiseEndpoints;
  SEARCH_CENTERS: RiseEndpoints;
  SEARCH_ARTICLES: RiseEndpoints;
  MERGE_HCPS: RiseEndpoints;
  MERGE_CENTERS: RiseEndpoints;
  UPDATE_PROJECT_COUNTS: RiseEndpoints;
  HCP_BATCH_UPDATE: RiseEndpoints;
  CENTER_BATCH_UPDATE: RiseEndpoints;
  DELETE_CENTERS: RiseEndpoints;
  DELETE_HCPS: RiseEndpoints;
  BATCH_UPDATE_CENTERS: RiseEndpoints;
  BATCH_UPDATE_HCPS: RiseEndpoints;
  GET_CT_FOR_PROJECT: RiseEndpoints;
  CONFIRM_OPT_IN: RiseEndpoints;
  GET_HCPS_BY_NAME: RiseEndpoints;
  GET_ECR_PROJECT_ARTICLES: RiseEndpoints;
  GET_ECR_PROJECT_CLINICAL_TRIALS: RiseEndpoints;
  MIGRATE_ARTICLES_DATES: RiseEndpoints;
  GET_ECR_PROJECT_CLINICAL_TRIALS_WITH_COUNTRIES: RiseEndpoints;
  UPDATE_ARTICLES_CITATIONS: RiseEndpoints;
  PROCESS_ARTICLES_BATCH_DATA: RiseEndpoints;
  UPDATE_PROJECT_DATA: RiseEndpoints;
  GET_AUTO_IMPORT_PROJECTS: RiseEndpoints;
}

export interface TAuthEndpoints extends TBaseEndpoints {
  NAMESPACE: 'auth';
  CURRENT_USER: AuthEndpoints;
  LOGIN: AuthEndpoints;
  GET_BY_TOKEN: AuthEndpoints;
}

export interface TCTEndpoints extends TBaseEndpoints {
  NAMESPACE: 'ct';
  HCP_DATA: CTEndpoints;
  HCP_LOCAL_DATA: CTEndpoints;
  HCP_LOCAL_DATA_BY_IDS: CTEndpoints;
  MIGRATE_CT_DATES: CTEndpoints;
  MIGRATE_CT_CONTENT: CTEndpoints;
  MIGRATE_CT_ALL_CONTENT: CTEndpoints;
}

export interface TGeonamesEndpoints extends TBaseEndpoints {
  NAMESPACE: 'geonames';
  CITY_DATA: GeonamesEndpoints;
}

export interface TExportEndpoints extends TBaseEndpoints {
  NAMESPACE: 'export';
  CENTERS: ExportEndpoints;
  HCPS: ExportEndpoints;
  HCPS_REPORT: ExportEndpoints;
  CENTERS_REPORT: ExportEndpoints;
  GENERATE_EXCEL_SSE: ExportEndpoints;
}

export interface TSettingsEndpoints extends TBaseEndpoints {
  NAMESPACE: 'settings';
  BASE: SettingsEndpoints;
  APP: SettingsEndpoints;
  UPDATE: SettingsEndpoints;
}

export interface TReportEndpoints extends TBaseEndpoints {
  NAMESPACE: 'report';
  HCPS: ReportEndpoints;
  CENTERS: ReportEndpoints;
}

export interface TMailerEndpoints extends TBaseEndpoints {
  NAMESPACE: 'mailer';
  OPT_IN: MailerEndpoints;
  OPT_OUT: MailerEndpoints;
}

export interface TProximityEndpoints extends TBaseEndpoints {
  NAMESPACE: 'proximity';
  GET_DATA: ProximityEndpoints;
}

export interface TTwitterEndpoints extends TBaseEndpoints {
  NAMESPACE: 'twitter';
  USER_NAME_DATA: TwitterEndpoints;
  USER_TWITTER_DATA: TwitterEndpoints;
  TWITTER_PROXIMITY: TwitterEndpoints;
}

export interface TScoreEndpoints extends TBaseEndpoints {
  NAMESPACE: 'score';
  HCP_INFLUENCE_WEIGHTS: 'hcpInfluenceWeights';
  HCP_ENGAGEMENT_WEIGHTS: 'hcpEngagementWeights';
  HCP_VALUE_WEIGHTS: 'hcpValueWeights';
  CENTER_VALUE_WEIGHTS: 'centerValueWeights';
  RISING_STAR_WEIGHTS: 'risingStarValueWeights';
  UPDATE_CENTERS_WITH_SCORE: 'updateCentersWithScore';
  UPDATE_SINGLE_CENTER_WITH_SCORE: 'updateSingleCenterWithScore';
  UPDATE_SINGLE_HCP_WITH_SCORE: `updateSingleHCPWithScore`;
  UPDATE_HCPS_WITH_SCORE: `updateHCPsWithScore`;
  WEIGHTS: 'weights';
}

export interface TImportEndpoints extends TBaseEndpoints {
  NAMESPACE: 'import';
  CENTERS: ImportEndpoints;
  HCPS: ImportEndpoints;
  PRODUCT_SALES: ImportEndpoints;
  HEADERS: ImportEndpoints;
  PDF: ImportEndpoints;
  VALIDATE_CENTERS: ImportEndpoints;
  VALIDATE_HCPS: ImportEndpoints;
  VALIDATE_PRODUCT_SALES: ImportEndpoints;
  ENGAGEMENT: ImportEndpoints;
}

export interface TUsersEndpoints extends TBaseEndpoints {
  NAMESPACE: 'users';
  CREATE: UsersEndpoints;
  UPDATE: UsersEndpoints;
  GET_ALL: UsersEndpoints;
  DELETE: UsersEndpoints;
  MASS_UPDATE: UsersEndpoints;
  FIND_ONE: UsersEndpoints;
}

export interface TSupportDocsEndpoints extends TBaseEndpoints {
  NAMESPACE: 'support';
  UPLOAD: 'upload';
  DELETE: 'deleteDocument';
  LIST: 'list';
  AVAILABLE: 'isAvailable';
}

export class Endpoints {
  public static NCBI: TNCBIEndpoints = {
    NAMESPACE: `ncbi`,
    DATA_SIZE: `dataSize`,
    METADATA: `metadata`,
    IDS: `ids`,
    CITATIONS: `citations`
  };

  public static RISE: TRiseEndpoints = {
    NAMESPACE: `rise`,
    GET_CATEGORIES: `getCategories`,
    CREATE_CATEGORY: `createCategory`,
    GET_HCPS: `getHCPS`,
    GET_ARTICLES: `getArticles`,
    UPDATE_CATEGORY: `updateCategory`,
    UPDATE_CATEGORIES: `updateCategories`,
    REMOVE_PROJECT: `removeProject`,
    REMOVE_CATEGORY: `removeCategory`,
    GET_CENTERS: `getCenters`,
    GET_CENTERS_BY_NAME: `getCentersByName`,
    GET_CENTER_BY_NAME: `getCenterByName`,
    GET_CENTERS_BY_ID: `getCentersById`,
    GET_COMPANIES: `getCompanies`,
    GET_ECR_PROJECT_ARTICLES: 'getECRProjectArticles',
    GET_ECR_PROJECT_CLINICAL_TRIALS: 'getECRProjectClinicalTrials',
    GET_HCPS_FOR_QUERY: `getHCPsForCenter`,
    PROCESS_ARTICLES: 'processArticles',
    PROCESS_ARTICLES_SSE: 'processArticlesSSE',
    PROCESS_INSTITUTIONS_NAME: `processInstitutionsName`,
    GET_ARTICLES_FOR_AUTHOR: `getArticlesForAuthor`,
    PROCESS_ARTICLES_STATUS_EVENT: `processArticlesStatusEvent`,
    PROCESS_MISSING_HCP_DATA: `processMissingHCPsData`,
    REMOVE_CENTRES_DUPLICATES: `removeCentresDuplicates`,
    REMOVE_HCP_DUPLICATES: `removeHCPDuplicates`,
    PROCESS_CT_DATA: `processCTData`,
    SEARCH_ALL: 'searchAll',
    SEARCH_HCPS: `searchHcps`,
    SEARCH_CENTERS: `searchCenters`,
    SEARCH_ARTICLES: `searchArticles`,
    MERGE_HCPS: `mergeHCPs`,
    MERGE_CENTERS: `mergeCenters`,
    UPDATE_PROJECT_COUNTS: `updateProjectCounts`,
    GET_ARTICLES_FOR_CENTER: `getArticlesForCenter`,
    HCP_BATCH_UPDATE: `hcpBatchUpdate`,
    CENTER_BATCH_UPDATE: `centerBatchUpdate`,
    MIGRATE_HCPS: `migrateHCPs`,
    MIGRATE_CENTERS: `migrateCenters`,
    DELETE_CENTERS: `deleteCenters`,
    DELETE_HCPS: `deleteHCPs`,
    GET_ARTICLES_BY_IDS: `getArticlesByIDs`,
    BATCH_UPDATE_CENTERS: `batchUpdateCenters`,
    BATCH_UPDATE_HCPS: `batchUpdateHCPs`,
    GET_CT_FOR_PROJECT: `getCTForProject`,
    CONFIRM_OPT_IN: `confirmOptIn`,
    MIGRATE_ARTICLES_DATES: `migrateArticlesDates`,
    GET_HCPS_BY_NAME: `getHCPsByName`,
    GET_ECR_PROJECT_CLINICAL_TRIALS_WITH_COUNTRIES: 'getECRProjectClinicalTrialsWithCountries',
    UPDATE_ARTICLES_CITATIONS: 'updateArticlesCitations',
    PROCESS_ARTICLES_BATCH_DATA: 'processArticlesBatchData',
    UPDATE_PROJECT_DATA: 'updateProjectData',
    GET_AUTO_IMPORT_PROJECTS: 'getAutoImportProjects'
  };

  public static AUTH: TAuthEndpoints = {
    NAMESPACE: `auth`,
    CURRENT_USER: 'currentUser',
    LOGIN: 'login',
    GET_BY_TOKEN: 'getByToken'
  };

  public static CT: TCTEndpoints = {
    NAMESPACE: `ct`,
    HCP_DATA: `hcpData`,
    HCP_LOCAL_DATA: `hcpLocalData`,
    HCP_LOCAL_DATA_BY_IDS: `hcpLocalDataByIDs`,
    MIGRATE_CT_DATES: `migrateCTDates`,
    MIGRATE_CT_CONTENT: `migrateCTContent`,
    MIGRATE_CT_ALL_CONTENT: `migrateCTAllContent`
  };

  public static GEONAMES: TGeonamesEndpoints = {
    NAMESPACE: `geonames`,
    CITY_DATA: `cityData`
  };

  public static EXPORT: TExportEndpoints = {
    NAMESPACE: `export`,
    CENTERS: `centers`,
    HCPS: `hcps`,
    GENERATE_EXCEL_SSE: `generateExcelSSE`,
    HCPS_REPORT: `hcpsReport`,
    CENTERS_REPORT: `centersReport`
  };

  public static IMPORT: TImportEndpoints = {
    NAMESPACE: `import`,
    CENTERS: `centers`,
    HCPS: `hcps`,
    PRODUCT_SALES: `centersProductsSales`,
    HEADERS: `headers`,
    PDF: `pdf`,
    VALIDATE_CENTERS: `validateCenters`,
    VALIDATE_HCPS: `validateHCPs`,
    VALIDATE_PRODUCT_SALES: `validateProductSales`,
    ENGAGEMENT: `engagement`
  };

  public static SCORE: TScoreEndpoints = {
    NAMESPACE: `score`,
    HCP_INFLUENCE_WEIGHTS: 'hcpInfluenceWeights',
    HCP_ENGAGEMENT_WEIGHTS: 'hcpEngagementWeights',
    HCP_VALUE_WEIGHTS: 'hcpValueWeights',
    CENTER_VALUE_WEIGHTS: 'centerValueWeights',
    RISING_STAR_WEIGHTS: 'risingStarValueWeights',
    UPDATE_CENTERS_WITH_SCORE: 'updateCentersWithScore',
    UPDATE_SINGLE_CENTER_WITH_SCORE: 'updateSingleCenterWithScore',
    UPDATE_SINGLE_HCP_WITH_SCORE: 'updateSingleHCPWithScore',
    UPDATE_HCPS_WITH_SCORE: `updateHCPsWithScore`,
    WEIGHTS: 'weights'
  };

  public static SETTINGS: TSettingsEndpoints = {
    NAMESPACE: `settings`,
    BASE: `base`,
    APP: `app`,
    UPDATE: `update`
  };

  public static REPORT: TReportEndpoints = {
    NAMESPACE: `report`,
    HCPS: `hcps`,
    CENTERS: `centers`
  };

  public static MAILER: TMailerEndpoints = {
    NAMESPACE: `mailer`,
    OPT_IN: `optIn`,
    OPT_OUT: `optOut`
  };

  public static PROXIMITY: TProximityEndpoints = {
    NAMESPACE: `proximity`,
    GET_DATA: `getData`
  };

  public static TWITTER: TTwitterEndpoints = {
    NAMESPACE: `twitter`,
    USER_NAME_DATA: `userNameData`,
    USER_TWITTER_DATA: `userTwitterData`,
    TWITTER_PROXIMITY: `twitterProximity`
  };

  public static USERS: TUsersEndpoints = {
    NAMESPACE: `users`,
    CREATE: `create`,
    UPDATE: `update`,
    GET_ALL: `getAll`,
    DELETE: `delete`,
    MASS_UPDATE: `massUpdate`,
    FIND_ONE: `findOne`
  };

  public static SUPPORT: TSupportDocsEndpoints = {
    NAMESPACE: `support`,
    UPLOAD: `upload`,
    DELETE: `deleteDocument`,
    LIST: `list`,
    AVAILABLE: `isAvailable`
  };

  public static BUILD_URL<T extends EndpointNamespace>(namespace: T, params: EndpointParams[T]) {
    const sendRequest = (...args: [EndpointNamespace, TURLParts]) => {
      const url = `${args[1].base}/${args[0]}/${args[1].endpoint}/${args[1].params ?? ``}`;
      return url;
    };
    return sendRequest(namespace, params);
  }
}
