import { Component } from '@angular/core';
import { TUser, UserLevel, UserStatus } from '@shared/interfaces/TUser';

@Component({
  selector: 'app-users-batch-update',
  templateUrl: './users-batch-update.component.html',
  styleUrls: ['./users-batch-update.component.scss'],
})
export class UsersBatchUpdateComponent {
  public newUserData: TUser = {
    categories: [],
    countries: [],
    email: `pch@pp.pl`,
    firstName: `Piotr`,
    lastName: `Chwirot`,
    level: UserLevel.User,
    password: `password`,
    projects: [],
    status: UserStatus.Active,
  };
}
