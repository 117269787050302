<div class="merge">
  <h2 class="mat-h2 mx-2">Merge rows</h2>
  <div class="overflow-auto merge__table-wrapper">
    <table class="mb-2">
      <thead>
        <tr>
          <th>
            <div>Base row</div>
          </th>
          <th *ngFor="let header of getColumnsHeaders()">
            <div>{{ header[1] }}</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let row of getRows(); let i = index"
          [ngClass]="isRowSelected(i) ? 'selected' : ''"
        >
          <td>
            <mat-checkbox
              color="primary"
              (change)="selectRow(i)"
              [checked]="selectedRowIndex === i"
            >
            </mat-checkbox>
          </td>
          <td
            *ngFor="let header of getColumnsHeaders()"
            (click)="selectCell(i, header[1])"
          >
            <div [ngClass]="getCellCSS(i, header[1])">
              {{ getCellValue(row, header[0]) }}
            </div>
          </td>
        </tr>
        <tr class="merged" *ngIf="mergedRow !== null">
          <td [attr.colspan]="getColumnsHeaders().length + 1">
            <h4 class="mat-h3 mt-3 ml-2">Merged row results</h4>
          </td>
        </tr>
        <tr *ngIf="mergedRow !== null" class="merged">
          <td></td>
          <td *ngFor="let header of getColumnsHeaders()">
            <div>
              {{ getCellValue(mergedRow, header[0]) }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="merge__review"></div>
  <div class="merge__actions flex justify-end mt-4">
    <button mat-flat-button color="primary" type="button" (click)="mergeRows()">
      Merge rows
    </button>
  </div>
</div>
