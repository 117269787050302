import { FieldType } from '../Types/FieldType';
import { TDataFilter } from './TProject';

export const ADD_TO_PROJECT_ID: string = `addToProject`;

export enum BatchUpdateFilterType {
  Regular,
  EmptyCenters
}

export interface TBatchUpdateFilter extends TDataFilter {
  batchType: BatchUpdateFilterType;
}

export interface TTransformation {
  filedId: string;
  value: string | number | Date | string[] | boolean;
  addToProject: boolean;
  name: string;
}

export interface TBatchUpdateParams {
  type: FieldType;
  filters: TBatchUpdateFilter[];
  transformations: TTransformation[];
  projectId: string;
  preview: boolean;
}

export interface TCenterBatchUpdateResultsItem {
  name: string;
  id: string;
}

export interface TCenterBatchUpdateResults {
  results: TCenterBatchUpdateResultsItem[];
}
