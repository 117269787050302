import {OptInStatuses, OptOutStatuses} from "../constants/consent";
import {TReportFilterProperty} from "@shared/interfaces/TProject";
import {FilterLegend} from "../app/reports-review/report-filters/report-filters.component";

export const getFilteringLegend = (filter: TReportFilterProperty): FilterLegend | undefined => {
  switch (filter) {
    case TReportFilterProperty.CONSENT_LEVEL_1:
      return {
        property: TReportFilterProperty.CONSENT_LEVEL_1,
        label: 'Consent data capture (opt out)',
        values: OptOutStatuses.map((status) => `${status.value} = ${status.label}`),
      };
    case TReportFilterProperty.CONSENT_LEVEL_2:
      return {
        property: TReportFilterProperty.CONSENT_LEVEL_2,
        label: 'Consent engagement (opt in)',
        values: OptInStatuses.map((status) => `${status.value} = ${status.label}`),
      };
    default:
      return;
  }
}
