import { Component, OnInit } from '@angular/core';
import { PagingService } from '../services/paging/PagingService';
import { SettingsService } from '../services/SettingsService';

@Component({
  selector: 'app-settings-initializator',
  templateUrl: './settings-initializator.component.html',
  styleUrls: ['./settings-initializator.component.scss'],
})
export class SettingsInitializatorComponent implements OnInit {
  constructor(
    private _pagingService: PagingService,
    private _settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this._pagingService.addIfNotExists([]);
    this._settingsService.initLocalSettings();
    this._settingsService.setSettings();
  }
}
