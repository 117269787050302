import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SharedConstants } from '@shared/Constants';
import { ARTICLE_DB_MAPPINGS } from '@shared/db-fields-mappings/ARTICLE_DB_MAPPINGS';
import { SearchArea } from '@shared/enums/SearchArea';
import { TArticle } from '@shared/interfaces/TArticle';
import { TCategory } from '@shared/interfaces/TCategory';
import { TProject } from '@shared/interfaces/TProject';
import { TSearchParams } from '@shared/interfaces/TSearchParams';
import { isNullOrEmpty } from '@shared/utils/isNullOrEmpty';
import { Subscription } from 'rxjs';
import { TDataColumnDef, buildColumnsDef } from 'src/utils/buildColumnsDef';
import { sortByDate } from 'src/utils/sort';
import { sortingDataAccessor } from 'src/utils/sortingDataAccessor';
import { CommonTableComponent } from '../common-table/common-table.component';
import {
  ICommonTableParams,
  IHandleCommonTable,
  SortType,
  TArticleTableRow,
  TTDataType,
  TTableRowType,
  TableRowType,
} from '../common-table/common-table.component.types';
import { ArticlesService } from '../services/ArticlesService';
import { PagingService } from '../services/paging/PagingService';
import { TPagingItem } from '../services/paging/TPagingItem';

@Component({
  selector: 'app-articles-review',
  templateUrl: './articles-review.component.html',
  styleUrls: ['./articles-review.component.scss'],
})
export class ArticlesReviewComponent
  implements OnInit, OnDestroy, IHandleCommonTable
{
  public dataSource: MatTableDataSource<TArticleTableRow> =
    new MatTableDataSource();
  public articles: TArticle[] = [];
  public articlesLoaded: boolean = false;
  public articlesCount: number = 0;
  public columns: TDataColumnDef<TArticle>[] = [
    ...buildColumnsDef({
      textColumns: [],
      fieldsMappings: Array.from(ARTICLE_DB_MAPPINGS.values()).filter(
        (f) => f.visibleInSummary
      ),
      columnIdsWithLink: [`title`],
    }),
  ];
  @Input()
  public selectedCategory: TCategory | undefined;
  @Input()
  public selectedProject: TProject | undefined;

  public pageEvent: PageEvent;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(CommonTableComponent) table: CommonTableComponent;

  private _subs: Subscription[] = [];
  private searchTerm: string = ``;

  constructor(
    private _articlesService: ArticlesService,
    private _pagingService: PagingService
  ) {
    this._subs.push(
      this._articlesService
        .getVisibleArticlesObservable()
        .subscribe((articles) => {
          this.articles = articles;
          this._processTableData();
        })
    );
    this._subs.push(
      this._articlesService
        .getArticlesCountObservable()
        .subscribe((articlesCount) => {
          this.articlesCount = articlesCount;
        })
    );
    this.dataSource.sortingDataAccessor = sortingDataAccessor;
  }

  public ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
  }

  public paginationCallback(e: PageEvent): void {
    if (this.selectedProject !== undefined) {
      const currentPaging: TPagingItem = structuredClone(
        this._pagingService.getPagingItem(this.selectedProject.id)
      );
      currentPaging.articles.pageIndex = e.pageIndex;
      currentPaging.articles.pageSize = e.pageSize;
      this._pagingService.updatePagingItem(
        this.selectedProject.id,
        currentPaging
      );
      this._setTableData();
    }
  }

  public getSlug(text: string): string {
    return `${text.substring(0, 100)}...`;
  }

  public ngOnInit(): void {
    this.articlesLoaded = true;
    if (this.selectedProject !== undefined) {
      this._setTableData();
    }
  }

  public applyFilter(term: string) {
    this.searchTerm = term;
    if (term.length > 3) {
      this._setTableData(this.searchTerm);
    } else if (term.length === 0) {
      this._setTableData();
      this.searchTerm = ``;
    }
  }

  public getTableParams(): ICommonTableParams<TTableRowType, TTDataType> {
    const currentPaging: TPagingItem = this._pagingService.getPagingItem(
      this.selectedProject?.id ?? ``
    );
    return {
      columns: this.columns as TDataColumnDef<TTDataType>[],
      dataCount: currentPaging.articles.total,
      dataLoaded: this.articlesLoaded,
      dataSource: this.dataSource as MatTableDataSource<TTableRowType>,
      displayedColumns: Array.from(ARTICLE_DB_MAPPINGS.values())
        .filter((f) => f.visibleInSummary)
        .map((f) => f.fieldId),
      handleNavigateToClick: (row: TTableRowType) =>
        this._handleNavigateToClick(<TArticleTableRow>row),
      pageIndex: currentPaging.articles.pageIndex,
      pageSize: currentPaging.articles.pageSize,
      pageSizeOptions: this._pagingService.pageSizeOptions,
      paginationCallback: (e) => this.paginationCallback(e),
      downloadCallback: undefined,
      importCallback: undefined,
      area: SearchArea.Articles,
      projectId: this.selectedProject?.id ?? ``,
      resize: true,
      selection: false,
      actionsAvailable: true,
      sortType: SortType.Simple,
      filterCallback: (term: string) => this.applyFilter(term),
      simpleSortHeaders: [`Title`, `Publication day`, `Authors`, `Pubmed ID`],
      defaultSortFieldId: `publication_day`,
    };
  }

  private _handleNavigateToClick(row: TArticleTableRow): void {
    if (
      this.selectedCategory !== undefined &&
      this.selectedProject !== undefined
    ) {
      window
        .open(`${SharedConstants.PUBMED_URL}${row.pmid}`, '_blank')
        ?.focus();
    }
  }

  private _setTableData(query: string = ``): void {
    if (this.selectedProject !== undefined) {
      const currentPaging: TPagingItem = this._pagingService.getPagingItem(
        this.selectedProject.id
      );
      if (
        isNullOrEmpty(query) &&
        !isNullOrEmpty(this.table?.currentQuery) &&
        this.table?.currentQuery.length > 3
      ) {
        query = this.table.currentQuery;
      }
      const params: TSearchParams<TArticle> = {
        area: SearchArea.Articles,
        paging: currentPaging.articles,
        projectId: this.selectedProject.id,
        query: query,
        results: [],
        countries: [],
      };
      this._articlesService.searchAndSetArticles(params);
    }
  }

  private _processTableData(): void {
    const tempData: TArticleTableRow[] = [];
    this.articles.forEach((article: TArticle, index: number) => {
      tempData.push({
        ...article,
        position: index,
        abstract: this.getSlug(article.abstract),
        __type: TableRowType.Article,
      });
    });
    this.articlesLoaded = true;
    this.dataSource.data = sortByDate(tempData, `publication_day`, `desc`);
  }
}
