import { Component, Input, OnInit } from '@angular/core';
import {
  buildCenterMock,
  buildCenterProjectDataMock,
  TCenter,
  TCenterProjectsData,
} from '@shared/interfaces/TCenter';
import { TTierFieldItem } from '@shared/interfaces/TProject';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { CategoriesService } from 'src/app/services/CategoriesService';

@Component({
  selector: 'app-tier-component',
  templateUrl: './tier-component.component.html',
  styleUrls: ['./tier-component.component.scss'],
})
export class TierComponentComponent implements OnInit {
  public tierList: string[] = [];

  @Input()
  public centerData: TCenter = buildCenterMock({});
  @Input()
  public projectId: string = ``;
  @Input()
  public fieldsDisabled: boolean = false;

  constructor(private _categoriesService: CategoriesService) {}

  ngOnInit(): void {}

  public getFieldDescription(field: string): string {
    return this._categoriesService.getFieldDescription(field, this.projectId);
  }

  public getTiers(fieldId: string): TTierFieldItem[] {
    const data = this._categoriesService.getTiersForField(
      this.projectId,
      fieldId,
      `center`
    );
    return data;
  }

  public getProjectData(): TCenterProjectsData {
    if (this.centerData.projectsData.length > 0) {
      const currentProjectData: TCenterProjectsData | undefined =
        this.centerData.projectsData.find(
          (it) => it.projectId === this.projectId
        );
      if (!isNullOrUndefined(currentProjectData)) {
        return currentProjectData;
      }
    }
    return buildCenterProjectDataMock({});
  }
}
