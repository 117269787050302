<div class="hcp-review has-sticky-footer">
  <mat-card>
    <mat-card-content>
      <mat-tab-group
        #tabs
        dynamicHeight
        (selectedTabChange)="tabChanged($event)"
      >
        <mat-tab label="Profile">
          <ng-template matTabContent>
            <app-hcp-profile-tab
              [studiesCount]="studiesCount"
              [hcpData]="hcpData"
              [centerData]="centerData"
              [hcpParams]="hcpParams"
              [onHCPSelectCallback]="onHCPSelectCallback.bind(this)"
              [isNewFromOtherProject]="isNewFromOtherProject"
            >
            </app-hcp-profile-tab>
          </ng-template>
        </mat-tab>
        <mat-tab label="Publications">
          <ng-template matTabContent>
            <app-publications
              [params]="hcpParams"
              [hcpData]="hcpData"
            ></app-publications>
          </ng-template>
        </mat-tab>
        <mat-tab label="Studies">
          <ng-template matTabContent>
            <app-studies [params]="hcpParams" [hcpData]="hcpData"></app-studies>
          </ng-template>
        </mat-tab>
        <mat-tab label="Network">
          <ng-template matTabContent>
            <app-proximity-score
              [params]="hcpParams"
              [hcpData]="hcpData"
            ></app-proximity-score>
          </ng-template>
        </mat-tab>
        <mat-tab label="Other">
          <ng-template matTabContent>
            <app-other [params]="hcpParams" [hcpData]="hcpData"></app-other>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
      <app-data-maintenance-footer-button
        class=""
        [buttonsData]="footerButtonsData"
      >
      </app-data-maintenance-footer-button>
    </mat-card-content>
  </mat-card>
</div>
