import { CdkDragEnd, Point } from '@angular/cdk/drag-drop';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { EventMessageStatus } from '@shared/interfaces/TEventMessage';
import { Subscription } from 'rxjs';
import { LoadingService, TLogMessage } from '../services/LoadingService';
import { SettingsService } from '../services/SettingsService';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit, OnDestroy {
  public cacheAvailable: boolean = false;
  public loadingItems: string[] = [];
  public log: TLogMessage[] = [];
  public logVisible: boolean = true;
  public isLogMinimized: boolean = false;
  public dragPosition: Point = { x: 0, y: 0 };

  private _subs: Subscription[] = [];
  constructor(
    private _zone: NgZone,
    private _loadingService: LoadingService,
    private _settingsService: SettingsService
  ) {
    this._subs.push(
      this._loadingService.getLoadingItemsObservable().subscribe((messages) => {
        this._zone.run(() => {
          this.loadingItems = messages.map((item) => item.message);
        });
      })
    );
    this._subs.push(
      this._loadingService.getLogObservable().subscribe((messages) => {
        this._zone.run(() => {
          this.log = messages;
        });
      })
    );
    this._subs.push(
      this._loadingService.getLogVisibleObservable().subscribe((isVisible) => {
        this._zone.run(() => {
          this.logVisible = isVisible;
        });
      })
    );
  }

  public logCSS(): string {
    return this.isLogMinimized ? `log--minimized` : ``;
  }

  public dragEnd($event: CdkDragEnd): void {
    this.dragPosition = $event.source.getFreeDragPosition();
  }

  public toggleMinimize(): void {
    if (this.isLogMinimized) {
      this.dragPosition = {
        x: this.dragPosition.x + 200,
        y: this.dragPosition.y + 165,
      };
    } else {
      this.dragPosition = {
        x: this.dragPosition.x - 200,
        y: this.dragPosition.y - 165,
      };
    }
    this.isLogMinimized = !this.isLogMinimized;
  }

  public hideLog(): void {
    this._loadingService.setLogVisible(false);
    this._settingsService.updateLocalSetting(`LogVisible`, false);
  }

  public loadingComponentCSS(): string {
    if (this.loadingItems.length > 0) {
      return `loading-component-wrapper--visible`;
    } else {
      return `loading-component-wrapper--hidden`;
    }
  }

  public getStatusClass(status: EventMessageStatus): string {
    switch (status) {
      case EventMessageStatus.Error:
        return `warning`;
      case EventMessageStatus.Finished:
      case EventMessageStatus.OK:
      case EventMessageStatus.Started:
        return `success`;
      case EventMessageStatus.Info:
        return `info`;
      case EventMessageStatus.External:
        return `external`;
      default:
        return `info`;
    }
  }

  public logAvailable(): boolean {
    return this.logVisible;
  }

  public ngOnDestroy(): void {
    this._subs.forEach((sub) => sub.unsubscribe());
  }

  public getRequestLoaderCSS(): string {
    return this.loadingItems.length > 0 ? `loading-info` : `hidden`;
  }

  public ngOnInit(): void {}
}
