<div class="flex gap-4">
  <mat-form-field appearance="fill" *ngIf="isNew">
    <mat-label>Add new field</mat-label>
    <mat-select required [(ngModel)]="selectedField" name="Fields">
      <mat-option *ngFor="let field of availableFields" [value]="field">
        {{ field.fieldName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="!isNew" appearance="fill">
    <mat-label>Field</mat-label>
    <input disabled matInput [value]="selectedField.fieldName" />
  </mat-form-field>

  <div>
    <mat-form-field appearance="fill">
      <mat-label>Operator</mat-label>
      <mat-select
        required
        [(ngModel)]="operator"
        name="Fields"
        [disabled]="!isNew"
      >
        <mat-option
          *ngFor="let operator of selectedField.availableOperators"
          [value]="operator"
        >
          {{ operatorToName(operator) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field appearance="fill">
      <mat-label>Score</mat-label>
      <input required matInput [(ngModel)]="scoreValue" [disabled]="!isNew" />
    </mat-form-field>
  </div>

  <div *ngIf="isNew">
    <button
      mat-flat-button
      color="accent"
      [disabled]="addNewFieldDisabled(selectedField, operator, scoreValue)"
      (click)="addNewField(selectedField, operator, scoreValue)"
    >
      Add field
    </button>
  </div>
  <div *ngIf="!isNew">
    <button
      mat-icon-button
      (click)="deleteField(selectedField)"
      aria-label="Delete"
      color="error"
    >
      <mat-icon>delete_forever</mat-icon>
    </button>
  </div>
</div>
