<h1 mat-dialog-title>Custom Region</h1>

<div mat-dialog-content>
  <form [formGroup]="customRegionForm" class="flex flex-col">
    <mat-form-field>
      <input
        matInput
        placeholder="Region name"
        type="text"
        formControlName="name"
        required
      />
    </mat-form-field>

    <app-region-select
      [regions]="regions"
      [regionsFiltered]="regionsFiltered"
      [selectedRegions]="countries"
    >
    </app-region-select>
  </form>
</div>

<div mat-dialog-actions class="flex justify-between">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button color="error" (click)="onSave()">Save</button>
</div>
