import { Status } from './enums/Status';

export class SharedConstants {
  public static MAX_ARTICLES_SIZE: number = 50;
  public static MAX_EXCEL_PROCESS_SIZE: number = 100;
  public static MAX_ARTICLES_SIZE_FOR_CITATIONS: number = 250;
  public static PUBMED_URL: string = `https://pubmed.ncbi.nlm.nih.gov/`;
  public static ALLOWED_STATUSES: Status[] = [Status.OK, Status.MERGED, Status.HAS_DUPLICATES];
  public static DEFAULT_COLUMN_WIDTH: number = 100;
  public static IDS_SPLIT: string = `,`;
  public static RETMAX: number = 9999; //NCBI max fetch
  public static FIRST_NAME_REPLACER: string = `{{first_name}}`;
  public static LAST_NAME_REPLACER: string = `{{last_name}}`;
  public static TITLE_REPLACER: string = `{{title}}`;
  public static COMPANY_NAME_REPLACER: string = `{{company}}`;
  public static CONFIRM_LINK: string = `{{link}}`;
  public static CONSENT_HASHTAG: string = `{{consent_hashtag}}`;
  public static FILES_NAME_SPLITTER: string = `-file-`;
  public static GCP_API_URL = 'https://storage.googleapis.com';
  public static TIME_PERIODS = [
    '2024',
    '2024H1',
    '2024H2',
    '2024Q1',
    '2024Q2',
    '2024Q3',
    '2024Q4',
    '2023',
    '2023H1',
    '2023H2',
    '2023Q1',
    '2023Q2',
    '2023Q3',
    '2023Q4',
    '2022',
    '2022H1',
    '2022H2',
    '2022Q1',
    '2022Q2',
    '2022Q3',
    '2022Q4',
    '2021',
    '2021H1',
    '2021H2',
    '2021Q1',
    '2021Q2',
    '2021Q3',
    '2021Q4',
    '2020',
    '2020H1',
    '2020H2',
    '2020Q1',
    '2020Q2',
    '2020Q3',
    '2020Q4'
  ];
}
