<form>
  <mat-form-field class="w-full">
    <mat-label>{{ label }}</mat-label>
    <input
      type="text"
      placeholder="{{ label }}"
      matInput
      [formControl]="myControl"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="onSelectionChange($event)"
    >
      <mat-option
        *ngFor="let option of filteredOptions | async"
        [value]="option"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
