import { DateUtils } from '@shared/utils/dateUtils';
export const sortByDate = <T>(
  data: T[],
  fieldName: keyof T,
  order: 'asc' | 'desc'
): T[] => {
  return data.sort((a: T, b: T) => {
    const dateA = DateUtils.convertDateStringToDate(
      a[fieldName] as string
    ).getTime();
    const dateB = DateUtils.convertDateStringToDate(
      b[fieldName] as string
    ).getTime();
    return order === 'asc' ? dateA - dateB : dateB - dateA;
  });
};
