<h3 class="mat-h3 p-4">Batch update categories</h3>
<mat-dialog-content>
  <div>
    <app-projects-selector [newUserData]="newUserData"></app-projects-selector>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button color="accent" mat-flat-button mat-dialog-close>Cancel</button>
  <button color="primary" mat-flat-button [mat-dialog-close]="newUserData" cdkFocusInitial>
    Update
  </button>
</mat-dialog-actions>