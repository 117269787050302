<div class="pt-4">
  <div class="px-5 mb-4">
    <h3 class="my-4 text-base">App logo</h3>
    <div class="flex gap-4">
      <img class="logo" [src]="logoURL" />
      <div class="flex items-center">
        <button
          mat-flat-button
          color="primary"
          aria-label="Import"
          matTooltip="Upload logo"
          (click)="fileInputLogo.click()"
        >
          <mat-icon class="mr-4">cloud_upload</mat-icon>
          <span class="inline-flex">Upload logo</span>
        </button>
        <input
          hidden
          (change)="uploadLogo($event)"
          #fileInputLogo
          type="file"
          id="file"
        />
      </div>
      <img class="logo" [src]="faviconURL" />
      <div class="flex items-center">
        <button
          mat-flat-button
          color="primary"
          aria-label="Import"
          matTooltip="Upload favicon"
          (click)="fileInputFavicon.click()"
        >
          <mat-icon class="mr-4">cloud_upload</mat-icon>
          <span class="inline-flex">Upload favicon</span>
        </button>
        <input
          hidden
          (change)="uploadFavicon($event)"
          #fileInputFavicon
          type="file"
          id="file"
        />
      </div>
    </div>
  </div>
  <div class="px-5 mb-4">
    <h3 class="my-4 text-base">App title</h3>
    <mat-form-field appearance="fill">
      <mat-label>title</mat-label>
      <input matInput [(ngModel)]="appSettings.pageTitle" />
    </mat-form-field>
  </div>
  <div class="px-5 mb-4">
    <h3 class="my-4 text-base">Company data</h3>
    <div class="flex gap-4">
      <mat-form-field appearance="fill">
        <mat-label>Company name</mat-label>
        <input matInput [(ngModel)]="appSettings.companyName" />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Account name</mat-label>
        <input matInput [(ngModel)]="appSettings.accountName" />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Company specific fields section name</mat-label>
        <input
          matInput
          [(ngModel)]="appSettings.companySpecificFieldsDescription"
        />
      </mat-form-field>
      <div>
        <mat-label>Always fetch articles size</mat-label>
        <mat-checkbox
          [(ngModel)]="appSettings.fetchArticlesCount"
        ></mat-checkbox>
      </div>
    </div>
  </div>

  <div class="px-5 mb-4 max-w-fit">
    <app-note
      [text]="emailTemplateDescription"
      [severity]="templateDescriptionSeverity"
    ></app-note>
  </div>
  <div class="px-5 mb-4">
    <h3 class="my-4 text-base">
      {{ appSettings.appTranslations.optOutTemplateTitle }}
    </h3>
    <mat-form-field appearance="fill">
      <mat-label
        >{{ appSettings.appTranslations.optOutTemplateTitle }} title</mat-label
      >
      <input matInput [(ngModel)]="appSettings.optOutTemplateTitle" />
    </mat-form-field>
    <div class="ngxEditor__wrapper" *ngIf="optOutEditor">
      <ngx-editor-menu [editor]="optOutEditor" [toolbar]="toolbar">
      </ngx-editor-menu>
      <ngx-editor
        [editor]="optOutEditor"
        [(ngModel)]="appSettings.optOutTemplate"
        [disabled]="false"
        [placeholder]="'Type here...'"
      ></ngx-editor>
    </div>
  </div>
  <div class="px-5 mb-4">
    <h3 class="my-4 text-base">
      {{ appSettings.appTranslations.optInTemplateTitle }}
    </h3>
    <mat-form-field appearance="fill">
      <mat-label
        >{{ appSettings.appTranslations.optInTemplateTitle }} title</mat-label
      >
      <input matInput [(ngModel)]="appSettings.optInTemplateTitle" />
    </mat-form-field>
    <div class="ngxEditor__wrapper" *ngIf="optInEditor">
      <ngx-editor-menu [editor]="optInEditor" [toolbar]="toolbar">
      </ngx-editor-menu>
      <ngx-editor
        [editor]="optInEditor"
        [(ngModel)]="appSettings.optInTemplate"
        [disabled]="false"
        [placeholder]="'Type here...'"
      ></ngx-editor>
    </div>
  </div>

  <div class="px-5 mb-4 max-w-fit">
    <app-note
      [text]="paperTemplateDescription"
      [severity]="templateDescriptionSeverity"
    ></app-note>
  </div>
  <div class="px-5 mb-4">
    <h3 class="my-4 text-base">
      {{ appSettings.appTranslations.optOutPaperTemplateTitle }}
    </h3>
    <mat-form-field appearance="fill">
      <mat-label
        >{{
          appSettings.appTranslations.optOutPaperTemplateTitle
        }}
        title</mat-label
      >
      <input matInput [(ngModel)]="appSettings.optOutPaperTemplateTitle" />
    </mat-form-field>
    <div class="ngxEditor__wrapper" *ngIf="optOutPaperEditor">
      <ngx-editor-menu [editor]="optOutPaperEditor" [toolbar]="toolbar">
      </ngx-editor-menu>
      <ngx-editor
        [editor]="optOutPaperEditor"
        [(ngModel)]="appSettings.optOutPaperTemplate"
        [disabled]="false"
        [placeholder]="'Type here...'"
      ></ngx-editor>
    </div>
  </div>
  <div class="px-5 mb-4">
    <h3 class="my-4 text-base">
      {{ appSettings.appTranslations.optInPaperTemplateTitle }}
    </h3>
    <mat-form-field appearance="fill">
      <mat-label
        >{{
          appSettings.appTranslations.optInPaperTemplateTitle
        }}
        title</mat-label
      >
      <input matInput [(ngModel)]="appSettings.optInPaperTemplateTitle" />
    </mat-form-field>
    <div class="ngxEditor__wrapper" *ngIf="optInPaperEditor">
      <ngx-editor-menu [editor]="optInPaperEditor" [toolbar]="toolbar">
      </ngx-editor-menu>
      <ngx-editor
        [editor]="optInPaperEditor"
        [(ngModel)]="appSettings.optInPaperTemplate"
        [disabled]="false"
        [placeholder]="'Type here...'"
      ></ngx-editor>
    </div>
  </div>

  <div class="flex mt-4 justify-end">
    <button mat-flat-button color="primary" (click)="updateSettings()">
      <span>Save settings</span>
    </button>
  </div>
</div>
