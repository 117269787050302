import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@shared/Endpoints';
import { TCtData } from '@shared/interfaces/TCtData';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoadingService, TLoadingItem } from './LoadingService';

@Injectable()
export class ClinicalTrialsService {
  private _clinicalTrials: Subject<TCtData[]> = new Subject<TCtData[]>();

  constructor(
    private _loadingService: LoadingService,
    private _http: HttpClient
  ) {}

  public getClinicalTrialsObservable(): Observable<TCtData[]> {
    return this._clinicalTrials.asObservable();
  }

  public resetClinicalTrials(): void {
    this._clinicalTrials.next([]);
  }

  public setClinicalTrialsByIDs(
    ids: string[],
    projectId: string,
    callback?: (res: TCtData[]) => void
  ): void {
    const loadingItem: TLoadingItem = this._loadingService.buildLoadingItem(
      `Fetching clinical trials`
    );
    this._loadingService.addLoadingItem(loadingItem);
    const url: string = Endpoints.BUILD_URL(`ct`, {
      base: environment.apiUrl,
      endpoint: 'hcpLocalDataByIDs',
      params: ``,
    });
    const response = this._http.post<TCtData[]>(url, {
      studiesIds: ids,
      projectId,
    });
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (response) => {
        this._clinicalTrials.next(response);
        if (callback) callback(response);
        this._loadingService.removeItem(loadingItem);
      },
    });
  }
}
