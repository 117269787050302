<div class="profile-details">
  <!-- <h3 class="mat-h3">Profile details</h3> -->
  <div class="flex">
    <div class="flex columns-2 gap-10 w-full">
      <div class="flex w-1/2 flex-col gap-3">
        <div class="flex flex-col gap-3">
          <mat-checkbox [labelPosition]="'before'" [(ngModel)]="hcpData.treats_adult_patients">Treats adult patients
          </mat-checkbox>
          <mat-checkbox [labelPosition]="'before'" [(ngModel)]="hcpData.treats_pediatric_patients">Treats pediatric
            patients</mat-checkbox>
          <mat-checkbox [labelPosition]="'before'" [(ngModel)]="hcpData.does_not_treat_any_patients_phd">
            Does not treat</mat-checkbox>
        </div>
        <div class="flex flex-col gap-3">
          <mat-checkbox [labelPosition]="'before'" [(ngModel)]="getProjectData().hcp_proj_interest_as_advisor">Interest
            as advisor
          </mat-checkbox>
          <mat-checkbox [labelPosition]="'before'" [(ngModel)]="getProjectData().hcp_proj_interested_in_research_study">
            Interest for research/study</mat-checkbox>
        </div>
        <div class="flex flex-col gap-3">
          <mat-checkbox [labelPosition]="'before'" [(ngModel)]="hcpData.clinical_trials_investigator">
            Clinical trial investigator</mat-checkbox>
          <mat-checkbox [labelPosition]="'before'" [(ngModel)]="hcpData.clinical_trials_principal_investigator">
            Clinical trial principal investigator</mat-checkbox>
          <mat-checkbox [labelPosition]="'before'" [(ngModel)]="hcpData.participated_in_guidelines">
            Participated in guidelines</mat-checkbox>
        </div>
      </div>
      <div class="flex w-1/2 flex-col gap-5">
        <div class="flex flex-col gap-2">
          <h4>Speaker & presenter frequency</h4>
          <app-toggle-button-wrapper [value]="hcpData.speaker_presenter_frequency"
            [onChange]="onSpeakerAndPresenterChange.bind(this)">
          </app-toggle-button-wrapper>
        </div>
        <div class="flex flex-col gap-2">
          <h4>Digital influencer</h4>
          <app-toggle-button-wrapper [value]="hcpData.digital_influencer"
            [onChange]="onDigitalInfluencerChange.bind(this)"></app-toggle-button-wrapper>
        </div>
        <div class="flex flex-col gap-2">
          <app-twitter-review [projectId]="params.projectId" [categoryId]="params.categoryId"></app-twitter-review>
        </div>
      </div>
    </div>
  </div>
</div>