import { DateUtils } from '@shared/utils/dateUtils';

import { NumbersUtils } from './numbersUtils';

export const sortingDataAccessor = <T>(item: T, property: string) => {
  const val = item[property as keyof T];
  if (DateUtils.isDateString(val as string)) {
    return DateUtils.convertDateStringToDate(val as string).getTime();
  }
  if (NumbersUtils.isNumeric(val)) {
    return val as number;
  }
  return val as string;
};
