<div class="login-wrapper">
  <mat-card class="login-form">
    <mat-card-title>Login</mat-card-title>
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="submit()">
        <mat-form-field class="w-full">
          <input type="text" matInput placeholder="Username" formControlName="username" />
        </mat-form-field>
        <mat-form-field class="w-full">
          <input type="password" matInput placeholder="Password" formControlName="password" />
        </mat-form-field>
        <p *ngIf="error" class="error">
          {{ error }}
        </p>
        <div class="flex justify-end">
          <button *ngIf="!isLoading" color="primary" type="submit" mat-flat-button>Login</button>
          <mat-spinner *ngIf="isLoading" [diameter]="30"></mat-spinner>

        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>