<div class="settings-modal-content">
  <h2 class="mat-h2 my-4">Center fields</h2>
  <div class="flex gap-4 flex-wrap justify-center">
    <div *ngFor="let centerField of centerFieldsNames">
      <mat-form-field appearance="fill">
        <mat-label>{{ centerField.fieldName }}</mat-label>
        <input matInput [(ngModel)]="centerField.filedViewValue" />
      </mat-form-field>
    </div>
  </div>
</div>
