import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { SettingsService } from '../services/SettingsService';

const { version: appVersion } = require('../../../package.json');
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  public frontendVersion: string = appVersion;
  public backendVersion: string = ``;
  public frontendEnv: `Prod` | `Local` = environment.production
    ? `Prod`
    : `Local`;
  public footerCss: string = ``;
  public isProdDB: boolean = false;
  public db: string = ``;
  public footerData = '';
  private _subs: Subscription[] = [];
  constructor(private _settingsService: SettingsService) {
    this._subs.push(
      this._settingsService.getVersionObservable().subscribe((settings) => {
        this.backendVersion = settings.version;
        this.isProdDB = settings.isProdEnv;
        this.db = settings.env;
        if (this.frontendEnv === 'Local' && this.isProdDB == true) {
          this.footerCss = 'highlight';
        }
        this.footerData = this.getFooterData();
      })
    );
  }

  public getFooterData(): string {
    return `©${new Date().getFullYear()} Spikelands | Version: ${
      this.frontendVersion
    } ${this.frontendEnv} ${this.getDBWarning()}`;
  }

  public ngOnInit(): void {
    this._settingsService.setVersion();
  }

  public ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
  }

  private getDBWarning(): string {
    return this.frontendEnv === 'Local' && this.isProdDB == true
      ? `!!! Using prod DB on local - ${this.db} !!!`
      : ``;
  }
}
