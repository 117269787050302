<div>
  <h3 class="my-4 text-base">Current user</h3>
  <div>
    <app-user [editCallback]="editUser.bind(this)" [deleteCallback]="deleteUser.bind(this)" [user]="user"
      [selectable]="false"></app-user>
  </div>
  <div class="actions my-4 flex gap-4" *ngIf="isAdminUser()">
    <button mat-flat-button color="primary" type="button" (click)="toggleSelectAll()">
      {{ getToggleAllDescription() }}
    </button>
    <button mat-flat-button color="primary" type="button" (click)="openNewUserDialog()">
      Add new user
    </button>
    <button mat-flat-button color="primary" type="button" (click)="triggerBatchUpdate()">
      Users batch update
    </button>
  </div>
  <div class="flex gap-4 flex-wrap" *ngIf="isAdminUser()">
    <app-user *ngFor="let it of allUsers" [user]="it.user" [selectable]="true" [isSelected]="it.selected"
      [editCallback]="editUser.bind(this)" [deleteCallback]="deleteUser.bind(this)"
      [selectedChange]="selectedChange.bind(this)"></app-user>
  </div>
</div>