<ng-container *ngIf="hasType()">
    <ng-container *ngIf="isTierField()">
        <mat-form-field appearance="fill" class="h-full">
            <mat-label>Value</mat-label>
            <mat-select [(value)]="filterItem.value" (change)="onInputChange(filterItem.value)">
                <mat-option *ngFor="let tier of getTiers(filterItem.property)" [value]="tier.name">{{
                    tier.name
                    }}</mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>
    <ng-container *ngIf="isRegionField()">
        <mat-form-field appearance="fill" class="h-full">
            <mat-label>Value</mat-label>
            <mat-select [(value)]="filterItem.value" (change)="onInputChange(filterItem.value)">
                <mat-option *ngFor="let region of getAvailableRegions()" [value]="region">
                    {{ region }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>
    <ng-container *ngIf="!isTierField() && !isRegionField()">
        <ng-container *ngIf="isNumberType()">
            <ng-container *ngIf="isTupleField()">
                <mat-select [(value)]="filterItem.value" (change)="onInputChange(filterItem.value)"
                    class="tuple-editor">
                    <mat-option *ngFor="let item of getTupleList()" [value]="item.value">
                        {{ item.key }}
                    </mat-option>
                </mat-select>
            </ng-container>
            <ng-container *ngIf="!isTupleField()">
                <mat-form-field class="h-full">
                    <mat-label>Value</mat-label>
                    <input type="number" matInput [(ngModel)]="filterItem.value"
                        (change)="onInputChange(filterItem.value)" />
                </mat-form-field>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="isStringType()">
            <mat-form-field class="h-full">
                <mat-label>Value</mat-label>
                <input type="text" matInput [(ngModel)]="filterItem.value" (change)="onInputChange(filterItem.value)" />
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="isBooleanType()">
            <mat-slide-toggle [(ngModel)]="filterItem.value"
                (change)="onInputChange(filterItem.value)">{{filterItem.value}}</mat-slide-toggle>
        </ng-container>
    </ng-container>
</ng-container>
<ng-container *ngIf="!hasType()">
    <ng-container *ngIf="isTagField()">
        <mat-form-field appearance="fill" class="w-full no-margin">
            <mat-select class="w-full" multiple (selectionChange)="handleTagsChangeChange($event)"
                [(ngModel)]="selectedTags">
                <mat-option *ngFor="let membership of availableTags" [value]="membership.tag">
                    {{ membership.tag }} - {{ membership.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>
    <ng-container *ngIf="!isTagField()">
        <mat-form-field class="h-full">
            <mat-label>Value</mat-label>
            <input type="text" matInput [(ngModel)]="filterItem.value" (change)="onInputChange(filterItem.value)" />
        </mat-form-field>
    </ng-container>
</ng-container>