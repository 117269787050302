import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedConstants } from '@shared/Constants';
import { Endpoints } from '@shared/Endpoints';
import {
  CleanupMode,
  TProcessMissingDataQueryParams,
} from '@shared/interfaces/TProcessMissingDataQueryParams';
import { objectParamsToString } from '@shared/utils/objectParamsToString';
import { environment } from 'src/environments/environment';
import { CategoriesService } from './CategoriesService';
import { LoadingService, TLoadingItem } from './LoadingService';
import { SSEService } from './SSEService';

export interface TProcessDataStatus {
  projectId: string;
  categoryId: string;
  newArticlesId: string[];
  existingArticlesIds: string[];
  invalidIds: string[];
  processingStarted: boolean;
  processedArticles: string[];
}

export function getDefaultStatus(): TProcessDataStatus {
  return {
    projectId: ``,
    categoryId: ``,
    newArticlesId: [],
    existingArticlesIds: [],
    processingStarted: false,
    invalidIds: [],
    processedArticles: [],
  };
}

@Injectable()
export class DataProcessorService {
  constructor(
    private _http: HttpClient,
    private _categoriesService: CategoriesService,
    private _loadingService: LoadingService,
    private _SSEService: SSEService
  ) {}

  public processMissingHCPsData(projectId: string): void {
    const loadingItem: TLoadingItem = this._loadingService.buildLoadingItem(
      `Filling missing HCP data`
    );
    this._loadingService.addLoadingItem(loadingItem);
    this._SSEService.handleProcessArticlesStatusEvent(
      void 0,
      () => {
        this._loadingService.removeItem(loadingItem);
      },
      () => {
        const queryParams: TProcessMissingDataQueryParams = {
          projectId,
          cleanupMode: CleanupMode.Full,
        };
        const url: string = Endpoints.BUILD_URL(`rise`, {
          base: environment.apiUrl,
          endpoint: 'processMissingHCPsData',
          params: objectParamsToString(queryParams),
        });
        this._http.get<void>(url).subscribe({
          error: (err) => {
            console.error(err);
            this._loadingService.onError();
          },
          next: () => {},
        });
      },
      () => {
        this._loadingService.removeItem(loadingItem);
        this._loadingService.onError();
      }
    );
  }

  public updateProjectCounts(projectId: string | string[]): void {
    const loadingItem: TLoadingItem = this._loadingService.buildLoadingItem(
      `Recalculating project`
    );
    this._loadingService.addLoadingItem(loadingItem);
    const params: string = Array.isArray(projectId)
      ? projectId.join(SharedConstants.IDS_SPLIT)
      : projectId;
    const url: string = Endpoints.BUILD_URL(`rise`, {
      base: environment.apiUrl,
      endpoint: 'updateProjectCounts',
      params: `?projectId=${params}&categoryId=`,
    });
    this._http.get<void>(url).subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: () => {
        this._categoriesService.setCategories();
        this._loadingService.removeItem(loadingItem);
      },
    });
  }

  public removeHCPsDuplicates(categoryId: string, projectId: string): void {
    const loadingItem: TLoadingItem = this._loadingService.buildLoadingItem(
      `Removing HCP duplicates`
    );
    this._loadingService.addLoadingItem(loadingItem);
    this._SSEService.handleProcessArticlesStatusEvent(
      () => {
        this._categoriesService.setCategories();
      },
      () => {
        this._loadingService.removeItem(loadingItem);
      },
      () => {
        const url: string = Endpoints.BUILD_URL(`rise`, {
          base: environment.apiUrl,
          endpoint: 'removeHCPDuplicates',
          params: `?categoryId=${categoryId}&projectId=${projectId}`,
        });
        this._http.get<void>(url).subscribe({
          error: (err) => {
            console.error(err);
            this._loadingService.onError();
          },
          next: (res) => {
            console.log(res);
          },
        });
      },
      () => {
        this._loadingService.removeItem(loadingItem);
        this._loadingService.onError();
      }
    );
  }

  public removeCentresDuplicates(categoryId: string, projectId: string): void {
    const loadingItem: TLoadingItem = this._loadingService.buildLoadingItem(
      `Removing Center duplicates`
    );
    this._loadingService.addLoadingItem(loadingItem);
    this._SSEService.handleProcessArticlesStatusEvent(
      () => {
        this._categoriesService.setCategories();
      },
      () => {
        this._loadingService.removeItem(loadingItem);
      },
      () => {
        const url: string = Endpoints.BUILD_URL(`rise`, {
          base: environment.apiUrl,
          endpoint: 'removeCentresDuplicates',
          params: `?categoryId=${categoryId}&projectId=${projectId}`,
        });
        this._http.get<void>(url).subscribe({
          error: (err) => {
            console.error(err);
            this._loadingService.onError();
          },
          next: () => {},
        });
      },
      () => {
        this._loadingService.removeItem(loadingItem);
        this._loadingService.onError();
      }
    );
  }

  public processCTData(categoryId: string, projectId: string): void {
    const loadingItem: TLoadingItem = this._loadingService.buildLoadingItem(
      `Processing clinical trials data`
    );
    this._loadingService.addLoadingItem(loadingItem);
    this._SSEService.handleProcessArticlesStatusEvent(
      void 0,
      () => {
        this._loadingService.removeItem(loadingItem);
      },
      () => {
        const url: string = Endpoints.BUILD_URL(`rise`, {
          base: environment.apiUrl,
          endpoint: 'processCTData',
          params: `?categoryId=${categoryId}&projectId=${projectId}`,
        });
        this._http.get<void>(url).subscribe({
          error: (err) => {
            console.error(err);
            this._loadingService.onError();
          },
          next: () => {},
        });
      },
      () => {
        this._loadingService.removeItem(loadingItem);
        this._loadingService.onError();
      }
    );
  }

  public processData(categoryId: string, projectId: string): void {
    const loadingItem: TLoadingItem = this._loadingService.buildLoadingItem(
      `Start processing articles`
    );
    this._loadingService.addLoadingItem(loadingItem);
    this._SSEService.handleProcessArticlesStatusEvent(
      () => {
        this._categoriesService.setCategories();
      },
      () => {
        this._loadingService.removeItem(loadingItem);
      },
      () => {
        const url: string = Endpoints.BUILD_URL('rise', {
          base: environment.apiUrl,
          endpoint: 'processArticles',
          params: `?categoryId=${categoryId}&projectId=${projectId}`,
        });
        this._http.get<{ response: string }>(url).subscribe({
          error: (err) => {
            console.error(err);
            this._loadingService.onError();
          },
          next: (res) => {
            console.log(res);
          },
        });
      },
      () => {
        this._loadingService.removeItem(loadingItem);
        this._loadingService.onError();
      }
    );
  }
}
