import { Component, Input } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { TEngagementActual } from '@shared/interfaces/THCP';
import { TProject, TTagItem } from '@shared/interfaces/TProject';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { newGuid } from '@shared/utils/newGuild';
import { Subscription } from 'rxjs';
import {
  TComponentType,
  THCPParams,
} from '../../../../interfaces/TComponentParams';
import { CategoriesService } from '../../../../services/CategoriesService';

@Component({
  selector: '[engagement-tracking-tr]',
  templateUrl: './engagement-tracking-item.component.html',
  styleUrls: ['./engagement-tracking-item.component.scss'],
})
export class EngagementTrackingItemComponent {
  @Input()
  public engagementTrackingItem: TEngagementActual = {
    engagement_actual_activity: `Activity`,
    engagement_actual_date: new Date(),
    engagement_actual_point_person: `Person`,
    id: newGuid(),
    tags: [],
  };
  @Input()
  public onDateChangeCallback: () => void = () => void 0;
  @Input()
  public params: THCPParams = {
    hcpId: ``,
    categoryId: ``,
    projectId: ``,
    __type: TComponentType.hcp,
  };
  @Input()
  public itemProjectId: string = ``;
  @Input()
  public project: string = `PN`;
  @Input()
  public deleteCallback: (item: TEngagementActual) => void = (
    item: TEngagementActual
  ) => console.log(item);
  public selectedItems: string[] = [];
  private _subs: Subscription[] = [];
  private _engagementTagsFieldId: string = `engagementTags`;

  constructor(private _categoriesService: CategoriesService) {}

  public ngOnInit(): void {
    const selectedTags: TTagItem[] = this.engagementTrackingItem?.tags ?? [];
    if (selectedTags.length > 0) {
      this.selectedItems = selectedTags.map((it) => it.tag);
    }
  }

  public getAvailableTags(): TTagItem[] {
    let availableTags: TTagItem[] = [];
    const project: TProject | undefined = this._categoriesService.getProject(
      this.itemProjectId
    );

    if (!isNullOrUndefined(project)) {
      availableTags =
        project.hcpTagsDefinitions.find(
          (def) => def.connEctedFieldId === this._engagementTagsFieldId
        )?.tags ?? [];
    }
    return availableTags;
  }

  public ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
  }

  public handleTagsChangeChange(event: MatSelectChange): void {
    this.engagementTrackingItem.tags = this.getAvailableTags().filter((t) =>
      (<string[]>event.value).includes(t.tag)
    );
  }

  public onDateChange(event: Event): void {
    this.engagementTrackingItem.engagement_actual_date = new Date(
      (<HTMLInputElement>event.target).value
    );
    this.onDateChangeCallback();
  }
}
