import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CENTER_DB_MAPPINGS } from '@shared/db-fields-mappings/CENTER_DB_MAPPINGS';
import { HCP_DB_MAPPINGS } from '@shared/db-fields-mappings/HCP_DB_MAPPINGS';
import { PRODUCT_USE_MAPPINGS } from '@shared/db-fields-mappings/PRODUCT_USE_MAPPINGS';
import { TDBFieldMappingItem } from '@shared/interfaces/TDBFieldMappingItem';
import {
  TProductUseScoreItem,
  TProject,
  TScoreItem,
  operatorToName,
} from '@shared/interfaces/TProject';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { Subscription } from 'rxjs';
import { ScoreArea } from '../project-review/project-settings/Constants';
import { CategoriesService } from '../services/CategoriesService';

@Component({
  selector: 'app-score-view',
  templateUrl: './score-view.component.html',
  styleUrls: ['./score-view.component.scss'],
})
export class ScoreViewComponent implements OnInit, OnDestroy {
  @Input() public score: number = 0;
  @Input() public projectId: string = ``;
  @Input() public title: string = `Score`;
  @Input() public area: ScoreArea | undefined = undefined;
  public scoresUsedInCalculations: string = ``;

  private _subs: Subscription[] = [];

  constructor(private _categoriesService: CategoriesService) {}

  public ngOnInit(): void {
    this._categoriesService.getCategoriesObservable().subscribe(() => {
      const project: TProject | undefined = this._categoriesService.getProject(
        this.projectId
      );
      if (!isNullOrUndefined(project)) {
        if (!isNullOrUndefined(this.area) && this.area !== `hcpProductUse`) {
          this.scoresUsedInCalculations = this._buildScoreDescription(
            project.scores[this.area]
          );
        }
        if (this.area === `hcpProductUse`) {
          this.scoresUsedInCalculations = this._buildProductScoreDescription(
            project.scores.hcpProductUse
          );
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  public formatScore(score: number): number {
    return Math.round(score * 100) / 100;
  }

  private _buildScoreDescription(items: TScoreItem[]): string {
    const scoreItemsDescriptions: string[] = (items ?? []).map((it) => {
      return this._getScoreItemDescription(it);
    });
    if (scoreItemsDescriptions.length === 0) {
      return `No fields defined for this score`;
    }
    scoreItemsDescriptions.unshift(`Fields used in calculations`);
    return scoreItemsDescriptions.join(`\n`);
  }

  private _getScoreItemDescription(scoreItem: TScoreItem): string {
    const mapping: Map<string, TDBFieldMappingItem> = new Map([
      ...HCP_DB_MAPPINGS,
      ...CENTER_DB_MAPPINGS,
      ...PRODUCT_USE_MAPPINGS,
    ]);
    const item: TDBFieldMappingItem | undefined = mapping.get(
      scoreItem.fieldId
    );
    if (!isNullOrUndefined(item)) {
      if (item.tierField === true) {
        const fieldName = this._categoriesService.getFieldDescription(
          item.fieldId,
          this.projectId
        );

        return this.descriptionFormatter(fieldName, scoreItem);
      } else {
        return this.descriptionFormatter(item.name, scoreItem);
      }
    }
    return `N/A`;
  }

  private _buildProductScoreDescription(items: TProductUseScoreItem[]): string {
    const scoreItemsDescriptions: string[] = (items ?? []).map((it) => {
      return this._getProductScoreItemDescription(it);
    });
    if (scoreItemsDescriptions.length === 0) {
      return `No product defined for this score`;
    }
    scoreItemsDescriptions.unshift(`Products used in calculations`);
    return scoreItemsDescriptions.join(`\n`);
  }

  private _getProductScoreItemDescription(
    scoreItem: TProductUseScoreItem
  ): string {
    if (
      !isNullOrUndefined(scoreItem.product) &&
      !isNullOrUndefined(scoreItem.timePeriod)
    ) {
      return `[${scoreItem.product} - ${
        scoreItem.timePeriod
      }] [${operatorToName(scoreItem.operator)}] [${scoreItem.score}]`;
    }
    return `N/A`;
  }

  private descriptionFormatter(name: string, scoreItem: TScoreItem): string {
    return `[${name}] [${operatorToName(scoreItem.operator)}] [${
      scoreItem.score
    }]`;
  }
}
