import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Input } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-chips-wrapper',
  templateUrl: './chips-wrapper.component.html',
  styleUrls: ['./chips-wrapper.component.scss'],
})
export class ChipsWrapperComponent {
  public addOnBlur = true;
  public readonly separatorKeysCodes = [ENTER, COMMA] as const;

  @Input()
  public items: string[] = [];
  @Input()
  public onChipChange: (items: string[]) => void = () => void 0;
  @Input()
  public placeholder: string = ``;

  constructor() {}

  public add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.items.push(value);
    }
    event.chipInput!.clear();
    this.onChipChange(this.items);
  }

  public remove(specialty: string): void {
    const index = this.items.indexOf(specialty);
    if (index >= 0) {
      this.items.splice(index, 1);
      this.onChipChange(this.items);
    }
  }
}
