import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoadingService, TLoadingItem } from '../services/LoadingService';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  @Input() error: string | null;
  public form: FormGroup = new FormGroup({
    username: new FormControl(``),
    password: new FormControl(``),
  });
  public isLoading: boolean = false;
  constructor(
    private _authService: AuthService,
    private _loadingService: LoadingService
  ) {}

  public ngOnInit(): void {}

  public submit(): void {
    if (this.form.valid) {
      this.isLoading = true;
      const username: string = this.form.get(`username`)?.value ?? ``;
      const password: string = this.form.get(`password`)?.value ?? ``;
      const loadingItem: TLoadingItem =
        this._loadingService.buildLoadingItem(`Loading, please wait`);
      this._loadingService.addLoadingItem(loadingItem);
      this._authService.login(
        username,
        password,
        () => {
          this._loadingService.removeItem(loadingItem);
        },
        () => {
          this._loadingService.triggerErrorMessage(
            `Invalid username or password`,
            5000
          );
          this._loadingService.removeItem(loadingItem);
          this.isLoading = false;
        }
      );
    }
  }
}
