import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@shared/Endpoints';
import {
  DEFAULT_APP_SETTINGS,
  TSettings,
  TSupportDoc,
} from '@shared/interfaces/TSettings';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { LoadingService, TLoadingItem } from './LoadingService';

@Injectable({
  providedIn: 'root',
})
export class SupportDocumentsService {
  public appSettings: TSettings = structuredClone(DEFAULT_APP_SETTINGS);
  private _supportDocsUrls: BehaviorSubject<string[]> = new BehaviorSubject<
    string[]
  >([]);
  private _pageAvailable: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(
    private _http: HttpClient,
    private _loadingService: LoadingService
  ) {}

  public getSupportDocsUrlsObservable(): Observable<string[]> {
    return this._supportDocsUrls.asObservable();
  }

  public getPageAvailableObservable(): Observable<boolean> {
    return this._pageAvailable.asObservable();
  }

  getIsAvailable(): void {
    const url: string = Endpoints.BUILD_URL('support', {
      base: environment.apiUrl,
      endpoint: 'isAvailable',
      params: ``,
    });
    this._http.get<{ available: boolean }>(url).subscribe({
      error: (err) => {
        console.error(err);
        this._pageAvailable.next(false);
      },
      next: (result) => {
        this._pageAvailable.next(result.available);
      },
    });
  }

  uploadFile(file: File): Observable<any> {
    const url: string = Endpoints.BUILD_URL('support', {
      base: environment.apiUrl,
      endpoint: 'upload',
      params: ``,
    });

    const formData = new FormData();
    formData.append('file', file);

    return this._http.post(url, formData);
  }

  async getFiles(): Promise<void> {
    const url: string = Endpoints.BUILD_URL('support', {
      base: environment.apiUrl,
      endpoint: 'list',
      params: ``,
    });
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Fetching documents`);
    this._loadingService.addLoadingItem(loadingItem);

    this._http
      .get<
        {
          fileName: TSupportDoc['fileName'];
          fileUrl: TSupportDoc['fileUrl'];
        }[]
      >(url)
      .subscribe({
        error: (err) => {
          console.error(err);
          this._loadingService.removeItem(loadingItem);
        },
        next: (results) => {
          this._supportDocsUrls.next(results.map((result) => result.fileUrl));
          this._loadingService.removeItem(loadingItem);
        },
      });
  }

  deleteFile(fileUrl: string): Observable<any> {
    const fileName: string = fileUrl.split('/rise-files/')[1];

    const url: string = Endpoints.BUILD_URL('support', {
      base: environment.apiUrl,
      endpoint: 'deleteDocument',
      params: ``,
    });
    return this._http.delete(`${url}${fileName}`);
  }
}
