<div class="loading-component-wrapper {{ loadingComponentCSS() }}">
  <div class="loading-component">
    <mat-progress-bar color="primary" mode="indeterminate" *ngIf="loadingItems.length > 0"
      class="mb-2"></mat-progress-bar>
    <div>
      <p *ngFor="let item of loadingItems">{{ item }}</p>
      <!-- <app-time-counter #child></app-time-counter> -->
    </div>
  </div>
</div>
<div *ngIf="logAvailable()" class="log mt-2" cdkDrag [ngClass]="logCSS()" [cdkDragFreeDragPosition]="dragPosition"
  (cdkDragEnded)="dragEnd($event)">
  <p class="mb-2 log__header">
    <span class="log__header__name" cdkDragHandle>Log</span>
    <span class="flex">
      <span class="log__header__close" (click)="toggleMinimize()">
        <mat-icon *ngIf="!isLogMinimized">minimize</mat-icon>
      </span>
      <span class="log__header__close" (click)="toggleMinimize()">
        <mat-icon *ngIf="isLogMinimized">maximize</mat-icon>
      </span>
      <span class="log__header__close">
        <mat-icon (click)="hideLog()">close</mat-icon>
      </span>
    </span>
  </p>
  <div class="log__content">
    <p *ngFor="let logItem of log" class="log__item">
      <span class="log__item__date">{{ logItem.date.toLocaleString() }}</span>
      <span class="log__item__message {{ getStatusClass(logItem.status) }}">{{
        logItem.message
        }}</span>
    </p>
  </div>
</div>