import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pdf-viewer-modal',
  templateUrl: './pdf-viewer-modal.component.html',
  styleUrls: ['./pdf-viewer-modal.component.scss'],
})
export class PdfViewerModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { name: string; url: string }
  ) {}

  downloadPdf() {
    window.open(this.data.url, '_blank');
  }
}
