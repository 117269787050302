import { Component, Input } from '@angular/core';
import { TUser, UserLevel } from '@shared/interfaces/TUser';
import { isNullOrEmpty } from '@shared/utils/isNullOrEmpty';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';

export function getAvatar(user: Partial<TUser> | undefined): string {
  let firstNameLetter: string = ``;
  let lastNameLetter: string = ``;
  if (
    isNullOrUndefined(user) ||
    (isNullOrEmpty(user.firstName) && isNullOrEmpty(user.lastName))
  ) {
    return `N/A`;
  } else {
    if (!isNullOrUndefined(user.firstName) && !isNullOrEmpty(user.firstName)) {
      firstNameLetter = user.firstName;
    }
    if (!isNullOrUndefined(user.lastName) && !isNullOrEmpty(user.lastName)) {
      lastNameLetter = user.lastName;
    }
  }
  return `${firstNameLetter[0]}${lastNameLetter[0]}`;
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent {
  @Input()
  public user: Partial<TUser> | undefined = undefined;
  @Input()
  public selectable: boolean = true;
  @Input()
  public isSelected: boolean = true;
  @Input()
  public deleteCallback: (user: string) => void = () =>
    console.log(`delete callback`);
  @Input()
  public editCallback: (user: string) => void = () =>
    console.log(`edit callback`);
  @Input()
  public selectedChange: (user: string) => void = () =>
    console.log(`selectedChange callback`);

  public getAvatar(): string {
    return getAvatar(this.user);
  }

  public getRole(): string {
    if (!isNullOrUndefined(this.user) && !isNullOrUndefined(this.user.level)) {
      switch (this.user.level) {
        case UserLevel.Admin:
          return `Admin`;
        case UserLevel.User:
          return `User`;
      }
    }
    return ``;
  }
}
