<div class="inline-flex justify-end items-center">
  <button
    *ngIf="downloadAvailable()"
    mat-icon-button
    aria-label="Download"
    matTooltip="Download table data"
    (click)="downloadCallback()"
  >
    <mat-icon>cloud_download</mat-icon>
  </button>
  <button
    *ngIf="importAvailable()"
    mat-icon-button
    aria-label="Import"
    matTooltip="Import table data from excel"
    (click)="fileInput.click()"
  >
    <mat-icon>cloud_upload</mat-icon>
  </button>
  <input
    hidden
    (change)="importCallback($event)"
    #fileInput
    type="file"
    id="file"
  />
</div>
