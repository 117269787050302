<div class="settings-modal-content batch-update">
  <h2 class="mat-h2">Batch update</h2>
  <mat-tab-group mat-stretch-tabs class="">
    <mat-tab label="Centers">
      <div class="mt-4">
        <h4 class="mat-h4">Center filters</h4>
        <div class="flex gap-4">
          <mat-form-field appearance="fill" *ngIf="availableCentersFilters.length > 0">
            <mat-label>Add new Center filter</mat-label>
            <mat-select required name="Fields">
              <mat-option *ngFor="let field of availableCentersFilters" [value]="field.value"
                (click)="addNewFilter(field.value)">
                {{ field.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-flat-button color="primary" type="button" class="mt-4"
            [disabled]="addEmptyCentersFilterAvailable()" (click)="addEmptyCentersFilter()">
            Select empty centers
          </button>
        </div>
        <div *ngIf="filters.length > 0">
          <ng-container *ngFor="let item of filters">
            <ng-container *ngIf="!isEmptyCentersFilter(item)">
              <app-filter-item [filterItem]="item" [fieldName]="getCenterFieldName(item.property)"
                [deleteFilter]="deleteFilterItem.bind(this)"></app-filter-item>
            </ng-container>
            <div *ngIf="isEmptyCentersFilter(item)" class="flex gap-4">
              <mat-form-field>
                <mat-label>Filter</mat-label>
                <input disabled matInput value="Centers without HCPs" />
              </mat-form-field>
              <button mat-icon-button (click)="deleteEmptyCenterFilter()" aria-label="Delete" color="error">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>
          </ng-container>
        </div>
        <h4 class="mat-h4">Transformations</h4>
        <div>
          <mat-form-field appearance="fill" *ngIf="availableCentersTransformationFields.length > 0">
            <mat-label>Add center field transformation</mat-label>
            <mat-select required name="Fields">
              <mat-option *ngFor="let field of availableCentersTransformationFields" [value]="field.value"
                (click)="addNewTransformation(field.value)">
                {{ field.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <ng-container *ngFor="let item of transformations">
            <app-transformation-item [item]="item" [projectId]="data.selectedProject?.id ?? ''" [fieldType]="fieldType"
              [deleteCallback]="deleteCallback.bind(this)"></app-transformation-item>
          </ng-container>
        </div>
        <div class="flex gap-4 mb-2 justify-end">
          <button mat-flat-button color="primary" type="button" class="mt-4" (click)="batchUpdate(true)">
            Update preview
          </button>
          <button mat-flat-button color="primary" type="button" class="mt-4" (click)="batchUpdate(false)">
            Update
          </button>
        </div>
        <div *ngIf="previewVisible">
          <h4 class="mat-h4">Affected items</h4>
          <div class="overflow-auto max-h-32">
            <div *ngFor="let item of batchUpdateResults.results">
              <label>{{item.name}}</label>
            </div>
          </div>
          <app-note *ngIf="batchUpdateResults.results.length === 0" [text]="'No results'"></app-note>
        </div>
      </div>
    </mat-tab>
    <mat-tab disabled label="HCPs"> Content 2 </mat-tab>
  </mat-tab-group>
</div>