import { TDBFieldMappingItem } from '@shared/interfaces/TDBFieldMappingItem';
import { DateUtils } from '@shared/utils/dateUtils';
import { NumbersUtils } from './numbersUtils';

export const ACTIONS_COLUMN_ID: string = `actions`;
export const ACTIONS_COLUMN_WIDTH: number = 70;
export const ACTIONS_ICON_WIDTH: number = 30;
export interface TDataColumnDef<T> {
  columnDef: keyof T | `actions`;
  header: string;
  cell: (element: T) => string;
  CSS: string;
  isDataCell: boolean;
  sortable: boolean;
  width: number;
  minWidth: number;
  linkContent: boolean;
}

export interface TBuildColumnParams {
  textColumns: string[];
  columnIdsWithLink?: string[];
  fieldsMappings: TDBFieldMappingItem[];
}

function cellFormatter<T>(element: T, columnId: keyof T): string {
  const item: T[keyof T] = element[columnId];
  if (Array.isArray(item)) {
    return item.join(`, `);
  }
  if (((element[columnId] as string) ?? ``).length === 0) {
    return ``;
  }
  if (
    columnId === `priority_timestamp` &&
    DateUtils.isValidIsoDate(element[columnId] as string)
  ) {
    const isoDate = new Date(element[columnId] as string);
    const currentDate = new Date();
    const differenceInDays = DateUtils.differenceBetweenDatesInDays(
      isoDate,
      currentDate
    );

    return `${differenceInDays} days`;
  }
  if (NumbersUtils.isNumeric(element[columnId])) {
    const numberValue: number = Number(element[columnId]);
    return `${NumbersUtils.roundNumber(numberValue)}`;
  }
  if (DateUtils.isValidIsoDate(element[columnId] as string)) {
    return DateUtils.convertIsoDateStringToDate(element[columnId] as string);
  }
  if (typeof element[columnId] === `boolean`) {
    return element[columnId] ? `Yes` : `No`;
  }
  return `${element[columnId] ?? ``}`;
}

export function buildColumnsDef<T>(
  params: TBuildColumnParams
): TDataColumnDef<T>[] {
  const columns: TDataColumnDef<T>[] = Array.from(params.fieldsMappings).map(
    (v: TDBFieldMappingItem) => {
      const columnId: keyof T | `actions` = v.fieldId as keyof T;
      const columnName: string = v.name;
      return buildColumnDef(columnId, columnName, params, v);
    }
  );
  return columns;
}

export function buildColumnDef<T>(
  columnId: keyof T | `actions`,
  columnName: string,
  params: TBuildColumnParams,
  v: TDBFieldMappingItem
) {
  return {
    cell: (element: T) => cellFormatter(element, columnId as keyof T),
    columnDef: columnId,
    header: columnName,
    CSS: params.textColumns.includes(columnId.toString()) ? `cell-text` : ``,
    isDataCell: columnId !== ACTIONS_COLUMN_ID,
    sortable: v.isSortable,
    width: v.columnWidth,
    minWidth: v.columnWidth,
    linkContent:
      params.columnIdsWithLink?.includes(columnId.toString()) ?? false,
  };
}
