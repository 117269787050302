<div class="settings-modal-content">
  <h2 class="mat-h2 mt-2">Tiers</h2>
  <div>
    <h3 class="mat-h3 my-4">Add new Tier</h3>
    <app-tier-item
      [isNew]="true"
      [selectedTierItems]="tiers"
      [tierItem]="newTierItem"
      [addNewTier]="addNewTier.bind(this)"
    >
    </app-tier-item>
  </div>
  <div>
    <div class="flex relative">
      <h3 class="mat-h3 my-4">Defined tiers</h3>
      <button
        class="refresh-totals"
        mat-icon-button
        color="primary"
        type="button"
        (click)="recalculateTotals()"
        matTooltip="Refresh totals (use if score index recalculation is not needed)"
      >
        <mat-icon color="primary">refresh</mat-icon>
      </button>
    </div>
    <div cdkDropList (cdkDropListDropped)="onDrop($event)">
      <app-tier-item
        *ngFor="let tier of tiers"
        [deleteTier]="deleteTier.bind(this)"
        [tierItem]="tier"
        [addNewTier]="addNewTier.bind(this)"
        cdkDrag
      >
      </app-tier-item>
    </div>
  </div>
</div>
