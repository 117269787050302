<div class="settings-modal-content">
  <h2 class="my-4 mat-h2">Custom metrics</h2>
  <div>
    <h3 class="mat-h4">Select metrics</h3>
    <mat-form-field appearance="fill">
      <mat-label>Add new custom metric</mat-label>
      <mat-select required name="Fields">
        <mat-option
          *ngFor="let field of data.projectCustomMetrics"
          [value]="field.tag"
          (click)="addNewFilter(field.tag)"
        >
          {{ field.tag }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="data.filters.length > 0">
    <div class="flex gap-4" *ngFor="let filterItem of data.filters">
      <div class="flex gap-4 w-full" *ngIf="filterItem.metric">
        <mat-form-field>
          <mat-label>Field</mat-label>
          <input disabled matInput [value]="filterItem.property" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Property</mat-label>
          <mat-select required name="option" [(ngModel)]="filterItem.metric">
            <mat-option
              *ngFor="let option of customMetricOptions"
              [value]="option.value"
            >
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Operator</mat-label>
          <mat-select required name="Fields" [(ngModel)]="filterItem.operator">
            <mat-option
              *ngFor="let operator of availableOperators"
              [value]="operator.filter"
            >
              {{ operator.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="filterItem.metric === 'value'">
          <mat-label>Value</mat-label>
          <input
            required
            type="number"
            matInput
            [(ngModel)]="filterItem.value"
          />
        </mat-form-field>
        <mat-form-field *ngIf="filterItem.metric === 'year'">
          <mat-label>Year</mat-label>
          <mat-select required name="option" [(ngModel)]="filterItem.value">
            <mat-option *ngFor="let option of yearOptions" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button
          mat-icon-button
          (click)="deleteFilter(filterItem)"
          aria-label="Delete"
          color="error"
        >
          <mat-icon>delete_forever</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
