import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TCategory } from '@shared/interfaces/TCategory';
import {
  DynamicNumberProjectsFieldItem,
  DynamicStringProjectsFieldItem,
  TProject,
  getDefaultProject,
} from '@shared/interfaces/TProject';
import { newGuid } from '@shared/utils/newGuild';
import { Subscription } from 'rxjs';
import { ROUTES_PATHS } from '../app-routing.module';
import { TProjectReviewParams } from '../project-review/project-review.component';
import { ArticlesService } from '../services/ArticlesService';
import { CategoriesService } from '../services/CategoriesService';
import { KeywordValidator, indicationDateValidator } from './new-project.utils';

interface TNewProjectForm {
  indicationName: AbstractControl<string | null>;
  selectedCategory: AbstractControl<string | null>;
  indicationDescription: AbstractControl<string | null>;
  indicationMinYear: AbstractControl<string | null>;
  pinToDashboard: AbstractControl<boolean | null>;
  indicationKeywords: AbstractControl<string | null>;
}

@Component({
  selector: 'new-category-dialog',
  templateUrl: './new-category-dialog.html',
})
export class NewCategoryDialog {
  @Input()
  public categoryName: string = ``;
  constructor(public dialogRef: MatDialogRef<NewCategoryDialog>) {}
}

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss'],
})
export class NewProjectComponent implements OnInit, OnDestroy {
  public searchResultsVisible: boolean = false;
  public numberOfArticles: number = 0;
  public categories: TCategory[] = [];

  public newProjectForm: FormGroup<TNewProjectForm> =
    new FormGroup<TNewProjectForm>({
      indicationName: new FormControl(`Test`),
      selectedCategory: new FormControl(),
      indicationDescription: new FormControl(`Test`),
      indicationMinYear: new FormControl(`2022`),
      pinToDashboard: new FormControl(true),
      indicationKeywords: new FormControl(`xxy 47 syndrome`, [
        Validators.required,
        indicationDateValidator(),
        KeywordValidator.keywordsFormatValidator(),
      ]),
    });

  private _subs: Subscription[] = [];

  constructor(
    public newCategoryDialog: MatDialog,
    private _router: Router,
    private _route: ActivatedRoute,
    private _categoriesService: CategoriesService,
    private _articlesService: ArticlesService,
    private _location: Location
  ) {
    //TODO add unsubscribe
    this._subs.push(
      this._categoriesService
        .getCategoriesObservable()
        .subscribe((categories) => (this.categories = categories.slice()))
    );
    this._articlesService
      .getSearchedArticlesObservable()
      .subscribe((articlesCount) => (this.numberOfArticles = articlesCount));
  }

  public openNewCategoryDialog(
    enterAnimationDuration: string,
    exitAnimationDuration: string
  ): void {
    let dialogRef = this.newCategoryDialog.open(NewCategoryDialog, {
      width: `260px`,
      enterAnimationDuration,
      exitAnimationDuration,
    });
    dialogRef.afterClosed().subscribe((result) => {
      const categoryId: string = newGuid();
      this.categories.push({
        categoryId: categoryId,
        name: result,
        projects: [],
      });
      this.newProjectForm.value.selectedCategory = categoryId;
    });
  }

  public async ngOnInit(): Promise<void> {
    const currentCategories = this._categoriesService.getCategories();
    if (currentCategories.length === 0) {
      //await this._categoriesService.setSimpleCategories();
    }
  }

  public ngOnDestroy(): void {
    this._subs.forEach((sub) => sub.unsubscribe());
  }

  public handleSearch(): void {
    if (this.newProjectForm.valid) {
      this.searchResultsVisible = true;
      const keywords: string =
        this.newProjectForm.value.indicationKeywords ?? ``;
      const startYear: string =
        this.newProjectForm.value.indicationMinYear ?? ``;
      this._articlesService.setSearchedArticlesCount(
        keywords,
        Number(startYear) ?? new Date().getFullYear(),
        true
      );
    } else {
      if (this.newProjectForm.controls.indicationKeywords.errors) {
      }
    }
  }

  public async addProject(): Promise<void> {
    const projectId: string = newGuid();
    const categoryId: string =
      this.newProjectForm.value.selectedCategory ?? newGuid();
    const categoryExists: boolean = this._categoriesService
      .getCategories()
      .map((c) => c.categoryId)
      .includes(categoryId);
    const navigateToCategory: (pId: string, cId: string) => void = (
      pId: string,
      cId: string
    ) => {
      const projectReviewParams: TProjectReviewParams = {
        projectId: pId,
        categoryId: cId,
      };
      this._router.navigate([`/${ROUTES_PATHS.project}`, projectReviewParams], {
        relativeTo: this._route,
      });
    };
    const startYear: number =
      Number(this.newProjectForm.value.indicationMinYear) ??
      new Date().getFullYear();

    const defaultProximityFields: DynamicNumberProjectsFieldItem[] = [
      [`networkCtWeight`, 2],
      [`networkInstWeight`, 1.5],
      [`networkPubWeight`, 1],
    ];

    const defaultDynamicFields: DynamicStringProjectsFieldItem[] = [
      [`dynamicTestStringField`, `Dynamic test field`],
    ];

    const project: TProject = getDefaultProject({
      id: projectId,
      isPinned: this.newProjectForm.value.pinToDashboard ?? false,
      name: this.newProjectForm.value.indicationName ?? ``,
      keywords: this.newProjectForm.value.indicationKeywords ?? ``,
      indicationMinDate: this.newProjectForm.value.indicationMinYear ?? ``,
      description: this.newProjectForm.value.indicationDescription ?? ``,
      startYear: startYear,
      selectedMinYear: startYear,
      dynamicFields: [...defaultProximityFields, ...defaultDynamicFields],
    });
    if (categoryExists) {
      this._categoriesService.updateProject(categoryId, project, () => {
        navigateToCategory(projectId, categoryId);
      });
    } else {
      const newCategory: TCategory = {
        categoryId: categoryId,
        name:
          this.categories.find(
            (c) => c.categoryId === this.newProjectForm.value.selectedCategory
          )?.name ?? ``,
        projects: [project],
      };
      this._categoriesService.createCategory(newCategory, () => {
        navigateToCategory(projectId, categoryId);
      });
    }
  }

  public handleCancel(): void {
    this._location.back();
  }
}
