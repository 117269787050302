import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TRegion } from '@shared/interfaces/TProject';
import { PDFService } from 'src/app/services/PDFService';
import { TBaseSettingsTabParams } from '../project-settings.component';

@Component({
  selector: 'app-regions',
  templateUrl: './regions.component.html',
  styleUrls: ['./regions.component.scss'],
})
export class RegionsComponent implements OnInit {
  constructor(
    private _pdfService: PDFService,
    public dialogRef: MatDialogRef<RegionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TBaseSettingsTabParams
  ) {
    this.params = data;
  }

  public newRegionData: TRegion = {
    description: ``,
    name: ``,
    salesData: ``,
  };
  public pdfSrc: Uint8Array = new Uint8Array([]);
  public params: TBaseSettingsTabParams;

  private _isPdfViewerVisible: boolean = false;

  public getRegions(): TRegion[] {
    return this.params.selectedProject?.regions ?? [];
  }

  public add() {
    if (
      this.params.selectedProject !== undefined &&
      this.params.selectedProject.regions === undefined
    ) {
      this.params.selectedProject.regions = [this.newRegionData];
    } else {
      this.params.selectedProject?.regions.push(
        structuredClone(this.newRegionData)
      );
    }
  }

  private _isNameDuplicated(): boolean {
    return (this.params.selectedProject?.regions ?? [])
      .map((r) => r.name)
      .includes(this.newRegionData.name);
  }

  public addAvailable(): boolean {
    return (
      this.newRegionData.name !== `` &&
      (this.newRegionData.description !== ``) !== this._isNameDuplicated()
    );
  }

  public remove(region: TRegion): void {
    if (
      this.params.selectedProject !== undefined &&
      this.params.selectedProject.regions !== undefined
    ) {
      const regionIndex: number = this.params.selectedProject.regions.findIndex(
        (r) => r.name === region.name
      );
      this.params.selectedProject.regions.splice(regionIndex, 1);
    }
  }

  public loadPDF(filename: string): void {
    this._pdfService.loadSalesData(filename, (res) => {
      this.pdfSrc = res;
      this._isPdfViewerVisible = true;
    });
  }

  public checkIfPDFViewerVisible(): string {
    return this._isPdfViewerVisible ? `` : `hidden-by-size`;
  }

  ngOnInit(): void {}
}
