import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './authentication.guard';
import { CenterComponent } from './center/center.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HcpComponent } from './hcp/hcp.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { NewProjectComponent } from './new-project/new-project.component';
import { OptInComponent } from './opt-in/opt-in.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ProjectReviewComponent } from './project-review/project-review.component';
import { ReportsReviewComponent } from './reports-review/reports-review.component';
import { SystemSettingsComponent } from './system-settings/system-settings.component';
import { SupportPageComponent } from "./support-page/support-page.component";

interface TRoutePaths {
  dashboard: string;
  root: string;
  newProject: string;
  project: string;
  systemSettings: string;
  support: string;
  center: string;
  login: string;
  notFound: string;
  hcp: string;
  report: string;
  optIn: string;
}

export const ROUTES_PATHS: TRoutePaths = {
  dashboard: `dashboard`,
  newProject: `new-project`,
  project: `project`,
  root: ``,
  systemSettings: `system-settings`,
  center: `center`,
  login: `login`,
  notFound: `**`,
  hcp: `hcp`,
  report: `report`,
  optIn: `opt-in`,
  support: `support`
};

const routes: Routes = [
  {
    path: ROUTES_PATHS.center,
    component: CenterComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: ROUTES_PATHS.hcp,
    component: HcpComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: ROUTES_PATHS.root,
    component: DashboardComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: ROUTES_PATHS.dashboard,
    component: DashboardComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: ROUTES_PATHS.newProject,
    component: NewProjectComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: ROUTES_PATHS.project,
    component: ProjectReviewComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: ROUTES_PATHS.systemSettings,
    component: SystemSettingsComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: ROUTES_PATHS.report,
    component: ReportsReviewComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: ROUTES_PATHS.support,
    component: SupportPageComponent,
    canActivate: [AuthenticationGuard],
  },
  { path: ROUTES_PATHS.login, component: LoginPageComponent },
  {
    path: ROUTES_PATHS.optIn,
    component: OptInComponent,
  },
  {
    path: ROUTES_PATHS.notFound,
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: `reload`,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
