import { TDBFieldMappingItem } from '@shared/interfaces/TDBFieldMappingItem';

export const ARTICLE_DB_MAPPINGS: Map<string, TDBFieldMappingItem> = new Map([
  [
    `title`,
    {
      columnWidth: 400,
      fieldId: `title`,
      name: `Title`,
      isSortable: false,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false
    }
  ],
  [
    `pmid`,
    {
      columnWidth: 150,
      fieldId: `pmid`,
      name: `Pubmed ID`,
      isSortable: false,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false
    }
  ],
  [
    `author_names`,
    {
      columnWidth: 150,
      fieldId: `author_names`,
      name: `Authors`,
      isSortable: false,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false
    }
  ],
  [
    `translated_title`,
    {
      columnWidth: 150,
      fieldId: `translated_title`,
      name: `Translated title`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false
    }
  ],
  [
    `abstract`,
    {
      columnWidth: 150,
      fieldId: `abstract`,
      name: `Abstract`,
      isSortable: false,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false
    }
  ],
  [
    `other_term`,
    {
      columnWidth: 150,
      fieldId: `other_term`,
      name: `Other term`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false
    }
  ],
  [
    `mesh_term`,
    {
      columnWidth: 150,
      fieldId: `mesh_term`,
      name: `Mesh term`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false
    }
  ],
  [
    `publication_day`,
    {
      columnWidth: 150,
      fieldId: `publication_day`,
      name: `Publication day`,
      isSortable: false,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false
    }
  ],
  [
    `centers_ids`,
    {
      columnWidth: 150,
      fieldId: `centers_ids`,
      name: `centers ids`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: true,
      isEditable: false
    }
  ],
  [
    `centers_names`,
    {
      columnWidth: 150,
      fieldId: `centers_names`,
      name: `centers names`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: true,
      isEditable: false
    }
  ],
  [
    `projects`,
    {
      columnWidth: 150,
      fieldId: `projects`,
      name: `projects`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: true,
      isEditable: false
    }
  ]
]);
