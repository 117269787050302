import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTree, MatTreeNestedDataSource } from '@angular/material/tree';
import { TCategory } from '@shared/interfaces/TCategory';
import { TUser, UserLevel, UserStatus } from '@shared/interfaces/TUser';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { Subscription } from 'rxjs';
import { CategoriesService } from '../services/CategoriesService';

export class TreeItemNode {
  children?: TreeItemNode[];
  name: string;
  selected: boolean;
  id: string;
}
@Component({
  selector: 'app-projects-selector',
  templateUrl: './projects-selector.component.html',
  styleUrls: ['./projects-selector.component.scss'],
})
export class ProjectsSelectorComponent implements OnDestroy, OnInit {
  @Input()
  public newUserData: Partial<TUser> = {
    categories: [],
    countries: [],
    email: ``,
    firstName: ``,
    lastName: ``,
    level: UserLevel.User,
    password: ``,
    projects: [],
    status: UserStatus.Active,
  };
  public treeControl = new NestedTreeControl<TreeItemNode>(
    (node) => node.children
  );
  public dataSource = new MatTreeNestedDataSource<TreeItemNode>();
  @ViewChild('tree')
  public tree: MatTree<TreeItemNode>;
  private subs: Subscription[] = [];

  constructor(private _categoriesService: CategoriesService) {}

  public ngOnInit(): void {
    this.subs.push(
      this._categoriesService
        .getCategoriesObservable()
        .subscribe((categories) => {
          this.buildCategoriesTree(categories);
        })
    );
  }

  public ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  public hasChild = (_: number, node: TreeItemNode) =>
    !!node.children && node.children.length > 0;

  public buildCategoriesTree(categories: TCategory[]): void {
    const treeData: TreeItemNode[] = categories.map((c) => {
      const leaf: TreeItemNode = {
        id: c.categoryId,
        name: c.name,
        selected: (this.newUserData?.categories ?? []).includes(c.categoryId),
      };
      if (c.projects.length > 0) {
        leaf.children = c.projects.map((p) => {
          return {
            id: p.id,
            name: p.name,
            selected: (this.newUserData?.projects ?? []).includes(p.id),
          };
        });
      }
      return leaf;
    });
    this.dataSource.data = treeData;
    this.treeControl.dataNodes = treeData;
  }

  public onCheckobxChange(node: TreeItemNode): void {
    if (this.hasChild(NaN, node)) {
      node.children?.forEach((child) => {
        child.selected = node.selected;
      });
    } else {
      const parent: TreeItemNode | undefined = this.dataSource.data.find((it) =>
        (it.children ?? []).map((it) => it.id).includes(node.id)
      );
      if (!isNullOrUndefined(parent)) {
        if (node.selected) {
          parent.selected = true;
        } else {
          const childrensWithoutCurrent = (parent.children ?? []).filter(
            (it) => it.id !== node.id
          );
          if (
            !node.selected &&
            childrensWithoutCurrent.every((it) => !it.selected)
          ) {
            parent.selected = false;
          }
        }
      }
    }

    const categories: string[] = [];
    const projects: string[] = [];
    this.dataSource.data.forEach((c) => {
      if (c.selected) {
        categories.push(c.id);
      }
      (c.children ?? []).forEach((p) => {
        if (p.selected) {
          projects.push(p.id);
        }
      });
    });
    this.newUserData.categories = categories;
    this.newUserData.projects = projects;
  }
}
