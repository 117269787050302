export const PATH_DELIMITER: string = `.`;
export interface TDBFieldMappingItem {
  fieldId: string;
  name: string;
  isSortable: boolean;
  visibleInSummary: boolean;
  visibleInShortSummary?: boolean;
  visibleInRaport?: boolean;
  visibleInMerge?: boolean;
  columnWidth: number;
  systemProp: boolean;
  isEditable: boolean;
  tierField?: boolean;
  isTagField?: boolean;
  export?: boolean;
  requireValidation?: boolean;
  isRegionField?: boolean;
  isProjectData?: boolean;
  path?: string;
  isFilterField?: boolean;
  usOnly?: boolean;
  borrowedField?: boolean;
  isReportFilterField?: boolean;
  type?: `string` | `number` | `date` | `boolean`;
  engagement?: boolean;
  availableInTransformation?: boolean;
  import?: boolean;
  tupleList?: boolean;
}
