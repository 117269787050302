import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TCenter } from '@shared/interfaces/TCenter';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { Subscription } from 'rxjs';
import {
  TBaseComponentParams,
  TComponentType,
} from 'src/app/interfaces/TComponentParams';
import { CentersService } from 'src/app/services/CentersService';

@Component({
  selector: 'app-aliases',
  templateUrl: './aliases.component.html',
  styleUrls: ['./aliases.component.scss'],
})
export class AliasesComponent implements OnInit, OnDestroy {
  @Input()
  public params: TBaseComponentParams = {
    categoryId: ``,
    projectId: ``,
    __type: TComponentType.hcp,
  };
  @Input()
  public centerData: TCenter | undefined = undefined;
  public aliasCenters: TCenter[] = [];

  private _subs: Subscription[] = [];
  constructor(private _centersService: CentersService) {}

  public ngOnInit(): void {
    if (
      !isNullOrUndefined(this.centerData) &&
      this.centerData.c_aliases.length > 0
    ) {
      this._subs.push(
        this._centersService
          .getCentersById(this.centerData.c_aliases.join(`,`))
          .subscribe((centers) => {
            this.aliasCenters = centers;
          })
      );
    }
  }

  public ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
  }
}
