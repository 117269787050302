import { Status } from '@shared/enums/Status';
import { TArticle } from './TArticle';
import { NCTid } from './TCtData';
import { TTagItem } from './TProject';

export type THCPPropertyType =
  | string
  | number
  | boolean
  | string[]
  | Date
  | THistoryCenters[]
  | THCPProjectsData[]
  | null;

export function buildProjectDataHCPMock(overwrites: Partial<THCPProjectsData>): THCPProjectsData {
  const defaultHCP: THCPProjectsData = {
    projectId: ``,
    influenceScore: 0,
    engagementScore: 0,
    valueScore: 0,
    risingStarScore: 0,
    productUseScore: 0,
    memberships_committees: [],
    proximityScore: { HCPToCenter: [], HCPToHCP: [], HCPToHCPClinicalTrials: [], proximitySum: [] },
    hcp_proj_KOL: false,
    hcp_proj_rising_star: false,
    hcp_proj_interest_as_advisor: false,
    hcp_proj_interested_in_research_study: false,
    hcp_proj_promoter_score_local: ``,
    hcp_proj_promoter_score_global: ``,
    hcp_proj_potential_to_influence_peers: ``,
    engagement_actual: [],
    engagement_plan: [],
    history_publications: [],
    history_publications_count: 0
  };
  return Object.assign(defaultHCP, overwrites);
}

export function buildHCPMock(overwrites: Partial<THCP>): THCP {
  const defaultHCP: THCP = {
    id: ``,
    profile_image: ``,
    gender: ``,
    title: ``,
    first_name: ``,
    last_name: ``,
    alias: [],
    center: ``,
    city: ``,
    country: ``,
    department: ``,
    street1: ``,
    street2: ``,
    zip: ``,
    opt_out_information_status: 0,
    opt_out_email_sent_date: null,
    opt_out_paper_mail_printed_date: null,
    opt_in_email_sent_date: null,
    opt_in_status: 0,
    optInHash: '',
    optOutHash: '',
    treats_adult_patients: false,
    treats_pediatric_patients: false,
    does_not_treat_any_patients_phd: false,
    function: ``,
    primary_email_address: ``,
    secondary_email_address: ``,
    specialties: [],
    description: ``,
    retired: false,
    key_account_global: ``,
    key_account_local: ``,
    resume: ``,
    speaker_presenter_frequency: 0,
    digital_influencer: 0,
    participated_in_guidelines: false,
    clinical_trials_investigator: false,
    clinical_trials_principal_investigator: false,
    languages: [],
    speaker: false,
    speaker_history: ``,
    initial_year: ``,
    interest_in_clinical_trials_RD: ``,
    interest_in_health_economics: ``,
    influencers: [],
    social_media_engagements: ``,
    linkedin: ``,
    further_links_and_sources: ``,
    kols_status: 0, //1-3
    labelHCP_treats_adult: [],
    labelHCP_treats_pediatric: [],
    labelHCP_does_not_treat_phd: [],
    labelHCP_guidelines: [],
    history_centers: [],
    status: Status.OK,
    affiliationYear: 0,
    studies: [],
    projects: [],
    projectsData: [],
    centerName: ``,
    twitterData: {
      followers: [],
      following: [],
      latestTweets: []
    },
    twitterUserName: '',
    has_priority: false,
    priority_timestamp: null
  };
  return Object.assign(defaultHCP, overwrites);
}

export enum OptInStatus {
  //Informed about right to opt out; 0 not contacted about opt out; 1 opt out information sent, no response; 2 accepted; 3 declined
  NotContacted = 0,
  ContactedNoResponse = 1,
  Accepted = 2,
  Declined = 3
}

export enum OptOutStatus {
  //Status: 0 not informed about opt in; 1 opt in sent & accepted; 2 declined
  NotInformed = 0,
  InformedAndAccepted = 1,
  Declined = 2
}

export interface TTwitterData {
  followers: string[];
  following: string[];
  latestTweets: TTweets[];
}

export interface TTwitterProximityData {
  followers: THCP[];
  following: THCP[];
}

export interface TTweets {
  date: string;
  retweets: number;
  text: string;
}

export interface THCP {
  id: string;
  profile_image: string;
  gender: string;
  title: string;
  first_name: string;
  last_name: string;
  alias: string[];
  center: string; //TODO rename center_id
  centerName: string; //TODO rename center_name
  city: string;
  country: string;
  department: string;
  street1: string;
  street2: string;
  zip: string;
  opt_out_information_status: OptOutStatus;
  opt_out_email_sent_date?: Date | null;
  opt_out_paper_mail_printed_date?: Date | null;
  opt_in_email_sent_date?: Date | null;
  opt_in_status: OptInStatus;
  treats_adult_patients: boolean;
  treats_pediatric_patients: boolean;
  does_not_treat_any_patients_phd: boolean;
  function: string;
  primary_email_address: string;
  secondary_email_address: string;
  specialties: string[];
  description: string;
  retired: boolean;
  key_account_global: string;
  key_account_local: string;
  resume: string;
  speaker_presenter_frequency: number;
  digital_influencer: number;
  participated_in_guidelines: boolean;
  clinical_trials_investigator: boolean;
  clinical_trials_principal_investigator: boolean;
  languages: string[];
  speaker: boolean;
  speaker_history: string;
  initial_year: string; //TODO do we need this?
  interest_in_clinical_trials_RD: string;
  interest_in_health_economics: string;
  influencers: string[];
  social_media_engagements: string;
  linkedin: string;
  further_links_and_sources: string;
  kols_status: number; //0-2
  labelHCP_treats_adult: string[]; //TODO do we need this?
  labelHCP_treats_pediatric: string[]; //TODO do we need this?
  labelHCP_does_not_treat_phd: string[]; //TODO do we need this?
  labelHCP_guidelines: string[]; //TODO do we need this?
  history_centers: THistoryCenters[];
  affiliationYear: number;
  studies: TTHCPStudy[];
  status: Status;
  projects: string[]; //TODO remove because we have this information in projectsData
  projectsData: THCPProjectsData[];
  twitterData: TTwitterData;
  twitterUserName: string;
  optInHash: string;
  optOutHash?: string;
  has_priority: boolean;
  priority_timestamp?: string | null;
}

export interface TTHCPStudy {
  id: NCTid;
  condition: string;
}

export interface TProximityScoreItem {
  firstName: string;
  lastName: string;
  hcpId: string;
  count: number;
}

export interface TProximityScore {
  HCPToHCP: TProximityScoreItem[];
  HCPToCenter: TProximityScoreItem[];
  HCPToHCPClinicalTrials: TProximityScoreItem[];
  proximitySum: TProximityScoreItem[];
}

export interface TProximityData {
  id: string;
  articles: TArticle[];
  centers: THistoryCenters[];
  fullName: string;
}

export interface TProximityWorkingInCenterData {
  id: string;
  startYear: number;
  endYear: number;
}

export interface TProximityDataForHCP {
  id: string;
  proximityScore: TProximityScore;
}

export interface THCPToCenterProximityScoreItem {
  centerName: string;
  centerId: string;
  count: number;
}

export interface TEngagementPlan {
  engagement_plan_time: Date | null;
  engagement_plan_objectives: string;
  engagement_plan_accountable: string;
  engagement_plan_completed: boolean;
  tags: TTagItem[];
  id: string;
}

export interface TEngagementActual {
  engagement_actual_date: Date | null;
  engagement_actual_activity: string;
  engagement_actual_point_person: string;
  engagement_actual_quantity?: number;
  tags: TTagItem[];
  id: string;
}

export type THCPProjectsDataProp =
  | string
  | number
  | TProximityScore
  | TTagItem[]
  | boolean
  | TEngagementPlan[]
  | TEngagementActual[]
  | string[];

export interface THCPProjectsData {
  projectId: string;
  influenceScore: number;
  engagementScore: number;
  valueScore: number;
  risingStarScore: number;
  proximityScore: TProximityScore;
  productUseScore: number;
  memberships_committees: TTagItem[];
  hcp_proj_KOL: boolean;
  hcp_proj_rising_star: boolean;
  hcp_proj_interest_as_advisor: boolean;
  hcp_proj_interested_in_research_study: boolean;
  hcp_proj_promoter_score_local: string;
  hcp_proj_promoter_score_global: string;
  hcp_proj_potential_to_influence_peers: string;
  engagement_plan: TEngagementPlan[];
  engagement_actual: TEngagementActual[];
  history_publications: string[];
  history_publications_count: number;
}

export interface THistoryCenters {
  id: string;
  name: string;
  year: number;
}
