import { Component, OnInit } from '@angular/core';
import { DEFAULT_APP_SETTINGS, TSettings } from '@shared/interfaces/TSettings';
import { Subscription } from 'rxjs';
import { CategoriesService } from './services/CategoriesService';
import { RiseAppApiService } from './services/RiseAppApiService';
import { SettingsService } from './services/SettingsService';
import { AuthService, TCurrentUser } from './services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public isLogged: boolean = false;
  public appSettings: TSettings = structuredClone(DEFAULT_APP_SETTINGS);
  private _subs: Subscription[] = [];
  private _userHasChanged = false;
  private _currentUser: TCurrentUser | undefined;

  constructor(
    private _authService: AuthService,
    private _settingsService: SettingsService,
    private _apiService: RiseAppApiService,
    private _categoriesService: CategoriesService
  ) {}

  public ngOnInit(): void {
    this._apiService.initApi();

    this._subs.push(
      this._authService.getIsLoggedObservable().subscribe((isLogged) => {
        this.isLogged = isLogged;
        if (isLogged) {
          this._authService.setUserByToken();
        }
      })
    );

    this._subs.push(
      this._settingsService.getAppSettingsObservable().subscribe((settings) => {
        this.appSettings = settings;
        document.title = settings.pageTitle;
        this.updateFavicon(settings.favicon);
      })
    );

    this._subs.push(
      this._authService.getCurrentUserObservable().subscribe((user) => {
        if (user) {
          if (this._currentUser?.email !== user.email) {
            this._userHasChanged = true;
            this._currentUser = user;
          } else {
            this._userHasChanged = false;
          }
          if (this._userHasChanged) {
            this._categoriesService.setCategories();
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach((sub) => sub.unsubscribe());
  }

  private updateFavicon(newFaviconUrl: string) {
    const oldFavicon = document.querySelector(
      "link[rel*='icon']"
    ) as HTMLLinkElement;
    const newFavicon = document.createElement('link');
    newFavicon.rel = 'icon';
    newFavicon.type = 'image/x-icon';
    newFavicon.href = newFaviconUrl;

    if (oldFavicon) {
      document.head.removeChild(oldFavicon);
    }

    document.head.appendChild(newFavicon);
  }
}
