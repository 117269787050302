import { Injectable } from '@angular/core';
import { TCenter } from '@shared/interfaces/TCenter';
import { THCP } from '@shared/interfaces/THCP';
import { LoadingService } from '../services/LoadingService';

export interface TRiseAppApiService {
  logProject: (updateCounts: boolean) => string;
  migrateHCPs: () => void;
  setNormalizationIndex: () => void;
  batchUpdateHCPs: (data: THCP[]) => void;
  batchUpdateCenters: (data: TCenter[]) => void;
  migrateCenters: () => void;
  logCurrentHCPs: () => THCP[];
  logCurrentCenters: () => TCenter[];
  showLog: (state: boolean) => void;
}

@Injectable()
export class RiseAppApiService {
  public windowRef: any = window;
  constructor(private _loadingService: LoadingService) {
    const riseAppApiService: TRiseAppApiService = {
      showLog: (state: boolean = true) => {
        this._loadingService.setLogVisible(state);
      },
      logProject: () => '',
      migrateHCPs: () => {},
      setNormalizationIndex: () => {},
      batchUpdateHCPs: () => {},
      batchUpdateCenters: () => {},
      migrateCenters: () => {},
      logCurrentHCPs: () => [],
      logCurrentCenters: () => [],
    };
    this.windowRef.riseAppApiService = riseAppApiService;
  }

  public initApi(): void {
    console.log(`Rise app API initialized`);
  }

  public getApi(): TRiseAppApiService {
    return this.windowRef.riseAppApiService;
  }

  public setLogProjects(callback: (updateCounts: boolean) => string): void {
    this.getApi().logProject = callback;
  }

  public setMigrateHCPs(callback: () => void): void {
    this.getApi().migrateHCPs = callback;
  }

  public setNormalizationIndex(callback: () => void): void {
    this.getApi().setNormalizationIndex = callback;
  }

  public setBatchUpdateHCPs(callback: (data: THCP[]) => void): void {
    this.getApi().batchUpdateHCPs = callback;
  }

  public setBatchUpdateCenters(callback: (data: TCenter[]) => void): void {
    this.getApi().batchUpdateCenters = callback;
  }

  public setMigrateCenters(callback: () => void): void {
    this.getApi().migrateCenters = callback;
  }

  public setLogCurrentHCPs(callback: () => THCP[]): void {
    this.getApi().logCurrentHCPs = callback;
  }

  public setLogCurrentCenters(callback: () => TCenter[]): void {
    this.getApi().logCurrentCenters = callback;
  }
}
