import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SharedConstants } from '@shared/Constants';
import { ARTICLE_DB_MAPPINGS } from '@shared/db-fields-mappings/ARTICLE_DB_MAPPINGS';
import { SearchArea } from '@shared/enums/SearchArea';
import { TArticle } from '@shared/interfaces/TArticle';
import { THCP } from '@shared/interfaces/THCP';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { Subscription } from 'rxjs';
import {
  ICommonTableParams,
  IHandleCommonTable,
  PaginationVisibility,
  SortType,
  TArticleTableRow,
  TTDataType,
  TTableRowType,
  TableRowType,
} from 'src/app/common-table/common-table.component.types';
import { ArticlesService } from 'src/app/services/ArticlesService';
import { LoadingService, TLoadingItem } from 'src/app/services/LoadingService';
import { TDataColumnDef, buildColumnsDef } from 'src/utils/buildColumnsDef';
import { sortByDate } from 'src/utils/sort';
import { sortingDataAccessor } from 'src/utils/sortingDataAccessor';
import {
  TBaseComponentParams,
  TComponentType,
  isCenterParams,
  isHCPParams,
} from '../../interfaces/TComponentParams';
@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.scss'],
})
export class PublicationsComponent
  implements OnInit, OnDestroy, IHandleCommonTable
{
  @Input()
  public params: TBaseComponentParams = {
    categoryId: ``,
    projectId: ``,
    __type: TComponentType.center,
  };
  @Input()
  public hcpData: THCP | undefined = undefined;
  public dataSource: MatTableDataSource<TArticleTableRow> =
    new MatTableDataSource();
  public articles: TArticle[] = [];
  public articlesCount: number = 0;
  public columns: TDataColumnDef<TArticle>[] = [
    ...buildColumnsDef({
      textColumns: [],
      fieldsMappings: Array.from(ARTICLE_DB_MAPPINGS.values()).filter(
        (f) => f.visibleInSummary
      ),
      columnIdsWithLink: [`title`],
    }),
  ];

  public pageEvent: PageEvent;
  private _subs: Subscription[] = [];

  constructor(
    private _articlesService: ArticlesService,
    private _loadingService: LoadingService
  ) {
    this.dataSource = new MatTableDataSource();
    this.dataSource.sortingDataAccessor = sortingDataAccessor;
  }

  public getPublicationHistory(): string[] {
    if (!isNullOrUndefined(this.hcpData)) {
      return (
        this.hcpData.projectsData.find(
          (it) => it.projectId === this.params.projectId
        )?.history_publications ?? []
      );
    }
    return [];
  }

  public ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
  }

  public getSlug(text: string): string {
    return `${text.substring(0, 100)}...`;
  }

  public ngOnInit(): void {
    this._setTableData();
  }

  public getTableParams(): ICommonTableParams<TTableRowType, TTDataType> {
    return {
      columns: this.columns as TDataColumnDef<TTDataType>[],
      dataCount: this.articlesCount,
      dataLoaded: true,
      dataSource: this.dataSource as MatTableDataSource<TTableRowType>,
      displayedColumns: Array.from(ARTICLE_DB_MAPPINGS.values())
        .filter((f) => f.visibleInSummary)
        .map((f) => f.fieldId),
      handleEditClick: (row: TTableRowType) =>
        this._handleEditClick(<TArticleTableRow>row),
      pageIndex: 0,
      pageSize: 10000,
      pageSizeOptions: [10, 25, 50, 100],
      paginationCallback: () => void 0,
      downloadCallback: undefined,
      importCallback: undefined,
      area: SearchArea.None,
      projectId: this.params.projectId,
      selection: false,
      actionsAvailable: true,
      sortType: SortType.Simple,
      paginationVisibility: PaginationVisibility.Simple,
      handleNavigateToClick: (row: TTableRowType) =>
        this._handleNavigateToClick(<TArticleTableRow>row),
      resize: true,
      simpleSortHeaders: [`Title`, `Publication day`, `Authors`, `Pubmed ID`],
      defaultSortFieldId: `publication_day`,
    };
  }

  private _handleEditClick(row: TArticleTableRow): void {
    if (
      this.params.categoryId !== undefined &&
      this.params.projectId !== undefined
    ) {
      window
        .open(`${SharedConstants.PUBMED_URL}${row.pmid}`, '_blank')
        ?.focus();
    }
  }

  private _handleNavigateToClick(row: TArticleTableRow): void {
    window.open(`${SharedConstants.PUBMED_URL}${row.pmid}`, '_blank')?.focus();
  }

  private _setTableData(): void {
    if (isHCPParams(this.params)) {
      if (this.params.hcpId.length > 0) {
        const loadingItem: TLoadingItem = this._loadingService.buildLoadingItem(
          `Fetching articles for author id: ${this.params.hcpId}`
        );
        this._loadingService.addLoadingItem(loadingItem);
        this._articlesService
          .getArticlesByIds(this.getPublicationHistory())
          .subscribe((response) => {
            this.articles = response;
            this.articlesCount = response.length;
            this._processTableData();
            this._loadingService.removeItem(loadingItem);
          });
      }
    }
    if (isCenterParams(this.params)) {
      if (this.params.centerId.length > 0) {
        const loadingItem: TLoadingItem = this._loadingService.buildLoadingItem(
          `Fetching articles for center: ${this.params.centerId}`
        );
        this._loadingService.addLoadingItem(loadingItem);
        this._articlesService
          .getArticlesForCenter(this.params.centerId)
          .subscribe((response) => {
            this.articles = response;
            this.articlesCount = response.length;
            this._processTableData();
            this._loadingService.removeItem(loadingItem);
          });
      }
    }
  }

  private _processTableData(): void {
    const tempData: TArticleTableRow[] = [];
    this.articles.forEach((article: TArticle, index: number) => {
      tempData.push({
        ...article,
        position: index,
        abstract: this.getSlug(article.abstract),
        __type: TableRowType.Article,
      });
    });

    this.dataSource.data = sortByDate(tempData, `publication_day`, `desc`);
  }
}
