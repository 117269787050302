import { TImportMappingItem } from '@shared/interfaces/TImportMappingItem';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';

export class CSVUtils {
  public static async getImportFormData(
    file: File,
    projectId: string,
    mappings: TImportMappingItem[]
  ): Promise<FormData> {
    const separator = await CSVUtils.getCSVSeparatorFromFile(file);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('projectId', projectId);
    formData.append('mappings', JSON.stringify(mappings));
    formData.append('separator', separator || ';');

    return formData;
  }

  public static csvToArray(str: string): {
    data: any[];
    headers: string[];
  } {
    const separator = this.detectCSVSeparator(str);
    let headers: string[] = str.slice(0, str.indexOf(`\n`)).split(separator);
    headers = headers.map((header) => header.trim());
    const rows: string[] = str.slice(str.indexOf('\n') + 1).split('\n');
    const arr = rows.map(function (row) {
      const values = row.split(separator);
      const el = headers.reduce(
        (object: any, header: string, index: number) => {
          object[header] = values[index];
          return object;
        },
        {}
      );
      return el;
    });
    return {
      data: arr,
      headers,
    };
  }

  private static getCSVSeparatorFromFile(
    file: File
  ): Promise<string | undefined> {
    let separator: string | undefined;
    const reader: FileReader = new FileReader();
    reader.onload = (event) => {
      const results = event?.target?.result ?? ``;
      if (!isNullOrUndefined(results) && typeof results === `string`) {
        separator = CSVUtils.detectCSVSeparator(results);
      }
    };
    reader.readAsText(file);
    return new Promise((resolve) => {
      reader.onloadend = () => resolve(separator);
    });
  }

  private static detectCSVSeparator(csvContent: string): string {
    // Get the first 3 lines of the CSV
    const lines = csvContent.split('\n').slice(0, 3);
    // Array of potential separators to test
    const potentialSeparators = [',', ';', '\t'];

    for (const separator of potentialSeparators) {
      // Count occurrences of the separator in each line
      const occurrences = lines.map(
        (line: string) => line.split(separator).length - 1
      );

      // If the occurrences are consistent across lines, assume this separator is used
      if (occurrences.every((count: number) => count > 0)) {
        return separator;
      }
    }
    // If none of the potential separators are detected, default to comma
    return ';';
  }
}
