import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Endpoints } from '@shared/Endpoints';
import { TOptInResults } from '@shared/interfaces/TOptInResults';
import { Subscription } from 'rxjs';
import { Status } from '../../../../server/src/shared/enums/Status';
import { environment } from '../../environments/environment';
import { Severity } from '../note/note.component';

interface TOptInParams {
  hash: string;
  hcp: string;
}

@Component({
  selector: 'app-opt-in',
  templateUrl: './opt-in.component.html',
  styleUrls: ['./opt-in.component.scss'],
})
export class OptInComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;
  public optInMessage: string = `Invalid confirmation data. Please contact administrator`;
  public severity: Severity = Severity.Danger;
  private _subs: Subscription[] = [];

  constructor(private _http: HttpClient, private _route: ActivatedRoute) {}

  public ngOnInit(): void {
    this._subs.push(
      this._route.queryParams.subscribe((params: Params) => {
        const { hash, hcp } = params as TOptInParams;
        this._confirmOptIn(hcp, hash, (res) => {
          this.isLoading = false;
          if (res.status === Status.OK) {
            this.optInMessage = `${res.first_name} ${res.last_name} was successfully opt in`;
            this.severity = Severity.Success;
          }
        });
      })
    );
  }

  private _confirmOptIn(
    hcp: string,
    hash: string,
    callback: (res: TOptInResults) => void
  ): void {
    const url: string = Endpoints.BUILD_URL('rise', {
      base: environment.apiUrl,
      endpoint: 'confirmOptIn',
      params: `?hcp=${hcp}&hash=${hash}`,
    });
    const response = this._http.get<TOptInResults>(url);
    response.subscribe({
      error: (err) => {
        console.error(err);
        //this._loadingService.onError();
      },
      next: (res) => {
        callback(res);
      },
    });
  }

  public ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }
}
