<mat-toolbar class="navbar" color="primary">
  <ul class="navbar__menu navbar__menu--categories">
    <li class="navbar__menu__item" *ngFor="let category of categories">
      <button mat-flat-button color="primary" mat-button>
        {{ category.name }}
      </button>
      <ul class="navbar__menu--submenu">
        <li
          class="navbar__menu__item"
          *ngFor="let project of category.projects"
        >
          <button
            mat-flat-button
            color="primary"
            mat-button
            (click)="handleProjectClick(project.id, category.categoryId)"
          >
            {{ project.name }}
          </button>
          <button
            mat-icon-button
            (click)="handleProjectClick(project.id, category.categoryId, true)"
          >
            <mat-icon>settings_suggest</mat-icon>
          </button>
        </li>
      </ul>
    </li>
  </ul>
  <button
    *ngIf="supportDocs.length > 0"
    mat-icon-button
    [routerLink]="ROUTES_PATHS.support"
    matTooltip="Support"
  >
    <mat-icon class="support">help</mat-icon>
  </button>
  <button
    mat-button
    mat-flat-button
    color="primary"
    routerLink="/new-project"
    routerLinkActive="active"
    ariaCurrentWhenActive="page"
  >
    Add new project
  </button>
</mat-toolbar>
