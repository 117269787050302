import { Component, Input } from '@angular/core';
import { THCP } from '@shared/interfaces/THCP';
import { TComponentType, THCPParams } from '../../interfaces/TComponentParams';

export interface THCPDocument {
  name: string;
}

@Component({
  selector: 'app-other',
  templateUrl: './other.component.html',
  styleUrls: ['./other.component.scss'],
})
export class OtherComponent {
  public documents: THCPDocument[] = [];
  @Input()
  public params: THCPParams = {
    hcpId: ``,
    categoryId: ``,
    projectId: ``,
    __type: TComponentType.hcp,
  };
  @Input()
  public hcpData: THCP;

  constructor() {}

  public documentsVisible(): boolean {
    return this.documents.length > 0;
  }

  public aliasesVisible(): boolean {
    return this.hcpData.alias.length > 0;
  }
}
