export function authorIdBuilder(foreName: string, lastName: string): string {
  return sanitizeId(`${foreName}${lastName}}`);
}

export function centerIdBuilder(name = ``): string {
  return `${sanitizeId(name.trim()).trim()}`;
}

export function sanitizeId(str: string): string {
  return str === null || str === undefined
    ? ``
    : str
        .replace(/^[^a-zA-Z]+/gi, ``)
        .replace(/[^\w-]/gi, `_`)
        .toLowerCase();
}
