import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FieldType } from '@shared/Types/FieldType';
import { CENTER_DB_MAPPINGS } from '@shared/db-fields-mappings/CENTER_DB_MAPPINGS';
import { HCP_DB_MAPPINGS } from '@shared/db-fields-mappings/HCP_DB_MAPPINGS';
import { TDBFieldMappingItem } from '@shared/interfaces/TDBFieldMappingItem';
import { TTagDefinition, TTagItem } from '@shared/interfaces/TProject';
import { isNullOrEmpty } from '@shared/utils/isNullOrEmpty';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { CategoriesService } from 'src/app/services/CategoriesService';
import { TBaseSettingsTabParams } from '../project-settings.component';

@Component({
  selector: 'app-tags-settings',
  templateUrl: './tags-settings.component.html',
  styleUrls: ['./tags-settings.component.scss'],
})
export class TagsSettingsComponent {
  public params: TBaseSettingsTabParams;
  public availableHCPTagsFields: TDBFieldMappingItem[] = [
    ...Array.from(HCP_DB_MAPPINGS.values()).filter((f) => f.isTagField),
  ];
  public availableCenterTagsFields: TDBFieldMappingItem[] = [
    ...Array.from(CENTER_DB_MAPPINGS.values()).filter((f) => f.isTagField),
  ];
  public selectedHCPTagField: string = ``;
  public selectedCenterTagField: string = ``;

  constructor(
    private _categoriesService: CategoriesService,
    public dialogRef: MatDialogRef<TagsSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TBaseSettingsTabParams
  ) {
    this.params = data;
  }

  public tagsListVisible(type: FieldType): boolean {
    switch (type) {
      case 'center':
        return !isNullOrEmpty(this.selectedCenterTagField);
      case 'hcp':
        return !isNullOrEmpty(this.selectedHCPTagField);
    }
  }

  public getFieldName(type: FieldType): string {
    switch (type) {
      case 'center':
        return (
          this.availableCenterTagsFields.find(
            (f) => f.fieldId === this.selectedCenterTagField
          )?.name ?? ``
        );
      case 'hcp':
        return (
          this.availableHCPTagsFields.find(
            (f) => f.fieldId === this.selectedHCPTagField
          )?.name ?? ``
        );
    }
  }

  public getTags(type: FieldType): TTagItem[] {
    let selectedTagField: string = ``;
    switch (type) {
      case 'center':
        selectedTagField = this.selectedCenterTagField;
        break;
      case 'hcp':
        selectedTagField = this.selectedHCPTagField;
        break;
    }
    return this._categoriesService.getTagsForField(
      this.params.selectedProject?.id ?? ``,
      selectedTagField,
      type
    );
  }

  public addFieldTag(type: FieldType): void {
    if (!isNullOrUndefined(this.params.selectedProject)) {
      if (isNullOrUndefined(this.params.selectedProject.hcpTagsDefinitions)) {
        this.params.selectedProject.hcpTagsDefinitions = [];
      }
      if (
        isNullOrUndefined(this.params.selectedProject.centerTagsDefinitions)
      ) {
        this.params.selectedProject.centerTagsDefinitions = [];
      }
      const newTag: TTagItem = {
        name: `Tag name`,
        value: 1,
        tag: `TAG`,
      };
      let tagsDefinitions: TTagDefinition[] = [];
      let selectedField: string = ``;
      switch (type) {
        case 'center':
          tagsDefinitions =
            this.params.selectedProject?.centerTagsDefinitions ?? [];
          selectedField = this.selectedCenterTagField;
          break;
        case 'hcp':
          tagsDefinitions =
            this.params.selectedProject?.hcpTagsDefinitions ?? [];
          selectedField = this.selectedHCPTagField;
          break;
      }
      const currentTag: TTagDefinition | undefined = tagsDefinitions.find(
        (f) => f.connEctedFieldId === selectedField
      );
      if (isNullOrUndefined(currentTag)) {
        tagsDefinitions.push({
          connEctedFieldId: selectedField,
          description: ``,
          tags: [newTag],
        });
      } else {
        currentTag.tags.push(newTag);
      }
    }
  }

  public deleteTag(type: FieldType, tag: TTagItem): void {
    if (type === 'center') {
      this._deleteTagCenter(tag);
    }
    if (type === 'hcp') {
      this._deleteTagHCP(tag);
    }
  }

  private _deleteTagHCP(tag: TTagItem): void {
    if (
      !isNullOrUndefined(this.params.selectedProject) &&
      !isNullOrUndefined(this.selectedHCPTagField)
    ) {
      const hcpTagDefinition =
        this.params.selectedProject?.hcpTagsDefinitions.find(
          (it) => it.connEctedFieldId === this.selectedHCPTagField
        );

      if (!isNullOrUndefined(hcpTagDefinition)) {
        const indexToRemove: number = (
          hcpTagDefinition as TTagDefinition
        ).tags.findIndex((f) => f.name === tag.name);
        (hcpTagDefinition as TTagDefinition).tags.splice(indexToRemove, 1);
      }
    }
  }

  private _deleteTagCenter(tag: TTagItem): void {
    if (
      !isNullOrUndefined(this.params.selectedProject) &&
      !isNullOrUndefined(this.selectedCenterTagField)
    ) {
      const centerTagDefinition =
        this.params.selectedProject?.centerTagsDefinitions.find(
          (it) => it.connEctedFieldId === this.selectedCenterTagField
        );

      if (!isNullOrUndefined(centerTagDefinition)) {
        const indexToRemove: number = (
          centerTagDefinition as TTagDefinition
        ).tags.findIndex((f) => f.name === tag.name);
        (centerTagDefinition as TTagDefinition).tags.splice(indexToRemove, 1);
      }
    }
  }
}
