import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function indicationDateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return (<string>control.value)
      .toLocaleLowerCase()
      .indexOf(`date - publication`) >= 0
      ? { invalidIndication: { value: control.value } }
      : null;
  };
}

export class KeywordValidator {
  private static conditionsRegex = /(\b(?:AND|OR|NOT)\b)+/;

  private static stringRegex = /^(?:[a-zA-Z0-9\s'-]+)$/;
  private static quotesRegex = /^\"(?:[a-zA-Z0-9\s'-]+)\"$/;
  private static singleQuotesRegex = /^\"(?:[a-zA-Z0-9\s'-]+)\"$/;
  private static parenthesisRegex = /^\((?:[a-zA-Z0-9\s'-]+)\)$/;

  // keyword string is valid if is a simple text
  // may have parenthesis or quotes only if both are present
  private static isKeywordStringValid = (value: string): boolean => {
    return (
      this.stringRegex.test(value) ||
      this.quotesRegex.test(value) ||
      this.singleQuotesRegex.test(value) ||
      this.parenthesisRegex.test(value)
    );
  };

  // keywords can be separated with AND, OR, NOT
  private static hasConditions = (value: string): boolean => {
    return value.match(this.conditionsRegex) !== null;
  };

  private static isKeywordFormatValid = (value: string): boolean => {
    if (this.hasConditions(value)) {
      let valid = true;
      const parts = value.split(this.conditionsRegex);
      parts.forEach((p) => {
        if (
          !p.match(this.conditionsRegex) &&
          !this.isKeywordFormatValid(p.trim())
        ) {
          valid = false;
        }
      });
      return valid;
    } else {
      return this.isKeywordStringValid(value);
    }
  };

  public static keywordsFormatValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.isKeywordFormatValid(control.value)) {
        return null;
      }
      return { invalidFormat: true };
    };
  }
}
