<div class="settings-modal-content">
  <h2 class="mat-h2 mt-2">Settings</h2>
  <div class="flex gap-4">
    <mat-form-field appearance="fill" class="w-1/2">
      <mat-label>Project name</mat-label>
      <input matInput [(ngModel)]="name" (change)="onNameChange()" />
    </mat-form-field>
    <mat-form-field appearance="fill" class="w-1/2">
      <mat-label>project description</mat-label>
      <input
        matInput
        [(ngModel)]="description"
        (change)="onDescriptionChange()"
      />
    </mat-form-field>
  </div>

  <div class="flex gap-4 mb-2">
    <div class="w-1/2">
      <h3 class="mat-h3 mt-2 flex gap-2">
        <span>Project range</span>
        <small>(Min year {{ selectedMinProjectYear }})</small>
      </h3>
      <div class="flex gap-2 items-baseline">
        <div>
          <label class="mr-2">{{ minProjectYear }}</label>
          <mat-slider
            min="{{ minProjectYear }}"
            max="{{ maxProjectYear }}"
            (change)="onSliderChange()"
          >
            <input [(ngModel)]="selectedMinProjectYear" matSliderThumb />
          </mat-slider>
          <label class="ml-2">{{ maxProjectYear }}</label>
        </div>
        <button
          class="mb-3"
          mat-flat-button
          color="primary"
          type="button"
          (click)="reprocessData()"
        >
          Apply
        </button>
      </div>
    </div>
    <div class="w-1/2 mb-2">
      <h3 class="mat-h3 flex flex-col">
        <span>Automated import</span>
      </h3>
      <mat-slide-toggle
        color="primary"
        (change)="onAutoImportChange()"
        [(ngModel)]="enableAutoImport"
      >
        Enable Automated Imports
      </mat-slide-toggle>
    </div>
  </div>

  <div class="mb-2">
    <h3 class="mat-h3 mt-2 flex flex-col">
      <span>Clinical trials keywords (<code>or</code> separator)</span>
      <small
        >Narrow results by filtering condition field in Clinical trial item
        using keywords</small
      >
    </h3>
    <app-chips-wrapper
      class="block"
      [items]="clinicalTrialsKeywords"
      [onChipChange]="filterCT.bind(this)"
    >
    </app-chips-wrapper>
  </div>
  <h3 class="mat-h3">Data cleanup</h3>
  <div class="flex gap-4 p-4">
    <div
      class="settings__item"
      [ngClass]="getHCPsRemoveDuplicatesButtonCSS()"
      [matTooltip]="getRemoveHCPDuplicatesTooltip()"
    >
      <button (click)="removeHCPsDuplicates()">
        <mat-icon>description</mat-icon>
        <label>HCP's duplicates</label>
        <span> Remove HCP's duplicates </span>
      </button>
    </div>
    <div
      class="settings__item"
      [ngClass]="getCentersRemoveDuplicatesButtonCSS()"
      [matTooltip]="getCentersDuplicatesTooltip()"
    >
      <button (click)="removeCentresDuplicates()">
        <mat-icon>description</mat-icon>
        <label>Center's duplicates</label>
        <span> Remove center's duplicates </span>
      </button>
    </div>
    <div class="settings__item">
      <button (click)="processMissingHCPsData()">
        <mat-icon>description</mat-icon>
        <label>HCP's data</label>
        <span> Process missing data, calculate articles count </span>
      </button>
    </div>
    <div class="settings__item">
      <button (click)="processCTData()">
        <mat-icon>description</mat-icon>
        <label>Clinical Trials</label>
        <span> Pull clinical trials data </span>
      </button>
    </div>
  </div>
</div>
