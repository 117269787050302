import { Component, Input } from '@angular/core';
import { buildHCPMock, THCP, THCPPropertyType } from '@shared/interfaces/THCP';
import { CategoriesService } from '../services/CategoriesService';

@Component({
  selector: 'app-simple-hcp-editor',
  templateUrl: './simple-hcp-editor.component.html',
  styleUrls: ['./simple-hcp-editor.component.scss'],
})
export class SimpleHcpEditorComponent {
  @Input()
  public notEditableProperties: (keyof THCP)[] = [];
  @Input()
  public editableProperties: (keyof THCP)[] = [];
  @Input()
  public isHeader: boolean = false;
  @Input()
  public projectId: string = ``;
  @Input()
  public hcp: THCP = buildHCPMock({});

  constructor(private _categoriesService: CategoriesService) {}

  public onItemUpdate(event: Event, property: keyof THCP): void {
    (this.hcp[property] as THCPPropertyType | null) = (
      event.target as HTMLInputElement
    ).value;
  }

  public getAllItems(): (keyof THCP)[] {
    return this.notEditableProperties.concat(this.editableProperties);
  }

  public getPropertyName(fieldId: string): string {
    return this._categoriesService.getFieldDescription(fieldId, this.projectId);
  }

  public getModelValue(hcp: THCP, item: keyof THCP) {
    const value = hcp[item];
    return value;
  }
}
