<div class="pdf-container">
  <pdf-viewer
    #child
    [rotation]="0"
    [original-size]="true"
    [show-all]="false"
    [fit-to-page]="false"
    [render-text]="true"
    [autoresize]="true"
    style="height: 400px"
    [show-borders]="false"
    [src]="pdfSrc"
    (page-rendered)="pageRendered()"
  >
  </pdf-viewer>
</div>
