import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  TCenter,
  TCenterProjectsData,
  buildCenterMock,
} from '@shared/interfaces/TCenter';
import { THCP, buildHCPMock } from '@shared/interfaces/THCP';
import {
  Observable,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from 'rxjs';
import { ROUTES_PATHS } from 'src/app/app-routing.module';
import { CentersService } from 'src/app/services/CentersService';
import { OptInStatuses, OptOutStatuses } from '../../../../constants/consent';
import {
  TCenterParams,
  TComponentType,
  THCPParams,
} from '../../../interfaces/TComponentParams';
import { HCPService } from '../../../services/HCPService';

@Component({
  selector: 'app-center-information',
  templateUrl: './center-information.component.html',
  styleUrls: ['./center-information.component.scss'],
})
export class CenterInformationComponent implements OnInit, OnDestroy {
  public centerControl: FormControl<TCenter | null>;
  public filteredOptions: Observable<TCenter[]>;
  @Input()
  public centerData: TCenter = buildCenterMock({});
  @Input()
  public params: THCPParams = {
    categoryId: ``,
    projectId: ``,
    hcpId: ``,
    __type: TComponentType.hcp,
  };
  @Input()
  public hcpData: THCP = {
    ...buildHCPMock({}),
  };

  private _subs: Subscription[] = [];
  optOutStatuses = OptOutStatuses;
  optInStatuses = OptInStatuses;

  constructor(
    public dialog: MatDialog,
    private _centersService: CentersService,
    private _hcpService: HCPService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this._setCenterOptions();
    this._subs.push(
      this._hcpService.getHCPsObservable().subscribe((hcps) => {
        this.hcpData = hcps.find((hcp) => hcp.id === this.params.hcpId) as THCP;
      })
    );
  }

  public ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
    this.centerData = buildCenterMock({});
    this.centerControl?.reset();
  }

  public editCenter(): void {
    const centerParams: TCenterParams = {
      centerId: this.centerData.c_id,
      categoryId: this.params.categoryId ?? ``,
      projectId: this.params.projectId ?? ``,
      __type: TComponentType.center,
    };
    this._router.navigate([`/${ROUTES_PATHS.center}`, centerParams], {
      relativeTo: this._route,
    });
  }

  public getProjectData(): TCenterProjectsData {
    return this._centersService.getProjectData(
      this.centerData,
      this.params.projectId
    );
  }

  public getScoresByCenter(center: TCenter): number {
    const projectData: TCenterProjectsData =
      this._centersService.getProjectData(center, this.params.projectId);
    return Math.round(projectData.valueScore);
  }

  private _setCenterOptions(): void {
    this.centerControl = new FormControl(this.centerData);
    this.filteredOptions = this.centerControl.valueChanges.pipe(
      startWith(``),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((value: string | null | TCenter) => {
        const name = typeof value === `string` ? value : value?.c_name;
        return name ? this._filter(name as string) : [];
      })
    );
    this.centerControl.valueChanges.subscribe(
      (value: string | null | TCenter) => {
        if (value !== null && typeof value !== `string`) {
          this.hcpData.center = value.c_id;
          this.hcpData.centerName = value.c_name;
          this.centerData = value;
          this.hcpData.city = value.c_city;
          this.hcpData.country = value.c_country;
        }
      }
    );
  }

  public displayFn(center: TCenter): string {
    return center && center.c_name ? center.c_name : ``;
  }

  private _filter(value: string = ``): Observable<TCenter[]> {
    return this._centersService
      .getCenterByName(value, this.params.projectId)
      .pipe(map((response) => response));
  }
}
