import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatSelectChange } from '@angular/material/select';
import {
  TTuple,
  influenceOptions,
} from '@shared/db-fields-mappings/HCP_DB_MAPPINGS';
import { THCP, THCPProjectsData, buildHCPMock } from '@shared/interfaces/THCP';
import { TProject, TTagItem } from '@shared/interfaces/TProject';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { Subscription } from 'rxjs';
import { CategoriesService } from 'src/app/services/CategoriesService';
import { HCPService } from 'src/app/services/HCPService';
import {
  TComponentType,
  THCPParams,
} from '../../../interfaces/TComponentParams';

@Component({
  selector: 'app-influence',
  templateUrl: './influence.component.html',
  styleUrls: ['./influence.component.scss'],
})
export class InfluenceComponent implements OnInit, OnDestroy {
  public influenceOptions: TTuple[] = structuredClone(influenceOptions);
  @Input()
  public hcpData: THCP = {
    ...buildHCPMock({}),
  };
  public availableMemberships: TTagItem[] = [];
  public selectedItems: string[] = [];
  private _subs: Subscription[] = [];
  public influenceScore: number = 0;
  @Input()
  public params: THCPParams = {
    hcpId: ``,
    categoryId: ``,
    projectId: ``,
    __type: TComponentType.hcp,
  };
  @Input()
  public studiesCount: number = 0;

  private _membershipsFieldId: keyof THCPProjectsData = `memberships_committees`;

  constructor(
    private _categoriesService: CategoriesService,
    private _HCPsService: HCPService
  ) {}

  public ngOnInit(): void {
    this._subs.push(
      this._categoriesService.getCategoriesObservable().subscribe(() => {
        const project: TProject | undefined =
          this._categoriesService.getProject(this.params.projectId);
        if (!isNullOrUndefined(project)) {
          this.availableMemberships =
            project.hcpTagsDefinitions.find(
              (def) => def.connEctedFieldId === this._membershipsFieldId
            )?.tags ?? [];
        }
      })
    );
    this._subs.push(
      this._HCPsService.getExtendedHCPsObservable().subscribe((hcps) => {
        if (hcps.length === 1) {
          const projectData: THCPProjectsData =
            this._HCPsService.getProjectData(hcps[0], this.params.projectId);
          const memberships: TTagItem[] =
            projectData?.memberships_committees ?? [];
          this.selectedItems = memberships
            .filter((it) => !isNullOrUndefined(it))
            .map((m) => m.tag);
          this.influenceScore = projectData.influenceScore;
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  public onInfluenceChange(group: MatButtonToggleGroup): void {
    this.hcpData.kols_status = Number(group.value);
  }

  public handleMembershipChangeChange(event: MatSelectChange): void {
    let projectData = this._HCPsService.getProjectData(
      this.hcpData,
      this.params.projectId
    );
    projectData.memberships_committees = this.availableMemberships.filter((t) =>
      (<string[]>event.value).includes(t.tag)
    );
  }

  public getPublicationsCount(): number {
    const count: number =
      this.hcpData.projectsData.find(
        (it) => it.projectId === this.params.projectId
      )?.history_publications_count ?? 0;
    return count;
  }
}
