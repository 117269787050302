import { TFieldNameMappingItem } from './TFieldNameMappingItem';
import { ProductName } from './TProductUse';

export function getDefaultProject(overwrites: Partial<TProject>): TProject {
  const defaultProject: TProject = {
    id: ``,
    isPinned: false,
    name: ``,
    keywords: ``,
    dataProcessed: false,
    lastProcessDate: ``,
    indicationMinDate: ``,
    description: ``,
    startYear: 0,
    selectedMinYear: 0,
    scores: {
      hcpInfluence: [],
      hcpEngagement: [],
      hcpValue: [],
      center: [],
      clinicalTrials: [],
      risingStar: [],
      hcpProductUse: [],
      risingStarCutoff: 0
    },
    articlesCount: 0,
    regions: [],
    centersCount: 0,
    hcpsCount: 0,
    tiersDefinitions: {
      center: [],
      clinicalTrials: [],
      hcpEngagement: [],
      hcpInfluence: [],
      hcpValue: [],
      risingStar: [],
      participants: [],
      hcpProductUse: []
    },
    hcpTierFieldDefinitions: [],
    centerTierFieldDefinitions: [],
    hcpTagsDefinitions: [],
    centerTagsDefinitions: [],
    centerFields: [],
    hcpFields: [],
    dynamicFields: [],
    hcpFilter: [],
    centerFilter: [],
    hcpWeight: 1,
    centerWeight: 1,
    CTWeight: 1,
    clinicalTrialsKeywords: [],
    centersDuplicatesRemoved: false,
    hcpDuplicatesRemoved: false,
    hcpIndexes: {},
    centerIndexes: {}
  };
  return Object.assign(defaultProject, overwrites);
}
//network pub weight, network CT weight, network inst weight
export type DynamicNumberProjectsField =
  | `networkPubWeight`
  | `networkCtWeight`
  | `networkInstWeight`;

export type DynamicStringProjectsField = `dynamicTestStringField`;

export type DynamicNumberProjectsFieldItem = [DynamicNumberProjectsField, number];
export type DynamicStringProjectsFieldItem = [DynamicStringProjectsField, string];

export type ProjectsDynamicFields = DynamicNumberProjectsFieldItem | DynamicStringProjectsFieldItem;

export interface TProject {
  id: string;
  name: string;
  isPinned: boolean;
  keywords: string;
  dataProcessed: boolean;
  lastProcessDate: string;
  indicationMinDate: string;
  description: string;
  startYear: number;
  selectedMinYear: number;
  scores: TScore;
  articlesCount: number;
  hcpsCount: number;
  centersCount: number;
  regions: TRegion[];
  tiersDefinitions: TTiersDefinitions;
  hcpTierFieldDefinitions: TTierFieldDefinition[];
  centerTierFieldDefinitions: TTierFieldDefinition[];
  hcpTagsDefinitions: TTagDefinition[];
  centerTagsDefinitions: TTagDefinition[];
  centerFields: TFieldNameMappingItem[];
  hcpFields: TFieldNameMappingItem[];
  dynamicFields: Array<ProjectsDynamicFields>;
  hcpFilter: TDataFilter[];
  centerFilter: TDataFilter[];
  hcpWeight: number;
  centerWeight: number;
  CTWeight: number;
  clinicalTrialsKeywords: string[];
  hcpDuplicatesRemoved: boolean;
  centersDuplicatesRemoved: boolean;
  hcpIndexes: { [key: string]: number };
  centerIndexes: { [key: string]: number };
  enableAutoImport?: boolean;
}

export interface TTagDefinition {
  connEctedFieldId: string;
  description: string;
  tags: TTagItem[];
}

export interface TTagItem {
  tag: string;
  name: string;
  value: number;
}

export interface TTierItem {
  position: number;
  name: string;
  low: number;
  high: number;
  total: number;
}

export interface TTiersDefinitions {
  hcpInfluence: TTierItem[];
  hcpEngagement: TTierItem[];
  hcpValue: TTierItem[];
  hcpProductUse: TTierItem[];
  center: TTierItem[];
  clinicalTrials: TTierItem[];
  risingStar: TTierItem[];
  participants: TTierItem[];
}

export interface TRegion {
  name: string;
  description: string;
  salesData: string;
}

export interface TScore {
  hcpInfluence: TScoreItem[];
  hcpEngagement: TScoreItem[];
  hcpValue: TScoreItem[];
  center: TScoreItem[];
  clinicalTrials: TScoreItem[];
  risingStar: TScoreItem[];
  hcpProductUse: TProductUseScoreItem[];
  risingStarCutoff: number;
}

export enum Operator {
  Addition,
  Subtraction,
  Multiplication,
  Division,
  BooleanTrue,
  BooleanFalse
}

export interface TProductUseScoreItem {
  area: 'hcpProductUse';
  availableOperators: Operator[];
  operator: Operator;
  score: number | string;
  product: ProductName;
  timePeriod: string;
}

export interface TScoreItem {
  fieldId: string;
  operator: Operator;
  score: number | string;
}

export type NoScoreReport = 'basicHcp' | 'basicCenter' | 'compliance1' | 'metrics';

export interface TNoScoreReport {
  type: NoScoreReport;
  selected: boolean;
}

export type TReportType =
  | 'ValueInfluence'
  | 'ValueEngagement'
  | 'InfluenceEngagement'
  | 'Center'
  | 'RisingStar'
  | 'ProductUseValue'
  | 'ProductUseEngagement'
  | 'ProductUseInfluence';

export type TScoreType =
  | 'hcpInfluence'
  | 'hcpEngagement'
  | 'hcpValue'
  | `hcpProductUse`
  | 'center'
  | 'clinicalTrials'
  | 'risingStar'
  | 'risingStarCutoff';

export interface TTierFieldItem {
  name: string;
  value: number;
}

export interface TTierFieldDefinition {
  fieldId: string;
  tiers: TTierFieldItem[];
}

export enum TReportFilterProperty {
  CONSENT_LEVEL_1 = 'opt_out_information_status',
  CONSENT_LEVEL_2 = 'opt_in_status'
}

export interface TDataFilter {
  property: string;
  operator: FilterOperator;
  value: number | string | boolean;
  type: `engagement` | `regular`;
  tags?: TTagItem[];
  date?: Date;
  metric?: 'year' | 'value';
}

export enum FilterOperator {
  LessOrEqual,
  Less,
  More,
  MoreOrEqual,
  Equal,
  NotEqual
}

export const filterOperatorsMap: Map<FilterOperator, string> = new Map([
  [FilterOperator.Equal, `=`],
  [FilterOperator.Less, `<`],
  [FilterOperator.LessOrEqual, `<=`],
  [FilterOperator.More, `>`],
  [FilterOperator.MoreOrEqual, `>=`],
  [FilterOperator.NotEqual, `!=`]
]);

export function operatorToName(type: Operator): string {
  switch (type) {
    case Operator.Addition:
      return `+`;
    case Operator.BooleanFalse:
      return `False`;
    case Operator.BooleanTrue:
      return `True`;
    case Operator.Division:
      return `/`;
    case Operator.Multiplication:
      return `*`;
    case Operator.Subtraction:
      return `-`;
  }
}
