<div *ngIf="!settingsVisible">
  <mat-card>
    <div class="flex flex-row gap-2 justify-between">
      <div class="flex gap-2 flex-col">
        <mat-card-title>
          <div class="flex justify-between">
            <p>Project name - {{ selectedProject?.name }}</p>
          </div>
        </mat-card-title>
        <mat-card-subtitle
          >Category: {{ selectedCategory?.name }} | Keywords:
          {{ selectedProject?.keywords }} | Start date:
          {{ selectedProject?.selectedMinYear }}
        </mat-card-subtitle>
      </div>
      <div class="text-sm flex items-center gap-4">
        <button
          type="button"
          mat-flat-button
          color="accent"
          (click)="fileInput.click()"
        >
          Import sales data
        </button>
        <input
          hidden
          (change)="importSalesDataCallback($event)"
          #fileInput
          type="file"
          id="file"
        />
        <button
          type="button"
          mat-flat-button
          color="accent"
          (click)="openReports()"
        >
          Reports
        </button>
        <!-- <button type="button" mat-flat-button color="accent" (click)="processData()">
          Process data
        </button> -->
        <button mat-flat-button color="primary" (click)="openProjectSettings()">
          <span>Settings</span>
        </button>
      </div>
    </div>
    <mat-card-content class="mat-card-content--no-padding-bottom">
      <div class="flex justify-between text-base">
        <div class="flex gap-2.5 p-2.5">
          <div>
            <div class="pt-1">
              <button
                [ngClass]="isArticlesButtonActive()"
                type="button"
                *ngIf="reviewButtonAvailable('articles')"
                mat-flat-button
                color="primary"
                (click)="showArticles()"
              >
                <span class="flex items-center">
                  <mat-icon class="w-12 h-12 mr-1">assignment</mat-icon>
                  Articles:
                  <mat-spinner
                    *ngIf="articlesCountLoading"
                    [diameter]="20"
                    color="accent"
                  ></mat-spinner>
                  <span *ngIf="!articlesCountLoading">
                    {{ selectedProject?.articlesCount }}
                    <span *ngIf="invalidIdsCount > 0" class="error warning"
                      >({{ invalidIdsCount }})</span
                    >

                    <ng-container *ngIf="articlesAfterSearchVisible()">
                      ({{ articlesAfterSearch }} <small>filtered</small>)
                    </ng-container>
                  </span>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class="flex gap-2.5 p-2.5">
          <div>
            <div class="pt-1">
              <button
                [ngClass]="isStudiesButtonActive()"
                type="button"
                *ngIf="reviewButtonAvailable('studies')"
                mat-flat-button
                color="primary"
                (click)="showStudies()"
              >
                <span class="flex items-center">
                  <mat-icon class="w-12 h-12 mr-1">book</mat-icon>
                  Clinical Trials:
                  <mat-spinner
                    *ngIf="studiesCountLoading"
                    [diameter]="20"
                    color="accent"
                  ></mat-spinner>
                  <span *ngIf="!studiesCountLoading">
                    {{ studiesIds.length }}</span
                  >
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class="flex gap-2.5 p-2.5">
          <div>
            <div class="pt-1">
              <button
                [ngClass]="isHCPsButtonActive()"
                type="button"
                *ngIf="reviewButtonAvailable('hcps')"
                mat-flat-button
                color="primary"
                (click)="showHCPs()"
              >
                <span class="flex items-center">
                  <mat-icon class="w-12 h-12 mr-1"
                    >supervised_user_circle</mat-icon
                  >
                  HCPs:
                  <mat-spinner
                    *ngIf="hcpsCountLoading"
                    [diameter]="20"
                    color="accent"
                  ></mat-spinner>
                  <span *ngIf="!hcpsCountLoading">
                    {{ selectedProject?.hcpsCount ?? 0 }}
                    <ng-container *ngIf="HCPsAfterSearchVisible()">
                      ({{ hcpsAfterSearch }} <small>filtered</small>)
                    </ng-container>
                  </span>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class="flex gap-2.5 p-2.5">
          <div>
            <div class="pt-1">
              <button
                [ngClass]="isCenterButtonActive()"
                type="button"
                *ngIf="reviewButtonAvailable('centers')"
                (click)="showCenters()"
                mat-flat-button
                color="primary"
              >
                <span class="flex items-center">
                  <mat-icon class="w-12 h-12 mr-1"
                    >store_mall_directory</mat-icon
                  >
                  Centers:
                  <mat-spinner
                    *ngIf="centersCountLoading"
                    [diameter]="20"
                    color="accent"
                  ></mat-spinner>
                  <span *ngIf="!centersCountLoading">
                    {{ selectedProject?.centersCount ?? 0 }}
                    <ng-container *ngIf="centersAfterSearchVisible()">
                      ({{ centersAfterSearch }} <small>filtered</small>)
                    </ng-container>
                  </span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <div>
    <ng-container *ngIf="articlesVisible">
      <app-articles-review
        [selectedCategory]="selectedCategory"
        [selectedProject]="selectedProject"
      >
      </app-articles-review>
    </ng-container>
    <ng-container *ngIf="hcpsVisible">
      <app-hcps-view
        [selectedCategory]="selectedCategory"
        [selectedProject]="selectedProject"
        [onImportFinish]="onImportFinish.bind(this)"
      >
      </app-hcps-view>
    </ng-container>
    <ng-container *ngIf="centersVisible">
      <app-centers-review
        [selectedCategory]="selectedCategory"
        [selectedProject]="selectedProject"
        [onImportFinish]="onImportFinish.bind(this)"
      >
      </app-centers-review>
    </ng-container>
    <ng-container *ngIf="studiesVisible">
      <app-studies-review
        [selectedCategory]="selectedCategory"
        [selectedProject]="selectedProject"
        [studiesIds]="studiesIds"
      >
      </app-studies-review>
    </ng-container>
  </div>
</div>

<div *ngIf="settingsVisible">
  <app-project-settings [params]="buildSettingsParams()"></app-project-settings>
</div>
