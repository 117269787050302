<mat-card>
  <mat-card-title>
    <div class="flex justify-between">
      <div>
        <div>
          {{ params.selectedProject?.name }} -
          {{ params.selectedProject?.description }}
        </div>
      </div>
      <div class="text-sm flex items-center gap-4">
        <mat-checkbox
          color="primary"
          (change)="handleIsPinnedChange()"
          [(ngModel)]="isPinned"
        >
          Pin to dashboard
        </mat-checkbox>
        <button
          mat-flat-button
          color="error"
          type="button"
          (click)="deleteProject()"
        >
          Delete project
        </button>
        <button mat-flat-button color="accent" (click)="params.closeSettings()">
          <span>Close</span>
        </button>
      </div>
    </div>
  </mat-card-title>
  <mat-card-subtitle
    >Category: {{ params.selectedCategory?.name }} | Keywords:
    {{ params.selectedProject?.keywords }} | Start date:
    {{ params.selectedProject?.selectedMinYear }}</mat-card-subtitle
  >
  <mat-card-content>
    <mat-card-content>
      <div class="settings">
        <!-- <button *ngIf="importAvailable()" mat-icon-button aria-label="Import" matTooltip="Import table data from excel"
    (click)="fileInput.click()">
    <mat-icon>cloud_upload</mat-icon>
  </button> -->
        <input
          hidden
          (change)="handleImportProject($event)"
          #projectFileInput
          type="file"
          id="file"
        />
        <div class="settings__item" *ngFor="let item of settingsItems">
          <button (click)="item.openCallback()">
            <mat-icon>{{ item.icon }}</mat-icon>
            <label>{{ item.name }}</label>
            <span>
              {{ item.description }}
            </span>
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card-content>

  <div class="flex justify-end gap-4">
    <button mat-flat-button color="primary" (click)="save()">
      <span>Save</span>
    </button>
    <button mat-flat-button color="primary" (click)="save(true)">
      <span>Save and recalculate</span>
    </button>
  </div>
</mat-card>
