import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { SearchArea } from '@shared/enums/SearchArea';
import { THCP } from '@shared/interfaces/THCP';
import { Order, TPaging, TSortItem } from '@shared/interfaces/TSearchParams';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { ResizeEvent } from 'angular-resizable-element';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import {
  ACTIONS_COLUMN_ID,
  ACTIONS_COLUMN_WIDTH,
  ACTIONS_ICON_WIDTH,
  TDataColumnDef,
} from 'src/utils/buildColumnsDef';
import {
  EngagementImportComponent,
  EngagementImportComponentData,
} from '../engagement-import/engagement-import.component';
import { IImportExportParams } from '../import-export-panel/import-export-panel.component';
import { IPaginationParams } from '../pagination/pagination.component';
import { HCPService } from '../services/HCPService';
import { PagingService } from '../services/paging/PagingService';
import {
  ICommonTableParams,
  PaginationVisibility,
  SortType,
  THCPTableRow,
  TMergeData,
  TOptInOutData,
  TPrioritySettingsData,
  TTDataType,
  TTableRowType,
} from './common-table.component.types';
import { MergeComponent } from './merge/merge.component';
import { OptInOutComponent } from './opt-in-out/opt-in-out.component';
import { PrioritySettingsComponent } from './priority-settings/priority-settings.component';

@Component({
  selector: 'app-common-table',
  templateUrl: './common-table.component.html',
  styleUrls: ['./common-table.component.scss'],
})
export class CommonTableComponent implements OnInit, OnChanges {
  @Input()
  public params: ICommonTableParams<TTableRowType, TTDataType>;
  public selectedSorts: string[] = [];

  public start: any = undefined;
  public pressed: boolean = false;
  public startX: any;
  public startWidth: any;
  public resizableFnMousemove: () => void;
  public resizableFnMouseup: () => void;
  public selectionMode: boolean = false;
  public selection: SelectionModel<TTableRowType> =
    new SelectionModel<TTableRowType>(true, []);
  public paginationParams: IPaginationParams;
  public importExportParams: IImportExportParams;

  public currentQuery: string = ``;
  public searchModel: string = ``;
  public searchModelChanged: Subject<string> = new Subject<string>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public mergeDialog: MatDialog,
    public engagementDialog: MatDialog,
    public optInOutDialog: MatDialog,
    public prioritySettingsDialog: MatDialog,
    private _pagingService: PagingService,
    private _HCPsService: HCPService
  ) {
    this.searchModelChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((model) => {
        this.searchModel = model;
        this.applyFilter(model);
      });
  }

  public changed(text: string) {
    this.searchModelChanged.next(text);
  }

  public ngAfterViewInit(): void {
    if (this.params.paginationVisibility === PaginationVisibility.Simple) {
      this.params.dataSource.paginator = this.paginator;
    }
    if (this.params.sortType === SortType.Simple) {
      this.params.dataSource.sort = this.sort;
      if (!isNullOrUndefined(this.params.defaultSortFieldId)) {
        setTimeout(
          () =>
            this.sort.sort({
              id: this.params.defaultSortFieldId,
              start: `desc`,
            } as MatSortable),
          0
        );
      }
    }
  }

  public getUpSortArrowCSS(column: TDataColumnDef<TTDataType>): string {
    const classes: string[] = [];
    if (this.hasOrderSet(column.columnDef)) {
      classes.push(`sort-icon--has-order-set`);
    }
    if (
      this.hasOrderSet(column.columnDef) &&
      this.isAscendingOrder(column.columnDef)
    ) {
      classes.push(`sort-icon--visible`);
    }
    return classes.join(` `);
  }

  public getDownSortArrowCSS(column: TDataColumnDef<TTDataType>): string {
    return this.hasOrderSet(column.columnDef) &&
      !this.isAscendingOrder(column.columnDef)
      ? `sort-icon--visible`
      : ``;
  }

  public isSimpleSortHeader(header: string): boolean {
    return (this.params.simpleSortHeaders ?? []).includes(header);
  }

  public extendedSortAvailable(): boolean {
    return this.params.sortType === SortType.Extended;
  }

  public selectedSortsVisible(): boolean {
    return this.selectedSorts.length > 0 && this.extendedSortAvailable();
  }

  public noneSortAvailable(): boolean {
    return this.params.sortType === SortType.None;
  }

  public simpleSortAvailable(): boolean {
    return this.params.sortType === SortType.Simple;
  }

  public importEngagementAvailable(): boolean {
    return !isNullOrUndefined(this.params.importEngagementCallback);
  }

  public importEngagement(e: Event): void {
    if (isNullOrUndefined(this.params.importEngagementCallback)) {
      console.log(`not handled yet`);
    } else {
      this._HCPsService.importEngagement(
        e,
        this.params.projectId,
        `false`,
        (errors) => {
          if (errors.length > 0) {
            const data: EngagementImportComponentData = {
              errors,
            };
            this.engagementDialog.open(EngagementImportComponent, {
              data,
            });
          } else {
            this.params.importEngagementCallback!(e, this.params.projectId);
          }
        }
      );
    }
  }

  public ngOnInit(): void {
    this.setSelectedSorts();
    if (this.params.hasRisingStarButton) {
      this.selectCheckedRisingStars();
    }
  }

  public ngOnChanges(): void {
    this.paginationParams = {
      handlePageCallback: (e) => this.paginationCallback(e),
      length: this.params.dataCount,
      pageIndex: this.params.pageIndex,
      pageSize: this.params.pageSize,
      pageSizeOptions: this.params.pageSizeOptions,
    };
    this.importExportParams = {
      area: this.params.area,
      projectId: this.params.projectId,
      downloadCallback: this.params.downloadCallback,
      importCallback: this.params.importCallback,
    };
  }

  public mergeRowsAvailable(): boolean {
    return (
      this.selectionMode &&
      this.selection.selected.length > 0 &&
      this.params.mergeHidden !== true
    );
  }

  public risingStarButtonAvailable(): boolean {
    return this.params.hasRisingStarButton || false;
  }

  public optInOutAvailable(): boolean {
    return (
      this.selectionMode &&
      this.selection.selected.length > 0 &&
      this.params.area === SearchArea.HCP
    );
  }

  public optInOut() {
    const data: TOptInOutData = {
      selectedRows: this.selection.selected as THCPTableRow[],
      projectId: this.params.projectId,
    };

    this.optInOutDialog.open(OptInOutComponent, {
      data,
    });
  }

  public prioritySettingsAvailable(): boolean {
    return (
      this.selectionMode &&
      this.selection.selected.length > 0 &&
      this.params.area === SearchArea.HCP
    );
  }

  public openPrioritySettings() {
    const data: TPrioritySettingsData = {
      selectedRows: this.selection.selected as THCPTableRow[],
      closeCallback: () => {
        this.selectionMode = false;
        this.selection.clear();
        if (!isNullOrUndefined(this.params.resetTable)) {
          this.params.resetTable();
        }
      },
    };

    this.prioritySettingsDialog.open(PrioritySettingsComponent, {
      data,
    });
  }

  public mergeRows() {
    const data: TMergeData = {
      selectedRows: this.selection.selected,
      projectId: this.params.projectId,
      mergeCallback: () => {
        this.mergeDialog.closeAll();
        this.selectionMode = false;
        this.selection.clear();
        if (!isNullOrUndefined(this.params.resetTable)) {
          this.params.resetTable();
        }
      },
    };

    this.mergeDialog.open(MergeComponent, {
      data,
    });
  }

  public saveRisingStar(): void {
    const prevSelected = this.getRisingStars(true);
    const selected = this.selection.selected as THCPTableRow[];

    const dataToSelect = selected.filter(
      (it) => !prevSelected.map((selection) => selection.id).includes(it.id)
    );
    const dataToDeselect = prevSelected.filter(
      (it) => !selected.map((selection) => selection.id).includes(it.id)
    );

    dataToSelect.forEach((it) => {
      const projectData = it.projectsData.find(
        (projectsData) => projectsData.projectId === this.params.projectId
      );
      if (projectData) {
        projectData.hcp_proj_rising_star = true;
      }
    });

    dataToDeselect.forEach((it) => {
      const projectData = it.projectsData.find(
        (projectsData) => projectsData.projectId === this.params.projectId
      );
      if (projectData) {
        projectData.hcp_proj_rising_star = false;
      }
    });
    const dataToUpdate = [...dataToSelect, ...dataToDeselect];

    this._HCPsService.batchUpdate(dataToUpdate);
  }

  public checkboxLabel(row?: TTableRowType): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  public toggleSelectionMode(): void {
    this.selectionMode = !this.selectionMode;
    const actionColumn: TDataColumnDef<TTDataType> | undefined =
      this.params.columns.find((c) => c.columnDef === ACTIONS_COLUMN_ID);
    if (!isNullOrUndefined(actionColumn)) {
      if (this.selectionMode) {
        actionColumn.width = ACTIONS_COLUMN_WIDTH + ACTIONS_ICON_WIDTH;
        actionColumn.minWidth = ACTIONS_COLUMN_WIDTH + ACTIONS_ICON_WIDTH;
      } else {
        actionColumn.width = ACTIONS_COLUMN_WIDTH;
        actionColumn.minWidth = ACTIONS_COLUMN_WIDTH;
      }
    }
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.params.dataSource.data.length;
    return numSelected === numRows;
  }

  public selectionToggleAvailable(): boolean {
    return (
      !isNullOrUndefined(this.params.selection) &&
      this.params.selection === true &&
      this.params.selectionAlwaysVisible !== true
    );
  }

  public selectionAvailable(): boolean {
    if (this.params.selectionAlwaysVisible) {
      this.params.actionsAvailable = true;
      return true;
    }
    return !isNullOrUndefined(this.params.selection) && this.selectionMode;
  }

  public toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.params.dataSource.data);
  }

  public getTableCSS(): string {
    return this.params.resize === true ? `resize` : ``;
  }

  public headerClick(column: TDataColumnDef<TTDataType>): void {
    if (column.isDataCell && this.params.handleHeaderClick !== undefined) {
      const remove: boolean =
        this.hasOrderSet(column.columnDef) &&
        !this.isAscendingOrder(column.columnDef);
      this.params.handleHeaderClick(column.columnDef, remove);
      this.setSelectedSorts();
    }
  }

  public onResizeEnd(
    event: ResizeEvent,
    column: TDataColumnDef<TTDataType>
  ): void {
    const width: number = event.rectangle.width ?? 0;
    column.width = width >= column.minWidth ? width : column.minWidth;
  }

  public getStyles(
    column: TDataColumnDef<TTDataType>
  ): Partial<CSSStyleDeclaration> {
    if (column.width > 0) {
      return {
        width: `${column.width}px`,
        maxWidth: `${column.width}px`,
        minWidth: `${column.width}px`,
      };
    }
    return {};
  }

  public addNewAvailable(): boolean {
    return this.params.addNewParams !== undefined;
  }

  private setSelectedSorts(): void {
    const paging: TPaging = this._pagingService.getPaging(
      this.params.projectId,
      this.params.area
    );
    this.selectedSorts = paging.sortBy.map((s) => {
      return (
        this.params.columns.find((c) => c.columnDef === s.fieldId)?.header ?? ``
      );
    });
  }

  private selectCheckedRisingStars(): void {
    const data = this.getRisingStars(true);
    this.selection.select(...(data as THCPTableRow[]));
  }

  private getRisingStars(isChecked: boolean): THCPTableRow[] {
    const data = (this.params.dataSource.filteredData as THCP[]).filter(
      (it) => {
        const projectData = it.projectsData.find(
          (projectsData) => projectsData.projectId === this.params.projectId
        );
        if (!projectData) {
          return false;
        }
        return isChecked
          ? projectData.hcp_proj_rising_star
          : !projectData.hcp_proj_rising_star;
      }
    );

    return data as THCPTableRow[];
  }

  public isAscendingOrder(fieldId: string): boolean {
    if (this.hasOrderSet(fieldId)) {
      const paging: TPaging = this._pagingService.getPaging(
        this.params.projectId,
        this.params.area
      );
      const item: TSortItem | undefined = paging.sortBy.find(
        (s) => s.fieldId === fieldId
      );
      if (item !== undefined) {
        return item.order === Order.Asc;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public hasOrderSet(fieldId: string): boolean {
    const paging: TPaging = this._pagingService.getPaging(
      this.params.projectId,
      this.params.area
    );
    const item: TSortItem | undefined = paging.sortBy.find(
      (s) => s.fieldId === fieldId
    );
    return item !== undefined;
  }

  public getHeaderCSS<K>(column: TDataColumnDef<K>): string {
    return column.sortable ? `sortable` : ``;
  }

  public editButtonAvailable(): boolean {
    return this.params.handleEditClick !== undefined;
  }

  public navigateToButtonAvailable(): boolean {
    return this.params.handleNavigateToClick !== undefined;
  }

  public editRow(row: TTableRowType): void {
    if (this.params.handleEditClick !== undefined) {
      this.params.handleEditClick(row);
    }
  }

  public toggleRow(row: TTableRowType): void {
    if (this.selectionAvailable()) {
      this.selection.toggle(row);
    }
  }

  public navigateTo(row: TTableRowType): void {
    if (this.params.handleNavigateToClick !== undefined) {
      this.params.handleNavigateToClick(row);
    }
  }

  public isDataColumn<T>(column: TDataColumnDef<T>): boolean {
    return column.isDataCell === true;
  }

  public topPaginationVisible(): boolean {
    const paginationVisibility: PaginationVisibility | undefined =
      this.params.paginationVisibility;
    if (paginationVisibility === PaginationVisibility.None) {
      return false;
    }
    return (
      isNullOrUndefined(paginationVisibility) ||
      paginationVisibility === PaginationVisibility.Top ||
      paginationVisibility === PaginationVisibility.Both
    );
  }

  public bottomPaginationVisible(): boolean {
    const paginationVisibility: PaginationVisibility | undefined =
      this.params.paginationVisibility;
    if (paginationVisibility === PaginationVisibility.None) {
      return false;
    }
    return (
      isNullOrUndefined(paginationVisibility) ||
      paginationVisibility === PaginationVisibility.Bottom ||
      paginationVisibility === PaginationVisibility.Both
    );
  }

  public simplePaginationVisible(): boolean {
    const paginationVisibility: PaginationVisibility | undefined =
      this.params.paginationVisibility;
    return paginationVisibility === PaginationVisibility.Simple;
  }

  public isFilterVisible(): boolean {
    return this.params.filterCallback !== undefined;
  }

  public applyFilter(text: string) {
    if (this.params.filterCallback !== undefined) {
      const currentPaging = this._pagingService.getPagingItem(
        this.params.projectId
      );
      switch (this.params.area) {
        case SearchArea.Articles:
          currentPaging.articles.pageIndex = 0;
          currentPaging.articles.query = text;
          break;
        case SearchArea.Centers:
          currentPaging.centersPaging.pageIndex = 0;
          currentPaging.centersPaging.query = text;
          break;
        case SearchArea.HCP:
          currentPaging.hcpsPaging.pageIndex = 0;
          currentPaging.hcpsPaging.query = text;
          break;
      }
      this._pagingService.updatePagingItem(
        this.params.projectId,
        currentPaging
      );

      this.currentQuery = text;
      this.params.filterCallback(text);
    }
  }

  private paginationCallback(e: PageEvent): void {
    this.params.paginationCallback(e);
    this.selection.clear();
  }

  public getWrapperCSS(): string {
    const classes: string[] = [
      `max-w-full`,
      `mt-4`,
      `px-4`,
      `inline-flex`,
      `flex-col`,
    ];
    if (!this.params.sortType) {
      classes.push(`w-full`);
    }
    if (this.selectionMode) {
      classes.push(`selection-mode`);
    }
    return classes.join(` `);
  }
}
