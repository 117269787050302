<div class="columns-2 flex w-full">
  <div class="flex w-1/2 justify-start">
    <button mat-flat-button color="warn" type="button"
      (click)="buttonsData.deleteCallback()">{{buttonsData.deleteLabel}}</button>
  </div>
  <div class="flex w-1/2 justify-end gap-5">
    <button mat-flat-button color="accent" type="button"
      (click)="buttonsData.backCallback()">{{buttonsData.backLabel}}</button>
    <button mat-flat-button color="primary" type="button" (click)="buttonsData.saveCallback()">
      {{buttonsData.saveLabel}}
    </button>
    <button mat-flat-button color="primary" type="button" (click)="buttonsData.previousCallback()">
      {{buttonsData.previousLabel}}
    </button>
    <button mat-flat-button color="primary" type="button" (click)="buttonsData.nextCallback()">
      {{buttonsData.nextLabel}}
    </button>
  </div>
</div>