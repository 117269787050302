import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TUser, UserLevel } from '@shared/interfaces/TUser';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import {
  NewUserDialogComponent,
  TNewUserData,
} from './new-user-dialog/new-user-dialog.component';
import { UsersBatchUpdateComponent } from './users-batch-update/users-batch-update.component';

export interface TUserItem {
  user: TUser;
  selected: boolean;
}

@Component({
  selector: 'app-users-management',
  templateUrl: './users-management.component.html',
  styleUrls: ['./users-management.component.scss'],
})
export class UsersManagementComponent implements OnDestroy, OnInit {
  public user: Partial<TUser> | undefined = undefined;
  public allUsers: TUserItem[] = [];
  private _subs: Subscription[] = [];

  constructor(
    public newUserDialog: MatDialog,
    public usersBatchUpdateDialog: MatDialog,
    private _authService: AuthService
  ) {}

  public openNewUserDialog() {
    const dialogRef = this.newUserDialog.open(NewUserDialogComponent);
    dialogRef.afterClosed().subscribe((result: TUser | undefined) => {
      if (!isNullOrUndefined(result)) {
        this._authService.signup(result);
      }
    });
  }

  public ngOnInit(): void {
    this._subs.push(
      this._authService.getCurrentUserObservable().subscribe((user) => {
        this.user = user;
      })
    );
    this._subs.push(
      this._authService.getAllUsersObservable().subscribe((users) => {
        this.allUsers = users
          .filter((it) => it.email !== this.user?.email)
          .map((it) => {
            return {
              selected: false,
              user: it,
            };
          });
      })
    );
  }

  public editUser(userEmail: string): void {
    let isCurrentUser: boolean = false;
    let userIndex: number = -1;
    let user: Partial<TUser> | undefined =
      this.user?.email === userEmail ? this.user : undefined;
    if (isNullOrUndefined(user)) {
      userIndex = this.allUsers.findIndex((it) => it.user.email === userEmail);
      if (userIndex >= 0) {
        user = this.allUsers[userIndex].user;
      }
    } else {
      isCurrentUser = true;
    }
    if (!isNullOrUndefined(user)) {
      const dialogRef = this.newUserDialog.open<
        NewUserDialogComponent,
        TNewUserData
      >(NewUserDialogComponent, {
        data: {
          isNew: false,
          user: user,
          currentUser: user.email === this.user?.email,
          levelChangeAvailable: user.email !== this.user?.email,
        },
      });
      dialogRef.afterClosed().subscribe((result: TUser | undefined) => {
        if (!isNullOrUndefined(result)) {
          if (isCurrentUser) {
            this.user = result;
          } else {
            this.allUsers[userIndex].user = result;
          }

          this._authService.updateUser(result);
        }
      });
    }
  }

  public deleteUser(userEmail: string): void {
    let user: Partial<TUser> | undefined = [
      this.user,
      ...this.allUsers.map((it) => it.user),
    ].find((it) => it?.email === userEmail);
    if (!isNullOrUndefined(user)) {
      this._authService.deleteUser(user);
    }
  }

  public selectedChange(userEmail: string): void {
    let user: TUserItem | undefined = this.allUsers.find(
      (it) => it?.user.email === userEmail
    );
    if (!isNullOrUndefined(user)) {
      user.selected = !user.selected;
    }
  }

  public toggleSelectAll(): void {
    if (
      this.allUsers.some((it) => !it.selected) ||
      this.allUsers.every((it) => !it.selected)
    ) {
      this.allUsers.forEach((it) => (it.selected = true));
    } else if (this.allUsers.every((it) => it.selected)) {
      this.allUsers.forEach((it) => (it.selected = false));
    }
  }

  public getToggleAllDescription(): string {
    if (
      this.allUsers.some((it) => !it.selected) ||
      this.allUsers.every((it) => !it.selected)
    ) {
      return `Select all`;
    }
    return `Deselect all`;
  }

  public isAdminUser(): boolean {
    return this.user?.level === UserLevel.Admin;
  }

  public ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  public getCurrentUser(): void {
    this._authService.setUserByToken();
  }

  public triggerBatchUpdate(): void {
    const dialogRef = this.newUserDialog.open(UsersBatchUpdateComponent, {
      width: `50%`,
    });
    dialogRef.afterClosed().subscribe((result: TUser) => {
      if (!isNullOrUndefined(result)) {
        this.allUsers
          .filter((it) => it.selected)
          .forEach((it) => {
            it.user.categories = result.categories;
            it.user.projects = result.projects;
          });
      }
    });
  }
}
