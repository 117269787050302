<mat-form-field appearance="fill" class="w-full">
    <mat-label>{{placeholder}}</mat-label>
    <mat-chip-grid #chipGrid aria-label="Specialties">
        <mat-chip-option *ngFor="let specialty of items" (removed)="remove(specialty)">
            {{ specialty }}
            <button matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip-option>
        <input placeholder="Add new" [matChipInputFor]="chipGrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)" />
    </mat-chip-grid>
</mat-form-field>