import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TSupportDoc } from '@shared/interfaces/TSettings';
import { Subscription } from 'rxjs';
import { PdfViewerModalComponent } from '../pdf-viewer-modal/pdf-viewer-modal.component';
import { SettingsService } from '../services/SettingsService';

@Component({
  selector: 'app-support-page',
  templateUrl: './support-page.component.html',
  styleUrls: ['./support-page.component.scss'],
})
export class SupportPageComponent implements OnDestroy, OnInit {
  public supportDocs: TSupportDoc[] = [];
  private _subs: Subscription[] = [];
  public pageAvailable: boolean = true;

  constructor(
    public dialog: MatDialog,
    private _settingsService: SettingsService
  ) {}

  public ngOnInit(): void {
    this._subs.push(
      this._settingsService.getAppSettingsObservable().subscribe((settings) => {
        this.supportDocs = settings.supportDocs;
      })
    );
  }

  public ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  openDialog(doc: TSupportDoc) {
    this.dialog.open(PdfViewerModalComponent, {
      data: { name: doc.fileName, url: doc.fileUrl },
      width: '100%',
      maxWidth: '1200px',
    });
  }
}
