export const PAPER_NOTIFICATION_CONSTANTS = {
  TITLE: `{{title}}`,
  FIRST_NAME_REPLACER: `{{first_name}}`,
  LAST_NAME_REPLACER: `{{last_name}}`,
  TITLE_REPLACER: `{{title}}`,
  COMPANY_NAME_REPLACER: `{{company}}`,
  CENTER_NAME: `{{centerName}}`,
  STREET: `{{street}}`,
  ZIP: `{{zip}}`,
  CITY: `{{city}}`,
  COUNTRY: `{{country}}`,
  CONSENT_HASHTAG: `{{consent_hashtag}}`,
};
