import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Operator, TScoreItem } from '@shared/interfaces/TProject';
import { ScoreArea } from '../Constants';
import { TBaseSettingsTabParams } from '../project-settings.component';

export interface TBaseScoreItem extends TScoreItem {
  fieldName: string;
  availableOperators: Operator[];
  area: ScoreArea;
}

export enum ScoreSettingsType {
  Base,
  Cutoff,
}

export interface TScoreSettingsWithCutoffParams extends TScoreSettingsParams {
  __type: ScoreSettingsType.Cutoff;
  cutoff: number;
  showCutoff: boolean;
}

export interface TScoreSettingsParams {
  __type: ScoreSettingsType;
  availableFields: TBaseScoreItem[];
  selectedItems: TBaseScoreItem[];
  params: TBaseSettingsTabParams;
  name: string;
  scoreArea: ScoreArea;
  tiersAvailable: boolean;
}

export function isCutoffSettingsParams(
  params: TScoreSettingsParams
): params is TScoreSettingsWithCutoffParams {
  return params.__type === ScoreSettingsType.Cutoff;
}

@Component({
  selector: 'app-scoring-settings',
  templateUrl: './scoring-settings.component.html',
  styleUrls: ['./scoring-settings.component.scss'],
})
export class ScoringSettingsComponent implements OnInit {
  public availableFields: TBaseScoreItem[] = [];
  public selectedItems: TBaseScoreItem[] = [];
  public params: TBaseSettingsTabParams;
  public cutoff: number = 0;

  public addNewSelectedValue: TBaseScoreItem = {
    fieldId: ``,
    availableOperators: [],
    fieldName: ``,
    operator: Operator.Addition,
    score: 0,
    area: `center`,
  };
  public addNewOperator: Operator = Operator.Addition;
  public addNewScoreValue: number = 0;
  public scoreArea: ScoreArea = `hcpEngagement`;
  public tiersAvailable: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<ScoringSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TScoreSettingsWithCutoffParams
  ) {
    this.availableFields = data.availableFields;
    this.selectedItems = data.selectedItems;
    this.params = data.params;
    this.scoreArea = data.scoreArea;
    this.cutoff = data.cutoff;
    this.tiersAvailable = data.tiersAvailable;
  }

  ngOnInit(): void {}

  public getScoreValue(field: TBaseScoreItem): number {
    if (Number.isNaN(field.score)) {
      return 0;
    } else {
      return field.score as number;
    }
  }

  public addNewField(
    addNewSelectedValue: TBaseScoreItem | undefined,
    addNewOperator: Operator | undefined,
    scoreValue: number | string
  ): void {
    if (addNewSelectedValue !== undefined) {
      const newItem: TBaseScoreItem = structuredClone(addNewSelectedValue);
      if (addNewOperator !== undefined) {
        newItem.operator = addNewOperator;
      }
      newItem.score = scoreValue;
      this.selectedItems.push(newItem);
    }
  }

  public deleteField(field?: TBaseScoreItem): void {
    if (field !== undefined) {
      const indexToRemove: number = this.selectedItems.findIndex(
        (f) => field.fieldId === f.fieldId
      );
      this.selectedItems.splice(indexToRemove, 1);
    }
  }
}
