<td>
  <span class="project">{{ project }}</span>
</td>
<td class="date">
  <input
    type="date"
    [ngModel]="
      engagementTrackingItem.engagement_actual_date | date : 'yyyy-MM-dd'
    "
    (ngModelChange)="engagementTrackingItem.engagement_actual_date = $event"
    (change)="onDateChange($event)"
  />
</td>
<td>
  <textarea
    class="description"
    cdkTextareaAutosize
    placeholder="Objective"
    [(ngModel)]="engagementTrackingItem.engagement_actual_activity"
  ></textarea>
</td>
<td class="quantity">
  <input
    class="quantity"
    [(ngModel)]="engagementTrackingItem.engagement_actual_quantity"
    type="number"
  />
</td>
<td class="tags">
  <mat-select
    class="tags tag-wrapper"
    multiple
    (selectionChange)="handleTagsChangeChange($event)"
    [(ngModel)]="selectedItems"
  >
    <mat-option
      *ngFor="let membership of getAvailableTags()"
      [value]="membership.tag"
    >
      {{ membership.tag }}
    </mat-option>
  </mat-select>
</td>
<td class="person">
  <input
    class="person"
    [(ngModel)]="engagementTrackingItem.engagement_actual_point_person"
  />
</td>
<td>
  <button
    color="error"
    class="mr-4 delete-button"
    mat-icon-button
    (click)="deleteCallback(engagementTrackingItem)"
    matTooltip="Delete item"
  >
    <mat-icon>delete</mat-icon>
  </button>
</td>
