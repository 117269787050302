import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { Endpoints } from '@shared/Endpoints';
import { OptInStatus, OptOutStatus, THCP } from '@shared/interfaces/THCP';
import { HCPService } from 'src/app/services/HCPService';
import { environment } from 'src/environments/environment';

import { isNullOrEmpty } from '@shared/utils/isNullOrEmpty';

import {
  ConfirmDialogData,
  ConfirmationDialogComponent,
} from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { PaperNotificationService } from 'src/app/services/PaperNotificationService';
import { newGuid } from 'src/utils/uuid';
import { LoadingService } from '../../services/LoadingService';
import { TOptInOutData } from '../common-table.component.types';

const dialogData: ConfirmDialogData = {
  title: `Postal mail notification`,
  message:
    "You have selected HCPs in countries that require postal mail notifications. By clicking 'I agree', you confirm that you are downloading, printing and mailing these notifications. Your confirmation is captured in the database with your user identity and will be kept for legal and documentation purposes.",
  confirmButton: `I agree`,
  dismissButton: `Cancel`,
};

enum OptInOutHCPStatus {
  Valid,
  InvalidEmail,
  MissingPostalAddress,
  NegativeResponse,
  ContactedOptInDeclined,
}

interface TOptInOutHCP {
  data: THCP;
  isSelected: boolean;
  message: string;
  status: OptInOutHCPStatus;
}

@Component({
  selector: 'app-opt-in-out',
  templateUrl: './opt-in-out.component.html',
  styleUrls: ['./opt-in-out.component.scss'],
})
export class OptInOutComponent implements OnInit {
  public displayedOptInHCPs: TOptInOutHCP[] = [];
  public displayedOptOutHCPs: TOptInOutHCP[] = [];
  public invalidHCPs: TOptInOutHCP[] = [];
  public notEditablePropertiesEmail: (keyof THCP)[] = [
    `first_name`,
    `last_name`,
  ];
  public editablePropertiesEmail: (keyof THCP)[] = [`primary_email_address`];

  public notEditablePropertiesStatuses: (keyof THCP)[] = [
    `first_name`,
    `last_name`,
    `primary_email_address`,
  ];

  optOutInProgress: boolean = false;
  optInInProgress: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TOptInOutData,
    private _http: HttpClient,
    private _hcpService: HCPService,
    private _loadingService: LoadingService,
    private _paperNotificationService: PaperNotificationService,
    public dialog: MatDialog
  ) {
    this._setDisplayedHCPs();
  }

  public ngOnInit(): void {}

  public getHCPsWithInvalidEmails(): TOptInOutHCP[] {
    return this.invalidHCPs.filter(
      (it) => it.status === OptInOutHCPStatus.InvalidEmail
    );
  }

  public getHCPsWithMissingPostalAddress(): TOptInOutHCP[] {
    return this.invalidHCPs.filter(
      (it) => it.status === OptInOutHCPStatus.MissingPostalAddress
    );
  }

  public getHCPsWithInvalidStatus(): TOptInOutHCP[] {
    return this.invalidHCPs.filter(
      (it) =>
        it.status === OptInOutHCPStatus.ContactedOptInDeclined ||
        it.status === OptInOutHCPStatus.NegativeResponse ||
        it.data.opt_in_status === OptInStatus.Declined ||
        it.data.opt_out_information_status === OptOutStatus.Declined
    );
  }

  public sendOptIn(): void {
    this.optInInProgress = true;

    const optInHCPs: THCP[] = this.displayedOptInHCPs
      .filter((it) => it.isSelected)
      .map((it) => {
        return {
          ...it.data,
          primary_email_address: it.data.primary_email_address.trim(),
        };
      });
    const url: string = Endpoints.BUILD_URL('mailer', {
      base: environment.apiUrl,
      endpoint: 'optIn',
      params: ``,
    });
    const response = this._http.post(url, {
      hcps: optInHCPs,
    });

    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
        this.optInInProgress = false;
      },
      next: (res) => {
        this._loadingService.triggerOkMessage(`Opt in messages sent`);
        optInHCPs.forEach((it) => {
          it.opt_in_status = OptInStatus.ContactedNoResponse;
        });
        this._hcpService.batchUpdate(
          optInHCPs.map((it) => {
            return {
              id: it.id,
              opt_in_status: it.opt_in_status,
            };
          })
        );
      },
      complete: () => {
        this._updateSelectedHCPsOptInStatusInTable(optInHCPs);
        this._setDisplayedHCPs();
        this.optInInProgress = false;
      },
    });
  }

  public sendOptOut(): void {
    this.optOutInProgress = true;

    const selectedOptOutHCPs: TOptInOutHCP[] = this.displayedOptOutHCPs.filter(
      (hcp) => hcp.isSelected
    );
    const paperOptOutHcps: THCP[] = [];
    const emailOptOutHcps: THCP[] = [];
    selectedOptOutHCPs.forEach((hcp) => {
      if (this._hcpService.doesRequirePaperNotification(hcp.data)) {
        paperOptOutHcps.push(hcp.data);
      } else {
        emailOptOutHcps.push({
          ...hcp.data,
          primary_email_address: hcp.data.primary_email_address.trim(),
        });
      }
    });

    if (paperOptOutHcps.length > 0) {
      this._sendOptOutPapers(paperOptOutHcps);
    }
    if (emailOptOutHcps.length > 0) {
      this._sendOptOutEmails(emailOptOutHcps);
    }
  }

  private _sendOptOutPapers(paperOptOutHcps: THCP[]): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: dialogData,
      width: '600px',
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this._paperNotificationService.createForHCPs(paperOptOutHcps, () => {
          paperOptOutHcps.forEach((hcp) => {
            hcp.opt_out_paper_mail_printed_date = new Date();
            hcp.optOutHash = newGuid();
          });
          this._hcpService.batchUpdate(
            paperOptOutHcps.map((hcp) => {
              return {
                id: hcp.id,
                opt_out_paper_mail_printed_date:
                  hcp.opt_out_paper_mail_printed_date,
                optOutHash: hcp.optOutHash,
              };
            })
          );
          this.optOutInProgress = false;
        });
      } else {
        this.optOutInProgress = false;
      }
    });
  }

  private _sendOptOutEmails(optOutHCPs: THCP[]): void {
    const url: string = Endpoints.BUILD_URL('mailer', {
      base: environment.apiUrl,
      endpoint: 'optOut',
      params: ``,
    });
    const response = this._http.post(url, {
      hcps: optOutHCPs,
    });

    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
        this.optOutInProgress = false;
      },
      next: (res) => {
        this._loadingService.triggerOkMessage(`Opt out messages sent`);
        optOutHCPs.forEach((it) => {
          it.opt_out_information_status = OptOutStatus.InformedAndAccepted;
        });
        this._hcpService.batchUpdate(
          optOutHCPs.map((it) => {
            return {
              id: it.id,
              opt_out_information_status: it.opt_out_information_status,
            };
          })
        );
      },
      complete: () => {
        this._updateSelectedHCPsOptOutStatusInTable(optOutHCPs);
        this._setDisplayedHCPs();
        this.optOutInProgress = false;
      },
    });
  }

  private _updateSelectedHCPsOptInStatusInTable(updatedHCPs: THCP[]): void {
    this.data.selectedRows = this.data.selectedRows.map((hcp) => {
      if (updatedHCPs.find((it) => it.id === hcp.id)) {
        return {
          ...hcp,
          opt_in_status: OptInStatus.ContactedNoResponse,
        };
      } else {
        return hcp;
      }
    });
  }

  private _updateSelectedHCPsOptOutStatusInTable(updatedHCPs: THCP[]): void {
    this.data.selectedRows = this.data.selectedRows.map((hcp) => {
      if (updatedHCPs.find((it) => it.id === hcp.id)) {
        return {
          ...hcp,
          opt_out_information_status: OptOutStatus.InformedAndAccepted,
        };
      } else {
        return hcp;
      }
    });
  }

  public updateSelectedInvalidHCPs(): void {
    const validUpdateItems: TOptInOutHCP[] = this.invalidHCPs.filter(
      (it) =>
        !isNullOrEmpty(it.data.primary_email_address) &&
        it.status === OptInOutHCPStatus.InvalidEmail
    );
    this._hcpService.batchUpdate(validUpdateItems.map((it) => it.data));
    this._setDisplayedHCPs();
  }

  private _setDisplayedHCPs(): void {
    this._setDisplayedOptInHCPs();
    this._setDisplayedOptOutHCPs();
    this._setInvalidHCPs();
  }

  private _setDisplayedOptInHCPs(): void {
    this.displayedOptInHCPs = this.data.selectedRows
      .filter(
        (hcp) =>
          (hcp.opt_out_information_status ===
            OptOutStatus.InformedAndAccepted ||
            hcp.opt_out_information_status === OptOutStatus.Declined) &&
          hcp.opt_in_status === OptInStatus.NotContacted &&
          hcp.primary_email_address
      )
      .map((hcp) => {
        return {
          data: hcp,
          isSelected: true,
          message: ``,
          status: OptInOutHCPStatus.Valid,
        };
      });
  }

  private _setDisplayedOptOutHCPs(): void {
    this.displayedOptOutHCPs = this.data.selectedRows
      .filter(
        (hcp) =>
          hcp.opt_out_information_status === OptOutStatus.NotInformed &&
          ((!this._hcpService.doesRequirePaperNotification(hcp) &&
            hcp.primary_email_address) ||
            (this._hcpService.doesRequirePaperNotification(hcp) &&
              this._isHCPPostalAddressValid(hcp)))
      )
      .map((hcp) => {
        return {
          data: hcp,
          isSelected: true,
          message: ``,
          status: OptInOutHCPStatus.Valid,
        };
      });
  }

  private _setInvalidHCPs(): void {
    this.invalidHCPs = [];
    this.data.selectedRows.forEach((hcp) => {
      let message: string = ``;
      let status: OptInOutHCPStatus = OptInOutHCPStatus.Valid;
      if (
        this._hcpService.doesRequirePaperNotification(hcp) &&
        !this._isHCPPostalAddressValid(hcp)
      ) {
        message = `No postal address`;
        status = OptInOutHCPStatus.MissingPostalAddress;
      }
      if (
        !this._hcpService.doesRequirePaperNotification(hcp) &&
        isNullOrEmpty(hcp.primary_email_address)
      ) {
        message = `No primary email`;
        status = OptInOutHCPStatus.InvalidEmail;
      }
      if (hcp.opt_in_status === OptInStatus.Declined) {
        message = `HPC returned negative response for opt in`;
        status = OptInOutHCPStatus.NegativeResponse;
      }
      if (hcp.opt_out_information_status === OptOutStatus.Declined) {
        message = `HPC declined opt out`;
        status = OptInOutHCPStatus.ContactedOptInDeclined;
      }
      if (message.length > 0) {
        this.invalidHCPs.push({
          data: hcp,
          isSelected: false,
          message,
          status,
        });
      }
    });
  }

  private _isHCPPostalAddressValid(hcp: THCP): boolean {
    return (
      !isNullOrEmpty(hcp.street1) &&
      !isNullOrEmpty(hcp.zip) &&
      !isNullOrEmpty(hcp.city) &&
      !isNullOrEmpty(hcp.country) &&
      !isNullOrEmpty(hcp.first_name) &&
      !isNullOrEmpty(hcp.last_name)
    );
  }
}
