import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@shared/Endpoints';
import { environment } from 'src/environments/environment';

@Injectable()
export class PDFService {
  constructor(private _http: HttpClient) {}

  public loadSalesData(
    filename: string,
    callback: (res: any) => void = (res: any) => void 0
  ): void {
    const url: string = Endpoints.BUILD_URL('import', {
      base: environment.apiUrl,
      endpoint: 'pdf',
      params: `${filename}`,
    });
    this._http.get(url).subscribe((res: any) => {
      callback(res);
    });
  }
}
