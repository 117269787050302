<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <!-- use a disabled button to provide padding for tree leaf -->
        <button mat-icon-button disabled></button>
        <mat-form-field appearance="fill">
            <input matInput required [(ngModel)]="node.name" />
        </mat-form-field>
        <button mat-icon-button matTooltip="{{getDeleteContent(node.baseNode)}}" matTooltipPosition="above"
            (click)="handleDelete(node.baseNode)" aria-label="Delete" color="error">
            <mat-icon>delete_forever</mat-icon>
        </button>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
        </button>
        <mat-form-field appearance="fill">
            <input matInput required [(ngModel)]="node.name" />
        </mat-form-field>
        <button mat-icon-button matTooltip="{{getDeleteContent(node.baseNode)}}" matTooltipPosition="above"
            (click)="handleDelete(node.baseNode)" aria-label="Delete" color="error">
            <mat-icon>delete_forever</mat-icon>
        </button>
    </mat-tree-node>
</mat-tree>
<div class="flex mt-4 justify-end">
    <button mat-flat-button color="primary" (click)="updateSettings()">
        <span>Save settings</span>
    </button>
</div>