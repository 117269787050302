export enum TComponentType {
  hcp = 'hcp',
  center = 'center',
}

export enum TComponentParent {
  report = `report`,
}

export interface TBaseComponentParams {
  __type: TComponentType;
  categoryId: string;
  projectId: string;
  parent?: TComponentParent;
}
//TODO add first_name and last_name see studies component
export interface THCPParams extends TBaseComponentParams {
  hcpId: string;
}

export interface TCenterParams extends TBaseComponentParams {
  centerId: string;
}

export function isHCPParams(obj: TBaseComponentParams): obj is THCPParams {
  return obj.__type === TComponentType.hcp;
}

export function isCenterParams(
  obj: TBaseComponentParams
): obj is TCenterParams {
  return obj.__type === TComponentType.center;
}
