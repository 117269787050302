import { Component, Input, OnInit } from '@angular/core';
import { TProject, TScoreType, TTierItem } from '@shared/interfaces/TProject';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { CategoriesService } from 'src/app/services/CategoriesService';

export interface TReportCell {
  isSelected: boolean;
  xValue: string;
  yValue: string;
}

export interface TReportComponentParams {
  xAxisLabel: string;
  yAxisLabel: string;
  xAxisType: TScoreType;
  yAxisType: TScoreType;
  matrix: TReportCell[][];
  projectId: string;
}

const padding: number = 75;
const maxDimension: number = 375;

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
  @Input()
  public params: TReportComponentParams = {
    matrix: [],
    xAxisLabel: `X axis label`,
    yAxisLabel: `Y axis label`,
    xAxisType: `hcpEngagement`,
    yAxisType: `hcpInfluence`,
    projectId: ``,
  };
  public isPressed: boolean = false;
  constructor(private _categoriesService: CategoriesService) {}

  ngOnInit(): void {}

  public cellClick(cell: TReportCell): void {
    cell.isSelected = !cell.isSelected;
  }

  public toggleSelection(): void {
    const allCellsSelected: boolean = this.params.matrix
      .flat()
      .every((it) => it.isSelected);
    if (allCellsSelected) {
      this.params.matrix.forEach((row) => {
        row.forEach((cell) => {
          cell.isSelected = false;
        });
      });
    } else {
      this.params.matrix.forEach((row) => {
        row.forEach((cell) => {
          cell.isSelected = true;
        });
      });
    }
  }

  public getCellTooltip(cell: TReportCell): string {
    const xTooltip: string = `x axis: ${this.getTierValue(
      cell.xValue,
      this.params.xAxisType
    )}`;
    const yTooltip: string = `\n y axis: ${this.getTierValue(
      cell.yValue,
      this.params.yAxisType
    )}`;
    if (this.isVectorReport()) {
      return xTooltip;
    }

    return `${xTooltip}${yTooltip}`;
  }

  public isVectorReport(): boolean {
    return (
      this.params.xAxisType === 'risingStar' ||
      this.params.xAxisType === 'center'
    );
  }

  public getCellCSS(cell: TReportCell): string {
    return cell.isSelected ? `report-component__row__cell--selected` : ``;
  }

  public reportComponentVisible(): boolean {
    return this.params.matrix.length > 0 && this.params.matrix[0].length > 0;
  }

  public getContainerDimensions(): {
    width: string;
    height: string;
    padding: string;
  } {
    const matrix: TReportCell[][] = this.params.matrix;
    if (matrix.length > 0) {
      const highestCellsCount: number = Math.max(
        this.params.matrix.length,
        this.params.matrix[0].length
      );
      const width: number = Math.round(maxDimension / highestCellsCount);
      return {
        width: `${width * matrix.length + padding}px`,
        height: `${width * matrix[0].length + padding}px`,
        padding: `0 0 ${padding}px ${padding}px`,
      };
    }
    return {
      height: `auto`,
      width: `auto`,
      padding: `0`,
    };
  }

  public getCellDimensions(): { width: string; height: string } {
    const matrix: TReportCell[][] = this.params.matrix;
    if (matrix.length > 0) {
      const highestCellsCount: number = Math.max(
        this.params.matrix.length,
        this.params.matrix[0].length
      );
      const width: number = Math.round(maxDimension / highestCellsCount);
      return {
        height: `${width}px`,
        width: `${width}px`,
      };
    }
    return {
      height: `auto`,
      width: `auto`,
    };
  }

  private getTierValue(tierName: string, axisType: TScoreType): string {
    const project: TProject | undefined = this._categoriesService.getProject(
      this.params.projectId
    );
    if (!isNullOrUndefined(project)) {
      let tierItems: TTierItem[] = [];
      switch (axisType) {
        case `center`:
          tierItems = project.tiersDefinitions.center;
          break;
        case `hcpEngagement`:
          tierItems = project.tiersDefinitions.hcpEngagement;
          break;
        case `hcpInfluence`:
          tierItems = project.tiersDefinitions.hcpInfluence;
          break;
        case `hcpValue`:
          tierItems = project.tiersDefinitions.hcpValue;
          break;
        case `risingStar`:
          tierItems = project.tiersDefinitions.risingStar;
          break;
        case `hcpProductUse`:
          tierItems = project.tiersDefinitions.hcpProductUse;
          break;
      }
      const tier = tierItems.find((tier) => tier.name === tierName);
      return `${tier?.name}`;
    }
    return `N/A`;
  }
}
