import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TFieldNameMappingItem } from '@shared/interfaces/TFieldNameMappingItem';
import { TBaseSettingsTabParams } from '../project-settings.component';

export interface TFieldsNamingParams {
  params: TBaseSettingsTabParams;
  centerFieldsNames: TFieldNameMappingItem[];
}

@Component({
  selector: 'app-center-fields',
  templateUrl: './center-fields.component.html',
  styleUrls: ['./center-fields.component.scss'],
})
export class CenterFieldsComponent implements OnInit {
  public params: TBaseSettingsTabParams;
  public centerFieldsNames: TFieldNameMappingItem[] = [];

  constructor(
    public dialogRef: MatDialogRef<CenterFieldsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TFieldsNamingParams
  ) {
    this.params = this.data.params;
    this.centerFieldsNames = this.data.centerFieldsNames;
  }

  ngOnInit(): void {}
}
