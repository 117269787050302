<div>
    <h2 class="mat-h2 p-4">
        <div>Engagement import file contains errors</div>
        <!-- <div class="text-sm">Importing file - {{ fileName }}</div> -->
    </h2>
    <div class="px-4 pb-4" *ngIf="data.errors.length > 0">
        <ul>
            <li class="mb-1" *ngFor="let error of data.errors">{{error}}</li>
        </ul>
    </div>
</div>