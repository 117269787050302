<div class="flex justify-center">
  <mat-card class="w-1/2 mt-4">
    <mat-card-title>Add new project</mat-card-title>
    <mat-card-content>
      <div class="new-project__content justify-between flex gap-3">
        <form
          [formGroup]="newProjectForm"
          (ngSubmit)="handleSearch()"
          class="w-full"
        >
          <div class="new-project__content__search-form flex flex-col">
            <mat-form-field appearance="fill">
              <mat-label>Indication name</mat-label>
              <input required matInput formControlName="indicationName" />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Indication description</mat-label>
              <input matInput formControlName="indicationDescription" />
            </mat-form-field>
            <div class="form-field-wrapper--inline flex gap-3">
              <mat-form-field appearance="fill" class="form-field w-full">
                <mat-label>Indication categories</mat-label>
                <mat-select
                  required
                  formControlName="selectedCategory"
                  [(value)]="newProjectForm.value.selectedCategory"
                >
                  <mat-option
                    *ngFor="let category of categories"
                    [value]="category.categoryId"
                  >
                    {{ category.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button
                class="add-new"
                mat-flat-button
                type="button"
                color="primary"
                (click)="openNewCategoryDialog('0ms', '0ms')"
              >
                Add new
              </button>
            </div>
            <mat-form-field appearance="fill">
              <mat-label>Indication min. year</mat-label>
              <input matInput formControlName="indicationMinYear" />
            </mat-form-field>
            <div class="checkbox-wrapper">
              <mat-checkbox color="primary" formControlName="pinToDashboard">
                Pin to dashboard
              </mat-checkbox>
            </div>
            <mat-form-field appearance="fill">
              <mat-label>Indication keywords</mat-label>
              <input
                required
                matInput
                type="text"
                formControlName="indicationKeywords"
              />
            </mat-form-field>
            <div
              *ngIf="
                newProjectForm.controls.indicationKeywords.errors &&
                (newProjectForm.controls.indicationKeywords.dirty ||
                  newProjectForm.controls.indicationKeywords.touched)
              "
            >
              <div
                class="warning"
                *ngIf="
                  newProjectForm.controls.indicationKeywords.errors['required']
                "
              >
                Keywords are required.
              </div>
              <div
                class="warning"
                *ngIf="
                  newProjectForm.controls.indicationKeywords.errors[
                    'invalidFormat'
                  ]
                "
              >
                Input should be in the format of single or multiple strings
                separated by OR, AND, or NOT, and each string should be enclosed
                in parenthesis or quotes.
              </div>
            </div>
            <small class="info"
              >Run the query on
              <a href="https://pubmed.ncbi.nlm.nih.gov/" target="_blank"
                >pubmed.ncbi.nlm.nih.gov</a
              >(advanced query if several keywords) and copy that query into the
              indication keywords field. For example, (alagille) OR (liver
              transplantation) but do not use [Date - publication] because we
              alredy have it as indication min year</small
            >
            <div class="form-field-wrapper--actions flex gap-3 justify-end">
              <button mat-flat-button color="accent" type="submit">
                Search
              </button>
              <button
                mat-flat-button
                color="warn"
                type="button"
                (click)="handleCancel()"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
        <div
          class="new-project__content__search-results w-full"
          *ngIf="searchResultsVisible"
        >
          <mat-list>
            <div class="flex flex-col">
              <div mat-subheader>Search results</div>
              <mat-list-item>
                <mat-icon mat-list-icon>description</mat-icon>
                <div mat-line>Articles found: {{ numberOfArticles }}</div>
              </mat-list-item>
              <mat-divider></mat-divider>
              <mat-list-item>
                <div class="flex justify-center w-full mt-3">
                  <button
                    mat-flat-button
                    color="primary"
                    (click)="addProject()"
                  >
                    Add project
                  </button>
                </div>
              </mat-list-item>
            </div>
          </mat-list>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
