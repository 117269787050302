<div class="import">
  <h2 class="mat-h2 mt-2">
    <div>Import column mapper</div>
    <div class="text-sm">Importing file - {{ fileName }}</div>
  </h2>
  <div *ngIf="!validated">
    <div class="overflow-auto flex relative h-[600px] justify-center">
      <div>
        <h3 class="mat-h3">Local columns</h3>
        <div class="import__list">
          <div>
            <ng-container *ngFor="let header of localHeaders">
              <div class="flex">
                <div class="import__list__box w-full">
                  <div class="flex flex-col leading-tight">
                    <div>{{ header.name }}</div>
                    <small class="italic">({{ header.id }})</small>
                  </div>
                </div>
                <div class="import__list__arrow flex h-full items-center">
                  <mat-icon>arrow_right_alt</mat-icon>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div>
        <h3 class="mat-h3">Matched columns from file</h3>
        <div cdkDropList #matchedHeaders="cdkDropList" [cdkDropListData]="importedFileHeaders"
          [cdkDropListConnectedTo]="[notMatchedHeaders]" class="import__list"
          (cdkDropListDropped)="dropToMapped($event)">
          <div class="import__list__box" *ngFor="let header of importedFileHeaders" cdkDrag
            [cdkDragDisabled]="header.id === ''">
            <div class="import__list__box__placeholder" *cdkDragPlaceholder></div>
            {{ header.id }}
          </div>
        </div>
      </div>
      <div class="ml-8 sticky top-0 overflow-auto pr-2">
        <h3 class="mat-h3">Not matched columns from file</h3>
        <div cdkDropList #notMatchedHeaders="cdkDropList" [cdkDropListData]="notMatchedColumns"
          [cdkDropListConnectedTo]="[matchedHeaders]" class="import__list"
          (cdkDropListDropped)="dropToNotMatched($event)">
          <div class="import__list__box" *ngFor="let header of notMatchedColumns" cdkDrag>
            <div class="import__list__box__placeholder" *cdkDragPlaceholder></div>
            {{ header.id }}
          </div>
        </div>
      </div>
    </div>
    <div class="import__actions flex justify-end p-4">
      <button mat-flat-button color="primary" type="button" (click)="validate()">
        Validate
      </button>
    </div>
  </div>
  <div *ngIf="validated">
    <div class="overflow-auto flex relative h-[600px] justify-center">
      <div *ngIf="validationResults.length > 0">
        <p *ngFor="let item of validationResults">
          {{ item }}
        </p>
      </div>
      <div *ngIf="validationResults.length === 0">
        <p>File is valid</p>
      </div>
    </div>
    <div class="import__actions flex justify-end p-4">
      <button mat-flat-button color="primary" type="button" (click)="import()">
        Import
      </button>
    </div>
  </div>
</div>