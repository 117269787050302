export interface TProductUse {
  product: ProductName;
  formulary_status: FormularyStatus;
  sales: TProductSales[];
}

export interface TProductSales {
  time_period: string;
  iu: number;
}

export interface TProductSalesTableData {
  product: ProductName;
  formulary_status: FormularyStatus;
  time_period: string;
  iu: number;
}

export type ProductName = string;
export type FormularyStatus = 1 | 2 | 3;

// TODO: move to utils, test it
export function validateTimePeriodImportFormat(timePeriod: string): boolean {
  const pattern1 = /^(?:\d{4}(?:H[12]|Q[1-4])?)$/;
  const pattern2 = /^(?:H[12]|Q[1-4])\d{4}$/;
  return pattern1.test(timePeriod) || pattern2.test(timePeriod);
}

export function validateTimePeriodSaveFormat(timePeriod: string): boolean {
  const pattern = /^(?:\d{4}H[12]|\d{4}Q[1-4])$/;
  return pattern.test(timePeriod);
}

export function convertToTableData(productUses: TProductUse[]): TProductSalesTableData[] {
  return productUses.flatMap(
    (productUse) => {
      return productUse.sales.map((sale) => ({
        product: productUse.product,
        formulary_status: productUse.formulary_status,
        time_period: sale.time_period,
        iu: sale.iu
      }));
    }
    // productUse.sales.map((sale) => ({
    //   product: productUse.product,
    //   formulary_status: productUse.formulary_status,
    //   time_period: sale.time_period,
    //   iu: sale.iu
    // }))
  );
}
