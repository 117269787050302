import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CLINICAL_TRIALS_DB_MAPPINGS } from '@shared/db-fields-mappings/CLINICAL_TRIALS_DB_MAPPINGS';
import { SearchArea } from '@shared/enums/SearchArea';
import { TCategory } from '@shared/interfaces/TCategory';
import { TCtData } from '@shared/interfaces/TCtData';
import { TProject } from '@shared/interfaces/TProject';
import { Subscription } from 'rxjs';
import { TDataColumnDef, buildColumnsDef } from 'src/utils/buildColumnsDef';
import { sortByDate } from 'src/utils/sort';
import { sortingDataAccessor } from 'src/utils/sortingDataAccessor';
import {
  ICommonTableParams,
  PaginationVisibility,
  SortType,
  TCtDataTableRow,
  TTDataType,
  TTableRowType,
  TableRowType,
} from '../common-table/common-table.component.types';
import { ClinicalTrialsService } from '../services/ClinicalTrialsService';

@Component({
  selector: 'app-studies-review',
  templateUrl: './studies-review.component.html',
  styleUrls: ['./studies-review.component.scss'],
})
export class StudiesReviewComponent implements OnInit, OnDestroy {
  public dataSource: MatTableDataSource<TCtDataTableRow>;
  public clinicalTrialsData: TCtData[] = [];
  public columns: TDataColumnDef<TCtData>[] = [
    ...buildColumnsDef({
      textColumns: [],
      fieldsMappings: Array.from(CLINICAL_TRIALS_DB_MAPPINGS.values()).filter(
        (f) => f.visibleInSummary
      ),
      columnIdsWithLink: [`studyTitle`],
    }),
  ];

  @Input()
  public selectedCategory: TCategory | undefined;
  @Input()
  public selectedProject: TProject | undefined;
  @Input()
  public studiesIds: string[] = [];

  private _subs: Subscription[] = [];

  constructor(private _clinicalTrialsService: ClinicalTrialsService) {
    this.dataSource = new MatTableDataSource();
    this.dataSource.sortingDataAccessor = sortingDataAccessor;
    this._subs.push(
      this._clinicalTrialsService
        .getClinicalTrialsObservable()
        .subscribe((ctData) => {
          this.clinicalTrialsData = ctData;
          if (this.clinicalTrialsData.length > 0) {
            const dataColumns: TCtDataTableRow[] = this.clinicalTrialsData.map(
              (center, index) => {
                return {
                  ...center,
                  position: index + 1,
                  __type: TableRowType.CT,
                };
              }
            );
            this.dataSource.data = sortByDate(
              dataColumns,
              `studyStartYear`,
              `desc`
            );
          } else {
            this.dataSource.data = [];
          }
        })
    );
  }

  public ngOnInit(): void {}

  public ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
  }

  public getTableParams(): ICommonTableParams<TTableRowType, TTDataType> {
    return {
      columns: this.columns as TDataColumnDef<TTDataType>[],
      dataCount: this.clinicalTrialsData.length,
      dataLoaded: true,
      dataSource: this.dataSource as MatTableDataSource<TTableRowType>,
      displayedColumns: Array.from(CLINICAL_TRIALS_DB_MAPPINGS.values())
        .filter((f) => f.visibleInSummary)
        .map((f) => f.fieldId),
      handleEditClick: () => void 0,
      pageIndex: 0,
      pageSize: 1000,
      pageSizeOptions: [10, 25, 50, 100],
      paginationCallback: () => void 0,
      downloadCallback: undefined,
      importCallback: undefined,
      area: SearchArea.None,
      projectId: this.selectedProject?.id ?? ``,
      selection: false,
      actionsAvailable: false,
      sortType: SortType.Simple,
      resize: true,
      handleNavigateToClick: (row: TTableRowType) =>
        this._handleNavigateToClick(<TCtDataTableRow>row),
      paginationVisibility: PaginationVisibility.Simple,
      simpleSortHeaders: [`Title`, `Condition`, `Study Start Date`],
      defaultSortFieldId: 'studyStartYear',
    };
  }

  private _handleNavigateToClick(row: TCtDataTableRow): void {
    window.open(row.studyURL, '_blank')?.focus();
  }
}
