import { SharedConstants } from '@shared/Constants';
import { TDBFieldMappingItem } from '@shared/interfaces/TDBFieldMappingItem';

export const PRODUCT_USE_MAPPINGS: Map<string, TDBFieldMappingItem> = new Map([
  [
    `c_id`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_id`,
      name: `Center Id`,
      isSortable: false,
      visibleInSummary: false,
      visibleInShortSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `product`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `product`,
      name: `Product Name`,
      isSortable: false,
      visibleInSummary: false,
      visibleInShortSummary: true,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `time_period`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `time_period`,
      name: `Time Period`,
      isSortable: false,
      visibleInSummary: false,
      visibleInShortSummary: true,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `iu`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `iu`,
      name: `IU`,
      isSortable: false,
      visibleInSummary: false,
      visibleInShortSummary: true,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `formulary_status`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `formulary_status`,
      name: `Formulary Status`,
      isSortable: false,
      visibleInSummary: false,
      visibleInShortSummary: true,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ]
]);
