import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@shared/Endpoints';
import { TSearchAllParams } from '@shared/interfaces/TSearchAllParams';
import { TSearchGroup } from '@shared/interfaces/TSearchGroup';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoadingService } from './LoadingService';
import { RegionsService, WORLD_REGION } from './RegionsService';
@Injectable()
export class SearchService {
  private _searchResults: BehaviorSubject<TSearchGroup[]> = new BehaviorSubject<
    TSearchGroup[]
  >([]);

  constructor(
    private _http: HttpClient,
    private _loadingService: LoadingService,
    private _regionsService: RegionsService
  ) {}

  public getSearchResultsObservable(): Observable<TSearchGroup[]> {
    return this._searchResults.asObservable();
  }

  public performSearch(text: string = ``, projectId: string = ``): void {
    const url: string = Endpoints.BUILD_URL('rise', {
      base: environment.apiUrl,
      endpoint: 'searchAll',
      params: ``,
    });
    const regions: string[] = this._regionsService
      .getSelectedCountries()
      .map((r) => r.value);
    const countries: string[] = regions.includes(WORLD_REGION.value)
      ? []
      : regions;
    const body: TSearchAllParams = {
      text,
      projectId,
      countries,
    };
    const response = this._http.post<TSearchGroup[]>(url, body);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (response) => {
        this._searchResults.next(response);
      },
    });
  }

  public getSearchResults(projectId: string): Observable<TSearchGroup[]> {
    const url: string = Endpoints.BUILD_URL('rise', {
      base: environment.apiUrl,
      endpoint: 'searchAll',
      params: ``,
    });
    const regions: string[] = this._regionsService
      .getSelectedCountries()
      .map((r) => r.value);
    const countries: string[] = regions.includes(WORLD_REGION.value)
      ? []
      : regions;
    const body: TSearchAllParams = {
      text: ``,
      projectId,
      countries,
    };
    const response = this._http.post<TSearchGroup[]>(url, body);
    return response.pipe(tap((data) => data));
  }

  private _filter(opt: string[], value: string): string[] {
    const filterValue = value.toLowerCase();
    return opt.filter((item) => item.toLowerCase().includes(filterValue));
  }
}
