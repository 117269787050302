export function objectParamsToString(obj: Record<string, any>): string {
  let stringParams: string = `?`;
  let counter: number = 0;
  const paramsLength: number = Object.keys(obj).length;
  for (const key in obj) {
    stringParams += `${key}=${obj[key]}`;
    counter++;
    if (counter < paramsLength) {
      stringParams += `&`;
    }
  }
  return stringParams;
}
