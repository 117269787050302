import { CENTER_DB_MAPPINGS } from '@shared/db-fields-mappings/CENTER_DB_MAPPINGS';
import { HCP_DB_MAPPINGS } from '@shared/db-fields-mappings/HCP_DB_MAPPINGS';
import { Operator } from '@shared/interfaces/TProject';

import { TBaseScoreItem } from './scoring-settings/scoring-settings.component';

export type ScoreArea =
  | `hcpInfluence`
  | `hcpEngagement`
  | `hcpValue`
  | `center`
  | `risingStar`
  | `clinicalTrials`
  | `hcpProductUse`;

export const MATHEMATICAL_OPERATORS: Operator[] = [
  Operator.Addition,
  Operator.Division,
  Operator.Multiplication,
  Operator.Subtraction,
];
export const BOOLEAN_OPERATORS: Operator[] = [
  Operator.BooleanFalse,
  Operator.BooleanTrue,
];

export function buildHCPScoreItem(
  fieldId: string, //TODO make it keyof THCP / TCenter or keep in sync
  operator: Operator,
  availableOperators: Operator[],
  area: ScoreArea,
  score: number | string = 0
): TBaseScoreItem {
  return {
    fieldId,
    fieldName: HCP_DB_MAPPINGS.get(fieldId)?.name ?? ``,
    score,
    operator,
    availableOperators,
    area,
  };
}

export function buildCenterScoreItem(
  fieldId: string,
  operator: Operator,
  availableOperators: Operator[],
  area: ScoreArea,
  score: number | string = 0
): TBaseScoreItem {
  return {
    fieldId,
    fieldName: CENTER_DB_MAPPINGS.get(fieldId)?.name ?? ``,
    score,
    operator,
    availableOperators,
    area,
  };
}

export const AVAILABLE_CENTERS_FIELDS: TBaseScoreItem[] = [
  buildCenterScoreItem(
    `c_project_reference`,
    Operator.Multiplication,
    MATHEMATICAL_OPERATORS,
    `center`
  ),
  buildCenterScoreItem(
    `c_beds`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `center`
  ),
  buildCenterScoreItem(
    `number_of_publications`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `center`
  ),
  buildCenterScoreItem(
    `number_of_clinical_trials`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `center`
  ),
  buildCenterScoreItem(
    `c_proj_bu1_potential1`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `center`
  ),
  buildCenterScoreItem(
    `c_proj_bu1_potential2`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `center`
  ),
  buildCenterScoreItem(
    `c_proj_bu1_clin1`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `center`
  ),
  buildHCPScoreItem(
    `c_beds`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `c_inpatient_days`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `c_inpatient_surgeries`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `c_adultcardiacsurgery`,
    Operator.Addition,
    BOOLEAN_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `c_trauma_center`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `c_proj_CABP_surgeries`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `c_proj_periop_hemorrhage`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `c_decile`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpValue`
  ),
];

export const AVAILABLE_CT_FIELDS: TBaseScoreItem[] = [
  buildCenterScoreItem(
    `number_of_clinical_trials`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `center`
  ),
];

export const AVAILABLE_INFLUENCE_FIELDS: TBaseScoreItem[] = [
  buildHCPScoreItem(
    `history_publications_count`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpInfluence`
  ),
  buildHCPScoreItem(
    `studies`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpInfluence`
  ),
  buildHCPScoreItem(
    `treats_adult_patients`,
    Operator.BooleanFalse,
    BOOLEAN_OPERATORS,
    `hcpInfluence`
  ),
  buildHCPScoreItem(
    `treats_pediatric_patients`,
    Operator.BooleanFalse,
    BOOLEAN_OPERATORS,
    `hcpInfluence`
  ),
  buildHCPScoreItem(
    `participated_in_guidelines`,
    Operator.BooleanFalse,
    BOOLEAN_OPERATORS,
    `hcpInfluence`
  ),
  buildHCPScoreItem(
    `clinical_trials_principal_investigator`,
    Operator.BooleanFalse,
    BOOLEAN_OPERATORS,
    `hcpInfluence`
  ),
  buildHCPScoreItem(
    `hcp_proj_interest_as_advisor`,
    Operator.BooleanFalse,
    BOOLEAN_OPERATORS,
    `hcpInfluence`
  ),
  buildHCPScoreItem(
    `hcp_proj_interested_in_research_study`,
    Operator.BooleanFalse,
    BOOLEAN_OPERATORS,
    `hcpInfluence`
  ),
  buildHCPScoreItem(
    `speaker_presenter_frequency`,
    Operator.BooleanFalse,
    BOOLEAN_OPERATORS,
    `hcpInfluence`
  ),
  buildHCPScoreItem(
    `hcp_proj_potential_to_influence_peers`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `c_proj_bu1_clin1`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpInfluence`
  ),
  buildHCPScoreItem(
    `c_proj_bu1_potential1`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpInfluence`
  ),
  buildHCPScoreItem(
    `c_proj_bu1_potential2`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpInfluence`
  ),
  buildHCPScoreItem(
    `digital_influencer`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpInfluence`
  ),
  buildHCPScoreItem(
    `memberships_committees`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpInfluence`
  ),
];

export const AVAILABLE_ENGAGEMENT_FIELDS: TBaseScoreItem[] = [
  buildHCPScoreItem(
    `engagementTags`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpEngagement`
  ),
];

export const AVAILABLE_VALUE_FIELDS: TBaseScoreItem[] = [
  buildHCPScoreItem(
    `history_publications_count`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `treats_adult_patients`,
    Operator.BooleanFalse,
    BOOLEAN_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `treats_pediatric_patients`,
    Operator.BooleanFalse,
    BOOLEAN_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `participated_in_guidelines`,
    Operator.BooleanFalse,
    BOOLEAN_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `clinical_trials_principal_investigator`,
    Operator.BooleanFalse,
    BOOLEAN_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `hcp_proj_interest_as_advisor`,
    Operator.BooleanFalse,
    BOOLEAN_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `hcp_proj_interested_in_research_study`,
    Operator.BooleanFalse,
    BOOLEAN_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `speaker_presenter_frequency`,
    Operator.BooleanFalse,
    BOOLEAN_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `hcp_proj_potential_to_influence_peers`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `c_proj_bu1_clin1`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `c_proj_bu1_potential1`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `c_proj_bu1_potential2`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `digital_influencer`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `memberships_committees`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `c_proj_bu1_use1`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `c_proj_bu1_use2`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `c_beds`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `c_inpatient_days`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `c_inpatient_surgeries`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `c_adultcardiacsurgery`,
    Operator.Addition,
    BOOLEAN_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `c_trauma_center`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `c_proj_CABP_surgeries`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `c_proj_periop_hemorrhage`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpValue`
  ),
  buildHCPScoreItem(
    `c_decile`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `hcpValue`
  ),
];

export const AVAILABLE_RISING_STAR_FIELDS: TBaseScoreItem[] = [
  buildHCPScoreItem(
    `history_publications_count`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `risingStar`
  ),
  buildHCPScoreItem(
    `treats_adult_patients`,
    Operator.BooleanFalse,
    BOOLEAN_OPERATORS,
    `risingStar`
  ),
  buildHCPScoreItem(
    `treats_pediatric_patients`,
    Operator.BooleanFalse,
    BOOLEAN_OPERATORS,
    `risingStar`
  ),
  buildHCPScoreItem(
    `participated_in_guidelines`,
    Operator.BooleanFalse,
    BOOLEAN_OPERATORS,
    `risingStar`
  ),
  buildHCPScoreItem(
    `clinical_trials_principal_investigator`,
    Operator.BooleanFalse,
    BOOLEAN_OPERATORS,
    `risingStar`
  ),
  buildHCPScoreItem(
    `hcp_proj_interest_as_advisor`,
    Operator.BooleanFalse,
    BOOLEAN_OPERATORS,
    `risingStar`
  ),
  buildHCPScoreItem(
    `hcp_proj_interested_in_research_study`,
    Operator.BooleanFalse,
    BOOLEAN_OPERATORS,
    `risingStar`
  ),
  buildHCPScoreItem(
    `speaker_presenter_frequency`,
    Operator.BooleanFalse,
    BOOLEAN_OPERATORS,
    `risingStar`
  ),
  buildHCPScoreItem(
    `hcp_proj_potential_to_influence_peers`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `risingStar`
  ),
  buildHCPScoreItem(
    `c_proj_bu1_clin1`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `risingStar`
  ),
  buildHCPScoreItem(
    `c_proj_bu1_potential1`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `risingStar`
  ),
  buildHCPScoreItem(
    `c_proj_bu1_potential2`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `risingStar`
  ),
  buildHCPScoreItem(
    `digital_influencer`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `risingStar`
  ),
  buildHCPScoreItem(
    `memberships_committees`,
    Operator.Addition,
    MATHEMATICAL_OPERATORS,
    `risingStar`
  ),
];
