<div class="flex gap-4">
  <mat-form-field appearance="fill">
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="regionData.name" required />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Description</mat-label>
    <input matInput [(ngModel)]="regionData.description" required />
  </mat-form-field>
  <button *ngIf="regionData.salesData.length > 0" mat-button (click)="openPDF()">{{regionData.salesData}}</button>

  <div>
    <button mat-flat-button color="accent" aria-label="Import" matTooltip="Import sales data"
      (click)="fileInput.click()">
      <mat-icon class="mr-4">cloud_upload</mat-icon>
      <span class="inline-flex">Import sales data</span>
    </button>
    <input hidden (change)="importSalesData($event)" #fileInput type="file" id="file" />
  </div>
  <button *ngIf="deleteVisible()" mat-icon-button (click)="deleteRegion()" aria-label="Delete" color="error">
    <mat-icon>delete_forever</mat-icon>
  </button>
</div>
