import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Endpoints } from '@shared/Endpoints';
import {
  THCP,
  TProximityDataForHCP,
  TProximityScoreItem,
} from '@shared/interfaces/THCP';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { sortBy } from '@shared/utils/sortBy';
import { CacheService } from 'src/app/services/CacheService';
import { environment } from '../../../../environments/environment';
import { ROUTES_PATHS } from '../../../app-routing.module';
import {
  TComponentType,
  THCPParams,
} from '../../../interfaces/TComponentParams';
import { LoadingService } from '../../../services/LoadingService';
import { NEW_HCP_ID } from '../../hcp.component';

@Component({
  selector: 'app-simple-proximity',
  templateUrl: './simple-proximity.component.html',
  styleUrls: ['./simple-proximity.component.scss'],
})
export class SimpleProximityComponent implements OnChanges {
  @Input()
  public params: THCPParams = {
    categoryId: ``,
    projectId: ``,
    __type: TComponentType.hcp,
    hcpId: ``,
  };
  @Input()
  public hcpData: THCP | undefined = undefined;
  public proximityData: TProximityScoreItem[] = [];
  public proximityLoaded: boolean = false;

  constructor(
    private _http: HttpClient,
    private _loadingService: LoadingService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _cacheService: CacheService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!isNullOrUndefined(changes['hcpData'])) {
      const hcp: THCP | undefined = changes['hcpData'].currentValue;
      if (!isNullOrUndefined(hcp)) {
        this.getProximityScore(hcp);
      }
    }
  }

  public isNew(): boolean {
    return this.params.hcpId === NEW_HCP_ID;
  }

  public navigateToHCP(hcpId: string): void {
    const hcpParams: THCPParams = {
      hcpId,
      categoryId: this.params.categoryId ?? ``,
      projectId: this.params.projectId ?? ``,
      __type: TComponentType.hcp,
    };
    this.proximityLoaded = false;
    this._router.navigate([`/${ROUTES_PATHS.hcp}`, hcpParams], {
      relativeTo: this._route,
    });
  }

  private getProximityScore(hcpData: THCP): void {
    if (!isNullOrUndefined(hcpData) && hcpData.id !== '') {
      const proximityScoreData: TProximityDataForHCP | undefined =
        this._cacheService.getHCPProximityScore(
          hcpData.id,
          this.params.projectId
        );
      if (proximityScoreData) {
        this.proximityLoaded = true;
        this.handleProximityData(proximityScoreData);
      } else {
        this.proximityLoaded = false;
        this.getProximityScoreFromAPI(hcpData);
      }
    }
  }

  private getProximityScoreFromAPI(hcpData: THCP): void {
    const url: string = Endpoints.BUILD_URL('proximity', {
      base: environment.apiUrl,
      endpoint: 'getData',
      params: ``,
    });
    if (!isNullOrUndefined(hcpData) && hcpData.id !== '') {
      const payload: { hcpsId: string; projectId: string } = {
        hcpsId: hcpData.id,
        projectId: this.params.projectId,
      };
      const response = this._http.post<TProximityDataForHCP>(url, payload);
      response.subscribe({
        error: () => {
          this._loadingService.onError();
        },
        next: (response) => {
          this.proximityLoaded = true;
          this._cacheService.setHCPProximityScore(
            hcpData.id,
            this.params.projectId,
            response
          );
          this.handleProximityData(response);
        },
      });
    }
  }

  private handleProximityData(data: TProximityDataForHCP): void {
    const sortedList: TProximityScoreItem[] = sortBy(
      data.proximityScore.proximitySum,
      `count`
    )
      .reverse()
      .slice(0, 5);
    this.proximityData = sortedList;
  }
}
