import { Component, Input, OnInit } from '@angular/core';
import {
  Operator,
  TProject,
  TScoreItem,
  operatorToName,
} from '@shared/interfaces/TProject';
import { TBaseScoreItem } from '../scoring-settings.component';

export interface TScoringItemComponentParams {
  deleteField(field?: TBaseScoreItem): void;
}
@Component({
  selector: 'app-scoring-item',
  templateUrl: './scoring-item.component.html',
  styleUrls: ['./scoring-item.component.scss'],
})
export class ScoringItemComponent implements OnInit {
  @Input() public availableFields: TBaseScoreItem[] = [];
  @Input() public isNew: boolean = false;
  @Input() public selectedField: TBaseScoreItem = {
    fieldId: ``,
    availableOperators: [],
    fieldName: ``,
    operator: Operator.Addition,
    score: 0,
    area: `center`,
  };
  @Input() public operator: Operator = Operator.Addition;
  @Input() public scoreValue: number | string = 0;
  @Input() public addNewField: (
    addNewSelectedValue: TBaseScoreItem | undefined,
    addNewOperator: Operator | undefined,
    scoreValue: number | string
  ) => void = () => void 0;
  @Input() public deleteField: (field?: TBaseScoreItem) => void = () => void 0;
  @Input() public project: TProject | undefined;

  constructor() {}

  public ngOnInit(): void {}

  public addNewFieldDisabled(
    addNewSelectedValue: TBaseScoreItem | undefined,
    addNewOperator: Operator | undefined,
    scoreValue: number | string
  ): boolean {
    const isDisabled: boolean =
      addNewSelectedValue === undefined ||
      addNewSelectedValue.fieldId === `` ||
      addNewOperator === undefined ||
      Number.isNaN(scoreValue);
    return isDisabled;
  }

  public getScoreValue(field: TScoreItem): string {
    return field.score.toString();
  }

  public operatorToName(type: Operator): string {
    return operatorToName(type);
  }
}
