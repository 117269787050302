<div class="flex pt-5 gap-4">
  <div class="w-1/2">
    <div *ngIf="isNew()" class="flex gap-2 items-center mb-2">
      <div class="flex w-full search-wrapper">
        <mat-form-field appearance="fill" class="w-full no-margin">
          <mat-label>Search existing Center</mat-label>
          <input
            type="text"
            placeholder="Type at least 3 letters"
            matInput
            [formControl]="centerControl"
            [matAutocomplete]="auto"
            [value]="existingCenterSearch"
          />
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option
              *ngFor="let option of filteredOptions | async"
              [value]="option"
              (click)="fillCenter(option)"
            >
              {{ option.c_name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-spinner *ngIf="isLoading" [diameter]="30"></mat-spinner>
      </div>
      <button mat-flat-button color="primary" mat-button (click)="clearData()">
        Clear data
      </button>
    </div>
    <h3 class="mat-h3">Key information</h3>
    <div class="flex gap-4">
      <div class="flex flex-col w-1/2">
        <mat-form-field appearance="fill">
          <mat-label>Name</mat-label>
          <input matInput [(ngModel)]="centerData.c_name" />
        </mat-form-field>
        <app-autocomplete-wrapper
          #child
          [options]="countries"
          [label]="'Country'"
          [selectionChangeCallback]="onSelectionChange.bind(this)"
        ></app-autocomplete-wrapper>
        <div class="flex gap-4">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>City</mat-label>
            <input matInput [(ngModel)]="centerData.c_city" />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>State</mat-label>
            <input matInput [(ngModel)]="centerData.c_state" />
          </mat-form-field>
        </div>
        <div class="flex gap-4">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>Address</mat-label>
            <input matInput [(ngModel)]="centerData.c_address" />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Zip code</mat-label>
            <input matInput [(ngModel)]="centerData.c_zip" />
          </mat-form-field>
        </div>
        <mat-form-field appearance="fill">
          <mat-label>E-mail</mat-label>
          <input matInput [(ngModel)]="centerData.c_email" />
        </mat-form-field>
        <h3 class="mat-h3">US information</h3>
        <mat-form-field appearance="fill">
          <mat-label>Center AHA ID</mat-label>
          <input type="string" matInput [(ngModel)]="centerData.c_US_AHA_ID" />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Center US GPO</mat-label>
          <input matInput [(ngModel)]="centerData.c_US_GPO_name" />
        </mat-form-field>
        <div class="flex gap-4">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>Center GPO city</mat-label>
            <input matInput [(ngModel)]="centerData.c_US_GPO_city" />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>GPO State</mat-label>
            <input matInput [(ngModel)]="centerData.c_US_GPO_state" />
          </mat-form-field>
        </div>
        <mat-form-field appearance="fill">
          <mat-label>Center US system name</mat-label>
          <input matInput [(ngModel)]="centerData.c_US_system_name" />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Center US system ID</mat-label>
          <input
            type="number"
            matInput
            [(ngModel)]="centerData.c_US_systemID"
          />
        </mat-form-field>
        <div class="flex gap-4">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>Center US system city</mat-label>
            <input matInput [(ngModel)]="centerData.c_US_system_city" />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>US system state</mat-label>
            <input matInput [(ngModel)]="centerData.c_US_system_state" />
          </mat-form-field>
        </div>
      </div>
      <div class="flex flex-col w-1/2">
        <mat-form-field appearance="fill">
          <mat-label>Center decile for the project</mat-label>
          <input matInput [(ngModel)]="getProjectData().c_decile" />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Number of beds</mat-label>
          <input type="number" matInput [(ngModel)]="centerData.c_beds" />
        </mat-form-field>
        <mat-slide-toggle
          class="mb-2"
          [checked]="getProjectData().c_proj_treatment_center"
          [(ngModel)]="getProjectData().c_proj_treatment_center"
        >
          Treatment center
        </mat-slide-toggle>
        <mat-slide-toggle
          class="mb-2"
          [checked]="getProjectData().c_project_reference"
          [(ngModel)]="getProjectData().c_project_reference"
        >
          Reference center
        </mat-slide-toggle>
        <mat-slide-toggle
          class="mb-2"
          [checked]="centerData.c_gene_therapy_center"
          [(ngModel)]="centerData.c_gene_therapy_center"
        >
          Gene therapy center
        </mat-slide-toggle>
        <mat-slide-toggle
          class="mb-2"
          [checked]="centerData.c_for_profit"
          [(ngModel)]="centerData.c_for_profit"
        >
          For profit
        </mat-slide-toggle>
        <mat-slide-toggle
          class="mb-2"
          [checked]="centerData.c_340b"
          [(ngModel)]="centerData.c_340b"
        >
          340b hospital (US only)
        </mat-slide-toggle>
        <mat-slide-toggle
          class="mb-2"
          [checked]="centerData.c_US_directpurchase"
          [(ngModel)]="centerData.c_US_directpurchase"
        >
          Center direct purchasing
        </mat-slide-toggle>
        <mat-slide-toggle
          class="mb-2"
          [checked]="centerData.c_adultcardiacsurgery"
          [(ngModel)]="centerData.c_adultcardiacsurgery"
        >
          Center adult cardiac surgery
        </mat-slide-toggle>
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Center inpatient days</mat-label>
          <input
            type="number"
            matInput
            [(ngModel)]="centerData.c_inpatient_days"
          />
        </mat-form-field>
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Center inpatient surgeries</mat-label>
          <input
            type="number"
            matInput
            [(ngModel)]="centerData.c_inpatient_surgeries"
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Center trauma level certification</mat-label>
          <mat-select [(value)]="centerData.c_trauma_center">
            <mat-option
              *ngFor="let tier of getTiers('c_trauma_center')"
              [value]="tier.name"
              >{{ tier.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Medicare/Medicaid proportion</mat-label>
          <input
            type="number"
            matInput
            [(ngModel)]="centerData.c_US_medicaremedicaid"
          />
        </mat-form-field>
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Cardio pulmonary bypass surgeries/year</mat-label>
          <input
            type="number"
            matInput
            [(ngModel)]="centerData.c_proj_CABP_surgeries"
          />
        </mat-form-field>
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Perioperative hemorrhages/year</mat-label>
          <input
            type="number"
            matInput
            [(ngModel)]="centerData.c_proj_periop_hemorrhage"
          />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="w-1/2">
    <h3 class="mat-h3">
      {{ appSettings.companySpecificFieldsDescription }}
    </h3>
    <div class="flex flex-col score-view-wrapper">
      <app-tier-component
        [projectId]="centerParams.projectId"
        [centerData]="centerData"
      ></app-tier-component>
    </div>
    <div class="mb-2 flex gap-4">
      <mat-form-field>
        <mat-label>Account region 1</mat-label>
        <mat-select [(value)]="getProjectData().c_project_region1">
          <mat-option *ngFor="let region of availableRegions" [value]="region">
            {{ region }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Account region 2</mat-label>
        <mat-select [(value)]="getProjectData().c_project_region2">
          <mat-option *ngFor="let region of availableRegions" [value]="region">
            {{ region }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="flex gap-4">
      <div class="flex flex-col w-1/2 justify-between">
        <mat-form-field appearance="fill">
          <mat-label>{{ getFieldDescription("c_proj_bu1_use3") }}</mat-label>
          <mat-select [(value)]="getProjectData().c_proj_bu1_use3">
            <mat-option
              *ngFor="let tier of getTiers('c_proj_bu1_use3')"
              [value]="tier.name"
              >{{ tier.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ getFieldDescription("c_proj_bu1_clin2") }}</mat-label>
          <mat-select [(value)]="getProjectData().c_proj_bu1_clin2">
            <mat-option
              *ngFor="let tier of getTiers('c_proj_bu1_clin2')"
              [value]="tier.name"
              >{{ tier.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Number of surgical procedures</mat-label>
          <input
            type="number"
            matInput
            [(ngModel)]="getProjectData().c_proj_procedures"
          />
        </mat-form-field>
      </div>
      <div class="flex flex-col w-1/2 justify-between">
        <mat-slide-toggle
          class="mb-2"
          [checked]="getProjectData().c_proj_bu1_patient_group1"
          [(ngModel)]="getProjectData().c_proj_bu1_patient_group1"
        >
          {{ getFieldDescription("c_proj_bu1_patient_group1") }}
        </mat-slide-toggle>
        <mat-slide-toggle
          class="mb-2"
          [checked]="getProjectData().c_proj_bu1_patient_group2"
          [(ngModel)]="getProjectData().c_proj_bu1_patient_group2"
        >
          {{ getFieldDescription("c_proj_bu1_patient_group2") }}
        </mat-slide-toggle>
        <mat-slide-toggle
          class="mb-2"
          [checked]="getProjectData().c_proj_bu1_patient_group3"
          [(ngModel)]="getProjectData().c_proj_bu1_patient_group3"
        >
          {{ getFieldDescription("c_proj_bu1_patient_group3") }}
        </mat-slide-toggle>
      </div>
    </div>
    <app-score-view
      [title]="'Value score'"
      [score]="score"
      [projectId]="centerParams.projectId"
      [area]="'center'"
    ></app-score-view>
    <app-score-view
      [title]="'Clinical trials score'"
      class="ct-score"
      [score]="ctScore"
      [projectId]="centerParams.projectId"
      [area]="'clinicalTrials'"
    ></app-score-view>
    <div class="flex flex-col">
      <h4 class="mat-h4">Custom metrics</h4>
      <form [formGroup]="customMetricsForm" class="flex flex-col">
        <div
          formArrayName="metrics"
          *ngFor="let control of metrics.controls; let i = index"
        >
          <div class="flex gap-4 items-center" [formGroupName]="i">
            <mat-form-field appearance="fill">
              <mat-label>metric</mat-label>
              <mat-select formControlName="name">
                <mat-option
                  *ngFor="let tag of getCustomMetricsTags()"
                  [value]="tag.tag"
                  >{{ tag.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>year</mat-label>
              <input type="string" matInput formControlName="year" />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>quantity</mat-label>
              <input type="number" matInput formControlName="value" />
            </mat-form-field>
            <button mat-icon-button color="warn" (click)="removeMetric(i)">
              <mat-icon color="warn">delete</mat-icon>
            </button>
          </div>
        </div>
      </form>

      <button
        class="mt-2 w-44"
        mat-flat-button
        color="accent"
        (click)="addMetric()"
      >
        <span>Add custom metric</span>
      </button>
    </div>
  </div>
</div>
<app-hcps-table
  [centerParams]="centerParams"
  [centerData]="centerData"
></app-hcps-table>
<app-sales-table
  [centerParams]="centerParams"
  [centerData]="centerData"
></app-sales-table>
