import { Component, OnDestroy, OnInit } from '@angular/core';
import { THCP, TTweets, TTwitterData } from '@shared/interfaces/THCP';
import { isNullOrEmpty } from '@shared/utils/isNullOrEmpty';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { Subscription } from 'rxjs';
import { HCPService } from '../../../services/HCPService';
import { TwitterService } from '../../../services/TwitterService';

@Component({
  selector: 'app-tweets',
  templateUrl: './tweets.component.html',
  styleUrls: ['./tweets.component.scss'],
})
export class TweetsComponent implements OnInit, OnDestroy {
  public tweets: TTweets[] = [];
  private _subs: Subscription[] = [];

  constructor(
    private _twitterService: TwitterService,
    private _HCPsService: HCPService
  ) {
    this._subs.push(
      this._HCPsService
        .getExtendedHCPsObservable()
        .subscribe((hcps: THCP[]) => {
          if (hcps.length === 1 && !isNullOrEmpty(hcps[0].twitterUserName)) {
            this._subs.push(
              this._twitterService
                .getTwitterReview(hcps[0].id)
                .subscribe((data: TTwitterData) => {
                  if (!isNullOrUndefined(data)) {
                    this.tweets = data.latestTweets;
                  }
                })
            );
          }
        })
    );
  }

  public ngOnInit(): void {}

  public ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
  }
}
