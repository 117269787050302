import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-static-boolean',
  templateUrl: './static-boolean.component.html',
  styleUrls: ['./static-boolean.component.scss'],
})
export class StaticBooleanComponent implements OnInit {
  @Input()
  public isTrue: boolean = true;
  @Input()
  public isQuestionMark: boolean = false;
  @Input()
  public isUnreadMark: boolean = false;
  @Input()
  public name: string = `N/A`;

  ngOnInit(): void {}
}
