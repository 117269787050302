<mat-card>
  <mat-card-title>
    <div class="flex justify-between">
      <p>Reports</p>
      <div class="text-sm flex items-center gap-4">
        <button mat-flat-button color="accent" (click)="closeReport()">
          Close
        </button>
      </div>
    </div>
  </mat-card-title>
  <mat-card-content>
    <div class="flex gap-4 justify-around">
      <div class="w-1/5">
        <h2 class="mat-h2">Choose report</h2>
        <div class="flex flex-col gap-2 my-4">
          <button
            class="w-full choose-report-button"
            [ngClass]="{
              'choose-report-button--selected':
                noScoreReport.selected === true &&
                noScoreReport.type === 'basicHcp'
            }"
            type="button"
            color="primary"
            mat-flat-button
            (click)="selectNoScoreReport('basicHcp')"
          >
            Hcp Basic report
          </button>
          <button
            class="w-full choose-report-button"
            [ngClass]="{
              'choose-report-button--selected':
                noScoreReport.selected === true &&
                noScoreReport.type === 'basicCenter'
            }"
            type="button"
            color="primary"
            mat-flat-button
            (click)="selectNoScoreReport('basicCenter')"
          >
            Center Basic report
          </button>
          <div
            *ngFor="let scoreButton of scoresButtons"
            matTooltip="{{ getButtonError(scoreButton) }}"
            class="w-full"
          >
            <button
              class="w-full choose-report-button"
              [ngClass]="{
                'choose-report-button--selected': scoreButton.selected === true
              }"
              type="button"
              color="primary"
              mat-flat-button
              (click)="scoreButton.callback(scoreButton)"
              [disabled]="isReportButtonDisabled(scoreButton)"
            >
              {{ scoreButton.name }}
            </button>
          </div>
          <button
            class="w-full choose-report-button"
            [ngClass]="{
              'choose-report-button--selected':
                noScoreReport.selected === true &&
                noScoreReport.type === 'compliance1'
            }"
            type="button"
            color="primary"
            mat-flat-button
            (click)="selectNoScoreReport('compliance1')"
          >
            HCP Compliance Level 1
          </button>
          <button
            class="w-full choose-report-button"
            [ngClass]="{
              'choose-report-button--selected':
                noScoreReport.selected === true &&
                noScoreReport.type === 'metrics'
            }"
            type="button"
            color="primary"
            mat-flat-button
            (click)="selectNoScoreReport('metrics')"
          >
            Center custom metrics
          </button>
        </div>
      </div>
      <div class="w-1/3">
        <h2 class="mat-h2">Filters</h2>
        <div class="flex justify-center mt-4">
          <app-region-select
            [regions]="regions"
            [regionsFiltered]="regionsFiltered"
            [selectedRegions]="selectedRegions"
            [handleRegionClick]="handleRegionChange.bind(this)"
          ></app-region-select>
        </div>
        <div class="flex gap-2">
          <button
            *ngIf="selectedReport !== 'metrics'"
            mat-flat-button
            color="primary"
            (click)="openFiltersDialog()"
          >
            Select field
          </button>
          <button
            *ngIf="['metrics', 'basicCenter'].includes(selectedReport ?? '')"
            mat-flat-button
            color="primary"
            (click)="openCustomMetricsDialog()"
          >
            Select custom metrics
          </button>
        </div>
        <div *ngIf="reportFilters.length > 0">
          <h3 class="mat-h3">Selected filters</h3>
          <div
            class="mb-1 flex"
            *ngFor="let item of reportFilters"
            class="flex items-center"
          >
            {{ buildFilterReview(item) }}
            <button
              mat-icon-button
              (click)="deleteFilter(item)"
              aria-label="Delete"
              color="error"
            >
              <mat-icon>delete_forever</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="w-1/3">
        <div
          *ngIf="
            selectedReport &&
            !['basicHcp', 'basicCenter', 'compliance1', 'metrics'].includes(
              selectedReport
            )
          "
        >
          <h2 class="mat-h2">Select HCP groups</h2>
          <div class="flex justify-center mt-4">
            <app-report [params]="reportComponentParams"></app-report>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>

  <div class="flex mt-4 justify-end">
    <button mat-flat-button color="primary" (click)="generateReport()">
      <span>Generate report</span>
    </button>
  </div>

  <div *ngIf="hcps.length > 0">
    <h2 class="mat-h2">Report results</h2>
    <app-common-table [params]="hcpTableParams"></app-common-table>
  </div>
  <div *ngIf="centers.length > 0">
    <h2 class="mat-h2">Report results</h2>
    <app-common-table [params]="centerTableParams"></app-common-table>
  </div>
</mat-card>
