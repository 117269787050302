<div class="settings-modal-content">
  <h2 class="my-4 mat-h2">New region</h2>
  <div>
    <app-region-item [regionData]="newRegionData"></app-region-item>
    <button mat-flat-button color="primary" (click)="add()" [disabled]="!addAvailable()">
      <span>Add</span>
    </button>
  </div>
  <div *ngIf="getRegions().length > 0">
    <h3 class="my-4">Existing regions</h3>
    <div *ngFor="let region of getRegions()">
      <app-region-item [loadPDF]="loadPDF.bind(this)" [regionData]="region" [handleDeleteRegion]="remove.bind(this)">
      </app-region-item>
    </div>
  </div>
  <div class="mt-4 mat-h3" [ngClass]="checkIfPDFViewerVisible()">
    <app-sales-data-wrapper [pdfSrc]="pdfSrc"></app-sales-data-wrapper>
  </div>
</div>