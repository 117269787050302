<div class="settings-modal-content">
  <h2 class="my-4 mat-h2">HCPs tags definitions</h2>
  <div *ngIf="availableHCPTagsFields.length > 0">
    <div>
      <mat-form-field>
        <mat-label>Available tags fields</mat-label>
        <mat-select [(value)]="selectedHCPTagField">
          <mat-option
            *ngFor="let field of availableHCPTagsFields"
            [value]="field.fieldId"
          >
            {{ field.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="tagsListVisible('hcp')">
      <div class="flex gap-4 items-baseline">
        <h3 class="my-4 mat-h3">
          Defined tags for field {{ getFieldName("hcp") }}
        </h3>
        <button mat-flat-button color="primary" (click)="addFieldTag('hcp')">
          <span>Add new</span>
        </button>
      </div>
      <ng-container *ngIf="getTags('hcp').length === 0">
        <label>No tags defined</label>
      </ng-container>
      <ng-container *ngIf="getTags('hcp').length > 0">
        <div *ngFor="let tagItem of getTags('hcp')" class="flex gap-2">
          <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="tagItem.name" />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Tag</mat-label>
            <input matInput [(ngModel)]="tagItem.tag" />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Value</mat-label>
            <input matInput [(ngModel)]="tagItem.value" />
          </mat-form-field>
          <button
            mat-icon-button
            (click)="deleteTag('hcp', tagItem)"
            aria-label="Delete"
            color="error"
          >
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </div>

  <div *ngIf="availableCenterTagsFields.length > 0">
    <h3 class="my-4 mat-h3">Center fields</h3>
    <div>
      <mat-form-field>
        <mat-label>Available tags fields</mat-label>
        <mat-select [(value)]="selectedCenterTagField">
          <mat-option
            *ngFor="let field of availableCenterTagsFields"
            [value]="field.fieldId"
          >
            {{ field.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="tagsListVisible('center')">
      <div class="flex gap-4 items-baseline">
        <h3 class="my-4 mat-h3">
          Defined tags for field {{ getFieldName("center") }}
        </h3>
        <button mat-flat-button color="primary" (click)="addFieldTag('center')">
          <span>Add new</span>
        </button>
      </div>
      <ng-container *ngIf="getTags('center').length === 0">
        <label>No tags defined</label>
      </ng-container>
      <ng-container *ngIf="getTags('center').length > 0">
        <div *ngFor="let tagItem of getTags('center')" class="flex gap-2">
          <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="tagItem.name" />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Tag</mat-label>
            <input matInput [(ngModel)]="tagItem.tag" />
          </mat-form-field>
          <!-- <mat-form-field appearance="fill">
            <mat-label>Value</mat-label>
            <input matInput [(ngModel)]="tagItem.value" />
          </mat-form-field> -->
          <button
            mat-icon-button
            (click)="deleteTag('center', tagItem)"
            aria-label="Delete"
            color="error"
          >
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
