import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Endpoints, ImportEndpoints } from '@shared/Endpoints';
import { CENTER_DB_MAPPINGS } from '@shared/db-fields-mappings/CENTER_DB_MAPPINGS';
import { HCP_DB_MAPPINGS } from '@shared/db-fields-mappings/HCP_DB_MAPPINGS';
import { PRODUCT_USE_MAPPINGS } from '@shared/db-fields-mappings/PRODUCT_USE_MAPPINGS';
import { ImportArea } from '@shared/enums/SearchArea';
import { TDBFieldMappingItem } from '@shared/interfaces/TDBFieldMappingItem';
import { TImportMappingItem } from '@shared/interfaces/TImportMappingItem';
import { isNullOrEmpty } from '@shared/utils/isNullOrEmpty';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { environment } from 'src/environments/environment';
import { CSVUtils } from 'src/utils/csvUtils';
import { CategoriesService } from '../services/CategoriesService';
import { LoadingService, TLoadingItem } from '../services/LoadingService';

export interface TImportData {
  headers: string[];
  area: ImportArea;
  projectId: string;
  //file: File;
  e: Event;
  importCallback:
    | ((e: Event, mappings: TImportMappingItem[]) => void)
    | undefined;
}

export interface TImportItem {
  id: string;
  name: string;
  //position: number;
}

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss'],
})
export class ImportComponent implements OnInit {
  public importedFileHeaders: TImportItem[] = [];
  public localHeaders: TImportItem[] = [];
  public notMatchedColumns: TImportItem[] = [];
  public validationResults: string[] = [];
  public validated: boolean = false;
  public fileName: string = ``;

  constructor(
    public dialogRef: MatDialogRef<ImportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TImportData,
    private _categoriesService: CategoriesService,
    private _loadingService: LoadingService,
    private _http: HttpClient
  ) {}

  public ngOnInit(): void {
    this._buildLocalHeaders(this.data.area);
    this._buildImportedFileHeaders();
  }

  public dropToNotMatched(event: CdkDragDrop<TImportItem[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      const oldItem = structuredClone(
        this.importedFileHeaders[event.previousIndex]
      );

      this.importedFileHeaders[event.previousIndex] = {
        id: ``,
        name: ``,
      };
      this.notMatchedColumns[event.currentIndex] = oldItem;
    }
  }

  public dropToMapped(event: CdkDragDrop<TImportItem[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      const newItem = event.previousContainer.data[event.previousIndex];
      const oldItem = this.importedFileHeaders[event.currentIndex];

      this.importedFileHeaders[event.currentIndex] = newItem;
      if (isNullOrEmpty(oldItem.id)) {
        this.notMatchedColumns.splice(event.previousIndex, 1);
      } else {
        this.notMatchedColumns[event.previousIndex] = oldItem;
      }
    }
  }

  public async validate(): Promise<void> {
    const target: HTMLInputElement = <HTMLInputElement>this.data.e.target;
    const files: FileList | null = target.files;
    const endpoint = this._getValidateEndpoint();
    const url: string = Endpoints.BUILD_URL('import', {
      base: environment.apiUrl,
      endpoint,
      params: ``,
    });
    const mappings: TImportMappingItem[] = this.localHeaders
      .map((header, index) => {
        return {
          from: this.importedFileHeaders[index].id,
          to: header.id,
        };
      })
      .filter((it) => !isNullOrEmpty(it.from) && !isNullOrEmpty(it.to));
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Validating data...`);
    this._loadingService.addLoadingItem(loadingItem);
    const formData = await CSVUtils.getImportFormData(
      files![0],
      this.data.projectId,
      mappings
    );
    const response = this._http.post<string[]>(url, formData);
    response.subscribe((res) => {
      this.validationResults = res;
    });
    this._loadingService.removeItem(loadingItem);
    this._loadingService.triggerOkMessage(`Data validated`);
    this.validated = true;
  }

  private _getValidateEndpoint(): ImportEndpoints {
    switch (this.data.area) {
      case ImportArea.Centers:
        return 'validateCenters';
      case ImportArea.HCP:
        return 'validateHCPs';
      case ImportArea.ProductSales:
        return 'validateProductSales';
    }
  }

  public import(): void {
    if (!isNullOrUndefined(this.data.importCallback)) {
      const mappings: TImportMappingItem[] = this.localHeaders
        .map((header, index) => {
          return {
            from: this.importedFileHeaders[index].id,
            to: header.id,
          };
        })
        .filter((it) => !isNullOrEmpty(it.from) && !isNullOrEmpty(it.to));
      this.data.importCallback(this.data.e, mappings);
      this.dialogRef.close();
    }
  }

  private _buildImportedFileHeaders(): void {
    const notFoundHeaders: TImportItem[] = [];
    const importedFileHeaders: TImportItem[] = this.localHeaders.map(() => {
      return {
        id: ``,
        name: ``,
      };
    });
    this.data.headers.forEach((header) => {
      const localHeaderIndex: number = this.localHeaders.findIndex(
        (it) => it.id === header
      );
      if (localHeaderIndex > -1) {
        importedFileHeaders[localHeaderIndex].id = header;
      } else {
        notFoundHeaders.push({
          id: header,
          name: ``,
        });
      }
    });

    this.importedFileHeaders = importedFileHeaders;
    this.notMatchedColumns = notFoundHeaders;
  }

  private _buildLocalHeaders(area: ImportArea): void {
    let mappings: Map<string, TDBFieldMappingItem> = new Map();
    switch (area) {
      case ImportArea.Centers:
        mappings = CENTER_DB_MAPPINGS;
        break;
      case ImportArea.HCP:
        mappings = HCP_DB_MAPPINGS;
        break;
      case ImportArea.ProductSales:
        mappings = PRODUCT_USE_MAPPINGS;
        break;
    }
    const target: HTMLInputElement = <HTMLInputElement>this.data.e.target;
    if (!isNullOrUndefined(target.files)) {
      this.fileName = target.files[0].name;
    }

    this.localHeaders = [...mappings.values()]
      .filter((it) => it.export === true && it.import !== false)
      .map((it, position) => {
        return {
          name: this._categoriesService.getFieldDescription(
            it.fieldId,
            this.data.projectId
          ),
          id: it.fieldId,
          position,
        };
      });
  }
}
