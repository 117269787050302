import { DragDropModule } from '@angular/cdk/drag-drop';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { ResizableModule } from 'angular-resizable-element';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxEditorModule } from 'ngx-editor';
import { AuthService, ID_TOKEN_NAME } from 'src/app/services/auth/auth.service';
import { TokenInterceptor } from 'src/app/services/auth/token.interceptor';
import { environment } from 'src/environments/environment';
import { SafePipe } from 'src/utils/SafePipe';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ArticlesReviewComponent } from './articles-review/articles-review.component';
import { AutocompleteWrapperComponent } from './autocomplete-wrapper/autocomplete-wrapper.component';
import { AliasesComponent } from './center/aliases/aliases.component';
import { CenterProfileComponent } from './center/center-profile/center-profile.component';
import { CenterComponent } from './center/center.component';
import { HcpsTableComponent } from './center/hcps-table/hcps-table.component';
import { SalesDataWrapperComponent } from './center/sales-data-wrapper/sales-data-wrapper.component';
import { SalesTableComponent } from './center/sales-table/sales-table.component';
import { TierComponentComponent } from './center/tier-component/tier-component.component';
import { CentersReviewComponent } from './centers-review/centers-review.component';
import { ChipsWrapperComponent } from './chips-wrapper/chips-wrapper.component';
import { CommonTableComponent } from './common-table/common-table.component';
import { MergeComponent } from './common-table/merge/merge.component';
import { OptInOutComponent } from './common-table/opt-in-out/opt-in-out.component';
import { PrioritySettingsComponent } from './common-table/priority-settings/priority-settings.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { CounterComponent } from './counter/counter.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DataMaintenanceFooterButtonComponent } from './data-mainterance-fotter-button/data-maintenance-footer-button.component';
import { EngagementImportComponent } from './engagement-import/engagement-import.component';
import { FooterComponent } from './footer/footer.component';
import { CenterInformationComponent } from './hcp/hcp-profile-tab/center-information/center-information.component';
import { ConsentDataCaptureComponent } from './hcp/hcp-profile-tab/consent-data-capture/consent-data-capture.component';
import { EngagementPlanItemComponent } from './hcp/hcp-profile-tab/engagement-plan/engagement-plan-item/engagement-plan-item.component';
import { EngagementPlanComponent } from './hcp/hcp-profile-tab/engagement-plan/engagement-plan.component';
import { EngagementTrackingItemComponent } from './hcp/hcp-profile-tab/engagement-tracking/engagement-tracking-item/engagement-tracking-item.component';
import { EngagementTrackingComponent } from './hcp/hcp-profile-tab/engagement-tracking/engagement-tracking.component';
import { HcpProfileTabComponent } from './hcp/hcp-profile-tab/hcp-profile-tab.component';
import { InfluenceComponent } from './hcp/hcp-profile-tab/influence/influence.component';
import { KeyInformationComponent } from './hcp/hcp-profile-tab/key-information/key-information.component';
import { ProfileDetailsDescriptionComponent } from './hcp/hcp-profile-tab/profile-details-description/profile-details-description.component';
import { ProfileDetailsComponent } from './hcp/hcp-profile-tab/profile-details/profile-details.component';
import { SimpleProximityComponent } from './hcp/hcp-profile-tab/simple-proximity/simple-proximity.component';
import { TweetsComponent } from './hcp/hcp-profile-tab/tweets/tweets.component';
import { ValueComponent } from './hcp/hcp-profile-tab/value/value.component';
import { HcpComponent } from './hcp/hcp.component';
import { OtherComponent } from './hcp/other/other.component';
import { ProximityScoreComponent } from './hcp/proximity-score/proximity-score.component';
import { PublicationsComponent } from './hcp/publications/publications.component';
import { StudiesComponent } from './hcp/studies/studies.component';
import { HcpsViewComponent } from './hcps-view/hcps-view.component';
import { HeaderComponent } from './header/header.component';
import { ImportExportPanelComponent } from './import-export-panel/import-export-panel.component';
import { ImportComponent } from './import/import.component';
import { LoadingComponent } from './loading/loading.component';
import { LoginPageComponent } from './login-page/login-page.component';
import {
  NewCategoryDialog,
  NewProjectComponent,
} from './new-project/new-project.component';
import { NoteComponent } from './note/note.component';
import { OptInComponent } from './opt-in/opt-in.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PaginationComponent } from './pagination/pagination.component';
import { PdfViewerModalComponent } from './pdf-viewer-modal/pdf-viewer-modal.component';
import { ProjectReviewComponent } from './project-review/project-review.component';
import { BaseSettingsComponent } from './project-review/project-settings/base-settings/base-settings.component';
import { BatchUpdateComponent } from './project-review/project-settings/batch-update/batch-update.component';
import { TransformationItemComponent } from './project-review/project-settings/batch-update/transformation-item/transformation-item.component';
import { CenterFieldsComponent } from './project-review/project-settings/center-fields/center-fields.component';
import { FilterItemComponent } from './project-review/project-settings/filters/filter-item/filter-item.component';
import { FiltersComponent } from './project-review/project-settings/filters/filters.component';
import { ProductUseScoreItemComponent } from './project-review/project-settings/product-use-scoring/product-use-score-item/product-use-score-item.component';
import { ProductUseScoringComponent } from './project-review/project-settings/product-use-scoring/product-use-scoring.component';
import { ProjectSettingsComponent } from './project-review/project-settings/project-settings.component';
import { ProximityComponent } from './project-review/project-settings/proximity/proximity.component';
import { RegionItemComponent } from './project-review/project-settings/regions/region-item/region-item.component';
import { RegionsComponent } from './project-review/project-settings/regions/regions.component';
import { ScoringItemComponent } from './project-review/project-settings/scoring-settings/scoring-item/scoring-item.component';
import { ScoringSettingsComponent } from './project-review/project-settings/scoring-settings/scoring-settings.component';
import { TagsSettingsComponent } from './project-review/project-settings/tags-settings/tags-settings.component';
import { TierDefinitionsComponent } from './project-review/project-settings/tier-definitions/tier-definitions.component';
import { TierItemComponent } from './project-review/project-settings/tier-settings/tier-item/tier-item.component';
import { TierSettingsComponent } from './project-review/project-settings/tier-settings/tier-settings.component';
import { ProjectsReviewComponent } from './projects-review/projects-review.component';
import { ProjectsSelectorComponent } from './projects-selector/projects-selector.component';
import { RegionSelectComponent } from './region-select/region-select.component';
import { ReportCustomMetricComponent } from './reports-review/report-custom-metric/report-custom-metric.component';
import { ReportFiltersItemComponent } from './reports-review/report-filters/report-filters-item/report-filters-item.component';
import { ReportFiltersComponent } from './reports-review/report-filters/report-filters.component';
import { ReportComponent } from './reports-review/report/report.component';
import { ReportsReviewComponent } from './reports-review/reports-review.component';
import { ScoreViewComponent } from './score-view/score-view.component';
import { ArticlesService } from './services/ArticlesService';
import { CacheService } from './services/CacheService';
import { CategoriesService } from './services/CategoriesService';
import { CentersService } from './services/CentersService';
import { ClinicalTrialsService } from './services/ClinicalTrialsService';
import { DataProcessorService } from './services/DataProcessorService';
import { HCPService } from './services/HCPService';
import { LoadingService } from './services/LoadingService';
import { PDFService } from './services/PDFService';
import { PaperNotificationService } from './services/PaperNotificationService';
import { RegionsService } from './services/RegionsService';
import { RiseAppApiService } from './services/RiseAppApiService';
import { SSEService } from './services/SSEService';
import { ScoringService } from './services/ScoringService';
import { SearchService } from './services/SearchService';
import { SettingsService } from './services/SettingsService';
import { SupportDocumentsService } from './services/SupportDocumentsService';
import { TwitterService } from './services/TwitterService';
import { CommonService } from './services/common.service';
import { PagingService } from './services/paging/PagingService';
import { SettingsInitializatorComponent } from './settings-initializator/settings-initializator.component';
import { SimpleHcpEditorComponent } from './simple-hcp-editor/simple-hcp-editor.component';
import { StaticBooleanComponent } from './static-boolean/static-boolean.component';
import { StudiesReviewComponent } from './studies-review/studies-review.component';
import { SupportPageComponent } from './support-page/support-page.component';
import { CategoriesSettingsComponent } from './system-settings/categories-settings/categories-settings.component';
import { CountriesWithPaperNotificationsDialogComponent } from './system-settings/countries-with-paper-notifications-dialog/countries-with-paper-notifications-dialog.component';
import { CustomRegionsDialogComponent } from './system-settings/custom-regions-dialog/custom-regions-dialog.component';
import { EditorComponent } from './system-settings/editor/editor.component';
import { SupportDocsComponent } from './system-settings/support-docs/support-docs.component';
import { SystemSettingsComponent } from './system-settings/system-settings.component';
import { NewUserDialogComponent } from './system-settings/users-management/new-user-dialog/new-user-dialog.component';
import { UserComponent } from './system-settings/users-management/user/user.component';
import { UsersBatchUpdateComponent } from './system-settings/users-management/users-batch-update/users-batch-update.component';
import { UsersManagementComponent } from './system-settings/users-management/users-management.component';
import { ToggleButtonWrapperComponent } from './toggle-button-wrapper/toggle-button-wrapper.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { TwitterReviewComponent } from './twitter-review/twitter-review.component';
import { ValueEditorComponent } from './value-editor/value-editor.component';

export function tokenGetter() {
  return localStorage.getItem(ID_TOKEN_NAME);
}
@NgModule({
  declarations: [
    AppComponent,
    HcpsViewComponent,
    HeaderComponent,
    ToolbarComponent,
    DashboardComponent,
    AccountSettingsComponent,
    ProjectsReviewComponent,
    NewProjectComponent,
    ProjectReviewComponent,
    NewCategoryDialog,
    ArticlesReviewComponent,
    LoginPageComponent,
    SystemSettingsComponent,
    PaginationComponent,
    LoadingComponent,
    CentersReviewComponent,
    PageNotFoundComponent,
    CenterComponent,
    ScoreViewComponent,
    CommonTableComponent,
    HcpComponent,
    HcpProfileTabComponent,
    KeyInformationComponent,
    CenterInformationComponent,
    EngagementPlanComponent,
    ProfileDetailsComponent,
    InfluenceComponent,
    ValueComponent,
    EngagementTrackingComponent,
    StaticBooleanComponent,
    CounterComponent,
    DataMaintenanceFooterButtonComponent,
    SafePipe,
    CategoriesSettingsComponent,
    ProjectSettingsComponent,
    InfluenceComponent,
    ScoringItemComponent,
    ToggleButtonWrapperComponent,
    ChipsWrapperComponent,
    TierComponentComponent,
    AutocompleteWrapperComponent,
    FooterComponent,
    PublicationsComponent,
    StudiesComponent,
    OtherComponent,
    ScoringSettingsComponent,
    RegionsComponent,
    RegionItemComponent,
    SalesDataWrapperComponent,
    ReportsReviewComponent,
    ReportComponent,
    EditorComponent,
    TierSettingsComponent,
    TierItemComponent,
    MergeComponent,
    TierDefinitionsComponent,
    TagsSettingsComponent,
    ConfirmationDialogComponent,
    SettingsInitializatorComponent,
    AliasesComponent,
    CenterFieldsComponent,
    BaseSettingsComponent,
    ImportComponent,
    OptInOutComponent,
    ProximityScoreComponent,
    SimpleHcpEditorComponent,
    EngagementPlanItemComponent,
    EngagementTrackingItemComponent,
    UsersManagementComponent,
    UserComponent,
    NewUserDialogComponent,
    ProjectsSelectorComponent,
    UsersBatchUpdateComponent,
    RegionSelectComponent,
    ProfileDetailsDescriptionComponent,
    FiltersComponent,
    FilterItemComponent,
    SimpleProximityComponent,
    ProximityComponent,
    NoteComponent,
    TwitterReviewComponent,
    TweetsComponent,
    EngagementImportComponent,
    ReportFiltersComponent,
    ReportFiltersItemComponent,
    ValueEditorComponent,
    BatchUpdateComponent,
    TransformationItemComponent,
    StudiesReviewComponent,
    OptInComponent,
    ConsentDataCaptureComponent,
    CustomRegionsDialogComponent,
    PrioritySettingsComponent,
    SupportPageComponent,
    PdfViewerModalComponent,
    ReportCustomMetricComponent,
    ImportExportPanelComponent,
    CountriesWithPaperNotificationsDialogComponent,
    CenterProfileComponent,
    HcpsTableComponent,
    SalesTableComponent,
    ProductUseScoringComponent,
    ProductUseScoreItemComponent,
    SupportDocsComponent,
  ],
  imports: [
    MatSliderModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatCardModule,
    MatFormFieldModule,
    MatListModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatGridListModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTreeModule,
    MatRippleModule,
    MatTooltipModule,
    HttpClientModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    DragDropModule,
    ResizableModule,
    MatStepperModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: [`http://localhost:4000`, `${environment.apiUrl}`],
      },
    }),
    PdfViewerModule,
    MatExpansionModule,
    MatBadgeModule,
    NgxEditorModule,
    MatNativeDateModule,
    MatDatepickerModule,
  ],
  providers: [
    CategoriesService,
    CommonService,
    ArticlesService,
    SearchService,
    RegionsService,
    AuthService,
    HCPService,
    LoadingService,
    DataProcessorService,
    CentersService,
    CacheService,
    ScoringService,
    SettingsService,
    PaperNotificationService,
    ClinicalTrialsService,
    PDFService,
    PagingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    RiseAppApiService,
    SSEService,
    TwitterService,
    SupportDocumentsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
