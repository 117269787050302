<div class="key-informations-wrapper mb-4">
  <h3 class="mat-h3">Key information</h3>
  <div class="flex columns-2 gap-5">
    <div class="key-informations__image">
      <button
        mat-button
        class="mt-2"
        *ngIf="hcpData.profile_image.length === 0"
        (click)="profileImageInput.click()"
      >
        <mat-icon>add_circle_outline</mat-icon> Add image
      </button>
      <img
        class="logo"
        [src]="hcpData.profile_image"
        (click)="profileImageInput.click()"
      />
      <button
        mat-icon-button
        class="mt-2"
        color="error"
        *ngIf="hcpData.profile_image.length !== 0"
        (click)="clearProfileImage()"
      >
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
    </div>
    <input
      hidden
      (change)="uploadProfileImage($event)"
      #profileImageInput
      type="file"
      id="file"
    />
    <div class="flex w-full flex-col">
      <div class="flex gap-2">
        <mat-form-field appearance="fill" class="w-1/2">
          <mat-label>First name</mat-label>
          <input matInput [(ngModel)]="hcpData.first_name" />
        </mat-form-field>
        <mat-form-field appearance="fill" class="w-1/2">
          <mat-label>Last name</mat-label>
          <input matInput [(ngModel)]="hcpData.last_name" />
        </mat-form-field>
      </div>
      <div class="flex gap-2">
        <mat-form-field appearance="fill" class="w-1/2">
          <mat-label>Gender</mat-label>
          <input matInput [(ngModel)]="hcpData.gender" />
        </mat-form-field>
        <mat-form-field appearance="fill" class="w-1/2">
          <mat-label>Title</mat-label>
          <input matInput [(ngModel)]="hcpData.title" />
        </mat-form-field>
      </div>
      <mat-form-field appearance="fill">
        <mat-label>Function</mat-label>
        <input matInput [(ngModel)]="hcpData.function" />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Primary Email</mat-label>
        <input matInput [(ngModel)]="hcpData.primary_email_address" />
      </mat-form-field>
      <span
        *ngIf="
          hcpData.primary_email_address &&
          !isEmailValid(hcpData.primary_email_address)
        "
        class="error warning"
        >Invalid email</span
      >
      <app-chips-wrapper
        [placeholder]="'Specialties'"
        [items]="hcpData.specialties"
        [onChipChange]="onChipChange.bind(this)"
      >
      </app-chips-wrapper>
      <div class="flex gap-2">
        <mat-form-field appearance="fill" class="w-1/2">
          <mat-label>City</mat-label>
          <input matInput [(ngModel)]="hcpData.city" />
        </mat-form-field>
        <mat-form-field appearance="fill" class="w-1/2">
          <mat-label>Country</mat-label>
          <input matInput [(ngModel)]="hcpData.country" />
        </mat-form-field>
      </div>
      <div class="flex gap-2">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Street</mat-label>
          <input matInput [(ngModel)]="hcpData.street1" />
        </mat-form-field>
        <mat-form-field appearance="fill" class="w-36">
          <mat-label>Zip code</mat-label>
          <input matInput [(ngModel)]="hcpData.zip" />
        </mat-form-field>
      </div>
      <div class="flex gap-2">
        <mat-slide-toggle [(ngModel)]="hcpData.retired">
          Retired
        </mat-slide-toggle>
        <mat-slide-toggle [(ngModel)]="getProjectData().hcp_proj_rising_star">
          Rising star
        </mat-slide-toggle>
      </div>
    </div>
  </div>
</div>
