import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  TEngagementActual,
  THCP,
  THCPProjectsData,
  buildHCPMock,
} from '@shared/interfaces/THCP';
import { newGuid } from '@shared/utils/newGuild';
import { Subscription } from 'rxjs';
import { CategoriesService } from 'src/app/services/CategoriesService';
import { HCPService } from 'src/app/services/HCPService';
import { LoadingService } from 'src/app/services/LoadingService';
import {
  TComponentType,
  THCPParams,
} from '../../../interfaces/TComponentParams';
import { AuthService, TCurrentUser } from '../../../services/auth/auth.service';
import { EngagementUtils } from '../hcp-profile-tab.utils';

@Component({
  selector: 'app-engagement-tracking',
  templateUrl: './engagement-tracking.component.html',
  styleUrls: ['./engagement-tracking.component.scss'],
})
export class EngagementTrackingComponent implements OnInit, OnDestroy {
  @Input()
  public hcpData: THCP = {
    ...buildHCPMock({}),
  };
  @Input()
  public params: THCPParams = {
    hcpId: ``,
    categoryId: ``,
    projectId: ``,
    __type: TComponentType.hcp,
  };
  public engagementScore: number = 0;
  public headers = EngagementUtils.getEngagementTrackingHeaders();
  private _subs: Subscription[] = [];
  private projectsDescriptions: Map<string, string> = new Map();

  constructor(
    private _HCPsService: HCPService,
    private _autService: AuthService,
    private _categoriesService: CategoriesService,
    private _loadingService: LoadingService
  ) {}

  public ngOnInit(): void {
    this._subs.push(
      this._HCPsService.getExtendedHCPsObservable().subscribe((hcps) => {
        if (hcps.length === 1) {
          const projectData: THCPProjectsData =
            this._HCPsService.getProjectData(hcps[0], this.params.projectId);
          this.engagementScore = projectData.engagementScore;
        }
      })
    );
    this._subs.push(
      this._categoriesService
        .getCategoriesObservable()
        .subscribe((categories) => {
          categories.forEach((c) => {
            c.projects.forEach((p) => {
              this.projectsDescriptions.set(p.id, p.description);
            });
          });
        })
    );
  }

  public getParams(): THCPParams {
    return this.params;
  }

  public ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  public getEngagements(): TEngagementActual[] {
    const allEngagements: TEngagementActual[] = this.hcpData.projectsData
      .map((it) => it.engagement_actual ?? [])
      .flat();
    return allEngagements;
  }

  public addNewEngagement(): void {
    const user: TCurrentUser = this._autService.getCurrentUser();
    const newEngagement: TEngagementActual = {
      engagement_actual_point_person: `${user?.firstName ?? `N/A`} ${
        user?.lastName ?? `N/A`
      }`,
      engagement_actual_activity: `...`,
      engagement_actual_date: new Date(),
      id: newGuid(),
      tags: [],
    };
    const engagementsForProject: TEngagementActual[] =
      this._getEngagementsForProject();
    engagementsForProject.unshift(newEngagement);
  }

  public getSortedEngagements(): TEngagementActual[] {
    return this.getEngagements().sort((a, b) => {
      return (
        new Date(b.engagement_actual_date!).getTime() -
        new Date(a.engagement_actual_date!).getTime()
      );
    });
  }

  public getProjectFromEngagement(engagement: TEngagementActual): {
    id: string;
    description: string;
  } {
    let description: string = `N/A`;
    let projectId: string = ``;
    this.hcpData.projectsData.forEach((it) => {
      (it.engagement_actual ?? []).forEach((e) => {
        if (e.id === engagement.id) {
          description = this.projectsDescriptions.get(it.projectId) ?? `N/A`;
          projectId = it.projectId;
        }
      });
    });
    return {
      description,
      id: projectId,
    };
  }

  public deleteCallback(item: TEngagementActual): void {
    let engagements: TEngagementActual[] = [];
    this.hcpData.projectsData.forEach((it) => {
      if (
        it.engagement_actual?.find((engagement) => engagement.id === item.id) ??
        -1 > -1
      ) {
        engagements = it.engagement_actual;
      }
    });
    const itemTORemoveIndex: number = engagements.findIndex(
      (it) => it.id === item.id
    );
    if (itemTORemoveIndex === -1) {
      this._loadingService.triggerInfoMessage(`Item not found`);
    } else {
      engagements.splice(itemTORemoveIndex, 1);
    }
  }

  private _getEngagementsForProject(): TEngagementActual[] {
    return (
      this.hcpData.projectsData.find(
        (it) => it.projectId === this.params.projectId
      )?.engagement_actual ?? []
    );
  }
}
