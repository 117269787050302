import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TCenter, buildCenterMock } from '@shared/interfaces/TCenter';
import { THCP, THCPProjectsData, buildHCPMock } from '@shared/interfaces/THCP';
import { TTierFieldItem } from '@shared/interfaces/TProject';
import { Subscription } from 'rxjs';
import { CategoriesService } from 'src/app/services/CategoriesService';
import { HCPService } from 'src/app/services/HCPService';
import {
  TComponentType,
  THCPParams,
} from '../../../interfaces/TComponentParams';

@Component({
  selector: 'app-value',
  templateUrl: './value.component.html',
  styleUrls: ['./value.component.scss'],
})
export class ValueComponent implements OnInit, OnDestroy {
  @Input() public hcpData: THCP = {
    ...buildHCPMock({}),
  };
  @Input() public centerData: TCenter = buildCenterMock({});
  @Input() public params: THCPParams = {
    hcpId: ``,
    categoryId: ``,
    projectId: ``,
    __type: TComponentType.hcp,
  };
  public valueScore: number = 0;
  public productUseScore: number = 0;
  private _subs: Subscription[] = [];

  constructor(
    private _HCPsService: HCPService,
    private _categoriesService: CategoriesService
  ) {}

  public getProjectData(): THCPProjectsData {
    return this._HCPsService.getProjectData(
      this.hcpData,
      this.params.projectId
    );
  }

  public getTiers(fieldId: string): TTierFieldItem[] {
    const data = this._categoriesService.getTiersForField(
      this.params.projectId,
      fieldId,
      `hcp`
    );
    return data;
  }

  public ngOnInit(): void {
    this._subs.push(
      this._HCPsService.getExtendedHCPsObservable().subscribe((hcps) => {
        if (hcps.length === 1) {
          const projectData: THCPProjectsData =
            this._HCPsService.getProjectData(hcps[0], this.params.projectId);
          this.valueScore = projectData.valueScore;
          this.productUseScore = projectData.productUseScore;
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }
}
