<div class="profile-details-description">
  <!-- <h3 class="mat-h3">Profile details</h3> -->
  <div class="flex gap-5">
    <mat-form-field appearance="fill" class="w-full">
      <mat-label>Speaker history</mat-label>
      <textarea
        cdkTextareaAutosize
        matInput
        placeholder="Speaker history"
        [(ngModel)]="hcpData.speaker_history"
      ></textarea>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Year</mat-label>
      <input matInput [(ngModel)]="hcpData.initial_year" />
    </mat-form-field>
  </div>
  <mat-form-field appearance="fill" class="w-full">
    <mat-label>Clinical trials R+D</mat-label>
    <textarea
      cdkTextareaAutosize
      matInput
      placeholder="Clinical trials R+D"
      [(ngModel)]="hcpData.interest_in_clinical_trials_RD"
    ></textarea>
  </mat-form-field>
  <app-chips-wrapper
    [placeholder]="'Influencers'"
    [items]="hcpData.influencers"
    [onChipChange]="onInfluencersChipChange.bind(this)"
  >
  </app-chips-wrapper>
  <mat-form-field appearance="fill" class="w-full">
    <mat-label>Link and resources</mat-label>
    <textarea
      cdkTextareaAutosize
      matInput
      placeholder="Link and resources"
      [(ngModel)]="hcpData.further_links_and_sources"
    ></textarea>
  </mat-form-field>
  <app-chips-wrapper
    class="block mt-4"
    [placeholder]="'Languages'"
    [items]="hcpData.languages"
    [onChipChange]="onLanguagesChipChange.bind(this)"
  >
  </app-chips-wrapper>
</div>
