export class DateUtils {
  private static ONE_DAY = 1000 * 60 * 60 * 24;
  private static months = {
    Jan: '01',
    Feb: '02',
    Mar: '03',
    Apr: '04',
    May: '05',
    Jun: '06',
    Jul: '07',
    Aug: '08',
    Sep: '09',
    Oct: '10',
    Nov: '11',
    Dec: '12'
  };

  // convert to DD/MM/YYYY format
  public static convertDateFormat = (dateString: string | undefined): string => {
    if (!dateString) {
      return '';
    }
    // Check if the input date string is already in the correct format
    if (this.isDateString(dateString)) {
      return dateString;
    }

    // E.g. 03/Jan/2021
    let match = dateString.match(/(\d{2})\/([a-zA-Z]+)\/(\d{2,4})/);
    if (match) {
      const [, day, month, year] = match;
      return `${this.convertDay(day)}/${this.convertMonth(month)}/${this.convertYear(year)}`;
    }

    // E.g. /Jan/2021
    match = dateString.match(/\/([a-zA-Z]+)\/(\d{2,4})/);
    if (match) {
      const [, month, year] = match;
      return `${this.convertDay('01')}/${this.convertMonth(month)}/${this.convertYear(year)}`;
    }

    // E.g. 01//2021
    match = dateString.match(/(\d{1,2})\/\/(\d{2,4})/);
    if (match) {
      const [, day, year] = match;
      return `${this.convertDay(day)}/${this.convertMonth('01')}/${this.convertYear(year)}`;
    }

    // E.g. 01/03/
    match = dateString.match(/(\d{1,2})\/(\d{1,2})\//);
    if (match) {
      const [, day, month] = match;
      return `${this.convertDay(day)}/${this.convertMonth(month)}/${this.convertYear('')}`;
    }

    // E.g. 01/Feb/
    match = dateString.match(/(\d{1,2})\/([a-zA-Z]+)\//);
    if (match) {
      const [, day, month] = match;
      return `${this.convertDay(day)}/${this.convertMonth(month)}/${this.convertYear('')}`;
    }

    // E.g. //2023
    match = dateString.match(/\/\/(\d{2,4})/);
    if (match) {
      const [, year] = match;
      return `${this.convertDay('')}/${this.convertMonth('')}/${this.convertYear(year)}`;
    }

    // E.g. /Feb/
    match = dateString.match(/\/([a-zA-Z]+)\//);
    if (match) {
      const [, month] = match;
      return `${this.convertDay('')}/${this.convertMonth(month)}/${this.convertYear('')}`;
    }

    // E.g. May 17, 2020
    match = dateString.match(/([a-zA-Z]+) (\d{1,2}), (\d{2,4})/);
    if (match) {
      const [, month, day, year] = match;
      return `${this.convertDay(day)}/${this.convertMonth(month)}/${this.convertYear(year)}`;
    }

    // E.g. 17 May, 2020
    match = dateString.match(/(\d{1,2}) ([a-zA-Z]+), (\d{2,4})/);
    if (match) {
      const [, month, day, year] = match;
      return `${this.convertDay(day)}/${this.convertMonth(month)}/${this.convertYear(year)}`;
    }

    // E.g. January 2014
    match = dateString.match(/([a-zA-Z]+) (\d{2,4})/);
    if (match) {
      const [, month, year] = match;
      return `${this.convertDay('01')}/${this.convertMonth(month)}/${this.convertYear(year)}`;
    }

    // E.g. 2022-09-30 or 2022-30-09
    match = dateString.match(/(\d{4})-(\d{2})-(\d{2})/);
    if (match) {
      const [, year, month, day] = match;
      if (parseInt(month, 10) > 12) {
        return `${this.convertDay(month)}/${this.convertMonth(day)}/${this.convertYear(year)}`;
      }
      return `${this.convertDay(day)}/${this.convertMonth(month)}/${this.convertYear(year)}`;
    }

    // E.g. 2022-09
    match = dateString.match(/(\d{4})-(\d{2})/);
    if (match) {
      const [, year, month] = match;
      return `${this.convertDay('01')}/${this.convertMonth(month)}/${this.convertYear(year)}`;
    }

    // E.g. 30-08-2020 or 08-30-2020
    match = dateString.match(/(\d{2})-(\d{2})-(\d{4})/);
    if (match) {
      const [, day, month, year] = match;
      if (parseInt(month, 10) > 12) {
        return `${this.convertDay(month)}/${this.convertMonth(day)}/${this.convertYear(year)}`;
      }
      return `${this.convertDay(day)}/${this.convertMonth(month)}/${this.convertYear(year)}`;
    }

    // if not handled, return the input date string
    return dateString;
  };

  public static convertDateStringToDate = (date: string) => {
    const parts = date.split('/');

    // Extract day, month, and year
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);

    // Create Date object
    const dateObject = new Date(Date.UTC(year, month, day));

    return dateObject;
  };

  public static isDateString = (date: string): boolean => {
    return /\d{2}\/\d{2}\/\d{4}/.test(date);
  };

  public static isValidIsoDate(dateString: string) {
    const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    return isoDateRegex.test(dateString);
  }

  public static convertIsoDateStringToDate(dateString: string) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  public static differenceBetweenDatesInDays = (date1: Date, date2: Date): number => {
    date1.setUTCHours(0, 0, 0, 0);
    date2.setUTCHours(0, 0, 0, 0);
    const differenceInTime = date2.getTime() - date1.getTime();
    const differenceInDays = Math.floor(differenceInTime / this.ONE_DAY);
    return differenceInDays;
  };

  public static yearsArray = (startYear: number, sortType: 'asc' | 'desc' = 'asc'): number[] => {
    const years = Array.from(
      { length: new Date().getFullYear() - startYear + 1 },
      (_, i) => startYear + i
    );
    if (sortType === 'asc') {
      return years;
    }
    return years.reverse();
  };

  private static convertDay = (day: string) => {
    // If day is '00' or not a valid number, set it to '01'
    if (day === '00' || isNaN(parseInt(day, 10))) {
      day = '01';
    }
    // if day is just one digit, add a leading zero
    if (day.length === 1) {
      day = `0${day}`;
    }
    return day;
  };

  private static convertMonth = (month: string) => {
    // If month is a number return it as is
    if (!isNaN(parseInt(month, 10))) {
      if (month.length === 1) {
        month = `0${month}`;
      }
      return month;
    }
    // If month is missing return '01'
    if (!month) {
      return '01';
    }
    // Month is a string, if is invalid set it to 'Jan'
    let monthAbb = month.slice(0, 3);
    if (!this.months.hasOwnProperty(monthAbb)) {
      monthAbb = 'Jan';
    }
    return this.months[monthAbb as keyof typeof this.months];
  };

  private static convertYear = (year: string) => {
    // if year is a 2 digit number, convert it to a 4 digit number
    if (!isNaN(parseInt(year, 10)) && year.length === 2) {
      year = `20${year}`;
    }
    // If year is missing or not a valid number, set it to the current year
    if (!year || isNaN(parseInt(year, 10))) {
      const currentYear = new Date().getFullYear();
      year = currentYear.toString();
    }
    return year;
  };
}
