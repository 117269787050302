import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  TCenter,
  TCenterProjectsData,
  TCustomMetric,
  buildCenterMock,
  buildCenterProjectDataMock,
} from '@shared/interfaces/TCenter';
import { THCP } from '@shared/interfaces/THCP';
import {
  TProject,
  TTagItem,
  TTierFieldItem,
} from '@shared/interfaces/TProject';
import { DEFAULT_APP_SETTINGS, TSettings } from '@shared/interfaces/TSettings';
import {
  Observable,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from 'rxjs';
import { ROUTES_PATHS } from 'src/app/app-routing.module';
import { AutocompleteWrapperComponent } from 'src/app/autocomplete-wrapper/autocomplete-wrapper.component';
import {
  TCenterParams,
  TComponentType,
} from 'src/app/interfaces/TComponentParams';
import { CategoriesService } from 'src/app/services/CategoriesService';
import { CentersService } from 'src/app/services/CentersService';
import { RegionsService } from 'src/app/services/RegionsService';
import { SettingsService } from 'src/app/services/SettingsService';
import { yearStringValidator } from 'src/app/validators/yearStringValidator';
import { NEW_CENTER_ID } from '../center.component';

@Component({
  selector: 'app-center-profile',
  templateUrl: './center-profile.component.html',
  styleUrls: ['./center-profile.component.scss'],
})
export class CenterProfileComponent {
  @Input() public centerParams: TCenterParams = {
    __type: TComponentType.center,
    categoryId: ``,
    centerId: ``,
    projectId: ``,
  };
  @Input() public centerData: TCenter = {
    ...buildCenterMock({}),
  };
  @ViewChild(AutocompleteWrapperComponent)
  private countryAutocomplete: AutocompleteWrapperComponent;
  @Output() customMetricsFormValidityChange = new EventEmitter<boolean>();
  @Output() customMetricsFormValueChange = new EventEmitter<any>();

  public score = 0;
  public ctScore = 0;
  public usageOptions: string[] = [`High`, `Average`, `Low`, `None`];
  public articlesCount: number = 0;
  public hcps: THCP[] = [];
  public hcpsCount: number = 0;
  public existingCenterSearch: string = ``;
  public centerControl: FormControl<TCenter | null>;
  public availableRegions: string[] = [];
  public filteredOptions: Observable<TCenter[]>;
  public isLoading: boolean = false;
  public customMetricsForm: FormGroup;
  public appSettings: TSettings = structuredClone(DEFAULT_APP_SETTINGS);
  public countries: string[] = [];
  public selectedCountryObservable: Observable<string> = new Observable();
  private project: TProject;

  private _subs: Subscription[] = [];
  private isNewFromOtherProject: boolean = false;

  constructor(
    public deleteConfirmationDialog: MatDialog,
    private _route: ActivatedRoute,
    private _router: Router,
    private _centersService: CentersService,
    private _regionsService: RegionsService,
    private _categoriesService: CategoriesService,
    private _settingsService: SettingsService,
    private fb: FormBuilder
  ) {}

  public ngOnInit(): void {
    this._subs.push(
      this._settingsService.getAppSettingsObservable().subscribe((settings) => {
        this.appSettings = settings;
      })
    );
    this._subs.push(
      this._regionsService
        .getCountriesObservable()
        .subscribe(
          (regions) => (this.countries = regions.map((c) => c.viewValue))
        )
    );

    this.customMetricsForm = this.fb.group({
      metrics: this.fb.array([]),
    });
    this.customMetricsForm.statusChanges.subscribe((status) => {
      this.emitCustomMetrics();
    });
    this.metrics.valueChanges.subscribe(() => {
      this.emitCustomMetrics();
    });
  }

  ngOnChanges() {
    if (this.isCenterSelected()) {
      this.countryAutocomplete?.setValue(this.centerData.c_country);
      const project: TProject | undefined = this._categoriesService.getProject(
        this.centerParams.projectId
      );
      // if (project !== undefined) {
      //   this._setSalesData(project);
      // }
      const projectData: TCenterProjectsData =
        this._centersService.getProjectData(
          this.centerData,
          this.centerParams.projectId
        );
      this.score = projectData.valueScore;
      this.ctScore = isNaN(projectData.clinicalTrialsScore)
        ? 0
        : projectData.clinicalTrialsScore;

      this._subs.push(
        this._categoriesService
          .getCategoriesObservable()
          .subscribe((categories) => {
            const project: TProject | undefined = categories
              .find((c) => c.categoryId === this.centerParams.categoryId)
              ?.projects.find((p) => p.id === this.centerParams.projectId);
            if (project !== undefined) {
              this.project = project;
              this.availableRegions = project.regions.map((r) => r.name);
              // this._setSalesData(project);
            }
          })
      );

      const customMetrics: TCustomMetric[] = this.centerData.custom_metrics;
      if (customMetrics) {
        const metrics: FormArray = new FormArray([
          ...customMetrics
            .sort((a, b) => parseInt(a.year) - parseInt(b.year))
            .map(
              (item) =>
                new FormGroup({
                  name: new FormControl(item.name, Validators.required),
                  year: new FormControl(item.year, [
                    Validators.required,
                    yearStringValidator(),
                  ]),
                  value: new FormControl(item.value, [
                    Validators.required,
                    Validators.min(1),
                  ]),
                })
            ),
        ]);
        this.customMetricsForm.setControl('metrics', metrics);
      } else {
        this.customMetricsForm.setControl('metrics', this.fb.array([]));
      }

      this._setCenterOptions();
    }
  }

  emitCustomMetrics() {
    this.customMetricsFormValidityChange.emit(this.customMetricsForm.valid);
    this.customMetricsFormValueChange.emit(
      this.customMetricsForm.value.metrics
    );
  }

  public getProjectData(): TCenterProjectsData {
    const projectData: TCenterProjectsData =
      this._centersService.getProjectData(
        this.centerData,
        this.centerParams.projectId
      );
    return projectData;
  }

  public getFieldDescription(field: string): string {
    return this._categoriesService.getFieldDescription(
      field,
      this.centerParams.projectId
    );
  }

  get metrics(): FormArray {
    return <FormArray>this.customMetricsForm.get('metrics');
  }

  public addMetric() {
    this.metrics.push(
      this.fb.group({
        name: ['', Validators.required],
        year: ['', [Validators.required, yearStringValidator()]],
        value: [0, [Validators.required, Validators.min(1)]],
      })
    );
  }

  public removeMetric(index: number) {
    this.metrics.removeAt(index);
  }

  public getTiers(fieldId: string): TTierFieldItem[] {
    const data = this._categoriesService.getTiersForField(
      this.centerParams.projectId,
      fieldId,
      `center`
    );
    return data;
  }

  public getCustomMetricsTags(): TTagItem[] {
    return this._categoriesService.getTagsForField(
      this.centerParams.projectId,
      `custom_metrics`,
      `center`
    );
  }

  public isNew(): boolean {
    return (
      this.centerParams.centerId === NEW_CENTER_ID || this.isNewFromOtherProject
    );
  }

  public fillCenter(center: TCenter): void {
    const clonedCenter: TCenter = structuredClone(center);
    if (clonedCenter.projects.includes(this.centerParams.projectId)) {
      const centerParams: TCenterParams = {
        centerId: center.c_id,
        categoryId: this.centerParams.categoryId ?? ``,
        projectId: this.centerParams.projectId ?? ``,
        __type: TComponentType.center,
      };
      this._router.navigate([`/${ROUTES_PATHS.center}`, centerParams], {
        relativeTo: this._route,
      });
    } else {
      clonedCenter.projects.push(this.centerParams.projectId);
      const newProjectsData: TCenterProjectsData = buildCenterProjectDataMock({
        projectId: this.centerParams.projectId,
      });
      clonedCenter.projectsData.push(newProjectsData);
      this.onCenterSelectCallback(clonedCenter, false);
    }
  }

  private onCenterSelectCallback(center: TCenter, reset: boolean): void {
    if (reset) {
      this.centerData = center;
      this.centerParams = {
        ...this.centerParams,
        centerId: NEW_CENTER_ID,
      };
    } else {
      this.centerData = center;
      const clonedParams = structuredClone(this.centerParams);
      clonedParams.centerId = center.c_id;
      this.centerParams = clonedParams;
      this.isNewFromOtherProject = true;
    }
  }

  public onSelectionChange(newValue: string) {
    this.centerData.c_country = newValue;
  }

  public displayFn(center: TCenter): string {
    return center && center.c_name ? `${center.c_name}` : ``;
  }

  public clearData(): void {
    const newCenter: TCenter = buildCenterMock({});
    this.existingCenterSearch = ``;
    this.centerControl.setValue(null);
    this.onCenterSelectCallback(newCenter, true);
  }

  public ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
    this._centersService.resetCenters();
    this.centerData = {
      ...buildCenterMock({}),
      projectsData: [],
    };
  }

  private _filter(value: string = ``): Observable<TCenter[]> {
    this.isLoading = true;
    return this._centersService.getCenterByName(value, ``).pipe(
      map((response) => {
        this.isLoading = false;
        return response;
      })
    );
  }

  private _setCenterOptions(): void {
    this.centerControl = new FormControl();
    this.filteredOptions = this.centerControl.valueChanges.pipe(
      startWith(``),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((value: string | null | TCenter) => {
        const query = typeof value === `string` ? value : ``;
        return query ? this._filter(query as string) : [];
      })
    );
  }

  private isCenterSelected(): boolean {
    return this.centerData.c_id !== `` && this.centerParams.centerId !== ``;
  }
}
