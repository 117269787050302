<div class="mt-4">
  <h3 class="mat-h3">
    Proximity score based on scientific publications and clinical trials
  </h3>
  <div class="mb-4">
    <mat-paginator
      class="mb-2"
      [pageSizeOptions]="[5, 10, 25, 100]"
    ></mat-paginator>
    <table
      mat-table
      [dataSource]="combinedDataSource"
      matSort
      matSortActive="count"
      matSortDirection="desc"
    >
      <ng-container matColumnDef="hcpId">
        <th mat-header-cell *matHeaderCellDef class="w-4">
          <div class="p-2 text-center">Link</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="p-2">
            <button mat-icon-button (click)="navigateToHCP(element.hcpId)">
              <mat-icon>link</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="p-2">First name</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="p-2">{{ element.firstName }}</div>
        </td>
      </ng-container>
      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="p-2">Last Name</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="p-2">{{ element.lastName }}</div>
        </td>
      </ng-container>
      <ng-container matColumnDef="count">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="p-2">Count</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="p-2">{{ element.count }}</div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="combinedDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: combinedDisplayedColumns"></tr>
    </table>
  </div>
  <h3 class="mat-h3">Social media network</h3>
  <div class="mb-4">
    <mat-paginator
      class="mb-2"
      [pageSizeOptions]="[5, 10, 25, 100]"
    ></mat-paginator>
    <table mat-table [dataSource]="followersDataSource" matSort>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="w-4">
          <div class="p-2 w-36 text-left">Twitter user ID</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="p-2">{{ element.id }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="followers">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="p-2">Number of followers</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="p-2">{{ element.followers }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <div class="p-2">Name</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="p-2">{{ element.name }}</div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="twitterHeaders"></tr>
      <tr mat-row *matRowDef="let row; columns: twitterHeaders"></tr>
    </table>
  </div>
</div>
