<div class="px-5 mb-4">
  <h3 class="my-4 text-base">Support documents</h3>
  <div *ngIf="pageAvailable">
    <div class="flex gap-4 align-middle">
      <div class="">
        <mat-form-field appearance="fill">
          <mat-label>Button Label</mat-label>
          <input matInput required [(ngModel)]="newDocLabel" />
        </mat-form-field>
      </div>
      <div class="">
        <button
          mat-flat-button
          color="primary"
          aria-label="Import"
          matTooltip="Upload logo"
          (click)="fileInputDoc.click()"
          [disabled]="!newDocLabel"
        >
          <mat-icon class="mr-4">cloud_upload</mat-icon>
          <span class="inline-flex">Upload document</span>
        </button>
        <input
          hidden
          (change)="uploadDocument($event)"
          #fileInputDoc
          type="file"
          id="file"
        />
      </div>
    </div>

    <div class="flex flex-col gap-4" *ngIf="supportDocs.length">
      <h3>Uploaded Files</h3>
      <table class="w-1/2">
        <thead>
          <tr>
            <td class="font-medium">Button Label</td>
            <td class="font-medium">File Name</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let doc of supportDocs">
            <td>{{ doc.label }}</td>
            <td>{{ doc.fileName }}</td>
            <td class="flex">
              <button mat-icon-button color="warn" (click)="deleteFile(doc)">
                <mat-icon color="warn">delete</mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="!pageAvailable">
    <p>Page is not available</p>
  </div>
</div>
