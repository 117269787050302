import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { TCategory } from '@shared/interfaces/TCategory';
import { TProject } from '@shared/interfaces/TProject';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { Subscription } from 'rxjs';
import { CategoriesService } from 'src/app/services/CategoriesService';

export enum NodeType {
  Project,
  Category,
}

interface TBaseNode {
  __type: NodeType;
}

interface TProjectNode extends TBaseNode {
  projectName: string;
  projectId: string;
  categoryId: string;
}

interface TCategoryNode extends TBaseNode {
  categoryName: string;
  categoryId: string;
  children: TProjectNode[];
}

interface TFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  baseNode: TBaseNode;
}

function isCategoryNode(node: TBaseNode): node is TCategoryNode {
  return node.__type === NodeType.Category;
}

function isProjectNode(node: TBaseNode): node is TProjectNode {
  return node.__type === NodeType.Project;
}

function getName(node: TBaseNode): string {
  if (isCategoryNode(node)) {
    return node.categoryName;
  } else if (isProjectNode(node)) {
    return node.projectName;
  }
  return ``;
}

@Component({
  selector: 'app-categories-settings',
  templateUrl: './categories-settings.component.html',
  styleUrls: ['./categories-settings.component.scss'],
})
export class CategoriesSettingsComponent implements OnInit {
  private _subs: Subscription[] = [];
  constructor(private _categoriesService: CategoriesService) {
    this._subs.push(
      this._categoriesService
        .getCategoriesObservable()
        .subscribe(
          (categories) =>
            (this.dataSource.data = this._processNodes(categories.slice()))
        )
    );
  }

  public treeControl = new FlatTreeControl<TFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  public treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => (<TCategoryNode>node).children
  );

  public dataSource = new MatTreeFlatDataSource(
    this.treeControl,
    this.treeFlattener
  );

  public hasChild = (_: number, node: TFlatNode) => node.expandable;

  public getDeleteContent(node: TBaseNode): string {
    if (isCategoryNode(node)) {
      return `Delete category`;
    } else if (isProjectNode(node)) {
      return `Delete project`;
    }
    return ``;
  }

  public ngOnInit(): void {}

  public ngOnDestroy(): void {
    this._subs.forEach((sub) => sub.unsubscribe());
  }

  public handleDelete(node: TBaseNode): void {
    if (isCategoryNode(node)) {
      this._categoriesService.removeCategory(node.categoryId);
    } else if (isProjectNode(node)) {
      this._categoriesService.removeProject(node.categoryId, node.projectId);
    }
  }

  public updateSettings(): void {
    const categories: TCategory[] = this._categoriesService.getCategories();
    this.treeControl.dataNodes.forEach((node) => {
      if (isCategoryNode(node.baseNode)) {
        this.updateCategoryName(
          node.baseNode.categoryId,
          node.name,
          categories
        );
      } else if (isProjectNode(node.baseNode)) {
        this.updateProjectName(node.baseNode.projectId, node.name, categories);
      }
    });
    this._categoriesService.updateCategories(categories);
  }

  private updateCategoryName(
    categoryId: string,
    name: string,
    categories: TCategory[]
  ): void {
    const category: TCategory | undefined = categories.find(
      (it) => it.categoryId === categoryId
    );
    if (!isNullOrUndefined(category)) {
      category.name = name;
    }
  }

  private updateProjectName(
    projectId: string,
    name: string,
    categories: TCategory[]
  ): void {
    const project: TProject | undefined = categories
      .map((it) => it.projects)
      .flat()
      .find((it) => it.id === projectId);
    if (!isNullOrUndefined(project)) {
      project.name = name;
    }
  }

  private _processNodes(categories: TCategory[]): TCategoryNode[] {
    const data: TCategoryNode[] = categories.map((category) => {
      const projectNodes: TProjectNode[] = category.projects.map((project) => {
        const projectNode: TProjectNode = {
          __type: NodeType.Project,
          projectId: project.id,
          projectName: project.name,
          categoryId: category.categoryId,
        };
        return projectNode;
      });
      const categoryNode: TCategoryNode = {
        __type: NodeType.Category,
        categoryId: category.categoryId,
        categoryName: category.name,
        children: projectNodes,
      };
      return categoryNode;
    });
    return data;
  }

  private _transformer(node: TBaseNode, level: number): TFlatNode {
    const categoryNode = isCategoryNode(node) ? node : null;
    const isExpandable =
      !!categoryNode?.children && categoryNode.children.length > 0;
    return {
      expandable: isExpandable,
      name: getName(node),
      level: level,
      baseNode: node,
    };
  }
}
