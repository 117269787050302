import { Component, Input } from '@angular/core';
import { ProductName, TProductSales } from '@shared/interfaces/TProductUse';
import {
  Operator,
  TProductUseScoreItem,
  TProject,
  operatorToName,
} from '@shared/interfaces/TProject';
import { MATHEMATICAL_OPERATORS } from '../../Constants';

@Component({
  selector: 'app-product-use-score-item',
  templateUrl: './product-use-score-item.component.html',
  styleUrls: ['./product-use-score-item.component.scss'],
})
export class ProductUseScoreItemComponent {
  @Input() public isNew: boolean = false;
  @Input() public availableProducts: ProductName[] = [];
  @Input() public availableTimePeriods: TProductSales['time_period'][] = [];
  @Input() public operator: Operator = Operator.Addition;
  @Input() public selectedField: TProductUseScoreItem = {
    product: ``,
    timePeriod: ``,
    availableOperators: MATHEMATICAL_OPERATORS,
    operator: Operator.Addition,
    score: 0,
    area: `hcpProductUse`,
  };
  @Input() public scoreValue: number | string = 0;
  @Input() public project: TProject | undefined;

  @Input() public addNewField: (
    addNewSelectedValue: TProductUseScoreItem | undefined,
    addNewOperator: Operator | undefined,
    scoreValue: number | string
  ) => void = () => void 0;

  @Input() public deleteField: (field?: TProductUseScoreItem) => void = () =>
    void 0;

  public addNewFieldDisabled(
    addNewSelectedValue: TProductUseScoreItem | undefined,
    addNewOperator: Operator | undefined,
    scoreValue: number | string
  ): boolean {
    const isDisabled: boolean =
      addNewSelectedValue === undefined ||
      addNewSelectedValue.product === `` ||
      addNewSelectedValue.timePeriod === `` ||
      addNewOperator === undefined ||
      Number.isNaN(scoreValue);
    return isDisabled;
  }

  public operatorToName(type: Operator): string {
    return operatorToName(type);
  }
}
