import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ProductName } from '@shared/interfaces/TProductUse';
import {
  CustomRegion,
  DEFAULT_APP_SETTINGS,
  TSettings,
} from '@shared/interfaces/TSettings';
import { TUser, UserLevel } from '@shared/interfaces/TUser';
import { Subscription } from 'rxjs';
import { LoadingService } from '../services/LoadingService';
import { TRegion } from '../services/RegionsService';
import { SettingsService } from '../services/SettingsService';
import { AuthService } from '../services/auth/auth.service';
import { CountriesWithPaperNotificationsDialogComponent } from './countries-with-paper-notifications-dialog/countries-with-paper-notifications-dialog.component';
import { CustomRegionsDialogComponent } from './custom-regions-dialog/custom-regions-dialog.component';

const { version: appVersion } = require('../../../package.json');

@Component({
  selector: 'app-system-settings',
  templateUrl: './system-settings.component.html',
  styleUrls: ['./system-settings.component.scss'],
})
export class SystemSettingsComponent implements OnInit, OnDestroy {
  public appSettings: TSettings = structuredClone(DEFAULT_APP_SETTINGS);
  public frontendVersion: string = appVersion;
  public backendVersion: string = ``;
  private _subs: Subscription[] = [];
  public customRegions: CustomRegion[] = [];
  public products: ProductName[] = [];
  public autoImportForm: FormGroup;
  public productForm: FormGroup;
  public user: Partial<TUser> | undefined = undefined;

  constructor(
    private _settingsService: SettingsService,
    private _loadingService: LoadingService,
    private _authService: AuthService,
    public dialog: MatDialog,
    private fb: FormBuilder
  ) {
    this.autoImportForm = this.fb.group({
      enableAutoImport: [false],
    });
    this.productForm = this.fb.group({
      name: ['', [Validators.required]],
    });

    this._subs.push(
      this._settingsService.getAppSettingsObservable().subscribe((settings) => {
        this.appSettings = settings;
        this.customRegions = settings.customRegions;
        this.products = settings.products;
        this.autoImportForm.patchValue({
          enableAutoImport: settings.enableAutoImport,
        });
      })
    );
    this._subs.push(
      this._settingsService.getVersionObservable().subscribe((settings) => {
        if (settings.version !== undefined && settings.version.length > 0)
          this.backendVersion = settings.version;
      })
    );
  }

  public showLog(): void {
    this._loadingService.setLogVisible(true);
    this._settingsService.updateLocalSetting(`LogVisible`, true);
  }

  public ngOnInit(): void {
    this._settingsService.setVersion();
    this._subs.push(
      this._authService.getCurrentUserObservable().subscribe((user) => {
        this.user = user;
      })
    );
  }

  public ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
  }

  public updateSettings(): void {
    const newSettings: TSettings = {
      ...this.appSettings,
      customRegions: this.customRegions,
      enableAutoImport: this.autoImportForm.value.enableAutoImport,
      products: this.products,
    };
    this._settingsService.updateSettings(newSettings);
  }

  public countriesList(region: CustomRegion): string {
    return region.countries.map((x) => x.viewValue).join(', ');
  }

  get countriesWithPaperNotifications(): string {
    return this.appSettings.countriesWithPaperNotifications.join(', ');
  }

  public editRegion(region: CustomRegion): void {
    const dialogRef = this.dialog.open(CustomRegionsDialogComponent, {
      data: region,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.customRegions = this.customRegions.map((region) =>
          region._id === result._id ? result : region
        );
      }
    });
  }

  public addRegion(): void {
    const dialogRef = this.dialog.open(CustomRegionsDialogComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.customRegions.push(result);
      }
    });
  }

  public deleteRegion(region: CustomRegion): void {
    this.customRegions = this.customRegions.filter((r) => r._id !== region._id);
  }

  public editCountriesWithPaperNotifications(): void {
    const dialogRef = this.dialog.open(
      CountriesWithPaperNotificationsDialogComponent,
      {
        data: this.appSettings.countriesWithPaperNotifications.map(
          (country) => ({ value: country, viewValue: country })
        ),
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.appSettings.countriesWithPaperNotifications = result.countries.map(
          (country: TRegion) => country.value
        );
      }
    });
  }

  public deleteProduct(product: ProductName): void {
    this.products = this.products.filter((p) => p !== product);
  }

  public addProduct(name: string): void {
    if (this.products.includes(name.toLocaleLowerCase())) return;
    this.products.push(name.toLocaleLowerCase());
  }

  public isAdminUser(): boolean {
    return this.user?.level === UserLevel.Admin;
  }
}
