import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FieldType } from '@shared/Types/FieldType';
import { CENTER_DB_MAPPINGS } from '@shared/db-fields-mappings/CENTER_DB_MAPPINGS';
import { HCP_DB_MAPPINGS } from '@shared/db-fields-mappings/HCP_DB_MAPPINGS';
import {
  FilterOperator,
  NoScoreReport,
  TDataFilter,
  TReportFilterProperty,
  TReportType,
  TTagItem,
} from '@shared/interfaces/TProject';
import { newGuid } from '@shared/utils/newGuild';
import { getFilteringLegend } from '../../../utils/filter';

export interface FilterLegend {
  property: string;
  label: string;
  values: string[];
}

export interface TReportFiltersParams {
  selectedReport: TReportType | NoScoreReport | null;
  filters: TReportDataFilter[];
  availableTags: TTagItem[];
  projectId: string;
  filtersLegend?: FilterLegend[];
}

export interface TReportDataFilter extends TDataFilter {
  id: string;
}

interface TAvailableFilterItem {
  name: string;
  value: string;
}

@Component({
  selector: 'app-report-filters',
  templateUrl: './report-filters.component.html',
  styleUrls: ['./report-filters.component.scss'],
})
export class ReportFiltersComponent implements OnInit {
  private availableHCPFilters: TAvailableFilterItem[] = [
    ...Array.from(HCP_DB_MAPPINGS.values()).filter(
      (f) => f.isReportFilterField === true
    ),
  ].map((f) => {
    return { name: f.name, value: f.fieldId };
  });
  private availableCentersFilters: TAvailableFilterItem[] = [
    ...Array.from(CENTER_DB_MAPPINGS.values()).filter(
      (f) => f.isReportFilterField === true
    ),
  ].map((f) => {
    return { name: f.name, value: f.fieldId };
  });
  public fieldType: FieldType = `hcp`;
  public availableFilters: TAvailableFilterItem[] = [];
  public filterDescription: string = ``;
  public filters: TReportDataFilter[] = [];

  constructor(
    public dialogRef: MatDialogRef<ReportFiltersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TReportFiltersParams
  ) {}

  ngOnInit(): void {
    this.setFilters();
    if (
      ['Center', 'basicCenter'].includes(this.data.selectedReport as string)
    ) {
      this.fieldType = 'center';
      this.availableFilters = this.availableCentersFilters;
      this.filterDescription = `Add new Center filter`;
    } else {
      this.fieldType = 'hcp';
      this.availableFilters = this.availableHCPFilters;
      this.filterDescription = `Add new HCP filter`;
    }
  }

  public getFieldName(id: string): string {
    if (
      ['Center', 'basicCenter'].includes(this.data.selectedReport as string)
    ) {
      return (
        [...Array.from(CENTER_DB_MAPPINGS.values())].find(
          (it) => it.fieldId === id
        )?.name ?? ``
      );
    } else {
      return (
        [...Array.from(HCP_DB_MAPPINGS.values())].find(
          (it) => it.fieldId === id
        )?.name ?? ``
      );
    }
  }

  public deleteFilter(item: TReportDataFilter): void {
    const itemIndex: number = this.data.filters.findIndex(
      (it) => it.property === item.property
    );
    if (itemIndex > -1) {
      this.data.filters.splice(itemIndex, 1);
    }
    this.setFilters();
  }

  public addNewFilter(value: string): void {
    const type =
      HCP_DB_MAPPINGS.get(value)?.engagement === true
        ? `engagement`
        : `regular`;
    if (type === 'engagement') {
      const newFilter: TReportDataFilter = {
        operator: FilterOperator.Equal,
        property: value,
        value: ``,
        id: newGuid(),
        type: type,
        date: new Date(),
        tags: [],
      };
      this.data.filters.push(newFilter);
    } else {
      const newFilter: TReportDataFilter = {
        operator: FilterOperator.Equal,
        property: value,
        value: ``,
        id: newGuid(),
        type: type,
      };

      this.data.filters.push(newFilter);

      this.data.filters.forEach((f) => {
        this.getFilterLegend(f.property as TReportFilterProperty);
      });
      this.setFilters();
    }
  }

  getFilterLegend(filter: TReportFilterProperty) {
    const isLegendExisting = this.data.filtersLegend?.find(
      (legend) => legend.property === filter
    );
    if (isLegendExisting) {
      return;
    }
    const legend = getFilteringLegend(filter);
    legend && this.data.filtersLegend?.push(legend);
  }

  private setFilters() {
    this.filters = this.data.filters.filter((f) => f.metric === undefined);
  }
}
