import { HttpClient } from '@angular/common/http';
import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Endpoints } from '@shared/Endpoints';
import {
  THCP,
  TProximityDataForHCP,
  TProximityScoreItem,
} from '@shared/interfaces/THCP';
import { isNullOrEmpty } from '@shared/utils/isNullOrEmpty';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { ROUTES_PATHS } from 'src/app/app-routing.module';
import {
  TBaseComponentParams,
  TComponentType,
  THCPParams,
} from 'src/app/interfaces/TComponentParams';
import { LoadingService, TLoadingItem } from 'src/app/services/LoadingService';
import { TwitterService } from 'src/app/services/TwitterService';
import { environment } from 'src/environments/environment';

interface TTwitterDataItem {
  id: string;
  following: number;
  followers: number;
  name: string;
}

@Component({
  selector: 'app-proximity-score',
  templateUrl: './proximity-score.component.html',
  styleUrls: ['./proximity-score.component.scss'],
})
export class ProximityScoreComponent implements OnInit {
  @Input()
  public params: TBaseComponentParams = {
    categoryId: ``,
    projectId: ``,
    __type: TComponentType.hcp,
  };
  @Input()
  public hcpData: THCP | undefined = undefined;

  public combinedDataSource: MatTableDataSource<TProximityScoreItem>;
  public combinedDisplayedColumns: (keyof TProximityScoreItem)[] = [
    `hcpId`,
    `firstName`,
    `lastName`,
    `count`,
  ];
  public twitterHeaders: (keyof TTwitterDataItem)[] = [
    `id`,
    `followers`,
    `name`,
  ];
  public followindDataSource: MatTableDataSource<TTwitterDataItem>;
  public followersDataSource: MatTableDataSource<TTwitterDataItem>;

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  constructor(
    private _http: HttpClient,
    private _loadingService: LoadingService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _twitterService: TwitterService
  ) {
    this.combinedDataSource = new MatTableDataSource();
    this.followersDataSource = new MatTableDataSource();
  }

  public ngOnInit(): void {
    this.getProximityScore();
  }

  public navigateToHCP(hcpId: string): void {
    const hcpParams: THCPParams = {
      hcpId,
      categoryId: this.params.categoryId,
      projectId: this.params.projectId,
      __type: TComponentType.hcp,
    };
    this._router.navigate([`/${ROUTES_PATHS.hcp}`, hcpParams], {
      relativeTo: this._route,
    });
  }

  public getProximityScore(): void {
    const url: string = Endpoints.BUILD_URL('proximity', {
      base: environment.apiUrl,
      endpoint: 'getData',
      params: ``,
    });
    if (!isNullOrUndefined(this.hcpData)) {
      const loadingItem: TLoadingItem = this._loadingService.buildLoadingItem(
        `Generating proximity data`
      );
      this._loadingService.addLoadingItem(loadingItem);
      const payload: { hcpsId: string; projectId: string } = {
        hcpsId: this.hcpData.id,
        projectId: this.params.projectId,
      };
      const response = this._http.post<TProximityDataForHCP>(url, payload);
      response.subscribe({
        error: () => {
          this._loadingService.onError();
        },
        next: (response) => {
          this._loadingService.removeItem(loadingItem);
          this.combinedDataSource.data = response.proximityScore.proximitySum;
          this.combinedDataSource.paginator = this.paginator.toArray()[0];
          this.combinedDataSource.sort = this.sort.toArray()[0];
        },
      });
      if (!isNullOrEmpty(this.hcpData.twitterUserName)) {
        this._twitterService
          .getTwitterProximity(this.hcpData.id)
          .subscribe((results) => {
            this.followersDataSource.data = results.followers.map((it) => {
              return {
                followers: it.twitterData.followers.length,
                following: it.twitterData.following.length,
                id: it.twitterUserName,
                name: `${it.first_name} ${it.last_name}`,
              };
            });
            this.followersDataSource.paginator = this.paginator.toArray()[1];
            this.followersDataSource.sort = this.sort.toArray()[1];
          });
      }
    }
  }

  public getCSS(count: number): string {
    if (count > 1) {
      return `highlight`;
    }
    return ``;
  }
}
