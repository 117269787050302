<div class="static-boolean">
  <mat-label>{{ name }}</mat-label>
  <ng-container *ngIf="!isTrue && !isUnreadMark && !isQuestionMark">
    <mat-icon color="warn">close</mat-icon>
  </ng-container>
  <ng-container *ngIf="isQuestionMark">
    <mat-icon color="primary">question_mark</mat-icon>
  </ng-container>
  <ng-container *ngIf="isUnreadMark">
    <mat-icon color="primary">markunread_mailbox</mat-icon>
  </ng-container>
  <ng-container *ngIf="isTrue">
    <mat-icon color="success-text">check</mat-icon>
  </ng-container>
</div>
