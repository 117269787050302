<mat-tree
  #tree
  [dataSource]="dataSource"
  [treeControl]="treeControl"
  class="tree"
>
  <!-- This is the tree node template for leaf nodes -->
  <!-- There is inline padding applied to this node using styles.
      This padding value depends on the mat-icon-button width. -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <mat-checkbox
      [(ngModel)]="node.selected"
      (change)="onCheckobxChange(node)"
    ></mat-checkbox
    ><span>{{ node.name }}</span>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <div class="mat-tree-node">
      <button mat-icon-button matTreeNodeToggle>
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
        </mat-icon>
      </button>
      <mat-checkbox
        [(ngModel)]="node.selected"
        (change)="onCheckobxChange(node)"
      ></mat-checkbox
      ><span>{{ node.name }}</span>
    </div>
    <!-- There is inline padding applied to this div using styles.
            This padding value depends on the mat-icon-button width.  -->
    <div [class.tree-invisible]="!treeControl.isExpanded(node)" role="group">
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>
</mat-tree>
