<div class="close-dialog-container">
  <button mat-dialog-close mat-icon-button aria-label="close">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="opt-in-out">
  <h3 class="mat-h3">Available Opt out HCPs</h3>
  <div
    class="mt-4"
    *ngIf="displayedOptOutHCPs.length > 0"
    class="opt-in-out__selection"
  >
    <div class="opt-in-out__selection__items">
      <div *ngFor="let hcp of displayedOptOutHCPs">
        <mat-checkbox [(ngModel)]="hcp.isSelected"></mat-checkbox>
        {{ hcp.data.first_name }} {{ hcp.data.last_name }}
        {{
          hcp.data.primary_email_address
            ? " - " + hcp.data.primary_email_address
            : ""
        }}
      </div>
    </div>
    <div class="opt-in-out__selection__footer">
      <button
        mat-flat-button
        color="primary"
        (click)="sendOptOut()"
        [disabled]="optOutInProgress"
        class="opt-in-out__send-email-button"
      >
        <mat-spinner [diameter]="20" *ngIf="optOutInProgress"></mat-spinner>
        Send opt out message
      </button>
    </div>
  </div>
  <div class="mt-4 mb-2" *ngIf="displayedOptOutHCPs.length === 0">
    No available HCPs to opt out
  </div>

  <h3 class="mat-h3">Available Opt in HCPs</h3>
  <div *ngIf="displayedOptInHCPs.length > 0" class="opt-in-out__selection">
    <div class="opt-in-out__selection__items">
      <div *ngFor="let hcp of displayedOptInHCPs">
        <mat-checkbox [(ngModel)]="hcp.isSelected"></mat-checkbox>
        {{ hcp.data.first_name }} {{ hcp.data.last_name }}
        {{
          hcp.data.primary_email_address
            ? " - " + hcp.data.primary_email_address
            : ""
        }}
      </div>
    </div>
    <div class="opt-in-out__selection__footer">
      <button
        mat-flat-button
        color="primary"
        (click)="sendOptIn()"
        [disabled]="optInInProgress"
        class="opt-in-out__send-email-button"
      >
        <mat-spinner [diameter]="20" *ngIf="optInInProgress"></mat-spinner>
        Send opt in emails
      </button>
    </div>
  </div>

  <div class="mt-4 mb-2" *ngIf="displayedOptInHCPs.length === 0">
    <div>No available HCPs to opt in</div>
  </div>

  <div
    class="mt-4"
    *ngIf="invalidHCPs.length > 0"
    class="opt-in-out__selection"
  >
    <div *ngIf="getHCPsWithInvalidEmails().length > 0">
      <h3 class="mat-h3">HCPs with invalid emails</h3>
      <div class="opt-in-out__selection__items">
        <app-simple-hcp-editor
          [editableProperties]="editablePropertiesEmail"
          [notEditableProperties]="notEditablePropertiesEmail"
          [isHeader]="true"
          [projectId]="data.projectId"
        ></app-simple-hcp-editor>
        <div *ngFor="let hcp of getHCPsWithInvalidEmails()">
          <app-simple-hcp-editor
            [editableProperties]="editablePropertiesEmail"
            [notEditableProperties]="notEditablePropertiesEmail"
            [isHeader]="false"
            [projectId]="data.projectId"
            [hcp]="hcp.data"
          ></app-simple-hcp-editor>
        </div>
      </div>
      <div class="opt-in-out__selection__footer">
        <button
          mat-flat-button
          color="primary"
          (click)="updateSelectedInvalidHCPs()"
        >
          Update selected hcps
        </button>
      </div>
    </div>
    <div *ngIf="getHCPsWithMissingPostalAddress().length > 0">
      <h3 class="mat-h3">HCPs with invalid postal address</h3>
      <h4 class="mat-h4">Please add the address information for these HCPs:</h4>
      <div
        *ngFor="let hcp of getHCPsWithMissingPostalAddress()"
        class="flex gap-2"
      >
        <div class="flex-1">
          {{ hcp.data.first_name }} {{ hcp.data.last_name }}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="getHCPsWithInvalidStatus().length > 0">
    <h3 class="mat-h3">HCPs with invalid statuses (Opt in/out declined)</h3>
    <div class="mt-4">
      <app-simple-hcp-editor
        [notEditableProperties]="notEditablePropertiesStatuses"
        [isHeader]="true"
        [projectId]="data.projectId"
      ></app-simple-hcp-editor>
      <div *ngFor="let hcp of getHCPsWithInvalidStatus()">
        <app-simple-hcp-editor
          [notEditableProperties]="notEditablePropertiesStatuses"
          [isHeader]="false"
          [projectId]="data.projectId"
          [hcp]="hcp.data"
        ></app-simple-hcp-editor>
      </div>
    </div>
  </div>
</div>
