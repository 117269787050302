<div class="user">
  <div class="flex">
    <div class="user__avatar">
      <span>
        {{ getAvatar() }}
      </span>
    </div>
    <div class="user_info">
      <div class="user__info__name">
        {{ user?.firstName }} {{ user?.lastName }}
      </div>
      <div class="user__info__email two-lines">
        {{ user?.email }}
      </div>
      <div class="user__info__email">
        {{ getRole() }}
      </div>
    </div>
  </div>
  <div class="user__actions">
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="deleteCallback(user?.email ?? '')">
        <span>Delete</span>
      </button>
      <button mat-menu-item (click)="editCallback(user?.email ?? '')">
        <span>Edit</span>
      </button>
    </mat-menu>
    <mat-checkbox
      *ngIf="selectable"
      [(ngModel)]="isSelected"
      (change)="selectedChange(user?.email ?? '')"
    ></mat-checkbox>
  </div>
</div>
