import { Injectable } from '@angular/core';
import { SearchArea } from '@shared/enums/SearchArea';
import { TProject } from '@shared/interfaces/TProject';
import { Order, TPaging, TSortItem } from '@shared/interfaces/TSearchParams';
import { TPagingItem } from './TPagingItem';

export interface THandleSortParams {
  projectId: string;
  area: SearchArea;
  fieldId: string;
  remove: boolean;
}

export const PAGING_LOCAL_STORAGE_NAME: string = `riseAppPaging`;
export const PAGING_LOCAL_STORAGE_VERSION: string = `riseAppPagingVersion`;

@Injectable()
export class PagingService {
  public pageSizeOptions: number[] = [10, 25, 50, 100, 200];
  public defaultPagingItem: TPaging = {
    pageIndex: 0,
    pageSize: this.pageSizeOptions[3],
    total: this.pageSizeOptions[3],
    sortBy: [],
    query: ``
  };

  private _pagingItems: Map<string, TPagingItem> = new Map();
  private _version: string = `6`;

  constructor() {
    this.setSettings();
  }

  public getPagingItem(projectId: string): TPagingItem {
    const paging: TPagingItem | undefined = this._pagingItems.get(projectId);
    if (paging === undefined) {
      console.warn(`Project id ${projectId} not found`);
      return {
        articles: this.getPaging(projectId, SearchArea.Articles),
        centersPaging: this.getPaging(projectId, SearchArea.Centers),
        hcpsPaging: this.getPaging(projectId, SearchArea.HCP),
        hcpsReportPaging: this.getPaging(projectId, SearchArea.HCPsReports),
        centersReportPaging: this.getPaging(
          projectId,
          SearchArea.CentersReports
        ),
      };
    }
    return paging;
  }

  public getPaging(projectId: string, area: SearchArea): TPaging {
    const paging: TPagingItem | undefined = this._pagingItems.get(projectId);
    if (paging === undefined) {
      return this.defaultPagingItem;
    }
    switch (area) {
      case SearchArea.Articles:
        return paging.articles;
      case SearchArea.Centers:
        return paging.centersPaging;
      case SearchArea.HCP:
        return paging.hcpsPaging;
      case SearchArea.HCPsReports:
        return paging.hcpsReportPaging;
      case SearchArea.CentersReports:
        return paging.centersReportPaging;
      default:
        return this.defaultPagingItem;
    }
  }

  public updatePagingItem(projectId: string, item: TPagingItem): void {
    this._pagingItems.set(projectId, item);
    this._saveToLocalStorage();
  }

  public handleSort(params: THandleSortParams): void {
    const { projectId, area, fieldId, remove } = params;
    const paging: TPagingItem = this.getPagingItem(projectId);
    switch (area) {
      case SearchArea.Articles:
        this._onSortOrderChange(paging.articles.sortBy, fieldId, remove);
        break;
      case SearchArea.Centers:
        this._onSortOrderChange(paging.centersPaging.sortBy, fieldId, remove);
        break;
      case SearchArea.HCP:
        this._onSortOrderChange(paging.hcpsPaging.sortBy, fieldId, remove);
        break;
      case SearchArea.HCPsReports:
        this._onSortOrderChange(
          paging.hcpsReportPaging.sortBy,
          fieldId,
          remove
        );
        break;
      case SearchArea.CentersReports:
        this._onSortOrderChange(
          paging.centersReportPaging.sortBy,
          fieldId,
          remove
        );
        break;
    }
  }

  public removePagingItem(projectId: string): void {
    this._pagingItems.delete(projectId);
    this._saveToLocalStorage();
  }

  public addIfNotExists(projects: TProject[]): void {
    const existingPagingData: Map<string, TPagingItem> =
      this._getLocalStorageData();
    projects.forEach((p) => {
      const pagingData: TPagingItem | undefined = existingPagingData.get(p.id);
      if (pagingData === undefined) {
        existingPagingData.set(p.id, {
          articles: structuredClone(this.defaultPagingItem),
          centersPaging: structuredClone(this.defaultPagingItem),
          hcpsPaging: structuredClone(this.defaultPagingItem),
          hcpsReportPaging: structuredClone(this.defaultPagingItem),
          centersReportPaging: structuredClone(this.defaultPagingItem),
        });
      }
    });
    this._pagingItems = structuredClone(existingPagingData);
    this._saveToLocalStorage();
  }

  public updateSortOrder(
    currentSorts: TSortItem[],
    fieldId: string,
    remove: boolean
  ): void {
    const itemIndex: number = currentSorts.findIndex(
      (s) => s.fieldId === fieldId
    );
    if (remove) {
      currentSorts.splice(itemIndex, 1);
    } else {
      if (itemIndex === -1) {
        currentSorts.push({
          fieldId: fieldId,
          order: Order.Asc,
        });
      } else {
        currentSorts[itemIndex] = {
          fieldId: fieldId,
          order:
            currentSorts[itemIndex].order === Order.Asc
              ? Order.Desc
              : Order.Asc,
        };
      }
    }
  }

  private _onSortOrderChange(
    currentSorts: TSortItem[],
    fieldId: string,
    remove: boolean
  ): void {
    this.updateSortOrder(currentSorts, fieldId, remove);
    this._saveToLocalStorage();
  }

  private _saveToLocalStorage(): void {
    localStorage.setItem(
      PAGING_LOCAL_STORAGE_NAME,
      JSON.stringify(Array.from(this._pagingItems))
    );
  }

  private _getLocalStorageData(): Map<string, TPagingItem> {
    //TODO reset after version change to clear old data
    const localStorageData: string | null = localStorage.getItem(
      PAGING_LOCAL_STORAGE_NAME
    );
    if (localStorageData === null) {
      return new Map();
    } else {
      return new Map(JSON.parse(localStorageData));
    }
  }

  private setSettings(): void {
    const storedVersion: string | null = localStorage.getItem(
      PAGING_LOCAL_STORAGE_VERSION
    );
    if (storedVersion !== this._version) {
      localStorage.removeItem(PAGING_LOCAL_STORAGE_NAME);
      localStorage.setItem(PAGING_LOCAL_STORAGE_VERSION, this._version);
    }
  }
}
