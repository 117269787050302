import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { THCP, TTwitterData, buildHCPMock } from '@shared/interfaces/THCP';
import { ResponseStatus } from '@shared/interfaces/TResponseStatusMessage';
import { TTwitterDataResponse } from '@shared/interfaces/TTwitterDataResponse';
import { isNullOrEmpty } from '@shared/utils/isNullOrEmpty';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { Subscription } from 'rxjs';
import { HCPService } from '../services/HCPService';
import { LoadingService, TLoadingItem } from '../services/LoadingService';
import { TwitterService } from '../services/TwitterService';

@Component({
  selector: 'app-twitter-review',
  templateUrl: './twitter-review.component.html',
  styleUrls: ['./twitter-review.component.scss'],
})
export class TwitterReviewComponent implements OnInit, OnDestroy {
  public twitterData: TTwitterDataResponse = {
    firstName: ``,
    followers: 0,
    following: 0,
    hcpId: ``,
    lastName: ``,
  };
  public hcp: THCP = buildHCPMock({});

  @Input()
  public projectId: string = '';
  @Input()
  public categoryId: string = '';
  private _subs: Subscription[] = [];

  constructor(
    private _twitterService: TwitterService,
    private _HCPsService: HCPService,
    private _loadingService: LoadingService
  ) {
    this._subs.push(
      this._HCPsService
        .getExtendedHCPsObservable()
        .subscribe((hcps: THCP[]) => {
          if (hcps.length === 1) {
            this.hcp = hcps[0];
            this._subs.push(
              this._twitterService
                .getTwitterReview(this.hcp.id)
                .subscribe((data: TTwitterData) => {
                  if (!isNullOrUndefined(data)) {
                    this.twitterData = {
                      firstName: this.hcp.first_name,
                      lastName: this.hcp.last_name,
                      followers: data.followers.length,
                      following: data.following.length,
                      hcpId: this.hcp.id,
                    };
                  }
                })
            );
          }
        })
    );
  }

  public ngOnInit(): void {}

  public refreshDisabled(): boolean {
    return isNullOrEmpty(this.hcp.twitterUserName);
  }

  public ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
  }

  public async save(): Promise<void> {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Pulling Tweets`);
    this._loadingService.addLoadingItem(loadingItem);
    this._twitterService.setTwitterReview(
      this.hcp.twitterUserName,
      this.hcp.id,
      this.projectId,
      (res: ResponseStatus) => {
        if (res === ResponseStatus.OK) {
          this._HCPsService.setHCPs(
            {
              categoryId: this.categoryId,
              ids: [this.hcp.id],
              projectId: this.projectId,
            },
            () => {
              this._loadingService.removeItem(loadingItem);
            }
          );
        } else {
          this._loadingService.triggerErrorMessage(`Invalid Twitter user`);
          this._loadingService.removeItem(loadingItem);
        }
      }
    );
  }
}
