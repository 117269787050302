import { Injectable } from '@angular/core';
import { TCtData } from '@shared/interfaces/TCtData';
import { TProximityDataForHCP } from '@shared/interfaces/THCP';
import {
  NoScoreReport,
  TReportType,
  TTagItem,
} from '@shared/interfaces/TProject';
import { BehaviorSubject, Observable } from 'rxjs';
import { TReportDataFilter } from '../reports-review/report-filters/report-filters.component';
import { TReportComponentParams } from '../reports-review/report/report.component';
import { CentersService } from './CentersService';
import { TRegion } from './RegionsService';

export interface TCacheReportParams {
  type: TReportType | NoScoreReport | null;
  selectedRegions: TRegion[] | null;
  reportFilters: TReportDataFilter[];
  tags: TTagItem[] | null;
  reportComponentParams: TReportComponentParams;
}

export interface TCTCacheItem {
  ids: string[];
  data: TCtData[];
}

@Injectable()
export class CacheService {
  private _centersNameMap: BehaviorSubject<Map<string, string>> =
    new BehaviorSubject<Map<string, string>>(new Map());
  private _selectedReport: TCacheReportParams | undefined;
  private _ctCache: Map<string, TCTCacheItem> = new Map();
  private _projectHcpProximityScoreCache: Record<
    string,
    Map<string, TProximityDataForHCP>
  > = {};

  constructor(private _centersService: CentersService) {}

  public setCTItem(projectId: string, ids: string[], data: TCtData[]): void {
    this._ctCache.set(projectId, {
      ids,
      data,
    });
  }

  public getCTItem(projectId: string): TCTCacheItem | undefined {
    return this._ctCache.get(projectId);
  }

  public getSelectedReport(): TCacheReportParams | undefined {
    return this._selectedReport;
  }

  public setSelectedReport(params: TCacheReportParams): void {
    this._selectedReport = structuredClone(params);
  }

  public getHCPProximityScore(
    hcpId: string,
    projectId: string
  ): TProximityDataForHCP | undefined {
    if (this._projectHcpProximityScoreCache[projectId]) {
      return this._projectHcpProximityScoreCache[projectId].get(hcpId);
    }
    return undefined;
  }

  public setHCPProximityScore(
    hcpId: string,
    projectId: string,
    proximityData: TProximityDataForHCP
  ): void {
    if (!this._projectHcpProximityScoreCache[projectId]) {
      this._projectHcpProximityScoreCache[projectId] = new Map();
    }
    this._projectHcpProximityScoreCache[projectId].set(hcpId, proximityData);
  }

  public getCentersNameMapObservable(): Observable<Map<string, string>> {
    return this._centersNameMap.asObservable();
  }

  public getVisibleArticles(): Map<string, string> {
    return this._centersNameMap.value;
  }

  public setCentersNameMap(callback: () => void): void {
    this._centersService.getAllCenters().subscribe((response) => {
      this._centersNameMap.next(
        new Map(response.map((c) => [c.c_id, c.c_name]))
      );
      callback();
    });
  }
}
