import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FieldType } from '@shared/Types/FieldType';
import { CENTER_DB_MAPPINGS } from '@shared/db-fields-mappings/CENTER_DB_MAPPINGS';
import { HCP_DB_MAPPINGS } from '@shared/db-fields-mappings/HCP_DB_MAPPINGS';
import {
  ADD_TO_PROJECT_ID,
  BatchUpdateFilterType,
  TBatchUpdateFilter,
  TCenterBatchUpdateResults,
  TTransformation,
} from '@shared/interfaces/TBatchUpdateParams';
import { TDBFieldMappingItem } from '@shared/interfaces/TDBFieldMappingItem';
import { FilterOperator, TDataFilter } from '@shared/interfaces/TProject';
import { CentersService } from '../../../services/CentersService';
import { LoadingService, TLoadingItem } from '../../../services/LoadingService';
import { TSelectFilterItem } from '../filters/filters.component';
import { TBaseSettingsTabParams } from '../project-settings.component';

@Component({
  selector: 'app-batch-update',
  templateUrl: './batch-update.component.html',
  styleUrls: ['./batch-update.component.scss'],
})
export class BatchUpdateComponent {
  public availableHCPFilters: TSelectFilterItem[] = [
    ...Array.from(HCP_DB_MAPPINGS.values()).filter(
      (f) => f.isReportFilterField === true
    ),
  ].map((f) => {
    return {
      name: f.name,
      value: f.fieldId,
    };
  });
  public availableCentersFilters: TSelectFilterItem[] = [
    ...Array.from(CENTER_DB_MAPPINGS.values()).filter(
      (f) => f.isReportFilterField === true
    ),
  ].map((f) => {
    return {
      name: f.name,
      value: f.fieldId,
    };
  });
  public availableCentersTransformationFields: TSelectFilterItem[] = [
    {
      name: `Add to projects`,
      value: ADD_TO_PROJECT_ID,
    },
    ...Array.from(CENTER_DB_MAPPINGS.values())
      .filter((f) => f.availableInTransformation === true)
      .map((f) => {
        return {
          name: f.name,
          value: f.fieldId,
        };
      }),
  ];
  public filters: TBatchUpdateFilter[] = [];
  public transformations: TTransformation[] = [];
  public previewVisible: boolean = false;
  public batchUpdateResults: TCenterBatchUpdateResults = {
    results: [],
  };
  public fieldType: FieldType = `center`;

  constructor(
    public dialogRef: MatDialogRef<BatchUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TBaseSettingsTabParams,
    private _centerService: CentersService,
    private _loadingService: LoadingService
  ) {}

  public addNewFilter(value: string): void {
    const newFilter: TBatchUpdateFilter = {
      operator: FilterOperator.Equal,
      property: value,
      value: ``,
      type: 'regular',
      batchType: BatchUpdateFilterType.Regular,
    };
    this.filters.push(newFilter);
  }

  public addEmptyCentersFilter(): void {
    this.filters.push({
      operator: FilterOperator.Equal,
      type: 'regular',
      batchType: BatchUpdateFilterType.EmptyCenters,
      property: ``,
      value: ``,
    });
  }

  public addNewTransformation(value: string): void {
    const mappings: Map<string, TDBFieldMappingItem> =
      this.fieldType === 'center' ? CENTER_DB_MAPPINGS : HCP_DB_MAPPINGS;
    this.transformations.push({
      addToProject: value === ADD_TO_PROJECT_ID,
      filedId: value,
      value: ``,
      name: mappings.get(value)?.name ?? `N/A`,
    });
    this.availableCentersTransformationFields =
      this.availableCentersTransformationFields.filter(
        (it) => it.value !== value
      );
    this.previewVisible = false;
  }

  public isEmptyCentersFilter(item: TBatchUpdateFilter): boolean {
    return item.batchType === BatchUpdateFilterType.EmptyCenters;
  }

  public addEmptyCentersFilterAvailable(): boolean {
    return (
      this.filters.findIndex(
        (f) => f.batchType === BatchUpdateFilterType.EmptyCenters
      ) !== -1
    );
  }

  public batchUpdate(preview: boolean): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Updating Centers`);
    this._loadingService.addLoadingItem(loadingItem);
    this._centerService
      .batchUpdateWithFilters({
        filters: this.filters,
        transformations: this.transformations,
        type: 'center',
        projectId: this.data.selectedProject?.id ?? ``,
        preview: preview,
      })
      .subscribe((results) => {
        this.previewVisible = preview;
        this.batchUpdateResults = results;
        this._loadingService.removeItem(loadingItem);
        if (!preview) {
          this._loadingService.triggerOkMessage(
            `Center(s) updated - ${results.results.length}`
          );
        }
      });
  }

  public getHCPFieldName(id: string): string {
    return (
      [...Array.from(HCP_DB_MAPPINGS.values())].find((it) => it.fieldId === id)
        ?.name ?? ``
    );
  }

  public getCenterFieldName(id: string): string {
    return (
      [...Array.from(CENTER_DB_MAPPINGS.values())].find(
        (it) => it.fieldId === id
      )?.name ?? ``
    );
  }

  public deleteFilterItem(item: TDataFilter): void {
    const itemIndex: number = this.filters.findIndex(
      (it) => it.property === item.property
    );
    if (itemIndex > -1) {
      this.filters.splice(itemIndex, 1);
    }
  }

  public deleteEmptyCenterFilter(): void {
    const index: number = this.filters.findIndex(
      (it) => it.batchType === BatchUpdateFilterType.EmptyCenters
    );
    if (index > -1) {
      this.filters.splice(index, 1);
    }
  }

  public deleteCallback(item: TTransformation): void {
    const index: number = this.transformations.findIndex(
      (it) => it.filedId === item.filedId
    );
    if (index > -1) {
      this.transformations.splice(index, 1);
      this.availableCentersTransformationFields.push({
        name: `Add to projects`,
        value: item.addToProject ? ADD_TO_PROJECT_ID : item.filedId,
      });
    }
  }
}
