import { Injectable } from '@angular/core';
import { Endpoints } from '@shared/Endpoints';
import {
  EventMessageStatus,
  TBaseEventMessage,
  TEventMessageResponse,
} from '@shared/interfaces/TEventMessage';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { newGuid } from '@shared/utils/newGuild';
import { environment } from '../../environments/environment';
import { LoadingService } from './LoadingService';

@Injectable()
export class SSEService {
  private _localProcessDataEventSource: EventSource;
  private _globalProcessDataEventSource: EventSource;
  private _generateExcelEventSource: EventSource;
  private _guid: string = newGuid();
  private localProcessingOngoing: boolean = false;

  constructor(private _loadingService: LoadingService) {
    this.initProcessDataEventSource();
  }

  public initProcessDataEventSource(): void {
    const SSEUrl: string = Endpoints.BUILD_URL(`rise`, {
      base: environment.apiUrl,
      endpoint: `processArticlesStatusEvent`,
      params: ``,
    });
    this._globalProcessDataEventSource = new EventSource(SSEUrl);
    this._globalProcessDataEventSource.onmessage = ({ data }) => {
      const response: TEventMessageResponse = JSON.parse(data);
      if (response.guid !== this._guid && !this.localProcessingOngoing) {
        this._loadingService.addLogItem({
          ...response,
          date: new Date(),
          status: EventMessageStatus.External,
        });
      }
    };
  }

  public handleProcessArticlesStatusEvent(
    refreshCategoriesCallback?: () => void,
    onComplete: () => void = () => void 0,
    onOpen: () => void = () => void 0,
    onError: () => void = () => void 0
  ): void {
    const SSEUrl: string = Endpoints.BUILD_URL(`rise`, {
      base: environment.apiUrl,
      endpoint: `processArticlesStatusEvent`,
      params: `?guid=${this._guid}`,
    });
    this.localProcessingOngoing = true;
    this._localProcessDataEventSource = new EventSource(SSEUrl);
    this._localProcessDataEventSource.onopen = () => {
      onOpen();
    };
    this._localProcessDataEventSource.onerror = () => {
      this.localProcessingOngoing = false;
      this._localProcessDataEventSource.close();
      onError();
    };
    this._localProcessDataEventSource.onmessage = ({ data }) => {
      const response: TBaseEventMessage = JSON.parse(data);
      this._loadingService.addLogItem({
        ...response,
        date: new Date(),
      });
      if (response.status === EventMessageStatus.Finished) {
        this.localProcessingOngoing = false;
        this._localProcessDataEventSource.close();
        if (!isNullOrUndefined(refreshCategoriesCallback)) {
          refreshCategoriesCallback();
        }
        onComplete();
      }
    };
  }

  public _handleGenerateExcelSSE(onComplete: () => void = () => void 0): void {
    const SSEUrl: string = Endpoints.BUILD_URL(`export`, {
      base: environment.apiUrl,
      endpoint: `generateExcelSSE`,
      params: `?guid=${this._guid}`,
    });
    this._generateExcelEventSource = new EventSource(SSEUrl);
    this._generateExcelEventSource.onmessage = ({ data }) => {
      const response: TBaseEventMessage = JSON.parse(data);
      this._loadingService.addLogItem({
        ...response,
        date: new Date(),
      });
      if (response.status === EventMessageStatus.Finished) {
        this._generateExcelEventSource.close();
        onComplete();
      }
    };
  }
}
