<div *ngIf="reportComponentVisible()" class="mb-4">
  <button mat-flat-button color="primary" (click)="toggleSelection()">
    Toggle selection
  </button>
</div>
<div class="report-component" *ngIf="reportComponentVisible()" [ngStyle]="getContainerDimensions()">
  <div *ngFor="let matrixRow of params.matrix" class="report-component__row">
    <div *ngFor="let matrixCell of matrixRow" class="report-component__row__cell" (click)="cellClick(matrixCell)"
      [ngClass]="getCellCSS(matrixCell)" [ngStyle]="getCellDimensions()" matTooltipPosition="left"
      matTooltip="{{ getCellTooltip(matrixCell) }}"></div>
  </div>
  <div class="report-component__y-axis" *ngIf="!isVectorReport()">
    <div class="report-component__y-axis__max">max</div>
    <div class="report-component__y-axis__label">{{ params.yAxisLabel }}</div>
    <div class="report-component__y-axis__min">low</div>
  </div>
  <div class="report-component__x-axis">
    <div class="report-component__x-axis__max">low</div>
    <div class="report-component__x-axis__label">{{ params.xAxisLabel }}</div>
    <div class="report-component__x-axis__min">max</div>
  </div>
</div>
<div class="report-component" *ngIf="!reportComponentVisible()">
  <h3 class="mat-h3">To see groups, choose report first</h3>
</div>