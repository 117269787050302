import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TCenter, buildCenterMock } from '@shared/interfaces/TCenter';
import {
  THCP,
  THCPProjectsData,
  buildHCPMock,
  buildProjectDataHCPMock,
} from '@shared/interfaces/THCP';
import {
  Observable,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from 'rxjs';
import { ROUTES_PATHS } from 'src/app/app-routing.module';
import { HCPService } from 'src/app/services/HCPService';
import { RegionsService } from 'src/app/services/RegionsService';
import { TComponentType, THCPParams } from '../../interfaces/TComponentParams';
import { NEW_HCP_ID } from '../hcp.component';

@Component({
  selector: 'app-hcp-profile-tab',
  templateUrl: './hcp-profile-tab.component.html',
  styleUrls: ['./hcp-profile-tab.component.scss'],
})
export class HcpProfileTabComponent implements OnInit, OnDestroy {
  @Input() public hcpData: THCP;
  @Input() public centerData: TCenter = buildCenterMock({});
  @Input() public hcpParams: THCPParams = {
    hcpId: ``,
    categoryId: ``,
    projectId: ``,
    __type: TComponentType.hcp,
  };
  @Input() public studiesCount: number = 0;
  @Input() public onHCPSelectCallback: (hcp: THCP, reset: boolean) => void = (
    hcp: THCP
  ) => console.log(hcp);
  @Input() public isNewFromOtherProject: boolean = false;
  public countries: string[] = [];
  public existingHCPSearch: string = ``;
  public hcpControl: FormControl<THCP | null>;
  public filteredOptions: Observable<THCP[]>;
  public isLoading: boolean = false;

  private _subs: Subscription[] = [];

  constructor(
    private _regionsService: RegionsService,
    private _hcpService: HCPService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this._subs.push(
      this._regionsService
        .getCountriesObservable()
        .subscribe(
          (regions) => (this.countries = regions.map((c) => c.viewValue))
        )
    );
  }

  public clearData(): void {
    const newHCP: THCP = buildHCPMock({});
    this.existingHCPSearch = ``;
    this.hcpControl.setValue(null);
    this.onHCPSelectCallback(newHCP, true);
  }

  public fillHCP(hcp: THCP): void {
    const clonedHCP: THCP = structuredClone(hcp);
    if (clonedHCP.projects.includes(this.hcpParams.projectId)) {
      const hcpParams: THCPParams = {
        hcpId: hcp.id,
        categoryId: this.hcpParams.categoryId ?? ``,
        projectId: this.hcpParams.projectId ?? ``,
        __type: TComponentType.hcp,
      };
      this._router.navigate([`/${ROUTES_PATHS.hcp}`, hcpParams], {
        relativeTo: this._route,
      });
    } else {
      clonedHCP.projects.push(this.hcpParams.projectId);
      const newProjectsData: THCPProjectsData = buildProjectDataHCPMock({
        projectId: this.hcpParams.projectId,
      });
      clonedHCP.projectsData.push(newProjectsData);
      this.onHCPSelectCallback(clonedHCP, false);
    }
  }

  public displayFn(hcp: THCP): string {
    return hcp && hcp.first_name && hcp.last_name
      ? `${hcp.first_name} ${hcp.last_name}`
      : ``;
  }

  public isNew(): boolean {
    return this.hcpParams.hcpId === NEW_HCP_ID || this.isNewFromOtherProject;
  }

  public ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  public ngOnInit(): void {
    this._setHCPOptions();
  }

  private _setHCPOptions(): void {
    this.hcpControl = new FormControl();
    this.filteredOptions = this.hcpControl.valueChanges.pipe(
      startWith(``),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((value: string | null | THCP) => {
        const query = typeof value === `string` ? value : ``;
        return query ? this._filter(query as string) : [];
      })
    );
  }

  private _filter(value: string = ``): Observable<THCP[]> {
    this.isLoading = true;
    return this._hcpService.getHCPsByName(value).pipe(
      map((response) => {
        this.isLoading = false;
        return response;
      })
    );
  }
}
