import { SharedConstants } from '@shared/Constants';
import { TDBFieldMappingItem } from '@shared/interfaces/TDBFieldMappingItem';

export const CLINICAL_TRIALS_DB_MAPPINGS: Map<string, TDBFieldMappingItem> = new Map([
  [
    `NCTId`,
    {
      columnWidth: 130,
      fieldId: `NCTId`,
      name: `NCTId`,
      isSortable: false,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false
    }
  ],
  [
    `studyTitle`,
    {
      columnWidth: 300,
      fieldId: `studyTitle`,
      name: `Title`,
      isSortable: false,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false
    }
  ],
  [
    `condition`,
    {
      columnWidth: 150,
      fieldId: `condition`,
      name: `Condition`,
      isSortable: false,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false
    }
  ],
  [
    `intervention`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `intervention`,
      name: `Intervention`,
      isSortable: false,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false
    }
  ],
  [
    `clinicalPhase`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `clinicalPhase`,
      name: `Clinical phase`,
      isSortable: false,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false
    }
  ],
  [
    `studyStartYear`,
    {
      columnWidth: 150,
      fieldId: `studyStartYear`,
      name: `Study Start Date`,
      isSortable: false,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false
    }
  ],
  [
    `studyURL`,
    {
      columnWidth: 380,
      fieldId: `studyURL`,
      name: `Study URL`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false
    }
  ],
  [
    `author`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `author`,
      name: `Author`,
      isSortable: false,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false
    }
  ]
]);
