import { ProductName } from './TProductUse';

export const DEFAULT_APP_SETTINGS: Readonly<TSettings> = {
  pageTitle: `RISE`,
  companyName: `Company`,
  logo: ``,
  favicon: ``,
  accountName: `Account name`,
  companySpecificFieldsDescription: `Company specific field`,
  optInTemplate: `Lorem ipsum opt in`,
  optOutTemplate: `Lorem ipsum opt out`,
  optInPaperTemplate: `Lorem ipsum opt in`,
  optOutPaperTemplate: `Lorem ipsum opt out`,
  locale: `en-GB`,
  fetchArticlesCount: true,
  optInTemplateTitle: `Opt in title`,
  optOutTemplateTitle: `Opt out title`,
  optInPaperTemplateTitle: `Opt in paper template title`,
  optOutPaperTemplateTitle: `Opt out paper template title`,
  appTranslations: {
    optInTemplateTitle: `Opt in template title`,
    optOutTemplateTitle: `Opt out template title`,
    optInPaperTemplateTitle: `Opt in paper template title`,
    optOutPaperTemplateTitle: `Opt out paper template title`
  },
  customRegions: [],
  countriesWithPaperNotifications: [],
  enableAutoImport: false,
  products: [],
  supportDocs: []
};

export interface CustomRegion {
  _id: string;
  name: string;
  countries: { value: string; viewValue: string }[];
}

export interface TAppTranslations {
  optInTemplateTitle: string;
  optOutTemplateTitle: string;
  optInPaperTemplateTitle: string;
  optOutPaperTemplateTitle: string;
}

export interface TSupportDoc {
  label: string;
  fileName: string;
  fileUrl: string;
}

export interface TSettings {
  pageTitle: string;
  companySpecificFieldsDescription: string;
  accountName: string;
  companyName: string;
  logo: string;
  favicon: string;
  optInTemplate: string;
  optOutTemplate: string;
  optInPaperTemplate: string;
  optOutPaperTemplate: string;
  optInTemplateTitle: string;
  optOutTemplateTitle: string;
  optInPaperTemplateTitle: string;
  optOutPaperTemplateTitle: string;
  locale: string;
  fetchArticlesCount: boolean;
  appTranslations: TAppTranslations;
  customRegions: CustomRegion[];
  countriesWithPaperNotifications: string[];
  enableAutoImport?: boolean;
  products: ProductName[];
  supportDocs: TSupportDoc[];
}
