import { Component, Input } from '@angular/core';
import { FilterOperator, TDataFilter } from '@shared/interfaces/TProject';

interface TFilterOperatorNamingItem {
  filter: FilterOperator;
  name: string;
}

@Component({
  selector: 'app-filter-item',
  templateUrl: './filter-item.component.html',
  styleUrls: ['./filter-item.component.scss'],
})
export class FilterItemComponent {
  @Input()
  public filterItem: TDataFilter = {
    operator: FilterOperator.Less,
    property: ``,
    value: 1,
    type: 'regular',
  };
  @Input()
  public fieldName: string = ``;
  @Input()
  public deleteFilter: (item: TDataFilter) => void = (item: TDataFilter) =>
    console.log(item);
  public availableOperators: TFilterOperatorNamingItem[] = [
    {
      filter: FilterOperator.Less,
      name: `<`,
    },
    {
      filter: FilterOperator.LessOrEqual,
      name: `<=`,
    },
    {
      filter: FilterOperator.More,
      name: `>`,
    },
    {
      filter: FilterOperator.MoreOrEqual,
      name: `>=`,
    },
    {
      filter: FilterOperator.Equal,
      name: `=`,
    },
    {
      filter: FilterOperator.NotEqual,
      name: `!=`,
    },
  ];
  constructor() {}
}
