import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// test if is 4 digits only and start with either 19 or 20
export function yearStringValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const isYear = /^\d{4}$/.test(value);
    const isYearValid =
      isYear && (value.startsWith('19') || value.startsWith('20'));

    return !isYearValid ? { yearInvalid: true } : null;
  };
}
