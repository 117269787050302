import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@shared/Types/FieldType';
import { TTransformation } from '@shared/interfaces/TBatchUpdateParams';
import { FilterOperator } from '@shared/interfaces/TProject';
import { newGuid } from '@shared/utils/newGuild';
import { CategoriesService } from 'src/app/services/CategoriesService';
import { TReportDataFilter } from '../../../../reports-review/report-filters/report-filters.component';
interface TProjectItem {
  name: string;
  projectId: string;
  category: string;
}

@Component({
  selector: 'app-transformation-item',
  templateUrl: './transformation-item.component.html',
  styleUrls: ['./transformation-item.component.scss'],
})
export class TransformationItemComponent implements OnInit, OnDestroy {
  public availableProjects: TProjectItem[] = [];
  @Input() public item: TTransformation = {
    addToProject: false,
    filedId: ``,
    value: ``,
    name: ``,
  };
  @Input() public projectId: string = ``;
  @Input() public fieldType: FieldType = `center`;
  @Input() public deleteCallback: (item: TTransformation) => void = (
    item: TTransformation
  ) => console.log(item);
  public filterItem: TReportDataFilter = {
    id: newGuid(),
    operator: FilterOperator.Equal,
    property: ``,
    type: `regular`,
    value: ``,
  };

  constructor(private _categoriesService: CategoriesService) {}

  public ngOnInit(): void {
    this.filterItem.property = this.item.filedId;
    this._categoriesService.getCategories().forEach((category) => {
      category.projects.forEach((project) => {
        if (project.id !== this.projectId) {
          this.availableProjects.push({
            category: category.name,
            name: project.name,
            projectId: project.id,
          });
        }
      });
    });
  }

  public onInputChange(value: number | string | boolean): void {
    this.item.value = value;
  }

  public ngOnDestroy(): void {}

  public onAddProject(items: string[]): void {
    this.item.value = items;
  }
}
