<div class="system-settings">
  <mat-card>
    <mat-card-title> System settings </mat-card-title>
    <mat-card-subtitle> Version - {{ frontendVersion }} </mat-card-subtitle>
    <mat-card-content>
      <mat-tab-group dynamicHeight>
        <mat-tab label="Categories">
          <app-categories-settings class="block mt-4"></app-categories-settings>
        </mat-tab>
        <mat-tab label="Other">
          <div class="flex">
            <div class="w-1/3">
              <div class="mt-4">
                <button mat-flat-button color="accent" (click)="showLog()">
                  <span>Show log</span>
                </button>
              </div>
              <div class="mt-4">
                <form [formGroup]="autoImportForm">
                  <mat-slide-toggle
                    color="primary"
                    formControlName="enableAutoImport"
                    >Enable Automated Imports
                  </mat-slide-toggle>
                </form>
              </div>
              <div class="mt-4">
                <h3 class="mat-h3">App translations</h3>
                <div>
                  <mat-form-field appearance="fill">
                    <mat-label>Opt out title</mat-label>
                    <input
                      matInput
                      required
                      [(ngModel)]="
                        appSettings.appTranslations.optOutTemplateTitle
                      "
                    />
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field appearance="fill">
                    <mat-label>Opt out title (paper version)</mat-label>
                    <input
                      matInput
                      required
                      [(ngModel)]="
                        appSettings.appTranslations.optOutPaperTemplateTitle
                      "
                    />
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field appearance="fill">
                    <mat-label>Opt in title</mat-label>
                    <input
                      matInput
                      required
                      [(ngModel)]="
                        appSettings.appTranslations.optInTemplateTitle
                      "
                    />
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field appearance="fill">
                    <mat-label>Opt in title (paper version)</mat-label>
                    <input
                      matInput
                      required
                      [(ngModel)]="
                        appSettings.appTranslations.optInPaperTemplateTitle
                      "
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="w-2/3">
              <div class="mt-4">
                <h3 class="mat-h3">Regions</h3>

                <table *ngIf="customRegions.length">
                  <thead>
                    <tr>
                      <td class="name">Name</td>
                      <td class="countries">Selected countries</td>
                      <td class="actions">Actions</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let region of customRegions">
                      <td>{{ region.name }}</td>
                      <td>
                        {{ countriesList(region) }}
                      </td>
                      <td class="flex">
                        <button
                          mat-icon-button
                          color="primary"
                          (click)="editRegion(region)"
                        >
                          <mat-icon color="primary">edit</mat-icon>
                        </button>
                        <button
                          mat-icon-button
                          color="warn"
                          (click)="deleteRegion(region)"
                        >
                          <mat-icon color="warn">delete</mat-icon>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button
                  class="mt-2"
                  mat-flat-button
                  color="primary"
                  (click)="addRegion()"
                >
                  <span>Add new region</span>
                </button>
              </div>

              <div class="mt-4">
                <h3 class="mat-h3 flex items-center">
                  Countries requiring paper notifications
                  <button
                    mat-icon-button
                    color="primary"
                    (click)="editCountriesWithPaperNotifications()"
                  >
                    <mat-icon color="primary">edit</mat-icon>
                  </button>
                </h3>
                <div class="mb-2">
                  {{ countriesWithPaperNotifications }}
                </div>
              </div>

              <div class="mt-4">
                <h3 class="mat-h3">Products</h3>

                <table *ngIf="products.length">
                  <thead>
                    <tr>
                      <td class="name">Name</td>
                      <td class="actions">Actions</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let productName of products">
                      <td>{{ productName.toUpperCase() }}</td>
                      <td class="flex">
                        <button
                          mat-icon-button
                          color="warn"
                          (click)="deleteProduct(productName)"
                        >
                          <mat-icon color="warn">delete</mat-icon>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <form class="flex items-center mt-2" [formGroup]="productForm">
                  <mat-form-field appearance="fill">
                    <mat-label>Product name</mat-label>
                    <input matInput required formControlName="name" />
                  </mat-form-field>
                  <button
                    mat-icon-button
                    color="primary"
                    [disabled]="!productForm.valid"
                    (click)="addProduct(productForm.value.name)"
                  >
                    <mat-icon color="primary">add_circle_outline</mat-icon>
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div class="flex mt-4 justify-end">
            <button mat-flat-button color="primary" (click)="updateSettings()">
              <span>Save settings</span>
            </button>
          </div>
        </mat-tab>
        <mat-tab label="App settings">
          <app-editor></app-editor>
        </mat-tab>
        <mat-tab label="User management">
          <app-users-management></app-users-management>
        </mat-tab>
        <mat-tab label="Support documents" *ngIf="isAdminUser()">
          <app-support-docs></app-support-docs>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
