import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TCategory } from '@shared/interfaces/TCategory';
import { TSupportDoc } from '@shared/interfaces/TSettings';
import { Subscription } from 'rxjs';
import { ROUTES_PATHS } from '../app-routing.module';
import { TProjectReviewParams } from '../project-review/project-review.component';
import { CategoriesService } from '../services/CategoriesService';
import { SettingsService } from '../services/SettingsService';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnDestroy, OnInit {
  protected readonly ROUTES_PATHS = ROUTES_PATHS;
  public categories: TCategory[] = [];
  public supportDocs: TSupportDoc[] = [];
  private _subs: Subscription[] = [];
  constructor(
    private _router: Router,
    private _categoriesService: CategoriesService,
    private _settingsService: SettingsService
  ) {}

  public ngOnInit(): void {
    this._subs.push(
      this._categoriesService
        .getCategoriesObservable()
        .subscribe((categories) => {
          this.categories = categories.filter((c) => c.projects.length > 0);
        })
    );

    this._subs.push(
      this._settingsService.getAppSettingsObservable().subscribe((settings) => {
        this.supportDocs = settings.supportDocs;
      })
    );
  }

  public ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  public handleProjectClick(
    projectId: string,
    categoryId: string,
    settingsVisible: boolean = false
  ): void {
    const projectReviewParams: TProjectReviewParams = {
      projectId: projectId,
      categoryId: categoryId,
      settingsVisible: settingsVisible,
      articlesVisible: false,
      centersVisible: false,
      hcpsVisible: false,
    };
    this._router.navigate([`/${ROUTES_PATHS.project}`, projectReviewParams]);
  }
}
