<div class="flex gap-4 mb-2">
  <ng-container *ngIf="item.addToProject">
    <div class="flex gap-4">
      <mat-form-field>
        <input type="text" matInput value="Projects" disabled />
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="availableProjects.length > 0">
        <mat-label>Add centers to project</mat-label>
        <mat-select required name="Project" (valueChange)="onAddProject($event)">
          <mat-option *ngFor="let item of availableProjects" [value]="item.projectId">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>
  <div *ngIf="!item.addToProject" class="flex gap-4">
    <mat-form-field>
      <mat-label>Field</mat-label>
      <input type="text" matInput value="{{item.name}}" disabled />
    </mat-form-field>
    <app-value-editor [projectId]="projectId" [filterItem]="filterItem" [fieldType]="fieldType"
      [onInputChange]="onInputChange.bind(this)"></app-value-editor>
  </div>
  <button mat-icon-button aria-label="Delete" color="error" (click)="deleteCallback(item)">
    <mat-icon>delete_forever</mat-icon>
  </button>
</div>