import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SearchArea } from '@shared/enums/SearchArea';
import { TArticle } from '@shared/interfaces/TArticle';
import { TCenter } from '@shared/interfaces/TCenter';
import { TCtData } from '@shared/interfaces/TCtData';
import { THCP } from '@shared/interfaces/THCP';
import { TImportMappingItem } from '@shared/interfaces/TImportMappingItem';
import { TDataColumnDef } from 'src/utils/buildColumnsDef';

export interface IHandleCommonTable {
  getTableParams(): ICommonTableParams<TTableRowType, TTDataType>;
}

export enum TableRowType {
  Center,
  Article,
  HCP,
  CT,
  CenterSales,
}

export interface TBaseTableRow {
  __type: TableRowType;
}

export interface TCenterTableRow extends TCenter, TBaseTableRow {
  __type: TableRowType.Center;
  position: number;
}

export interface TArticleTableRow extends TArticle, TBaseTableRow {
  __type: TableRowType.Article;
  position: number;
}

export interface THCPTableRow extends THCP, TBaseTableRow {
  __type: TableRowType.HCP;
  position: number;
}

export interface TCenterSalesTableRow extends TCenterSales, TBaseTableRow {
  __type: TableRowType.CenterSales;
  position: number;
}

export interface TCenterSales {}

export interface TCtDataTableRow extends TCtData, TBaseTableRow {
  __type: TableRowType.CT;
  position: number;
}

export enum PaginationVisibility {
  Top,
  Bottom,
  Both,
  None,
  Simple,
}

export enum SortType {
  Simple,
  Extended,
  None,
}

export type TTableRowType =
  | TCenterTableRow
  | TArticleTableRow
  | THCPTableRow
  | TCtDataTableRow
  | TCenterSalesTableRow;

export type TTDataType = TCenter | TArticle | THCP | TCtData | TCenterSales;

export interface IAddNewParams {
  addNewCallback: (() => void) | undefined;
  tooltip: string;
}

export interface TMergeData {
  selectedRows: TTableRowType[];
  projectId: string;
  mergeCallback: () => void;
}

export interface TOptInOutData {
  selectedRows: THCPTableRow[];
  projectId: string;
}

export interface TPrioritySettingsData {
  selectedRows: THCPTableRow[];
  closeCallback: () => void;
}

export interface ICommonTableParams<T, K> {
  pageSize: number;
  pageSizeOptions: number[];
  pageIndex: number;
  dataSource: MatTableDataSource<T>;
  selection: boolean;
  selectionAlwaysVisible?: boolean;
  columns: TDataColumnDef<K>[];
  handleEditClick?: (row: T) => void;
  handleNavigateToClick?: (row: T) => void;
  displayedColumns: string[];
  paginationCallback: (e: PageEvent) => void;
  dataLoaded: boolean;
  dataCount: number;
  downloadCallback?: (() => void) | undefined;
  importCallback?:
    | ((e: Event, mappings: TImportMappingItem[]) => void)
    | undefined;
  paginationVisibility?: PaginationVisibility;
  handleHeaderClick?: (fieldId: string, remove: boolean) => void;
  projectId: string;
  area: SearchArea;
  filterCallback?: (term: string) => void;
  addNewParams?: IAddNewParams;
  resize?: boolean;
  sortType: SortType;
  defaultSortFieldId?: string;
  actionsAvailable: boolean;
  simpleSortHeaders?: string[];
  resetTable?: () => void;
  useNativePagination?: boolean;
  hasRisingStarButton?: boolean;
  importEngagementCallback?:
    | ((e: Event, projectId: string) => void)
    | undefined;
  mergeHidden?: boolean;
}
