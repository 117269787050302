import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSelectChange } from '@angular/material/select';
import { Observable, startWith, map } from 'rxjs';

@Component({
  selector: 'app-autocomplete-wrapper',
  templateUrl: './autocomplete-wrapper.component.html',
  styleUrls: ['./autocomplete-wrapper.component.scss'],
})
export class AutocompleteWrapperComponent implements OnInit {
  @Input()
  public label: string = `Label`;
  public myControl = new FormControl(``);
  @Input()
  public options: string[] = [`One`, `Two`, `Three`];
  public filteredOptions: Observable<string[]>;

  @Input()
  public selectionChangeCallback: (newValue: string) => void = (
    _newValue: string
  ) => void 0;

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(``),
      map((value) => this._filter(value || ``))
    );
  }

  public onSelectionChange(event: MatAutocompleteSelectedEvent): void {
    this.selectionChangeCallback(event.option.value);
  }

  public setValue(value: string): void {
    this.myControl.setValue(value);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
