<h1 mat-dialog-title>Countries with paper notifications</h1>

<div mat-dialog-content>
  <form [formGroup]="countriesWithPaperNotificationsForm" class="flex flex-col">
    <app-region-select
      [regions]="regions"
      [regionsFiltered]="regionsFiltered"
      [selectedRegions]="countries"
    >
    </app-region-select>
  </form>
</div>

<div mat-dialog-actions class="flex justify-between">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button color="error" (click)="onSave()">Save</button>
</div>
