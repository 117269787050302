<h1 mat-dialog-title>Add new category</h1>
<div mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>Category name</mat-label>
        <input matInput [(ngModel)]="categoryName">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>No</button>
    <button mat-button mat-dialog-close cdkFocusInitial [mat-dialog-close]="categoryName">Ok</button>
</div>