<div class="center-review has-sticky-footer">
  <mat-card>
    <mat-card-title class="center-review_title">
      <p>{{ centerData.c_name }}</p>
    </mat-card-title>
    <mat-card-content>
      <mat-tab-group>
        <mat-tab label="Profile">
          <ng-template matTabContent>
            <app-center-profile
              [centerParams]="centerParams"
              [centerData]="centerData"
              (customMetricsFormValidityChange)="
                onCustomMetricFormValidityChange($event)
              "
              (customMetricsFormValueChange)="
                onCustomMetricFormValueChange($event)
              "
            ></app-center-profile>
          </ng-template>
        </mat-tab>
        <mat-tab label="Publications">
          <ng-template matTabContent>
            <app-publications [params]="centerParams"></app-publications>
          </ng-template>
        </mat-tab>
        <mat-tab label="Studies">
          <ng-template matTabContent>
            <app-studies
              [params]="centerParams"
              [centerData]="centerData"
            ></app-studies>
          </ng-template>
        </mat-tab>
        <mat-tab label="Center alias">
          <ng-template matTabContent>
            <app-aliases
              [params]="centerParams"
              [centerData]="centerData"
            ></app-aliases>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
      <app-data-maintenance-footer-button
        class=""
        [buttonsData]="footerButtonsData"
      >
      </app-data-maintenance-footer-button>
    </mat-card-content>
  </mat-card>
</div>
