export enum SearchArea {
  HCP = 'HCPs',
  Centers = 'Centers',
  Articles = 'Articles',
  None = `None`,
  HCPsReports = `HCPsReports`,
  CentersReports = `CentersReports`
}

export enum ImportArea {
  HCP = 'HCPs',
  Centers = 'Centers',
  ProductSales = `ProductSales`
}
