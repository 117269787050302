<div class="inline-flex justify-end items-center">
  <button *ngIf="paginationVisible()" mat-button (click)="goToFirstPage()">
    Go to first page
  </button>
  <mat-paginator
    #paginator
    *ngIf="paginationVisible()"
    [length]="paginationParams.length"
    [pageIndex]="paginationParams.pageIndex"
    [pageSize]="paginationParams.pageSize"
    [pageSizeOptions]="paginationParams.pageSizeOptions"
    (page)="handlePage($event)"
    aria-label="Select page"
  >
  </mat-paginator>
</div>
