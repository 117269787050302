<div class="settings-modal-content">
  <h2 class="my-4 mat-h2">Filters</h2>
  <div *ngIf="data.filtersLegend" class="flex legend">
    <div *ngFor="let legend of data.filtersLegend" class="ml-4">
      <b>{{ legend.label }}:</b>
      <div *ngFor="let value of legend.values">
        {{ value }}
      </div>
    </div>
  </div>
  <div>
    <h3 class="mat-h4">Select filters</h3>
    <mat-form-field appearance="fill">
      <mat-label>{{ filterDescription }}</mat-label>
      <mat-select required name="Fields">
        <mat-option
          *ngFor="let field of availableFilters"
          [value]="field.value"
          (click)="addNewFilter(field.value)"
        >
          {{ field.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="filters.length > 0">
    <app-report-filters-item
      *ngFor="let item of filters"
      [filterItem]="item"
      [fieldName]="getFieldName(item.property)"
      [deleteFilter]="deleteFilter.bind(this)"
      [availableTags]="data.availableTags"
      [fieldType]="fieldType"
      [projectId]="data.projectId"
    ></app-report-filters-item>
  </div>
</div>
