export class EngagementUtils {
  static getEngagementPlanHeaders() {
    return {
      date: `Period`,
      description: `Description`,
      quantity: `Quantity`,
      tags: `Tags`,
      person: `Accountable`,
      project: `Project`,
    };
  }

  static getEngagementTrackingHeaders() {
    return {
      date: `Period`,
      description: `Description`,
      quantity: `Quantity`,
      tags: `Tags`,
      person: `Accountable`,
      project: `Project`,
    };
  }
}
