<div class="flex justify-center">
  <mat-card class="w-1/2 mt-4">
    <mat-card-content>
      <div *ngIf="supportDocs.length > 0 && pageAvailable; else noContent">
        <p class="mb-6">Click on button to preview file</p>
        <button
          *ngFor="let file of supportDocs"
          mat-flat-button
          class="mr-5"
          color="primary"
          type="button"
          (click)="openDialog(file)"
        >
          <mat-icon>remove_red_eye</mat-icon>
          {{ file.label }}
        </button>
      </div>
      <ng-template #noContent>
        <p>No content</p>
      </ng-template>
    </mat-card-content>
  </mat-card>
</div>
