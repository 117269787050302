import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CENTER_DB_MAPPINGS } from '@shared/db-fields-mappings/CENTER_DB_MAPPINGS';
import { HCP_DB_MAPPINGS } from '@shared/db-fields-mappings/HCP_DB_MAPPINGS';
import {
  TCenter,
  TCenterProjectsData,
  TCustomMetric,
  buildCenterMock,
} from '@shared/interfaces/TCenter';
import {
  THCP,
  THCPProjectsData,
  THistoryCenters,
  TTHCPStudy,
  TTwitterData,
  buildHCPMock,
} from '@shared/interfaces/THCP';
import { getUnique } from '@shared/utils/getUnique';
import { isNullOrEmpty } from '@shared/utils/isNullOrEmpty';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';

import { SharedConstants } from '@shared/Constants';
import { CategoriesService } from 'src/app/services/CategoriesService';
import { CentersService } from 'src/app/services/CentersService';
import { DataProcessorService } from 'src/app/services/DataProcessorService';
import { HCPService } from 'src/app/services/HCPService';
import { LoadingService } from 'src/app/services/LoadingService';
import {
  TCenterTableRow,
  THCPTableRow,
  TMergeData,
  TTableRowType,
  TableRowType,
} from '../common-table.component.types';

type HCPPropType =
  | string
  | number
  | boolean
  | string[]
  | Date
  | THCPProjectsData[]
  | THistoryCenters[]
  | TTwitterData
  | TTHCPStudy[]
  | null
  | undefined;
type CenterPropType =
  | string
  | number
  | boolean
  | string[]
  | TCenterProjectsData[]
  | TCustomMetric[];

@Component({
  selector: 'app-merge',
  templateUrl: './merge.component.html',
  styleUrls: ['./merge.component.scss'],
})
export class MergeComponent implements OnInit {
  public selectedRow: THCPTableRow | TCenterTableRow | null = null;
  public selectedCells: Map<string, number> = new Map();
  public selectedRowIndex: number = NaN;
  public mergedRow: THCPTableRow | TCenterTableRow | null = null;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TMergeData,
    private _HCPService: HCPService,
    private _centerService: CentersService,
    private _loadingService: LoadingService,
    private _dataProcessorService: DataProcessorService,
    private _categoriesService: CategoriesService
  ) {}

  ngOnInit(): void {}

  public selectCell(rowIndex: number, fieldName: string): void {
    const centerName: string = `Center name`;
    const centerId: string = `Center id`;
    if (rowIndex !== this.selectedRowIndex) {
      const currentSelection: number | undefined =
        this.selectedCells.get(fieldName);
      if (
        isNullOrUndefined(currentSelection) ||
        currentSelection !== rowIndex
      ) {
        this.selectedCells.set(fieldName, rowIndex);
        if (fieldName === centerName) {
          //update centerId as well
          this.selectedCells.set(centerId, rowIndex);
        }
      } else {
        this.selectedCells.delete(fieldName);
        if (fieldName === centerName) {
          //update centerId as well
          this.selectedCells.delete(centerId);
        }
      }
      this.buildMergedRow();
    }
  }

  public isRowSelected(rowIndex: number): boolean {
    return rowIndex === this.selectedRowIndex;
  }

  public isCellSelected(rowIndex: number, fieldName: string): boolean {
    const selectedRowIndex: number | undefined =
      this.selectedCells.get(fieldName);
    if (selectedRowIndex !== undefined && selectedRowIndex === rowIndex) {
      return true;
    }
    return false;
  }

  public getCellCSS(rowIndex: number, fieldName: string): string {
    if (this.isCellSelected(rowIndex, fieldName)) {
      return `selected`;
    }
    return ``;
  }

  public getRows(): (THCPTableRow | TCenterTableRow)[] {
    if (this.data.selectedRows[0].__type === TableRowType.HCP) {
      return this.data.selectedRows as THCPTableRow[];
    } else if (this.data.selectedRows[0].__type === TableRowType.Center) {
      return this.data.selectedRows as TCenterTableRow[];
    }
    return [];
  }

  public getColumnsHeaders(): [string, string][] {
    if (this.data.selectedRows[0].__type === TableRowType.HCP) {
      return Array.from(HCP_DB_MAPPINGS.values())
        .filter((f) => f.visibleInMerge === true)
        .map((f) => [f.fieldId, f.name]);
    } else if (this.data.selectedRows[0].__type === TableRowType.Center) {
      return Array.from(CENTER_DB_MAPPINGS.values())
        .filter((f) => f.visibleInMerge === true)
        .map((f) => [f.fieldId, f.name]);
    }
    return [];
  }

  public getCellValue(
    row: THCPTableRow | TCenterTableRow,
    fieldId: string
  ): any {
    if (row.__type === TableRowType.HCP) {
      return row[fieldId as keyof THCPTableRow];
    } else if (row.__type === TableRowType.Center) {
      return row[fieldId as keyof TCenterTableRow];
    }
    return ``;
  }

  public selectRow(rowIndex: number): void {
    if (this.selectedRowIndex === rowIndex) {
      this.selectedRowIndex = NaN;
    } else {
      this.selectedRowIndex = rowIndex;
    }
    this.selectedCells.forEach((v, k) => {
      if (v === rowIndex) {
        this.selectedCells.delete(k);
      }
    });
    this.buildMergedRow();
  }

  public mergeRows(): void {
    if (this.mergedRow?.__type === TableRowType.HCP) {
      if (isNullOrEmpty(this.mergedRow.id)) {
        this._loadingService.triggerErrorMessage(`Please select base HCP`);
      } else {
        const mergedRows: THCP[] = structuredClone(
          this.data.selectedRows as THCP[]
        );
        mergedRows.splice(this.selectedRowIndex, 1);
        this._HCPService.mergeRows(
          {
            mergedRows: mergedRows.map((r) => r.id),
            rowToMerge: this.mergedRow,
            projectId: this.data.projectId,
          },
          () => {
            this.data.mergeCallback();
            this._categoriesService.setCategories();
            this._dataProcessorService.updateProjectCounts(this.data.projectId);
          }
        );
      }
    }
    if (this.mergedRow?.__type === TableRowType.Center) {
      const center: TCenterTableRow = this.mergedRow;
      if (isNullOrEmpty(center.c_id)) {
        this._loadingService.triggerErrorMessage(`Please select base Center`);
      } else {
        const mergedRows: TCenter[] = structuredClone(
          this.data.selectedRows as TCenter[]
        );
        mergedRows.splice(this.selectedRowIndex, 1);
        this._centerService.mergeRows(
          {
            mergedRows: mergedRows.map((r) => r.c_id),
            rowToMerge: center,
            projectId: this.data.projectId,
          },
          () => {
            this.data.mergeCallback();
            const uniqueProjectIds: string[] = getUnique(
              [center, ...mergedRows]
                .map((it) => it.projectsData)
                .flat()
                .map((it) => it.projectId)
            );
            this._updateProjects(uniqueProjectIds);
          }
        );
      }
    }
  }

  private _updateProjects(projectsIds: string[]): void {
    const allProjectsIds: string[] = this._categoriesService
      .getCategories()
      .map((c) => c.projects)
      .flat()
      .map((p) => p.id);
    const existingProjects: string[] = projectsIds.filter((p) =>
      allProjectsIds.includes(p)
    );
    this._dataProcessorService.updateProjectCounts(
      existingProjects.join(SharedConstants.IDS_SPLIT)
    );
  }

  private buildMergedRow(): void {
    let mergedRow: THCPTableRow | TCenterTableRow | null = null;
    switch (this.data.selectedRows[0].__type) {
      case TableRowType.Center:
        mergedRow = {
          ...buildCenterMock({}),
          position: 0,
          __type: TableRowType.Center,
        };
        break;
      case TableRowType.HCP:
        mergedRow = {
          ...buildHCPMock({}),
          position: 0,
          __type: TableRowType.HCP,
        };
        break;
    }
    //if (!isNaN(this.selectedRowIndex)) {
    const row: TTableRowType | undefined =
      this.data.selectedRows[this.selectedRowIndex];
    switch (mergedRow!.__type) {
      case TableRowType.HCP:
        if (!isNullOrUndefined(row)) {
          mergedRow = structuredClone(row as THCPTableRow);
        }
        this.selectedCells.forEach((rowIndex: number, fieldName: string) => {
          let key: keyof THCPTableRow = Array.from(
            HCP_DB_MAPPINGS.values()
          ).find((item) => item.name === fieldName)
            ?.fieldId as keyof THCPTableRow;
          const selectedRow: THCPTableRow = this.data.selectedRows[
            rowIndex
          ] as THCPTableRow;
          const nv: HCPPropType = selectedRow[key];
          (<HCPPropType>(mergedRow as THCPTableRow)[key]) = nv;
        });
        break;
      case TableRowType.Center:
        if (!isNullOrUndefined(row)) {
          mergedRow = structuredClone(row as TCenterTableRow);
        }
        this.selectedCells.forEach((rowIndex: number, fieldName: string) => {
          let key: keyof TCenterTableRow = Array.from(
            CENTER_DB_MAPPINGS.values()
          ).find((item) => item.name === fieldName)
            ?.fieldId as keyof TCenterTableRow;
          const selectedRow: TCenterTableRow = this.data.selectedRows[
            rowIndex
          ] as TCenterTableRow;
          const nv: CenterPropType = selectedRow[key];
          (<CenterPropType>(mergedRow as TCenterTableRow)[key]) = nv;
        });
        break;
    }
    //}
    this.mergedRow = mergedRow;
  }
}
