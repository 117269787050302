import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@shared/Endpoints';
import { TTwitterData, TTwitterProximityData } from '@shared/interfaces/THCP';
import { ResponseStatus } from '@shared/interfaces/TResponseStatusMessage';
import { Observable, tap } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class TwitterService {
  constructor(private _http: HttpClient) {}

  public setTwitterReview(
    twitterId: string,
    hcpId: string,
    projectId: string,
    callback: (res: ResponseStatus) => void
  ): void {
    const url: string = Endpoints.BUILD_URL(`twitter`, {
      base: environment.apiUrl,
      endpoint: 'userNameData',
      params: ``,
    });

    const response = this._http.post<ResponseStatus>(url, {
      id: twitterId,
      userId: hcpId,
      projectId: projectId,
    });
    response.subscribe((res: ResponseStatus) => {
      callback(res);
    });
  }

  public getTwitterReview(id: string): Observable<TTwitterData> {
    const url: string = Endpoints.BUILD_URL(`twitter`, {
      base: environment.apiUrl,
      endpoint: 'userTwitterData',
      params: `?id=${id}`,
    });
    return this._http.get<TTwitterData>(url).pipe(tap((data) => data));
  }

  public getTwitterProximity(id: string): Observable<TTwitterProximityData> {
    const url: string = Endpoints.BUILD_URL(`twitter`, {
      base: environment.apiUrl,
      endpoint: 'twitterProximity',
      params: `?id=${id}`,
    });
    return this._http.get<TTwitterProximityData>(url).pipe(tap((data) => data));
  }
}
