import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TBaseSettingsTabParams } from '../project-settings.component';

@Component({
  selector: 'app-proximity',
  templateUrl: './proximity.component.html',
  styleUrls: ['./proximity.component.scss'],
})
export class ProximityComponent {
  constructor(
    public dialogRef: MatDialogRef<ProximityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TBaseSettingsTabParams
  ) {}
}
