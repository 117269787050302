<div class="flex gap-4">
  <div>
    <mat-form-field appearance="fill" *ngIf="isNew">
      <mat-label>Add Product</mat-label>
      <mat-select required [(ngModel)]="selectedField.product" name="Product">
        <mat-option *ngFor="let product of availableProducts" [value]="product">
          {{ product }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="!isNew" appearance="fill">
      <mat-label>Field</mat-label>
      <input disabled matInput [value]="selectedField.product" />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="fill" *ngIf="isNew">
      <mat-label>Add Time Period</mat-label>
      <mat-select
        required
        [(ngModel)]="selectedField.timePeriod"
        name="Time Period"
      >
        <mat-option *ngFor="let tp of availableTimePeriods" [value]="tp">
          {{ tp }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="!isNew" appearance="fill">
      <mat-label>Field</mat-label>
      <input disabled matInput [value]="selectedField.timePeriod" />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="fill">
      <mat-label>Operator</mat-label>
      <mat-select
        required
        [(ngModel)]="operator"
        name="Fields"
        [disabled]="!isNew"
      >
        <mat-option
          *ngFor="let operator of selectedField.availableOperators"
          [value]="operator"
        >
          {{ operatorToName(operator) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field appearance="fill">
      <mat-label>Score</mat-label>
      <input required matInput [(ngModel)]="scoreValue" [disabled]="!isNew" />
    </mat-form-field>
  </div>

  <div *ngIf="isNew">
    <button
      mat-flat-button
      color="accent"
      [disabled]="addNewFieldDisabled(selectedField, operator, scoreValue)"
      (click)="addNewField(selectedField, operator, scoreValue)"
    >
      Add field
    </button>
  </div>
  <div *ngIf="!isNew">
    <button
      mat-icon-button
      (click)="deleteField(selectedField)"
      aria-label="Delete"
      color="error"
    >
      <mat-icon>delete_forever</mat-icon>
    </button>
  </div>
</div>
