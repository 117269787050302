import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@shared/Endpoints';
import {
  CustomRegion,
  DEFAULT_APP_SETTINGS,
  TSettings,
  TSupportDoc,
} from '@shared/interfaces/TSettings';
import { TSettingsResponse } from '@shared/interfaces/TSettingsResponse';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { LoadingService, TLoadingItem } from './LoadingService';

export const LOCAL_SETTINGS_LOCAL_STORAGE_NAME: string = `rise-local-settings`;

export type AvailableLocalSettings = `LogVisible`;

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private _version: BehaviorSubject<TSettingsResponse> =
    new BehaviorSubject<TSettingsResponse>({
      version: `N/A`,
      env: ``,
      isProdEnv: false,
    });

  private _localSetting: Map<AvailableLocalSettings, boolean> = new Map();
  private _appSettings: BehaviorSubject<TSettings> =
    new BehaviorSubject<TSettings>(DEFAULT_APP_SETTINGS);
  private _isEditMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private _http: HttpClient,
    private _loadingService: LoadingService
  ) {}

  public getIsEditModeObservable(): Observable<boolean> {
    return this._isEditMode.asObservable();
  }

  public getIsEditMode(): boolean {
    return this._isEditMode.value;
  }

  public setIsEditMode(mode: boolean): void {
    this._isEditMode.next(mode);
  }

  public getAppSettingsObservable(): Observable<TSettings> {
    return this._appSettings.asObservable();
  }

  public getAppSettings(): TSettings {
    return this._appSettings.value;
  }

  public getCountriesWithPaperNotifications(): string[] {
    return this._appSettings.value.countriesWithPaperNotifications;
  }

  public getSupportDocuments(): Partial<TSupportDoc>[] {
    return this._appSettings.value.supportDocs;
  }

  public getOptOutEmailTemplate(): string {
    return this._appSettings.value.optOutTemplate;
  }

  public deleteCustomRegion(regionId: string): void {
    this._appSettings.next({
      ...this._appSettings.value,
      customRegions: this._appSettings.value.customRegions.filter(
        (region) => region._id !== regionId
      ),
    });
  }

  public addCustomRegion(region: CustomRegion): void {
    this._appSettings.next({
      ...this._appSettings.value,
      customRegions: [...this._appSettings.value.customRegions, region],
    });
  }

  public updateCustomRegion(region: CustomRegion): void {
    this._appSettings.next({
      ...this._appSettings.value,
      customRegions: this._appSettings.value.customRegions.map((r) => {
        if (r._id === region._id) {
          return region;
        }
        return r;
      }),
    });
  }

  public initLocalSettings(): void {
    this._localSetting = this._getLocalStorageData();
    this._loadingService.setLogVisible(
      this._localSetting.get(`LogVisible`) ?? true
    );
  }

  public setSettings(): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Fetching settings`);
    this._loadingService.addLoadingItem(loadingItem);
    const url: string = Endpoints.BUILD_URL(`settings`, {
      base: environment.apiUrl,
      endpoint: `app`,
      params: ``,
    });
    this._http.get<TSettings>(url).subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (results) => {
        this._appSettings.next({
          ...this._appSettings.value,
          ...results,
        });
        this._loadingService.removeItem(loadingItem);
      },
    });
  }

  public updateSettings(settings: TSettings): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Updating settings`);
    this._loadingService.addLoadingItem(loadingItem);
    const url: string = Endpoints.BUILD_URL('settings', {
      base: environment.apiUrl,
      endpoint: 'update',
      params: ``,
    });
    const response = this._http.post<TSettings>(url, settings);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (res) => {
        this._loadingService.removeItem(loadingItem);
        this._appSettings.next({ ...this._appSettings.value, ...settings });
      },
    });
  }

  public getLocalSettings(): Map<AvailableLocalSettings, boolean> {
    return this._localSetting;
  }

  public updateLocalSetting(
    setting: AvailableLocalSettings,
    value: boolean
  ): void {
    this._localSetting.set(setting, value);
    this._saveToLocalStorage();
  }

  public getVersionObservable(): Observable<TSettingsResponse> {
    return this._version.asObservable();
  }

  public getVersion(): TSettingsResponse {
    return this._version.value;
  }
  public setVersion(): void {
    const url: string = Endpoints.BUILD_URL(`settings`, {
      base: environment.apiUrl,
      endpoint: `base`,
      params: ``,
    });
    this._http.get<TSettingsResponse>(url).subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (results) => {
        this._version.next(results);
      },
    });
  }

  private _saveToLocalStorage(): void {
    localStorage.setItem(
      LOCAL_SETTINGS_LOCAL_STORAGE_NAME,
      JSON.stringify(Array.from(this._localSetting))
    );
  }

  private _getLocalStorageData(): Map<AvailableLocalSettings, boolean> {
    const localStorageData: string | null = localStorage.getItem(
      LOCAL_SETTINGS_LOCAL_STORAGE_NAME
    );
    if (localStorageData === null) {
      const initSettings: Map<AvailableLocalSettings, boolean> = new Map();
      initSettings.set(`LogVisible`, true);
      return new Map();
    } else {
      return new Map(JSON.parse(localStorageData));
    }
  }
}
