<div class="mt-5">
  <div class="flex columns-2 gap-5">
    <div class="flex flex-col w-1/2">
      <div *ngIf="isNew()" class="flex gap-2 items-center mb-2">
        <div class="flex w-full search-wrapper">
          <mat-form-field appearance="fill" class="w-full no-margin">
            <mat-label>Search existing HCP</mat-label>
            <input
              type="text"
              matInput
              [formControl]="hcpControl"
              [matAutocomplete]="auto"
              [value]="existingHCPSearch"
            />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option
                *ngFor="let option of filteredOptions | async"
                [value]="option"
                (click)="fillHCP(option)"
              >
                {{ option.first_name }} {{ option.last_name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-spinner *ngIf="isLoading" [diameter]="30"></mat-spinner>
        </div>
        <button
          mat-flat-button
          color="primary"
          mat-button
          (click)="clearData()"
        >
          Clear data
        </button>
      </div>
      <app-key-information
        [hcpData]="hcpData"
        [params]="hcpParams"
      ></app-key-information>
      <div class="flex gap-2">
        <app-center-information
          class="block mb-4 w-1/2"
          [centerData]="centerData"
          [hcpData]="hcpData"
          [params]="hcpParams"
        >
        </app-center-information>
        <div class="flex flex-col gap-4 w-full">
          <app-simple-proximity
            class="block"
            [hcpData]="hcpData"
            [params]="hcpParams"
          ></app-simple-proximity>
        </div>
      </div>
      <app-profile-details
        [countries]="countries"
        [hcpData]="hcpData"
        [params]="hcpParams"
      ></app-profile-details>
      <app-tweets> </app-tweets>
    </div>
    <div class="flex flex-col w-1/2">
      <app-influence
        [studiesCount]="studiesCount"
        [hcpData]="hcpData"
        [params]="hcpParams"
      ></app-influence>
      <app-value
        [centerData]="centerData"
        [hcpData]="hcpData"
        [params]="hcpParams"
      ></app-value>
      <h3 class="mat-h3">Engagement</h3>
      <app-engagement-plan
        [params]="hcpParams"
        [hcpData]="hcpData"
      ></app-engagement-plan>
      <app-engagement-tracking
        [hcpData]="hcpData"
        [params]="hcpParams"
      ></app-engagement-tracking>
      <app-profile-details-description
        [countries]="countries"
        [hcpData]="hcpData"
        [params]="hcpParams"
      ></app-profile-details-description>
    </div>
  </div>
</div>
