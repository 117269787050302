import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImportArea, SearchArea } from '@shared/enums/SearchArea';
import { TImportMappingItem } from '@shared/interfaces/TImportMappingItem';
import { UserLevel } from '@shared/interfaces/TUser';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { Subscription } from 'rxjs';
import { CSVUtils } from 'src/utils/csvUtils';
import { ImportComponent, TImportData } from '../import/import.component';
import { AuthService, TCurrentUser } from '../services/auth/auth.service';

export interface IImportExportParams {
  area: SearchArea;
  projectId: string;
  downloadCallback: (() => void) | undefined;
  importCallback:
    | ((e: Event, mappings: TImportMappingItem[]) => void)
    | undefined;
}

@Component({
  selector: 'app-import-export-panel',
  templateUrl: './import-export-panel.component.html',
  styleUrls: ['./import-export-panel.component.scss'],
})
export class ImportExportPanelComponent implements OnInit {
  @Input()
  public params: IImportExportParams = {
    area: SearchArea.HCP,
    projectId: ``,
    downloadCallback: undefined,
    importCallback: undefined,
  };

  public currentUser: TCurrentUser | undefined = undefined;
  private _subs: Subscription[] = [];

  constructor(
    public importDialog: MatDialog,
    private _authService: AuthService
  ) {}

  ngOnInit(): void {
    this._subs.push(
      this._authService.getCurrentUserObservable().subscribe((user) => {
        this.currentUser = user;
      })
    );
  }

  public ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
  }

  public downloadAvailable(): boolean {
    return (
      this.params.downloadCallback !== undefined &&
      this.currentUser?.level === UserLevel.Admin
    );
  }

  public downloadCallback(): void {
    if (this.params.downloadCallback !== undefined) {
      this.params.downloadCallback();
    }
  }

  public importAvailable(): boolean {
    return (
      this.params.importCallback !== undefined &&
      this.currentUser?.level === UserLevel.Admin
    );
  }

  public importCallback(e: Event): void {
    if (this.params.importCallback !== undefined) {
      const target: HTMLInputElement = <HTMLInputElement>e.target;
      if (!isNullOrUndefined(target.files)) {
        const reader: FileReader = new FileReader();
        reader.onload = (event) => {
          const results = event?.target?.result ?? ``;
          if (!isNullOrUndefined(results) && typeof results === `string`) {
            const csvOutput = CSVUtils.csvToArray(results);
            this._openImportDialog(csvOutput.headers, e);
          }
        };
        reader.readAsText(target.files[0]);
      }
    }
  }

  private _openImportDialog(headers: string[], e: Event): void {
    const area =
      this.params.area === SearchArea.HCP ? ImportArea.HCP : ImportArea.Centers;

    this.importDialog.open<ImportComponent, TImportData>(ImportComponent, {
      width: '80%',
      data: {
        headers: headers,
        area: area,
        projectId: this.params.projectId,
        importCallback: this.params.importCallback,
        e,
      },
    });
  }
}
