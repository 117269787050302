<div class="flex gap-4">
  <div class="flex gap-4 w-full">
    <mat-form-field>
      <mat-label>Field</mat-label>
      <input disabled matInput [value]="fieldName" />
    </mat-form-field>
    <ng-container *ngIf="!isEngagement()">
      <mat-form-field appearance="fill">
        <mat-label>Operator</mat-label>
        <mat-select required name="Fields" [(ngModel)]="filterItem.operator">
          <mat-option
            *ngFor="let operator of availableOperators"
            [value]="operator.filter"
          >
            {{ operator.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="flex">
        <app-value-editor
          [projectId]="projectId"
          [filterItem]="filterItem"
          [fieldType]="fieldType"
        ></app-value-editor>
      </div>
    </ng-container>
    <ng-container *ngIf="isEngagement()">
      <mat-form-field appearance="fill">
        <mat-label>Engagement tags</mat-label>
        <mat-select multiple [(ngModel)]="filterItem.tags">
          <mat-option *ngFor="let item of availableTags" [value]="item">{{
            item.tag
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Operator</mat-label>
        <mat-select required name="Fields" [(ngModel)]="filterItem.operator">
          <mat-option
            *ngFor="let operator of availableOperators"
            [value]="operator.filter"
          >
            {{ operator.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Choose a date</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          [(ngModel)]="filterItem.date"
          (dateChange)="onDateChange($event)"
        />
        <mat-hint>yyyy-MM-dd</mat-hint>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </ng-container>
    <button
      mat-icon-button
      (click)="deleteFilter(filterItem)"
      aria-label="Delete"
      color="error"
    >
      <mat-icon>delete_forever</mat-icon>
    </button>
  </div>
</div>
