export enum ResponseStatus {
  OK,
  Error,
  AlreadyExists
}

export interface TResponseStatusMessage<T> {
  message: string;
  status: ResponseStatus;
  data: T;
}
