import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedConstants } from '@shared/Constants';
import { ProductName, TProductSales } from '@shared/interfaces/TProductUse';
import { Operator, TProductUseScoreItem } from '@shared/interfaces/TProject';
import { Subscription } from 'rxjs';
import { SettingsService } from 'src/app/services/SettingsService';
import { MATHEMATICAL_OPERATORS, ScoreArea } from '../Constants';
import { TBaseSettingsTabParams } from '../project-settings.component';
import { ScoreSettingsType } from '../scoring-settings/scoring-settings.component';

export interface TProductUseScoreSettingsParams {
  __type: ScoreSettingsType;
  selectedItems: TProductUseScoreItem[];
  params: TBaseSettingsTabParams;
  scoreArea: ScoreArea;
}

@Component({
  selector: 'app-product-use-scoring',
  templateUrl: './product-use-scoring.component.html',
  styleUrls: ['./product-use-scoring.component.scss'],
})
export class ProductUseScoringComponent implements OnInit {
  public availableProducts: ProductName[] = [];
  public availableTimePeriods: TProductSales['time_period'][] =
    SharedConstants.TIME_PERIODS;
  public selectedItems: TProductUseScoreItem[] = [];
  public params: TBaseSettingsTabParams;
  public scoreArea: ScoreArea = `hcpProductUse`;
  public addNewSelectedValue: TProductUseScoreItem = {
    product: ``,
    timePeriod: ``,
    availableOperators: MATHEMATICAL_OPERATORS,
    operator: Operator.Addition,
    score: 0,
    area: `hcpProductUse`,
  };

  public addNewOperator: Operator = Operator.Addition;
  public addNewScoreValue: number = 0;
  private _subs: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<ProductUseScoringComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TProductUseScoreSettingsParams,
    private _settingsService: SettingsService
  ) {
    this.selectedItems = data.selectedItems;
    this.params = data.params;
    this.scoreArea = data.scoreArea;
  }

  ngOnInit(): void {
    this._subs.push(
      this._settingsService.getAppSettingsObservable().subscribe((results) => {
        this.availableProducts = results.products.map((p) => p.toUpperCase());
      })
    );
  }

  public getScoreValue(field: TProductUseScoreItem): number {
    if (Number.isNaN(field.score)) {
      return 0;
    } else {
      return field.score as number;
    }
  }

  public addNewField(
    addNewSelectedValue: TProductUseScoreItem | undefined,
    addNewOperator: Operator | undefined,
    scoreValue: number | string
  ): void {
    if (addNewSelectedValue !== undefined) {
      const newItem: TProductUseScoreItem =
        structuredClone(addNewSelectedValue);
      if (addNewOperator !== undefined) {
        newItem.operator = addNewOperator;
      }
      newItem.score = scoreValue;
      this.selectedItems.push(newItem);
    }
  }

  // TODO: should be done better - items should have better identifier than product and time period
  public deleteField(field?: TProductUseScoreItem): void {
    if (field !== undefined) {
      const indexToRemove: number = this.selectedItems.findIndex(
        (f) => field.product === f.product && field.timePeriod === f.timePeriod
      );
      this.selectedItems.splice(indexToRemove, 1);
    }
  }
}
