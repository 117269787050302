import { OptOutStatus, THCP, buildHCPMock } from '@shared/interfaces/THCP';

export class HCPsUtils {
  public static getHCPDataForUpdate(hcp: THCP): THCP {
    let hcpForUpdate: THCP;
    // if hcp has declined opt out, remove all data besides
    // first name, last name, primary_email_address,
    // opt_out_email_sent_date, opt_out_information_status, optOutHash, opt_out_paper_mail_printed_date
    if (hcp.opt_out_information_status === OptOutStatus.Declined) {
      hcpForUpdate = buildHCPMock({
        primary_email_address: hcp.primary_email_address.trim(),
        id: hcp.id,
        first_name: hcp.first_name,
        last_name: hcp.last_name,
        opt_out_email_sent_date: hcp.opt_out_email_sent_date,
        opt_out_paper_mail_printed_date: hcp.opt_out_paper_mail_printed_date,
        opt_out_information_status: hcp.opt_out_information_status,
        optOutHash: hcp.optOutHash,
      });
    } else {
      hcpForUpdate = {
        ...hcp,
        primary_email_address: hcp.primary_email_address.trim(),
      };
    }
    return hcpForUpdate;
  }
}
