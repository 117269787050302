import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RegionsService, TRegion } from 'src/app/services/RegionsService';

@Component({
  selector: 'app-countries-with-paper-notifications-dialog',
  templateUrl: './countries-with-paper-notifications-dialog.component.html',
  styleUrls: ['./countries-with-paper-notifications-dialog.component.scss'],
})
export class CountriesWithPaperNotificationsDialogComponent implements OnInit {
  public countriesWithPaperNotificationsForm: FormGroup;
  public regions: TRegion[] = [];
  public regionsFiltered: TRegion[] = [];

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CountriesWithPaperNotificationsDialogComponent>,
    private _regionsService: RegionsService,
    @Inject(MAT_DIALOG_DATA) public data: string[]
  ) {
    this.countriesWithPaperNotificationsForm = this.fb.group({
      countries: [[], [Validators.required]],
    });
    if (this.data) {
      this.countriesWithPaperNotificationsForm.patchValue(this.data);
    }
  }

  public ngOnInit(): void {
    this.regions = this._regionsService.getCountriesOnly();
    this.regionsFiltered = this.regions;
  }

  public get countries() {
    return this.countriesWithPaperNotificationsForm.get(
      'countries'
    ) as FormControl;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (this.countriesWithPaperNotificationsForm.valid) {
      this.dialogRef.close(this.countriesWithPaperNotificationsForm.value);
    }
  }
}
