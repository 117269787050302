<div *ngIf="salesCount > 0" class="mt-4">
  <h3 class="mat-h3">Sales</h3>
  <app-common-table [params]="params"></app-common-table>
</div>

<!-- This is an old functionality and can be removed -->
<!-- <div class="mt-4" [ngClass]="getPDFWrapperCSS()">
  <h3 class="mat-h3">Sales data</h3>
  <div class="mt-2 flex gap-4 regions-buttons-wrapper">
    <button
      *ngIf="region1Data.salesData.length > 0"
      mat-flat-button
      color="primary"
      type="button"
      (click)="showSalesData1()"
    >
      {{ region1Data.description }}
    </button>
    <button
      *ngIf="region2Data.salesData.length > 0"
      mat-flat-button
      color="primary"
      type="button"
      (click)="showSalesData2()"
    >
      {{ region2Data.description }}
    </button>
  </div>
  <app-sales-data-wrapper [pdfSrc]="pdfSrc"></app-sales-data-wrapper>
</div> -->
