<div class="simple-hcp-editor">
    <div *ngIf="isHeader" class="flex gap-2 simple-hcp-editor__row">
        <div class="simple-hcp-editor__row__item simple-hcp-editor__row__item--header font-bold"
            *ngFor="let item of getAllItems()">
            {{getPropertyName(item)}}
        </div>
    </div>
    <div *ngIf="!isHeader" class="flex gap-2 simple-hcp-editor__row">
        <div class="simple-hcp-editor__row__item simple-hcp-editor__row__item--not-editable"
            *ngFor="let item of notEditableProperties">
            {{hcp[item]}}
        </div>
        <div class="simple-hcp-editor__row__item simple-hcp-editor__item--editable"
            *ngFor="let item of editableProperties">
            <mat-form-field appearance="fill">
                <input matInput (change)='onItemUpdate($event, item)' [value]="hcp[item]" />
            </mat-form-field>
        </div>
    </div>
</div>