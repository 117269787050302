<div class="settings-modal-content relative">
  <h2 class="mat-h2 mt-2">Product Use scores</h2>
  <div class="text-sm">
    <div>
      <h3 class="my-4">Add new product</h3>
      <app-product-use-score-item
        [isNew]="true"
        [selectedField]="addNewSelectedValue"
        [operator]="addNewOperator"
        [scoreValue]="addNewScoreValue"
        [addNewField]="addNewField.bind(this)"
        [availableProducts]="availableProducts"
        [availableTimePeriods]="availableTimePeriods"
        [project]="params.selectedProject"
      >
      </app-product-use-score-item>
    </div>
    <div *ngIf="selectedItems.length > 0">
      <h3 class="my-4">Selected products</h3>
      <div *ngFor="let field of selectedItems">
        <app-product-use-score-item
          [isNew]="false"
          [selectedField]="field"
          [operator]="field.operator"
          [scoreValue]="getScoreValue(field)"
          [deleteField]="deleteField.bind(this)"
          [availableProducts]="availableProducts"
          [availableTimePeriods]="availableTimePeriods"
          [project]="params.selectedProject"
        >
        </app-product-use-score-item>
      </div>
    </div>
  </div>
  <app-tier-settings
    [params]="params"
    [scoreArea]="scoreArea"
  ></app-tier-settings>
  <div mat-dialog-actions class="actions">
    <button mat-flat-button color="primary" [mat-dialog-close]="true">
      Close
    </button>
  </div>
</div>
