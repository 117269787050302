import { Component, Input } from '@angular/core';

export enum Severity {
  Info,
  Danger,
  Warning,
  Success,
}

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent {
  @Input()
  public text: string = `This is the note`;
  @Input()
  public severity: Severity = Severity.Info;

  public getCSS(): string {
    switch (this.severity) {
      case Severity.Warning:
        return `note--warning`;
      case Severity.Danger:
        return `note--danger`;
      case Severity.Success:
        return `note--success`;
      default:
        return `note--info`;
    }
  }
}
