import { Component, Input } from '@angular/core';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import {
  TTuple,
  frequencyOptions,
} from '@shared/db-fields-mappings/HCP_DB_MAPPINGS';

@Component({
  selector: 'app-toggle-button-wrapper',
  templateUrl: './toggle-button-wrapper.component.html',
  styleUrls: ['./toggle-button-wrapper.component.scss'],
})
export class ToggleButtonWrapperComponent {
  @Input()
  public options: TTuple[] = structuredClone(frequencyOptions);
  @Input()
  public onChange: (group: MatButtonToggleGroup) => void = () => void 0;
  @Input()
  public value: number = 0;

  public getStringValue(value: number | null | undefined): string {
    if (value === undefined || value === null) {
      return ``;
    } else {
      return value.toString();
    }
  }

  constructor() {}
}
