import { SharedConstants } from '@shared/Constants';
import { PATH_DELIMITER, TDBFieldMappingItem } from '@shared/interfaces/TDBFieldMappingItem';

export interface TTuple {
  key: string;
  value: number;
}

export type FieldDataType = `string` | `number` | `date` | `boolean`;
export const influenceOptions: TTuple[] = [
  { key: `None`, value: 0 },
  { key: `Actively engaged KOL`, value: 1 },
  { key: `In discussion to become KOL`, value: 2 },
  { key: `Potential KOL`, value: 3 }
];
export const frequencyOptions: TTuple[] = [
  { key: `None`, value: 0 },
  { key: `Low`, value: 1 },
  { key: `Medium`, value: 2 },
  { key: `High`, value: 3 }
];

export const MISSING_DESCRIPTION_LABEL: string = `Missing field description`;

export const HCP_DB_MAPPINGS: Map<string, TDBFieldMappingItem> = new Map([
  [
    `actions`,
    {
      columnWidth: 130,
      fieldId: `actions`,
      name: `Actions`,
      isSortable: false,
      visibleInSummary: true,
      visibleInShortSummary: true,
      systemProp: false,
      isEditable: false,
      visibleInRaport: true
    }
  ],
  [
    `id`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `id`,
      name: `ID`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `gender`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `gender`,
      name: `Gender`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      isReportFilterField: true
    }
  ],
  [
    `title`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `title`,
      name: `Title`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      visibleInMerge: true,
      export: true
    }
  ],
  [
    `first_name`,
    {
      columnWidth: 150,
      fieldId: `first_name`,
      name: `First name`,
      isSortable: true,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false,
      visibleInMerge: true,
      visibleInRaport: true,
      visibleInShortSummary: true,
      export: true
    }
  ],
  [
    `last_name`,
    {
      columnWidth: 150,
      fieldId: `last_name`,
      name: `Last name`,
      isSortable: true,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false,
      visibleInMerge: true,
      visibleInRaport: true,
      visibleInShortSummary: true,
      export: true
    }
  ],
  [
    `alias`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `alias`,
      name: `Alias`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `has_priority`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `has_priority`,
      name: `Priority HCP`,
      isSortable: false,
      visibleInSummary: true,
      visibleInShortSummary: true,
      systemProp: false,
      isEditable: false,
      visibleInRaport: true
    }
  ],
  [
    `priority_timestamp`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `priority_timestamp`,
      name: `Priority set`,
      isSortable: false,
      visibleInSummary: false,
      visibleInShortSummary: false,
      systemProp: false,
      isEditable: false,
      visibleInRaport: true
    }
  ],
  [
    `center`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `center`,
      name: `Center id`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      visibleInRaport: false,
      export: true
    }
  ],
  [
    `centerName`,
    {
      columnWidth: 150,
      fieldId: `centerName`,
      name: `Center name`,
      isSortable: true,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false,
      visibleInMerge: true,
      visibleInRaport: true,
      export: true
    }
  ],
  [
    `city`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `city`,
      name: `City`,
      isSortable: true,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false,
      visibleInMerge: true,
      visibleInRaport: true,
      visibleInShortSummary: true,
      export: true
    }
  ],
  [
    `country`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `country`,
      name: `Country`,
      isSortable: true,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false,
      visibleInMerge: true,
      visibleInRaport: true,
      visibleInShortSummary: true,
      export: true
    }
  ],
  [
    `department`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `department`,
      name: `Department`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      visibleInMerge: true,
      export: true
    }
  ],
  [
    `street1`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `street1`,
      name: `Street`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      visibleInMerge: true,
      export: true
    }
  ],
  [
    `street2`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `street2`,
      name: `Street more`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      visibleInMerge: true,
      export: true
    }
  ],
  [
    `zip`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `zip`,
      name: `ZIP`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      visibleInMerge: true,
      export: true
    }
  ],
  [
    `opt_out_information_status`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `opt_out_information_status`,
      name: `Opt out information status`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      isReportFilterField: true,
      type: `number`
    }
  ],
  [
    `opt_in_status`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `opt_in_status`,
      name: `Opt in status`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      isReportFilterField: true,
      type: `number`
    }
  ],
  [
    `treats_adult_patients`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `treats_adult_patients`,
      name: `Treats adult patients`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      isReportFilterField: true,
      type: `boolean`
    }
  ],
  [
    `treats_pediatric_patients`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `treats_pediatric_patients`,
      name: `Treats pediatric patients`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      isReportFilterField: true,
      type: `boolean`
    }
  ],
  [
    `does_not_treat_any_patients_phd`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `does_not_treat_any_patients_phd`,
      name: `Does not treat any patients`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `hcp_proj_KOL`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `hcp_proj_KOL`,
      name: `Key opinion leader`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      path: `projectsData${PATH_DELIMITER}hcp_proj_KOL`
    }
  ],
  [
    `hcp_proj_rising_star`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `hcp_proj_rising_star`,
      name: `Rising star`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      path: `projectsData${PATH_DELIMITER}hcp_proj_rising_star`,
      isReportFilterField: true,
      type: `boolean`
    }
  ],
  [
    `hcp_proj_interest_as_advisor`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `hcp_proj_interest_as_advisor`,
      name: `Interest as advisor`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      path: `projectsData${PATH_DELIMITER}hcp_proj_interest_as_advisor`,
      isReportFilterField: true,
      type: `boolean`
    }
  ],
  [
    `hcp_proj_interested_in_research_study`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `hcp_proj_interested_in_research_study`,
      name: `Interested in research study`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      path: `projectsData${PATH_DELIMITER}hcp_proj_interested_in_research_study`
    }
  ],
  [
    `hcp_proj_promoter_score_local`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `hcp_proj_promoter_score_local`,
      name: `Promoter score local`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      tierField: true,
      requireValidation: true,
      isProjectData: true,
      path: `projectsData${PATH_DELIMITER}hcp_proj_promoter_score_local`,
      isReportFilterField: true,
      type: `string`
    }
  ],
  [
    `hcp_proj_promoter_score_global`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `hcp_proj_promoter_score_global`,
      name: `Promoter score global`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      tierField: true,
      requireValidation: true,
      isProjectData: true,
      path: `projectsData${PATH_DELIMITER}hcp_proj_promoter_score_global`,
      isReportFilterField: true,
      type: `string`
    }
  ],
  [
    `hcp_proj_potential_to_influence_peers`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `hcp_proj_potential_to_influence_peers`,
      name: `Potential to influence peers`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      tierField: true,
      requireValidation: true,
      isProjectData: true,
      path: `projectsData${PATH_DELIMITER}hcp_proj_potential_to_influence_peers`,
      isReportFilterField: true,
      type: `string`
    }
  ],
  [
    `function`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `function`,
      name: `Function`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `primary_email_address`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `primary_email_address`,
      name: `Primary email address`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      visibleInMerge: true,
      export: true
    }
  ],
  [
    `secondary_email_address`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `secondary_email_address`,
      name: `Secondary email address`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      visibleInMerge: true,
      export: true
    }
  ],
  [
    `specialties`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `specialties`,
      name: `Specialties`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `description`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `description`,
      name: `Description`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `memberships_committees`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `memberships_committees`,
      name: `Membership committees`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      isTagField: true,
      export: true,
      requireValidation: true,
      isProjectData: true,
      isReportFilterField: true
    }
  ],
  [
    `engagementTags`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `engagementTags`,
      name: `Engagement Actual Tags`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      isTagField: true,
      export: false,
      requireValidation: true,
      isProjectData: true,
      isReportFilterField: true,
      engagement: true
    }
  ],
  [
    `retired`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `retired`,
      name: `Retired`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      isReportFilterField: true,
      type: 'boolean'
    }
  ],
  [
    `key_account_global`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `key_account_global`,
      name: `Key account global`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `key_account_local`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `key_account_local`,
      name: `Key account global`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `resume`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `resume`,
      name: `Resume`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false
    }
  ],
  [
    `speaker_presenter_frequency`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `speaker_presenter_frequency`,
      name: `Speaker presenter frequency`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `digital_influencer`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `digital_influencer`,
      name: `Digital influencer`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `participated_in_guidelines`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `participated_in_guidelines`,
      name: `Participated in guidelines`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      isReportFilterField: true,
      type: 'boolean'
    }
  ],
  [
    `clinical_trials_investigator`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `clinical_trials_investigator`,
      name: `Clinical trials investigator`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      isReportFilterField: true,
      type: 'boolean'
    }
  ],
  [
    `clinical_trials_principal_investigator`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `clinical_trials_principal_investigator`,
      name: `Clinical trials principal investigator`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      isReportFilterField: true,
      type: 'boolean'
    }
  ],
  [
    `languages`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `languages`,
      name: `Languages`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `speaker`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `speaker`,
      name: `Speaker`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `speaker_history`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `speaker_history`,
      name: `Speaker history`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `initial_year`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `initial_year`,
      name: `Initial year`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `interest_in_clinical_trials_RD`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `interest_in_clinical_trials_RD`,
      name: `Interest in clinical trials`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `interest_in_health_economics`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `interest_in_health_economics`,
      name: `Interest in health economics`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `influencers`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `influencers`,
      name: `Influencers`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `social_media_engagements`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `social_media_engagements`,
      name: `Social media engagements`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `linkedin`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `linkedin`,
      name: `LinkedIn`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `further_links_and_sources`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `further_links_and_sources`,
      name: `Further links and sources`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `engagement_plan_time`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `engagement_plan_time`,
      name: `Engagement plan time`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false
    }
  ],
  [
    `engagement_plan_objectives`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `engagement_plan_objectives`,
      name: `Engagement plan time`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false
    }
  ],
  [
    `engagement_plan_accountable`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `engagement_plan_accountable`,
      name: `Engagement plan accountable`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false
    }
  ],
  [
    `engagement_actual_date`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `engagement_actual_date`,
      name: `Engagement actual date`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false
    }
  ],
  [
    `engagement_actual_activity`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `engagement_actual_activity`,
      name: `Engagement actual activity`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false
    }
  ],
  [
    `engagement_actual_point_person`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `engagement_actual_point_person`,
      name: `Engagement actual activity`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false
    }
  ],
  [
    `kols_status`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: 'kols_status',
      name: "KOL's status",
      isSortable: false,
      systemProp: false,
      isEditable: false,
      visibleInSummary: false,
      type: `number`,
      isReportFilterField: true,
      tupleList: true
    }
  ],
  [
    `labelHCP_treats_adult`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: 'labelHCP_treats_adult',
      name: 'Treats adult',
      isSortable: false,
      systemProp: false,
      isEditable: false,
      visibleInSummary: false,
      type: `boolean`
    }
  ],
  [
    `labelHCP_treats_pediatric`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: 'labelHCP_treats_pediatric',
      name: 'Treats pediatric',
      isSortable: false,
      systemProp: false,
      isEditable: false,
      visibleInSummary: false,
      type: `boolean`
    }
  ],
  [
    `labelHCP_does_not_treat_phd`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: 'labelHCP_does_not_treat_phd',
      name: 'Does not treat PHD',
      isSortable: false,
      systemProp: false,
      isEditable: false,
      visibleInSummary: false
    }
  ],
  [
    `labelHCP_guidelines`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: 'labelHCP_guidelines',
      name: 'HCP guidelines',
      isSortable: false,
      systemProp: false,
      isEditable: false,
      visibleInSummary: false
    }
  ],
  [
    `history_publications`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: 'history_publications',
      name: 'History publications',
      isSortable: false,
      systemProp: false,
      isEditable: false,
      visibleInSummary: false,
      export: false
    }
  ],
  [
    `history_publications_count`,
    {
      columnWidth: 200,
      fieldId: 'history_publications_count',
      name: 'History publications count',
      isSortable: true,
      systemProp: false,
      isEditable: false,
      visibleInSummary: true,
      export: true,
      path: `projectsData${PATH_DELIMITER}history_publications_count`,
      visibleInShortSummary: true
    }
  ],
  [
    `publications_total`,
    {
      columnWidth: 150,
      fieldId: `publications_total`,
      name: `Publications total`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: false,
      visibleInShortSummary: true
    }
  ],
  [
    `history_centers`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: 'history_centers',
      name: 'History centers',
      isSortable: false,
      systemProp: true,
      isEditable: false,
      visibleInSummary: false,
      export: false
    }
  ],
  [
    `affiliationYear`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: 'affiliationYear',
      name: 'Affiliation year',
      isSortable: false,
      systemProp: false,
      isEditable: false,
      visibleInSummary: false,
      export: true
    }
  ],
  [
    `studies`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: 'studies',
      name: 'Studies',
      isSortable: false,
      systemProp: false,
      isEditable: false,
      visibleInSummary: false,
      export: true
    }
  ],
  [
    `status`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: 'status',
      name: 'status',
      isSortable: false,
      systemProp: true,
      isEditable: false,
      visibleInSummary: false,
      export: true
    }
  ],
  [
    `projects`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: 'projects',
      name: 'Projects',
      isSortable: false,
      systemProp: true,
      isEditable: false,
      visibleInSummary: false
    }
  ],
  [
    `projectsData`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: 'projectsData',
      name: 'Projects data',
      isSortable: false,
      systemProp: true,
      isEditable: false,
      visibleInSummary: false,
      export: false
    }
  ],
  [
    `influenceScore`,
    {
      columnWidth: 120,
      fieldId: 'influenceScore',
      name: 'Influence score',
      isSortable: true,
      systemProp: false,
      isEditable: false,
      visibleInSummary: true,
      visibleInRaport: true,
      visibleInShortSummary: true,
      path: `projectsData${PATH_DELIMITER}influenceScore`,
      isFilterField: true,
      export: true,
      import: false
    }
  ],
  [
    `engagementScore`,
    {
      columnWidth: 150,
      fieldId: 'engagementScore',
      name: 'Engagement score',
      isSortable: true,
      systemProp: false,
      isEditable: false,
      visibleInSummary: true,
      visibleInRaport: true,
      visibleInShortSummary: true,
      path: `projectsData${PATH_DELIMITER}engagementScore`,
      isFilterField: true,
      export: true,
      import: false
    }
  ],
  [
    `valueScore`,
    {
      columnWidth: 120,
      fieldId: 'valueScore',
      name: 'Value score',
      isSortable: true,
      systemProp: false,
      isEditable: false,
      visibleInSummary: true,
      visibleInRaport: true,
      visibleInShortSummary: true,
      path: `projectsData${PATH_DELIMITER}valueScore`,
      isFilterField: true,
      export: true,
      import: false
    }
  ],
  [
    `risingStar`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `risingStarScore`,
      name: `Rising Star score`,
      isSortable: true,
      systemProp: false,
      isEditable: false,
      visibleInSummary: true,
      visibleInRaport: true,
      path: `projectsData${PATH_DELIMITER}risingStarScore`,
      isFilterField: true
    }
  ],
  [
    `c_proj_bu1_clin1`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: 'c_proj_bu1_clin1',
      name: 'Clinical Trial tier',
      isSortable: false,
      systemProp: false,
      isEditable: false,
      visibleInSummary: false,
      tierField: false,
      requireValidation: true
    }
  ],
  [
    `c_proj_bu1_potential1`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: 'c_proj_bu1_potential1',
      name: 'Patient Tier',
      isSortable: false,
      systemProp: false,
      isEditable: false,
      visibleInSummary: false,
      tierField: false,
      requireValidation: true
    }
  ],
  [
    `c_proj_bu1_potential2`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: 'c_proj_bu1_potential2',
      name: 'IU Tier',
      isSortable: false,
      systemProp: false,
      isEditable: false,
      visibleInSummary: false,
      tierField: false,
      requireValidation: true
    }
  ],
  [
    `c_proj_bu1_use1`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: 'c_proj_bu1_use1',
      name: 'Product use tier (1)',
      isSortable: false,
      systemProp: false,
      isEditable: false,
      visibleInSummary: false,
      tierField: false,
      requireValidation: true
    }
  ],
  [
    `c_proj_bu1_use2`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: 'c_proj_bu1_use2',
      name: 'Product use tier (2)',
      isSortable: false,
      systemProp: false,
      isEditable: false,
      visibleInSummary: false,
      tierField: false,
      requireValidation: true
    }
  ],
  [
    `profile_image`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `profile_image`,
      name: `Profile image`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: false
    }
  ],
  [
    `c_beds`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_beds`,
      name: `Beds`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      tierField: false,
      export: false,
      borrowedField: true
    }
  ],
  [
    `c_inpatient_days`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_inpatient_days`,
      name: `Inpatient days`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: false,
      borrowedField: true
    }
  ],
  [
    `c_inpatient_surgeries`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_inpatient_surgeries`,
      name: `Inpatient surgeries`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: false,
      borrowedField: true
    }
  ],
  [
    `c_adultcardiacsurgery`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_adultcardiacsurgery`,
      name: `Adult cardiac surgery`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: false,
      borrowedField: true
    }
  ],
  [
    `c_trauma_center`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_trauma_center`,
      name: `Trauma level certification`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: false,
      borrowedField: true
    }
  ],
  [
    `c_US_medicaremedicaid`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_US_medicaremedicaid`,
      name: `Medicare/Medicaid proportion`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: false,
      borrowedField: true
    }
  ],
  [
    `c_proj_CABP_surgeries`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_proj_CABP_surgeries`,
      name: `Cardio pulmonary bypass surgeries/year`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: false,
      borrowedField: true
    }
  ],
  [
    `c_proj_periop_hemorrhage`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_proj_periop_hemorrhage`,
      name: `perioperative hemorrhages/year`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: false,
      path: `projectsData${PATH_DELIMITER}c_decile`,
      borrowedField: true
    }
  ],
  [
    `c_decile`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_decile`,
      name: `Decile for the project`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: false,
      path: `projectsData${PATH_DELIMITER}c_decile`,
      borrowedField: true
    }
  ]
]);
