import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface EngagementImportComponentData {
  errors: string[];
}

@Component({
  selector: 'app-engagement-import',
  templateUrl: './engagement-import.component.html',
  styleUrls: ['./engagement-import.component.scss'],
})
export class EngagementImportComponent {
  public errors: string[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EngagementImportComponentData
  ) {}
}
