<div class="settings-modal-content">
    <h2 class="my-4 mat-h2">Proximity weights</h2>
    <div class="flex gap-4">
        <mat-form-field appearance="fill">
            <mat-label>Publication weight</mat-label>
            <input type="number" matInput [(ngModel)]="data.selectedProject!.hcpWeight" />
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Center weight</mat-label>
            <input type="number" matInput [(ngModel)]="data.selectedProject!.centerWeight" />
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Clinical Trials weight</mat-label>
            <input type="number" matInput [(ngModel)]="data.selectedProject!.CTWeight" />
        </mat-form-field>
    </div>
</div>
