import { Component, Input } from '@angular/core';
import { THCP } from '@shared/interfaces/THCP';
import { OptInStatuses, OptOutStatuses } from 'src/constants/consent';

@Component({
  selector: 'app-consent-data-capture',
  templateUrl: './consent-data-capture.component.html',
  styleUrls: ['./consent-data-capture.component.scss'],
})
export class ConsentDataCaptureComponent {
  @Input() public hcpData: THCP | undefined = undefined;

  optOutStatuses = OptOutStatuses;
  optInStatuses = OptInStatuses;

  public changeOptOutStatus(): void {
    if (!this.hcpData) return;

    this.hcpData.opt_out_email_sent_date = new Date();
  }

  public changeOptInStatus(): void {
    if (!this.hcpData) return;

    this.hcpData.opt_in_email_sent_date = new Date();
  }
}
