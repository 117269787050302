<div>
  <h3 class="mat-h3">Influence</h3>
  <div class="score-view-wrapper mb-1">
    <app-toggle-button-wrapper
      [options]="influenceOptions"
      [value]="hcpData.kols_status"
      [onChange]="onInfluenceChange.bind(this)"
    >
    </app-toggle-button-wrapper>
    <div class="flex gap-5 my-5">
      <app-counter
        [label]="'Publications'"
        [value]="getPublicationsCount()"
      ></app-counter>
      <app-counter [label]="'Studies'" [value]="studiesCount"></app-counter>
    </div>
    <!-- TO DO - logic to extract correct score from projectsData -->
    <app-score-view
      [score]="influenceScore"
      [projectId]="params.projectId"
      [area]="'hcpInfluence'"
    ></app-score-view>
  </div>
  <div>
    <mat-form-field appearance="fill" class="w-full">
      <mat-label>Memberships</mat-label>
      <mat-select
        class="w-full"
        multiple
        (selectionChange)="handleMembershipChangeChange($event)"
        [(ngModel)]="selectedItems"
      >
        <mat-option
          *ngFor="let membership of availableMemberships"
          [value]="membership.tag"
        >
          {{ membership.tag }} - {{ membership.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
