import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CENTER_DB_MAPPINGS } from '@shared/db-fields-mappings/CENTER_DB_MAPPINGS';
import {
  HCP_DB_MAPPINGS,
  MISSING_DESCRIPTION_LABEL,
} from '@shared/db-fields-mappings/HCP_DB_MAPPINGS';
import { TDBFieldMappingItem } from '@shared/interfaces/TDBFieldMappingItem';
import { TFieldNameMappingItem } from '@shared/interfaces/TFieldNameMappingItem';
import {
  TTierFieldDefinition,
  TTierFieldItem,
} from '@shared/interfaces/TProject';
import { isNullOrEmpty } from '@shared/utils/isNullOrEmpty';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { Subscription } from 'rxjs';
import { CategoriesService } from 'src/app/services/CategoriesService';
import { TBaseSettingsTabParams } from '../project-settings.component';

export type TierType = `center` | `hcp`;

@Component({
  selector: 'app-tier-definitions',
  templateUrl: './tier-definitions.component.html',
  styleUrls: ['./tier-definitions.component.scss'],
})
export class TierDefinitionsComponent implements OnInit, OnDestroy {
  public params: TBaseSettingsTabParams;
  public centerTier: TierType = `center`;
  public hcpTier: TierType = `hcp`;
  public availableHCPFields: TDBFieldMappingItem[] = [
    ...Array.from(HCP_DB_MAPPINGS.values()).filter((f) => f.tierField),
  ];
  public availableCenterFields: TDBFieldMappingItem[] = [
    ...Array.from(CENTER_DB_MAPPINGS.values()).filter((f) => f.tierField),
  ];

  public selectedHCPField: string = ``;
  public selectedCenterField: string = ``;
  private _subs: Subscription[] = [];

  constructor(
    private _categoriesService: CategoriesService,
    public dialogRef: MatDialogRef<TierDefinitionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TBaseSettingsTabParams
  ) {
    this._subs.push(
      this._categoriesService.getCategoriesObservable().subscribe(() => {
        this.processFieldNames();
      })
    );
    this.params = data;
  }

  public ngOnInit(): void {
    this.processFieldNames();
  }

  public ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  public getFieldName(type: TierType): string {
    switch (type) {
      case 'center':
        const centerName: string =
          this.availableCenterFields.find(
            (f) => f.fieldId === this.selectedCenterField
          )?.name ?? ``;
        return isNullOrEmpty(centerName)
          ? MISSING_DESCRIPTION_LABEL
          : centerName;
      case 'hcp':
        const HCPName: string =
          this.availableHCPFields.find(
            (f) => f.fieldId === this.selectedCenterField
          )?.name ?? ``;
        return isNullOrEmpty(HCPName) ? MISSING_DESCRIPTION_LABEL : HCPName;
    }
  }

  public deleteTier(tier: TTierFieldItem, type: TierType): void {
    if (!isNullOrUndefined(this.params.selectedProject)) {
      let definitions: TTierFieldDefinition[] = [];
      let selectedField: string = ``;
      switch (type) {
        case 'center':
          definitions =
            this.params.selectedProject?.centerTierFieldDefinitions ?? [];
          selectedField = this.selectedCenterField;
          break;
        case 'hcp':
          definitions =
            this.params.selectedProject?.hcpTierFieldDefinitions ?? [];
          selectedField = this.selectedHCPField;
          break;
      }
      const currentDefinition: TTierFieldDefinition | undefined =
        definitions.find((f) => f.fieldId === selectedField);
      if (!isNullOrUndefined(currentDefinition)) {
        const indexToRemove: number = currentDefinition.tiers.findIndex(
          (f) => f.name === tier.name
        );
        currentDefinition.tiers.splice(indexToRemove, 1);
      }
    }
  }

  public getTiers(type: TierType): TTierFieldItem[] {
    let selectedField: string = ``;
    switch (type) {
      case 'center':
        selectedField = this.selectedCenterField;
        break;
      case 'hcp':
        selectedField = this.selectedHCPField;
        break;
    }
    return this._categoriesService.getTiersForField(
      this.params.selectedProject?.id ?? ``,
      selectedField,
      type
    );
  }

  public addFieldTier(type: TierType): void {
    if (!isNullOrUndefined(this.params.selectedProject)) {
      if (
        isNullOrUndefined(this.params.selectedProject.hcpTierFieldDefinitions)
      ) {
        this.params.selectedProject.hcpTierFieldDefinitions = [];
      }
      if (
        isNullOrUndefined(
          this.params.selectedProject.centerTierFieldDefinitions
        )
      ) {
        this.params.selectedProject.centerTierFieldDefinitions = [];
      }
      const newTier: TTierFieldItem = {
        name: `Tier 1`,
        value: 0,
      };
      let definitions: TTierFieldDefinition[] = [];
      let selectedField: string = ``;
      switch (type) {
        case 'center':
          definitions =
            this.params.selectedProject?.centerTierFieldDefinitions ?? [];
          selectedField = this.selectedCenterField;
          break;
        case 'hcp':
          definitions =
            this.params.selectedProject?.hcpTierFieldDefinitions ?? [];
          selectedField = this.selectedHCPField;
          break;
      }
      const currentDefinition: TTierFieldDefinition | undefined =
        definitions.find((f) => f.fieldId === selectedField);
      if (isNullOrUndefined(currentDefinition)) {
        definitions.push({
          fieldId: selectedField,
          tiers: [newTier],
        });
      } else {
        currentDefinition.tiers.push(newTier);
      }
    }
  }

  public tierListVisible(type: TierType): boolean {
    switch (type) {
      case 'center':
        return !isNullOrEmpty(this.selectedCenterField);
      case 'hcp':
        return !isNullOrEmpty(this.selectedHCPField);
    }
  }

  private processFieldNames() {
    if (!isNullOrUndefined(this.params?.selectedProject)) {
      const centerFields: TFieldNameMappingItem[] =
        this.params.selectedProject.centerFields ?? [];
      const hcpFields: TFieldNameMappingItem[] =
        this.params.selectedProject.hcpFields ?? [];
      [...centerFields, ...hcpFields].forEach((field) => {
        [...this.availableHCPFields, ...this.availableCenterFields].forEach(
          (f) => {
            if (f.fieldId === field.fieldName) {
              f.name = isNullOrEmpty(field.filedViewValue)
                ? `${MISSING_DESCRIPTION_LABEL} - ${field.fieldName}`
                : field.filedViewValue;
            }
          }
        );
      });
    }
  }
}
