import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomRegion } from '@shared/interfaces/TSettings';
import { RegionsService, TRegion } from 'src/app/services/RegionsService';
import { shortUUid } from 'src/utils/uuid';

@Component({
  selector: 'app-custom-regions-dialog',
  templateUrl: './custom-regions-dialog.component.html',
  styleUrls: ['./custom-regions-dialog.component.scss'],
})
export class CustomRegionsDialogComponent implements OnInit {
  public customRegionForm: FormGroup;
  public regions: TRegion[] = [];
  public regionsFiltered: TRegion[] = [];

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CustomRegionsDialogComponent>,
    private _regionsService: RegionsService,
    @Inject(MAT_DIALOG_DATA) public data: CustomRegion
  ) {
    this.customRegionForm = this.fb.group({
      name: ['', [Validators.required]],
      countries: [[], [Validators.required]],
    });
    if (this.data) {
      this.customRegionForm.patchValue(this.data);
    }
  }

  public ngOnInit(): void {
    this.regions = this._regionsService.getCountriesOnly();
    this.regionsFiltered = this.regions;
  }

  public get name() {
    return this.customRegionForm.get('name')!;
  }

  public get countries() {
    return this.customRegionForm.get('countries') as FormControl;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (this.customRegionForm.valid) {
      // if editing
      let region;
      if (this.data) {
        region = {
          _id: this.data._id,
          ...this.customRegionForm.value,
        };
      } else {
        region = {
          _id: shortUUid(),
          ...this.customRegionForm.value,
        };
      }
      this.dialogRef.close(region);
    }
  }
}
