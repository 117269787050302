import { Component, Input, OnDestroy } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { TEngagementPlan } from '@shared/interfaces/THCP';
import { TProject, TTagItem } from '@shared/interfaces/TProject';
import { DEFAULT_APP_SETTINGS, TSettings } from '@shared/interfaces/TSettings';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { newGuid } from '@shared/utils/newGuild';
import { Subscription } from 'rxjs';
import {
  TComponentType,
  THCPParams,
} from '../../../../interfaces/TComponentParams';
import { CategoriesService } from '../../../../services/CategoriesService';
import { SettingsService } from '../../../../services/SettingsService';

@Component({
  selector: '[engagement-plan-tr]',
  templateUrl: './engagement-plan-item.component.html',
  styleUrls: ['./engagement-plan-item.component.scss'],
})
export class EngagementPlanItemComponent implements OnDestroy {
  @Input()
  public engagementPlanItem: TEngagementPlan = {
    engagement_plan_accountable: `Accountable`,
    engagement_plan_completed: false,
    engagement_plan_objectives: `Objectives`,
    engagement_plan_time: new Date(),
    id: newGuid(),
    tags: [],
  };
  @Input()
  public deleteCallback: (item: TEngagementPlan) => void = (
    item: TEngagementPlan
  ) => console.log(item);
  public isEditing: boolean = false;
  public appSettings: TSettings = structuredClone(DEFAULT_APP_SETTINGS);
  @Input()
  public onDateChangeCallback: () => void = () => void 0;
  @Input()
  public params: THCPParams = {
    hcpId: ``,
    categoryId: ``,
    projectId: ``,
    __type: TComponentType.hcp,
  };
  @Input()
  public project: string = `PN`;
  @Input()
  public itemProjectId: string = ``;
  public selectedItems: string[] = [];
  private _subs: Subscription[] = [];
  private _engagementTagsFieldId: string = `engagementTags`;

  constructor(
    private _settingsService: SettingsService,
    private _categoriesService: CategoriesService
  ) {
    this._subs.push(
      this._settingsService.getAppSettingsObservable().subscribe((settings) => {
        this.appSettings = settings;
      })
    );
  }
  public ngOnInit(): void {
    const selectedTags: TTagItem[] = this.engagementPlanItem?.tags ?? [];
    if (selectedTags.length > 0) {
      this.selectedItems = selectedTags.map((it) => it.tag);
    }
  }

  public getAvailableTags(): TTagItem[] {
    let availableTags: TTagItem[] = [];
    const project: TProject | undefined = this._categoriesService.getProject(
      this.itemProjectId
    );

    if (!isNullOrUndefined(project)) {
      availableTags =
        project.hcpTagsDefinitions.find(
          (def) => def.connEctedFieldId === this._engagementTagsFieldId
        )?.tags ?? [];
    }
    return availableTags;
  }

  public handleTagsChangeChange(event: MatSelectChange): void {
    this.engagementPlanItem.tags = this.getAvailableTags().filter((t) =>
      (<string[]>event.value).includes(t.tag)
    );
  }

  public ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
  }

  public toggleIsEditing(): void {
    this.isEditing = !this.isEditing;
    if (this.isEditing === false) {
      this.engagementPlanItem.engagement_plan_time = new Date(
        this.engagementPlanItem.engagement_plan_time as any
      );
    }
  }

  public onDateChange(event: Event): void {
    this.engagementPlanItem.engagement_plan_time = new Date(
      (<HTMLInputElement>event.target).value
    );
    this.onDateChangeCallback();
  }

  public getDateInputValue(date: string = ``): string {
    const formatDate: string = date.replace(/\//g, `.`);
    return formatDate;
  }

  public getClassName(): string {
    return this.isEditing ? `editing` : ``;
  }
}
