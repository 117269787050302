import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { Subscription } from 'rxjs';
import { SettingsService } from 'src/app/services/SettingsService';
import {
  ConfirmDialogData,
  ConfirmationDialogComponent,
} from '../../../confirmation-dialog/confirmation-dialog.component';
import { DataProcessorService } from '../../../services/DataProcessorService';
import { TBaseSettingsTabParams } from '../project-settings.component';

export interface TBaseSettingsParams {
  params: TBaseSettingsTabParams;
  minProjectYear: number;
  selectedMinProjectYear: number;
  clinicalTrialsKeywords: string[];
  applyCallback: (
    recalculate: boolean,
    shouldRecalculateProjectByDate: boolean
  ) => void;
}

@Component({
  selector: 'app-base-settings',
  templateUrl: './base-settings.component.html',
  styleUrls: ['./base-settings.component.scss'],
})
export class BaseSettingsComponent implements OnDestroy, OnInit {
  public maxProjectYear: number = new Date().getFullYear();

  public params: TBaseSettingsTabParams;
  public minProjectYear: number = 0;
  public selectedMinProjectYear: number = 0;
  public clinicalTrialsKeywords: string[];
  public applyCallback: (
    recalculate: boolean,
    shouldRecalculateProjectByDate: boolean
  ) => void;
  public description: string = ``;
  public name: string = ``;
  public enableAutoImport: boolean = false;
  public showAutoImportToggle: boolean = false;
  private _subs: Subscription[] = [];

  constructor(
    public confirmationDialog: MatDialog,
    public dialogRef: MatDialogRef<BaseSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TBaseSettingsParams,
    private _dataProcessorService: DataProcessorService,
    private _settingsService: SettingsService
  ) {
    this.params = data.params;
    this.minProjectYear = data.minProjectYear;
    this.selectedMinProjectYear = data.selectedMinProjectYear;
    this.clinicalTrialsKeywords = data.clinicalTrialsKeywords;
    this.description = this.params.selectedProject?.description ?? ``;
    this.name = this.params.selectedProject?.name ?? ``;
    this.enableAutoImport =
      this.params.selectedProject?.enableAutoImport ?? false;
    this.applyCallback = data.applyCallback;
  }

  public ngOnInit(): void {
    this._subs.push(
      this._settingsService.getAppSettingsObservable().subscribe((settings) => {
        this.showAutoImportToggle = Boolean(settings.enableAutoImport);
      })
    );
  }

  public ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
  }

  public onNameChange(): void {
    if (!isNullOrUndefined(this.params.selectedProject)) {
      this.params.selectedProject.name = this.name;
    }
  }

  public onDescriptionChange(): void {
    if (!isNullOrUndefined(this.params.selectedProject)) {
      this.params.selectedProject.description = this.description;
    }
  }

  public getRemoveHCPDuplicatesTooltip(): string {
    return this.params.selectedProject?.hcpDuplicatesRemoved === true
      ? `HCP duplicates already removed`
      : ``;
  }

  public getHCPsRemoveDuplicatesButtonCSS(): string {
    return this.params.selectedProject?.hcpDuplicatesRemoved === true
      ? `settings__item--disabled`
      : ``;
  }

  public getCentersDuplicatesTooltip(): string {
    return this.params.selectedProject?.centersDuplicatesRemoved === true
      ? `Centers duplicates already removed`
      : ``;
  }

  public getCentersRemoveDuplicatesButtonCSS(): string {
    return this.params.selectedProject?.centersDuplicatesRemoved === true
      ? `settings__item--disabled`
      : ``;
  }

  public onSliderChange(): void {
    if (!isNullOrUndefined(this.params.selectedProject)) {
      this.params.selectedProject.selectedMinYear = this.selectedMinProjectYear;
    }
  }

  public onAutoImportChange(): void {
    if (!isNullOrUndefined(this.params.selectedProject)) {
      this.params.selectedProject.enableAutoImport = this.enableAutoImport;
    }
  }

  public reprocessData(): void {
    const message = `Are you sure you want to filter project by date? It will affect all projects data`;
    const dialogData: ConfirmDialogData = { title: `Reprocess data`, message };
    const dialogRef = this.confirmationDialog.open(
      ConfirmationDialogComponent,
      {
        maxWidth: `400px`,
        data: dialogData,
      }
    );

    dialogRef.afterClosed().subscribe((dialogResult: boolean) => {
      if (dialogResult) {
        this.applyCallback(false, true);
      }
    });
  }

  public filterCT(data: string[]): void {
    if (this.params.selectedProject) {
      this.params.selectedProject.clinicalTrialsKeywords = data;
    }
  }

  public processMissingHCPsData(): void {
    if (
      this.params.selectedProject !== undefined &&
      this.params.selectedCategory !== undefined
    ) {
      this._dataProcessorService.processMissingHCPsData(
        this.params.selectedProject.id
      );
    }
    this.dialogRef.close();
  }

  public removeHCPsDuplicates(): void {
    if (
      this.params.selectedProject !== undefined &&
      this.params.selectedCategory !== undefined
    ) {
      this._dataProcessorService.removeHCPsDuplicates(
        this.params.selectedCategory.categoryId,
        this.params.selectedProject.id
      );
      this.dialogRef.close();
    }
  }

  public removeCentresDuplicates(): void {
    if (
      this.params.selectedProject !== undefined &&
      this.params.selectedCategory !== undefined
    ) {
      this._dataProcessorService.removeCentresDuplicates(
        this.params.selectedCategory.categoryId,
        this.params.selectedProject.id
      );
    }
    this.dialogRef.close();
  }

  public processCTData(): void {
    if (
      this.params.selectedProject !== undefined &&
      this.params.selectedCategory !== undefined
    ) {
      this._dataProcessorService.processCTData(
        this.params.selectedCategory.categoryId,
        this.params.selectedProject.id
      );
    }
    this.dialogRef.close();
  }
}
