import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import { Endpoints } from '@shared/Endpoints';
import { TArticle } from '@shared/interfaces/TArticle';
import { TSearchParams } from '@shared/interfaces/TSearchParams';
import { environment } from 'src/environments/environment';

import { LoadingService, TLoadingItem } from './LoadingService';
import { PagingService } from './paging/PagingService';
import { TPagingItem } from './paging/TPagingItem';
import { SettingsService } from './SettingsService';

@Injectable()
export class ArticlesService {
  private _visibleArticles: BehaviorSubject<TArticle[]> = new BehaviorSubject<
    TArticle[]
  >([]);
  private _articlesCount: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );
  private _searchedArticlesCount: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  private _articlesCountMap: Map<string, number> = new Map(); //keywords,count

  constructor(
    private _loadingService: LoadingService,
    private _http: HttpClient,
    private _pagingService: PagingService,
    private _settingsService: SettingsService
  ) {}

  public getSearchedArticlesObservable(): Observable<number> {
    return this._searchedArticlesCount.asObservable();
  }

  public getVisibleArticlesObservable(): Observable<TArticle[]> {
    return this._visibleArticles.asObservable();
  }

  public searchAndSetArticles(
    params: TSearchParams<TArticle>,
    callback?: (response: TSearchParams<TArticle>) => void
  ): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Fetching articles`);
    this._loadingService.addLoadingItem(loadingItem);
    const url: string = Endpoints.BUILD_URL('rise', {
      base: environment.apiUrl,
      endpoint: 'searchArticles',
      params: ``,
    });
    const response = this._http.post<TSearchParams<TArticle>>(url, params);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (response) => {
        const currentPaging: TPagingItem = this._pagingService.getPagingItem(
          params.projectId
        );
        currentPaging.articles = response.paging;
        this._pagingService.updatePagingItem(params.projectId, currentPaging);
        this._visibleArticles.next(response.results);
        if (callback !== undefined) {
          callback(response);
        }
        this._loadingService.removeItem(loadingItem);
      },
    });
  }

  public getArticlesCountObservable(): Observable<number> {
    return this._articlesCount.asObservable();
  }

  public setSearchedArticlesCount(
    keywords: string,
    startYear: number,
    newProject: boolean = false
  ): void {
    const settings = this._settingsService.getAppSettings();
    if (settings.fetchArticlesCount || newProject) {
      const loadingItem: TLoadingItem = this._loadingService.buildLoadingItem(
        `Fetching articles count`
      );
      this._loadingService.addLoadingItem(loadingItem);
      const cashedCount: number | undefined = this._articlesCountMap.get(
        this._articlesCountMapKeyBuilder(keywords, startYear)
      );
      if (cashedCount !== undefined) {
        this._searchedArticlesCount.next(cashedCount);
        this._loadingService.removeItem(loadingItem);
      } else {
        const url: string = Endpoints.BUILD_URL('ncbi', {
          base: environment.apiUrl,
          endpoint: 'dataSize',
          params: `?term=${keywords}&startYear=${startYear}`,
        });
        this._http.get<number>(url).subscribe({
          error: (err) => {
            console.error(err);
            this._loadingService.onError();
          },
          next: (results) => {
            if (results === -1) {
              this._loadingService.triggerInfoMessage(
                `PUBMED service is unavailable, cannot get current articles size.`
              );
              this._searchedArticlesCount.next(this._articlesCount.value);
              this._articlesCountMap.set(
                this._articlesCountMapKeyBuilder(keywords, startYear),
                this._articlesCount.value
              );
            } else {
              this._searchedArticlesCount.next(results);
              this._articlesCountMap.set(
                this._articlesCountMapKeyBuilder(keywords, startYear),
                results
              );
            }
            this._loadingService.removeItem(loadingItem);
          },
        });
      }
    } else {
      this._searchedArticlesCount.next(this._articlesCount.value);
      this._articlesCountMap.set(
        this._articlesCountMapKeyBuilder(keywords, startYear),
        this._articlesCount.value
      );
    }
  }

  public getArticlesForAuthor(
    id: string,
    projectId: string,
    filterByDate: boolean
  ): Observable<TArticle[]> {
    const url: string = Endpoints.BUILD_URL(`rise`, {
      base: environment.apiUrl,
      endpoint: 'getArticlesForAuthor',
      params: `?id=${id}&projectId=${projectId}&filterByDate=${filterByDate}`,
    });
    return this._http.get<TArticle[]>(url).pipe(tap((data) => data));
  }

  public getArticlesByIds(ids: string[]): Observable<TArticle[]> {
    const url: string = Endpoints.BUILD_URL(`rise`, {
      base: environment.apiUrl,
      endpoint: 'getArticlesByIDs',
      params: `?ids=${ids.join(`,`)}`,
    });
    return this._http.get<TArticle[]>(url).pipe(tap((data) => data));
  }

  public getArticlesForCenter(id: string): Observable<TArticle[]> {
    const url: string = Endpoints.BUILD_URL(`rise`, {
      base: environment.apiUrl,
      endpoint: 'getArticlesForCenter',
      params: `?id=${id}`,
    });
    return this._http.get<TArticle[]>(url).pipe(tap((data) => data));
  }

  private _articlesCountMapKeyBuilder(
    keywords: string,
    startYear: number
  ): string {
    return `${keywords}-${startYear}`;
  }
}
