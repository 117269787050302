<div *ngIf="hcpData" class="flex gap-4 mt-2 consent-container">
  <app-static-boolean
    [isTrue]="hcpData.opt_out_information_status === optOutStatuses[1].value"
    [isQuestionMark]="
      hcpData.opt_out_information_status === optOutStatuses[0].value
    "
    [name]="'Consent data capture'"
  ></app-static-boolean>
  <mat-form-field appearance="fill">
    <mat-label>Change consent data capture</mat-label>
    <mat-select
      [(value)]="hcpData.opt_out_information_status"
      (selectionChange)="changeOptOutStatus()"
    >
      <mat-option
        *ngFor="let status of optOutStatuses"
        [value]="status.value"
        >{{ status.label }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</div>
<div *ngIf="hcpData?.optOutHash">
  Opt-out consent hashtag: {{ hcpData?.optOutHash }}
</div>
<div *ngIf="hcpData" class="flex gap-4 mt-2 consent-container">
  <app-static-boolean
    [isTrue]="hcpData.opt_in_status === optInStatuses[2].value"
    [isQuestionMark]="hcpData.opt_in_status === optInStatuses[0].value"
    [isUnreadMark]="hcpData.opt_in_status === optInStatuses[1].value"
    [name]="'Consent engagement'"
  ></app-static-boolean>
  <mat-form-field appearance="fill">
    <mat-label>Change consent engagement</mat-label>
    <mat-select
      [(value)]="hcpData.opt_in_status"
      (selectionChange)="changeOptInStatus()"
    >
      <mat-option *ngFor="let status of optInStatuses" [value]="status.value">{{
        status.label
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div *ngIf="hcpData?.optInHash">
  Opt-in consent hashtag: <i>{{ hcpData?.optInHash }}</i>
</div>
