<mat-card>
  <mat-card-title>Pinned projects</mat-card-title>
  <mat-card-content class="mt-2">
    <div class="flex flex-row gap-5 flex-wrap">
      <mat-card *ngFor="let project of projects" class="project-tile">
        <mat-card-title
          class="project-title"
          (click)="goToProject(project.categoryId, project.id)"
          >{{ project.name }}</mat-card-title
        >
        <mat-card-subtitle
          >From category:
          {{ categoriesMap[project.categoryId].name }}</mat-card-subtitle
        >
        <mat-card-content>
          <p>Keywords: {{ project.keywords }}</p>
          <p>Start date: {{ project.startYear }}</p>
        </mat-card-content>
        <mat-card-actions>
          <button
            mat-button
            type="button"
            (click)="handleUnpin(project.categoryId, project.id)"
          >
            Unpin
          </button>
          <button
            mat-button
            type="button"
            (click)="goToProject(project.categoryId, project.id)"
          >
            More
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </mat-card-content>
</mat-card>
