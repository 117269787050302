<div>
    <!-- <h4 class="mat-h4">Proximity score based on scientific publications and clinical trials</h4> -->
    <h3 class="mat-h3">Network</h3>
    <div *ngIf="isNew()">
        <app-note [text]="'Not available for new HCP'"></app-note>
    </div>
    <div *ngIf="!isNew()">
    <div *ngIf="!proximityLoaded">
        <mat-spinner></mat-spinner>
    </div>
    <table *ngIf="proximityLoaded" class="w-full">
        <thead>
            <tr>
                <td>
                    <div>Link</div>
                </td>
                <td>
                    <div>First name</div>
                </td>
                <td>
                    <div>Last name</div>
                </td>
                <td>
                    <div>Count</div>
                </td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let hcp of proximityData">
                <td>
                    <div>
                        <button mat-icon-button (click)="navigateToHCP(hcp.hcpId)">
                            <mat-icon>link</mat-icon>
                        </button>
                    </div>
                </td>
                <td>
                    <div>{{hcp.firstName}}</div>
                </td>
                <td>
                    <div>{{hcp.lastName}}</div>
                </td>
                <td>
                    <div>{{hcp.count}}</div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
</div>