import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { THCP, THCPProjectsData } from '@shared/interfaces/THCP';
import { AutocompleteWrapperComponent } from 'src/app/autocomplete-wrapper/autocomplete-wrapper.component';
import {
  TComponentType,
  THCPParams,
} from 'src/app/interfaces/TComponentParams';
import { HCPService } from 'src/app/services/HCPService';

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss'],
})
export class ProfileDetailsComponent implements OnChanges {
  @Input()
  public countries: string[] = [];
  @Input()
  public hcpData: THCP;
  @Input()
  public params: THCPParams = {
    hcpId: ``,
    categoryId: ``,
    projectId: ``,
    __type: TComponentType.hcp,
  };

  @ViewChild(AutocompleteWrapperComponent)
  private countryAutocomplete: AutocompleteWrapperComponent;

  constructor(private _HCPsService: HCPService) {}

  public ngOnChanges() {
    this.countryAutocomplete?.setValue(this.hcpData.country);
  }

  public onCountryChange(country: string): void {
    this.hcpData.country = country;
  }

  public getProjectData(): THCPProjectsData {
    return this._HCPsService.getProjectData(
      this.hcpData,
      this.params.projectId
    );
  }

  public onSpeakerAndPresenterChange(group: MatButtonToggleGroup): void {
    this.hcpData.speaker_presenter_frequency = Number(group.value);
  }

  public onDigitalInfluencerChange(group: MatButtonToggleGroup): void {
    this.hcpData.digital_influencer = Number(group.value);
  }

  public onLanguagesChipChange(items: string[]): void {
    this.hcpData.languages = items;
  }

  public onInfluencersChipChange(items: string[]): void {
    this.hcpData.influencers = items;
  }
}
