import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { THCP } from '@shared/interfaces/THCP';
import { HCPService } from 'src/app/services/HCPService';
import { TPrioritySettingsData } from '../common-table.component.types';

@Component({
  selector: 'app-priority-settings',
  templateUrl: './priority-settings.component.html',
  styleUrls: ['./priority-settings.component.scss'],
})
export class PrioritySettingsComponent {
  public HCPsToPrioritize: THCP[] = [];
  public alreadyPrioritizedHCPs: THCP[] = [];
  public displayedColumns = [`name`, `email`, `has_priority`];
  public prioritizeAll: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<PrioritySettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TPrioritySettingsData,
    private _hcpService: HCPService
  ) {
    this._setSelectedHCPs();
  }

  private _setSelectedHCPs(): void {
    this.setHCPsToPrioritize();
    this.setAlreadyPrioritizedHCPs();
  }

  private setHCPsToPrioritize(): void {
    this.HCPsToPrioritize = [];
    this.data.selectedRows
      .filter((hcp) => !hcp.has_priority)
      .forEach((hcp) => this.HCPsToPrioritize.push({ ...hcp }));
  }

  private setAlreadyPrioritizedHCPs(): void {
    this.alreadyPrioritizedHCPs = [];
    this.data.selectedRows
      .filter((hcp) => hcp.has_priority)
      .forEach((hcp) => this.alreadyPrioritizedHCPs.push({ ...hcp }));
  }

  onPrioritizeAll(): void {
    if (this.prioritizeAll) {
      this.HCPsToPrioritize.map((hcp) => (hcp.has_priority = true));
    } else {
      this.HCPsToPrioritize.map((hcp) => (hcp.has_priority = false));
    }
  }

  onPriorityChange(): void {
    this.prioritizeAll = this.HCPsToPrioritize.every((hcp) => hcp.has_priority);
  }

  onConfirm(): void {
    this._hcpService.batchUpdate(
      this.HCPsToPrioritize.map((it) => {
        return {
          id: it.id,
          has_priority: it.has_priority,
          priority_timestamp: it.has_priority
            ? new Date().toISOString()
            : undefined,
        };
      })
    );
    this._hcpService.batchUpdate(
      this.alreadyPrioritizedHCPs.map((it) => {
        return {
          id: it.id,
          has_priority: it.has_priority,
          priority_timestamp: it.has_priority ? it.priority_timestamp : null,
        };
      })
    );
    this.data.closeCallback();
    this.dialogRef.close();
  }
}
