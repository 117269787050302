<div class="mb-4">
  <h3 class="mat-h3">Center information</h3>
  <div class="flex gap-5 columns-2">
    <div class="flex flex-col w-full">
      <div class="flex">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Name</mat-label>
          <input
            type="text"
            placeholder="Type at least 3 letters"
            matInput
            [formControl]="centerControl"
            [matAutocomplete]="auto"
            [value]="centerData.c_name"
          />
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option
              *ngFor="let option of filteredOptions | async"
              [value]="option"
            >
              {{ option.c_name }} -
              {{ getScoresByCenter(option) }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <button class="ml-2 flex mt-2" (click)="editCenter()">
          <mat-icon>edit</mat-icon>
        </button>
      </div>

      <mat-form-field appearance="fill">
        <mat-label>City</mat-label>
        <input matInput disabled [(ngModel)]="centerData.c_city" />
      </mat-form-field>
      <div class="flex gap-2">
        <mat-form-field appearance="fill">
          <mat-label>State</mat-label>
          <input matInput disabled [(ngModel)]="centerData.c_state" />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Country</mat-label>
          <input matInput disabled [(ngModel)]="centerData.c_country" />
        </mat-form-field>
      </div>
      <div class="flex gap-2">
        <mat-form-field appearance="fill">
          <mat-label>Zip code</mat-label>
          <input matInput disabled [(ngModel)]="centerData.c_zip" />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Street</mat-label>
          <input matInput disabled [(ngModel)]="centerData.c_address" />
        </mat-form-field>
      </div>
      <div class="flex w-40 items-end">
        <app-static-boolean
          [isTrue]="getProjectData().c_proj_treatment_center"
          [name]="'Treatment center'"
        ></app-static-boolean>
      </div>
    </div>
  </div>
  <app-consent-data-capture [hcpData]="hcpData"></app-consent-data-capture>
</div>
