import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ImportArea, SearchArea } from '@shared/enums/SearchArea';
import { TCategory } from '@shared/interfaces/TCategory';
import { TCenter } from '@shared/interfaces/TCenter';
import { THCP } from '@shared/interfaces/THCP';
import { TProject } from '@shared/interfaces/TProject';

import { isNullOrEmpty } from '@shared/utils/isNullOrEmpty';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { ROUTES_PATHS } from '../app-routing.module';

import { MatDialog } from '@angular/material/dialog';
import { CSVUtils } from 'src/utils/csvUtils';
import { ImportComponent, TImportData } from '../import/import.component';
import { TReportParams } from '../reports-review/reports-review.component';
import { ArticlesService } from '../services/ArticlesService';
import { CacheService, TCTCacheItem } from '../services/CacheService';
import { CategoriesService } from '../services/CategoriesService';
import { CentersService } from '../services/CentersService';
import { ClinicalTrialsService } from '../services/ClinicalTrialsService';
import {
  DataProcessorService,
  TProcessDataStatus,
  getDefaultStatus,
} from '../services/DataProcessorService';
import { HCPService } from '../services/HCPService';
import { LoadingService } from '../services/LoadingService';
import { RegionsService } from '../services/RegionsService';
import { RiseAppApiService } from '../services/RiseAppApiService';
import { SearchService } from '../services/SearchService';
import { TProjectSettingsParams } from './project-settings/project-settings.component';
export interface TProjectReviewParams {
  projectId: string;
  categoryId: string; //TODO remove
  articlesVisible?: boolean;
  hcpsVisible?: boolean;
  centersVisible?: boolean;
  settingsVisible?: boolean;
  studiesVisible?: boolean;
}

export function projectReviewParamsValidator(
  params: any
): TProjectReviewParams {
  return {
    projectId: params?.projectId ?? ``,
    categoryId: params?.categoryId ?? ``,
    articlesVisible: params?.articlesVisible === `true` ?? ``,
    hcpsVisible: params?.hcpsVisible === `true` ?? false,
    centersVisible: params?.centersVisible === `true` ?? false,
    settingsVisible: params?.settingsVisible ?? false,
    studiesVisible: params?.studiesVisible === `true` ?? false,
  };
}

type ReviewButtonType = `articles` | `hcps` | `centers` | `studies`;

@Component({
  selector: 'app-project-review',
  templateUrl: './project-review.component.html',
  styleUrls: ['./project-review.component.scss'],
})
export class ProjectReviewComponent implements OnInit, OnDestroy {
  public loadingTime: string = ``;
  public selectedCategory: TCategory | undefined;
  public selectedProject: TProject | undefined;
  public articlesVisible: boolean = false;
  public studiesVisible: boolean = false;
  public hcpsVisible: boolean = false;
  public centersVisible: boolean = false;
  public processDataInfo: TProcessDataStatus = getDefaultStatus();
  public reprocess: boolean = false;
  public hcpsAfterSearch: number = NaN;
  public centersAfterSearch: number = NaN;
  public articlesAfterSearch: number = NaN;
  public numberOfArticles: number = 0;
  public invalidIdsCount: number = 0;
  public studiesIds: string[] = [];

  public minProjectYear: number = 0;
  public maxProjectYear: number = 10;
  public selectedMinProjectYear: number = 0;
  public selectedMaxProjectYear: number = 10;

  public studiesCountLoading: boolean = true;
  public articlesCountLoading: boolean = true;
  public centersCountLoading: boolean = true;
  public hcpsCountLoading: boolean = true;

  @Input()
  public settingsVisible: boolean = false;
  @Input()
  public isPinned: boolean = true;

  private _subs: Subscription[] = [];
  private _projectReviewParams: TProjectReviewParams = {
    categoryId: ``,
    projectId: ``,
    articlesVisible: false,
    centersVisible: false,
    hcpsVisible: false,
  };

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _categoriesService: CategoriesService,
    private _dataProcessorService: DataProcessorService,
    private _loadingService: LoadingService,
    private _regionsService: RegionsService,
    private _HCPsService: HCPService,
    private _centerService: CentersService,
    private _articlesService: ArticlesService,
    private _riseApi: RiseAppApiService,
    private _searchService: SearchService,
    private _zone: NgZone,
    private _clinicalTrialsService: ClinicalTrialsService,
    private _cacheService: CacheService,
    public importSalesDataDialog: MatDialog
  ) {
    this._subs.push(
      this._route.params.subscribe(async (params: any) => {
        this.settingsVisible = Boolean(params.settingsVisible);
        this._projectReviewParams.articlesVisible =
          params.articlesVisible === `true`;
        this._projectReviewParams.categoryId = params.categoryId;
        this._projectReviewParams.centersVisible =
          params.centersVisible === `true`;
        this._projectReviewParams.hcpsVisible = params.hcpsVisible === `true`;
        this._projectReviewParams.settingsVisible =
          params.settingsVisible === `true`;
        this._projectReviewParams.studiesVisible =
          params.studiesVisible === `true`;
        this._projectReviewParams.projectId = params.projectId;
        await this._setProjectInfo();
      })
    );
    this._subs.push(
      this._articlesService
        .getSearchedArticlesObservable()
        .subscribe((articlesCount) => {
          this.numberOfArticles = articlesCount;
          this.invalidIdsCount =
            articlesCount - (this.selectedProject?.articlesCount ?? 0);
        })
    );
    this._subs.push(
      this._regionsService.getSelectedCountriesObservable().subscribe(() => {
        if (this.selectedProject !== undefined) {
          this._setCountsData();
        }
      })
    );

    this._riseApi.setMigrateHCPs(() => {
      this._HCPsService.migrateHCPs();
    });
    this._riseApi.setBatchUpdateHCPs((data: THCP[]) => {
      this._HCPsService.batchUpdate(data);
    });
    this._riseApi.setBatchUpdateCenters((data: TCenter[]) => {
      this._centerService.batchUpdate(data);
    });
    this._riseApi.setMigrateCenters(() => {
      this._centerService.migrateCenters();
    });
    this._riseApi.setNormalizationIndex(() => {
      this._HCPsService.setNormalizationIndex(
        this._projectReviewParams.projectId
      );
    });
  }

  public openReports(): void {
    const reportsParams: TReportParams = {
      data: ``,
      projectId: this.selectedProject?.id ?? ``,
    };
    this._router.navigate([`/${ROUTES_PATHS.report}`, reportsParams], {
      relativeTo: this._route,
    });
  }

  public centersAfterSearchVisible(): boolean {
    return (
      this.selectedProject?.centersCount !== this.centersAfterSearch &&
      !isNaN(this.centersAfterSearch)
    );
  }

  public HCPsAfterSearchVisible(): boolean {
    return (
      this.selectedProject?.hcpsCount !== this.hcpsAfterSearch &&
      !isNaN(this.hcpsAfterSearch)
    );
  }

  public articlesAfterSearchVisible(): boolean {
    return (
      this.selectedProject?.articlesCount !== this.articlesAfterSearch &&
      !isNaN(this.articlesAfterSearch)
    );
  }

  //TODO refresh list
  public onImportFinish(): void {
    this.centersVisible = false;
    this.hcpsVisible = false;
  }

  public async processData(): Promise<void> {
    if (
      this.selectedProject !== undefined &&
      this.selectedCategory !== undefined
    ) {
      this._loadingService.setLogVisible(true);
      this._dataProcessorService.processData(
        this.selectedCategory.categoryId,
        this.selectedProject.id
      );
    }
  }

  public buildSettingsParams(): TProjectSettingsParams {
    return {
      closeSettings: () => {
        const projectReviewParams: TProjectReviewParams = {
          projectId: this.selectedProject?.id ?? ``,
          categoryId: this.selectedCategory?.categoryId ?? ``,
          hcpsVisible: this.hcpsVisible,
          centersVisible: this.centersVisible,
        };
        this._router.navigate([
          `/${ROUTES_PATHS.project}`,
          projectReviewParams,
        ]);
      },
      selectedCategory: this.selectedCategory,
      selectedProject: this.selectedProject,
      isPinned: this.isPinned,
    };
  }

  public reviewButtonAvailable(type: ReviewButtonType): boolean {
    if (this.selectedProject !== undefined) {
      switch (type) {
        case `articles`:
          return this.selectedProject.articlesCount > 0;
        case `centers`:
          return this.selectedProject.centersCount > 0;
        case `hcps`:
          return this.selectedProject.hcpsCount > 0;
        case `studies`:
          return true; //TODO handle
      }
    }
    return false;
  }

  public showStudies(): void {
    const projectReviewParams: TProjectReviewParams = {
      projectId: this.selectedProject!.id,
      categoryId: this.selectedCategory!.categoryId,
      settingsVisible: false,
      articlesVisible: false,
      centersVisible: false,
      hcpsVisible: false,
      studiesVisible: true,
    };
    this._router.navigate([`/${ROUTES_PATHS.project}`, projectReviewParams]);
  }

  public showArticles(): void {
    const projectReviewParams: TProjectReviewParams = {
      projectId: this.selectedProject!.id,
      categoryId: this.selectedCategory!.categoryId,
      settingsVisible: false,
      articlesVisible: true,
      centersVisible: false,
      hcpsVisible: false,
      studiesVisible: false,
    };
    this._router.navigate([`/${ROUTES_PATHS.project}`, projectReviewParams]);
  }

  public showHCPs(): void {
    const projectReviewParams: TProjectReviewParams = {
      projectId: this.selectedProject!.id,
      categoryId: this.selectedCategory!.categoryId,
      settingsVisible: false,
      articlesVisible: false,
      centersVisible: false,
      hcpsVisible: true,
      studiesVisible: false,
    };
    this._router.navigate([`/${ROUTES_PATHS.project}`, projectReviewParams]);
  }

  public showCenters(): void {
    const projectReviewParams: TProjectReviewParams = {
      projectId: this.selectedProject!.id,
      categoryId: this.selectedCategory!.categoryId,
      settingsVisible: false,
      articlesVisible: false,
      centersVisible: true,
      hcpsVisible: false,
      studiesVisible: false,
    };
    this._router.navigate([`/${ROUTES_PATHS.project}`, projectReviewParams]);
  }

  public async ngOnInit(): Promise<void> {
    this._subs.push(
      this._categoriesService
        .getCategoriesObservable()
        .subscribe(async (categories: TCategory[]) => {
          await this._setProjectInfo(categories);
        })
    );
  }

  public ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
  }

  public openProjectSettings(): void {
    const projectReviewParams: TProjectReviewParams = {
      projectId: this.selectedProject!.id,
      categoryId: this.selectedCategory!.categoryId,
      settingsVisible: true,
      articlesVisible: this.articlesVisible,
      centersVisible: this.centersVisible,
      hcpsVisible: this.hcpsVisible,
      studiesVisible: this.studiesVisible,
    };
    this._router.navigate([`/${ROUTES_PATHS.project}`, projectReviewParams]);
  }

  public isStudiesButtonActive(): string {
    return this.studiesVisible === true ? `button--active` : ``;
  }

  public isArticlesButtonActive(): string {
    return this.articlesVisible === true ? `button--active` : ``;
  }

  public isCenterButtonActive(): string {
    return this.centersVisible === true ? `button--active` : ``;
  }

  public isHCPsButtonActive(): string {
    return this.hcpsVisible === true ? `button--active` : ``;
  }

  public importSalesDataCallback(e: Event): void {
    const target: HTMLInputElement = <HTMLInputElement>e.target;
    if (!isNullOrUndefined(target.files)) {
      const reader: FileReader = new FileReader();
      reader.onload = (event) => {
        const results = event?.target?.result ?? ``;
        if (!isNullOrUndefined(results) && typeof results === `string`) {
          const csvOutput = CSVUtils.csvToArray(results);
          this._openImportSalesDataDialog(csvOutput.headers, e);
        }
      };
      reader.readAsText(target.files[0]);
    }
  }

  private _openImportSalesDataDialog(headers: string[], e: Event): void {
    this.importSalesDataDialog.open<ImportComponent, TImportData>(
      ImportComponent,
      {
        width: '80%',
        data: {
          headers: headers,
          projectId: this.selectedProject!.id,
          area: ImportArea.ProductSales,
          e,
          importCallback: (e, mappings) => this.importProductSales(e, mappings),
        },
      }
    );
  }

  private importProductSales(e: Event, mappings: any): void {
    if (
      this.selectedCategory !== undefined &&
      this.selectedProject !== undefined
    ) {
      this._centerService.importCentersProductsSales(
        e,
        () => this.onImportFinish(),
        this.selectedProject.id,
        mappings
      );
    }
  }

  private _setCountsData(): void {
    if (this.selectedProject !== undefined) {
      if (!isNullOrEmpty(this.selectedProject.id)) {
        this._zone.run(() => {
          this._searchService
            .getSearchResults(this.selectedProject!.id)
            .subscribe((res) => {
              this.articlesCountLoading = false;
              this.centersCountLoading = false;
              this.hcpsCountLoading = false;
              this.articlesAfterSearch =
                res.find((it) => it.area === SearchArea.Articles)?.total ?? 0;
              this.centersAfterSearch =
                res.find((it) => it.area === SearchArea.Centers)?.total ?? 0;
              this.hcpsAfterSearch =
                res.find((it) => it.area === SearchArea.HCP)?.total ?? 0;
            });
        });
      }
    }
  }

  private async _setProjectInfo(
    updateCategories: TCategory[] = []
  ): Promise<void> {
    this.studiesCountLoading = true;
    this.articlesCountLoading = true;
    this.centersCountLoading = true;
    this.hcpsCountLoading = true;
    const categories: TCategory[] =
      updateCategories.length > 0
        ? updateCategories
        : this._categoriesService.getCategories();
    const category: TCategory | undefined = categories.find(
      (category) => category.categoryId === this._projectReviewParams.categoryId
    );
    if (category !== undefined) {
      this.selectedCategory = category;
      let project: TProject | undefined;
      project = category.projects.find(
        (p) => p.id === this._projectReviewParams.projectId
      );
      this._categoriesService.setSelectedProject(
        this._projectReviewParams.projectId
      );
      if (project !== undefined) {
        const currentYear: number = new Date().getFullYear();
        if (isNullOrUndefined(project.startYear)) {
          this.minProjectYear = currentYear;
          this.selectedMinProjectYear = currentYear;
        } else {
          const startYear: number = isNaN(Number(project.startYear))
            ? currentYear
            : Number(project.startYear);
          this.minProjectYear = startYear;
          this.selectedMinProjectYear = startYear;
        }
        this.selectedProject = project;
        this.isPinned = project.isPinned;
        this.centersVisible = this._projectReviewParams.centersVisible ?? false;
        this.hcpsVisible = this._projectReviewParams.hcpsVisible ?? false;
        this.articlesVisible =
          this._projectReviewParams.articlesVisible ?? false;
        this.settingsVisible =
          this._projectReviewParams.settingsVisible ?? false;
        this._articlesService.setSearchedArticlesCount(
          this.selectedProject.keywords,
          this.selectedProject.selectedMinYear
        );
        this.studiesVisible = this._projectReviewParams.studiesVisible ?? false;
        this._setCountsData();
        this._subs.push(
          this._HCPsService.getCTForProject(project.id).subscribe((res) => {
            this.studiesIds = res.studies;
            this.studiesCountLoading = false;
            const dataFromCache: TCTCacheItem | undefined =
              this._cacheService.getCTItem(project!.id);
            if (isNullOrUndefined(dataFromCache)) {
              this._cacheService.setCTItem(project!.id, res.studies, []);
            }
            if (this.studiesVisible) {
              this._clinicalTrialsService.setClinicalTrialsByIDs(
                this.studiesIds,
                this.selectedProject?.id ?? ``,
                (response) => {
                  if (
                    isNullOrUndefined(dataFromCache) ||
                    dataFromCache.data.length === 0
                  ) {
                    this._cacheService.setCTItem(
                      project!.id,
                      res.studies,
                      response
                    );
                  }
                }
              );
            }
          })
        );
      }
    }
  }
}
