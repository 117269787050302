<div class="close-dialog-container">
  <button mat-dialog-close mat-icon-button aria-label="close">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="hcps-priority">
  <h3 class="mat-h3">HCPs available to prioritize</h3>
  <div
    class="mt-4"
    *ngIf="HCPsToPrioritize.length > 0"
    class="hcps-priority__selection"
  >
    <mat-slide-toggle [(ngModel)]="prioritizeAll" (change)="onPrioritizeAll()"
      >Prioritize all</mat-slide-toggle
    >
    <div class="hcps-priority__selection__items">
      <table mat-table [dataSource]="HCPsToPrioritize" class="mat-elevation-z8">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let hcp">
            {{ hcp.first_name }} {{ hcp.last_name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>E-mail</th>
          <td mat-cell *matCellDef="let hcp">
            {{ hcp.primary_email_address }}
          </td>
        </ng-container>
        <ng-container matColumnDef="has_priority">
          <th mat-header-cell *matHeaderCellDef>Has priority</th>
          <td
            mat-cell
            *matCellDef="let hcp"
            style="height: 26px; min-height: 26px"
          >
            <mat-slide-toggle
              (change)="onPriorityChange()"
              [(ngModel)]="hcp.has_priority"
            ></mat-slide-toggle>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="mt-4 mb-2" *ngIf="HCPsToPrioritize.length === 0">
    No available HCPs to prioritize
  </div>

  <h3 *ngIf="alreadyPrioritizedHCPs.length > 0" class="mat-h3">
    HCPs already prioritized
  </h3>
  <div
    class="mt-4"
    *ngIf="alreadyPrioritizedHCPs.length > 0"
    class="hcps-priority__selection"
  >
    <div class="hcps-priority__selection__items">
      <table
        mat-table
        [dataSource]="alreadyPrioritizedHCPs"
        class="mat-elevation-z8"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let hcp">
            {{ hcp.first_name }} {{ hcp.last_name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>E-mail</th>
          <td mat-cell *matCellDef="let hcp">
            {{ hcp.primary_email_address }}
          </td>
        </ng-container>
        <ng-container matColumnDef="has_priority">
          <th mat-header-cell *matHeaderCellDef>Has priority</th>
          <td
            mat-cell
            *matCellDef="let hcp"
            style="height: 26px; min-height: 26px"
          >
            <mat-slide-toggle [(ngModel)]="hcp.has_priority"></mat-slide-toggle>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>
<div mat-dialog-actions class="bottom-container">
  <button mat-flat-button color="primary" (click)="onConfirm()">Save</button>
</div>
