<div class="settings-modal-content">
    <h2 class="my-4 mat-h2">Filters</h2>
    <div>
        <h3 class="mat-h4">HCP filters</h3>
        <mat-form-field appearance="fill" *ngIf="availableHCPFilters.length > 0">
            <mat-label>Add new HCP filter</mat-label>
            <mat-select required name="Fields" (valueChange)="addNewHCPFilter($event)">
                <mat-option *ngFor="let field of availableHCPFilters" [value]="field.value">
                    {{ field.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="getHCPsFilters().length > 0">
            <app-filter-item *ngFor="let item of getHCPsFilters()" [filterItem]="item"
                [fieldName]="getHCPFieldName(item.property)"
                [deleteFilter]="deleteHCPItem.bind(this)"></app-filter-item>
        </div>
    </div>
    <div>
        <h3 class="mat-h4">Center filters</h3>
        <mat-form-field appearance="fill" *ngIf="availableCentersFilters.length > 0">
            <mat-label>Add new Center filter</mat-label>
            <mat-select required name="Fields" (valueChange)="addNewCenterFilter($event)">
                <mat-option *ngFor="let field of availableCentersFilters" [value]="field.value">
                    {{ field.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="getCentersFilters().length > 0">
            <app-filter-item *ngFor="let item of getCentersFilters()" [filterItem]="item"
                [fieldName]="getCenterFieldName(item.property)"
                [deleteFilter]="deleteCenterItem.bind(this)"></app-filter-item>
        </div>
    </div>
</div>
