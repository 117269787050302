<div class="flex gap-4">
    <mat-form-field>
        <mat-label>Field</mat-label>
        <input disabled matInput [value]="fieldName" />
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Operator</mat-label>
        <mat-select required name="Fields" [(ngModel)]="filterItem.operator">
            <mat-option *ngFor="let operator of availableOperators" [value]="operator.filter">
                {{ operator.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Value</mat-label>
        <input matInput [(ngModel)]="filterItem.value" />
    </mat-form-field>
    <button mat-icon-button (click)="deleteFilter(filterItem)" aria-label="Delete" color="error">
        <mat-icon>delete_forever</mat-icon>
    </button>
</div>