<td>
  <span class="project">{{ project }}</span>
</td>
<td class="date">
  <input
    type="date"
    [ngModel]="engagementPlanItem.engagement_plan_time | date : 'yyyy-MM-dd'"
    (ngModelChange)="engagementPlanItem.engagement_plan_time = $event"
    (change)="onDateChange($event)"
  />
</td>
<td class="description">
  <textarea
    cdkTextareaAutosize
    placeholder="Objective"
    [(ngModel)]="engagementPlanItem.engagement_plan_objectives"
  ></textarea>
</td>
<td class="quantity"><div class="quantity"></div></td>
<td class="tags">
  <mat-select
    class="tags tag-wrapper"
    multiple
    (selectionChange)="handleTagsChangeChange($event)"
    [(ngModel)]="selectedItems"
  >
    <mat-option
      *ngFor="let membership of getAvailableTags()"
      [value]="membership.tag"
    >
      {{ membership.tag }}
    </mat-option>
  </mat-select>
</td>
<td class="person">
  <input class="person" [(ngModel)]="engagementPlanItem.engagement_plan_accountable" />
</td>
<td>
  <button color="error" class="mr-4 delete-button" mat-icon-button (click)="deleteCallback(engagementPlanItem)" matTooltip="Delete item">
    <mat-icon>delete</mat-icon>
  </button>
</td>
