<mat-dialog-content>
  <h1 class="mb-5">{{ data.name }}</h1>
  <pdf-viewer
    [src]="data.url"
    [rotation]="0"
    [original-size]="false"
    [show-all]="true"
    [fit-to-page]="true"
    [zoom]="0.9"
    [zoom-scale]="'page-width'"
    [stick-to-page]="false"
    [render-text]="true"
    [external-link-target]="'blank'"
    [autoresize]="true"
    [show-borders]="false"
    style="width: 100%; height: 50vh"
  ></pdf-viewer>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="downloadPdf()">Download</button>
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
