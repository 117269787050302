import { Component, Input } from '@angular/core';
import { buildHCPMock, THCP, THCPProjectsData } from '@shared/interfaces/THCP';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import {
  TComponentType,
  THCPParams,
} from 'src/app/interfaces/TComponentParams';
import { HCPService } from 'src/app/services/HCPService';
import { LoadingService } from 'src/app/services/LoadingService';

export interface TSpeciality {
  name: string;
}
@Component({
  selector: 'app-key-information',
  templateUrl: './key-information.component.html',
  styleUrls: ['./key-information.component.scss'],
  providers: [LoadingService, HCPService],
})
export class KeyInformationComponent {
  @Input()
  public hcpData: THCP = {
    ...buildHCPMock({}),
  };
  @Input()
  public params: THCPParams = {
    hcpId: ``,
    categoryId: ``,
    projectId: ``,
    __type: TComponentType.hcp,
  };

  constructor(
    private _loadingService: LoadingService,
    private _HCPsService: HCPService
  ) {}

  public getProjectData(): THCPProjectsData {
    return this._HCPsService.getProjectData(
      this.hcpData,
      this.params.projectId
    );
  }

  public isEmailValid(email: string): boolean {
    const re: RegExp = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  public onChipChange(items: string[]): void {
    this.hcpData.specialties = items;
  }

  public clearProfileImage(): void {
    this.hcpData.profile_image = ``;
  }

  public uploadProfileImage(e: Event): void {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    const files: FileList | null = target.files;
    const profileImageFile: File | undefined = isNullOrUndefined(files)
      ? undefined
      : files[0];
    if (!isNullOrUndefined(profileImageFile) && profileImageFile?.size === 0) {
      this._loadingService.triggerErrorMessage(`Invalid file`);
    } else if (profileImageFile!.size > 1000000) {
      this._loadingService.triggerErrorMessage(`File to large (max 1 MB)`);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(files![0]);
      reader.addEventListener(`loadend`, () => {
        this.hcpData.profile_image = reader.result?.toString() ?? ``;
      });
    }
  }
}
