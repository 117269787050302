<h3 class="mat-h3">Value</h3>
<div class="score-view-wrapper">
  <div class="flex columns-2 gap-10">
    <div class="flex flex-col w-1/2">
      <mat-form-field appearance="fill">
        <mat-label>Promoter Score Global</mat-label>
        <mat-select [(value)]="getProjectData().hcp_proj_promoter_score_global">
          <mat-option
            *ngFor="let tier of getTiers('hcp_proj_promoter_score_global')"
            [value]="tier.name"
            >{{ tier.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Promoter Score Local</mat-label>
        <mat-select [(value)]="getProjectData().hcp_proj_promoter_score_local">
          <mat-option
            *ngFor="let tier of getTiers('hcp_proj_promoter_score_local')"
            [value]="tier.name"
            >{{ tier.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Potential to influence peers</mat-label>
        <mat-select
          [(value)]="getProjectData().hcp_proj_potential_to_influence_peers"
        >
          <mat-option
            *ngFor="
              let tier of getTiers('hcp_proj_potential_to_influence_peers')
            "
            [value]="tier.name"
            >{{ tier.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div
      class="flex flex-col w-1/2"
      matTooltip="Values from {{ centerData.c_name }} data"
      [matTooltipPosition]="'above'"
    >
      <app-tier-component
        [projectId]="params.projectId"
        [centerData]="centerData"
        [fieldsDisabled]="true"
      ></app-tier-component>
    </div>
  </div>
  <app-score-view
    [score]="valueScore"
    [projectId]="params.projectId"
    [title]="'Value Score'"
    [area]="'hcpValue'"
  ></app-score-view>
  <!-- TODO: this is w workaround, should be done in score view component -->
  <div class="product-score-wrapper">
    <app-score-view
      [score]="productUseScore"
      [projectId]="params.projectId"
      [title]="'Product Use Score'"
      [area]="'hcpProductUse'"
    ></app-score-view>
  </div>
</div>
