import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TRegion } from '../services/RegionsService';

@Component({
  selector: 'app-region-select',
  templateUrl: './region-select.component.html',
  styleUrls: ['./region-select.component.scss'],
})
export class RegionSelectComponent {
  @Input()
  public regions: TRegion[] = [];
  @Input()
  public regionsFiltered: TRegion[] = [];
  @Input()
  public selectedRegions = new FormControl<TRegion[]>([]);
  @Input()
  public handleRegionClick: (region: TRegion) => void = (region: TRegion) => {
    //
  };
  regionFilterInput = new FormControl('');

  constructor() {}

  public onRegionFilter(event: Event): void {
    const searchValue: string = (<HTMLInputElement>(
      event.target
    )).value.toLowerCase();
    this.regionsFiltered = this.regions.filter((option) =>
      option.value.toLowerCase().startsWith(searchValue)
    );
  }

  public compareRegions(r1: TRegion, r2: TRegion): boolean {
    return r1 && r2 ? r1.value === r2.value : r1 === r2;
  }

  protected resetRegionFilter() {
    this.regionFilterInput.patchValue('');
  }

  public hiddenClass(region: TRegion): { hidden: boolean } {
    return {
      hidden: !region.viewValue
        .toLowerCase()
        .includes(this.regionFilterInput.value?.toLowerCase() ?? ''),
    };
  }
}
