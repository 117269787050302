import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FilterOperator,
  NoScoreReport,
  TReportType,
  TTagItem,
  filterOperatorsMap,
} from '@shared/interfaces/TProject';
import { DateUtils } from '@shared/utils/dateUtils';
import { newGuid } from '@shared/utils/newGuild';
import { TReportDataFilter } from '../report-filters/report-filters.component';

export interface TReportCustomMetricsParams {
  selectedReport: TReportType | NoScoreReport | null;
  filters: TReportDataFilter[];
  projectCustomMetrics: TTagItem[];
  projectId: string;
}

interface TFilterOperatorNamingItem {
  filter: FilterOperator;
  name: string;
}

@Component({
  selector: 'app-report-custom-metric',
  templateUrl: './report-custom-metric.component.html',
  styleUrls: ['./report-custom-metric.component.scss'],
})
export class ReportCustomMetricComponent {
  public availableOperators: TFilterOperatorNamingItem[] = Array.from(
    filterOperatorsMap.entries()
  ).map((entry) => {
    return {
      filter: entry[0],
      name: entry[1],
    };
  });
  public customMetricOptions = [
    { name: 'year', value: 'year' },
    { name: 'quantity', value: 'value' },
  ];

  public yearOptions = DateUtils.yearsArray(2010, 'desc').map((year) =>
    year.toString()
  );

  constructor(
    public dialogRef: MatDialogRef<ReportCustomMetricComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TReportCustomMetricsParams
  ) {}

  // change filter to use custom metrics with year and value
  public addNewFilter(value: string): void {
    const newFilter: TReportDataFilter = {
      operator: FilterOperator.Equal,
      property: value,
      value: ``,
      id: newGuid(),
      type: 'regular',
      date: new Date(),
      tags: [],
      metric: 'value',
    };
    this.data.filters.push(newFilter);
  }

  public deleteFilter(item: TReportDataFilter): void {
    const itemIndex: number = this.data.filters.findIndex(
      (it) => it.property === item.property
    );
    if (itemIndex > -1) {
      this.data.filters.splice(itemIndex, 1);
    }
  }
}
