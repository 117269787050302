export enum EventMessageStatus {
  OK,
  Error,
  Started,
  Finished,
  Info,
  External
}

export enum EventMessageSource {
  Server,
  Frontend
}

export interface TBaseEventMessage {
  message: string;
  status: EventMessageStatus;
  source: EventMessageSource;
  //TODO move date here
}

export interface TEventMessageResponse extends TBaseEventMessage {
  guid: string;
}
