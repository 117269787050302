import { CENTER_DB_MAPPINGS } from '@shared/db-fields-mappings/CENTER_DB_MAPPINGS';
import { HCP_DB_MAPPINGS } from '@shared/db-fields-mappings/HCP_DB_MAPPINGS';
import { TCenter } from '@shared/interfaces/TCenter';
import {
  PATH_DELIMITER,
  TDBFieldMappingItem,
} from '@shared/interfaces/TDBFieldMappingItem';
import { THCP } from '@shared/interfaces/THCP';
import { isNullOrEmpty } from '@shared/utils/isNullOrEmpty';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { getUnique } from '../../../../../server/src/shared/utils/getUnique';

export class TableDataExtender {
  public static HCPTableDataExtender(hcp: THCP, projectId: string): any {
    const nestedFields: TDBFieldMappingItem[] = Array.from(
      HCP_DB_MAPPINGS.values()
    ).filter((it: TDBFieldMappingItem) => {
      return !isNullOrEmpty(it.path);
    });
    const extendedHCPprops: any = {};
    nestedFields.forEach((it) => {
      const path = it.path!.split(PATH_DELIMITER);
      let tempObject: any = structuredClone(hcp);
      path.reduce((_: any, key: string, index: number) => {
        if (Array.isArray(tempObject[key])) {
          const tempItem = (tempObject[key] as any[]).find(
            (item) => item.projectId === projectId
          );
          if (!isNullOrUndefined(tempItem)) {
            tempObject = tempItem;
          }
        } else {
          tempObject = tempObject[key];
        }
        if (index === path.length - 1) {
          extendedHCPprops[key] = tempObject;
        }
        return;
      }, undefined);
    });
    const allPublications: string[] = getUnique(
      hcp.projectsData.map((it) => it.history_publications).flat()
    ).filter((it) => !isNullOrUndefined(it));
    extendedHCPprops.publications_total = allPublications.length;
    return extendedHCPprops;
  }

  public static CenterTableDataExtender(
    center: TCenter,
    projectId: string
  ): any {
    const nestedFields: TDBFieldMappingItem[] = Array.from(
      CENTER_DB_MAPPINGS.values()
    ).filter((it: TDBFieldMappingItem) => {
      return !isNullOrEmpty(it.path);
    });
    const extendedCenterProps: any = {};
    nestedFields.forEach((it) => {
      const path = it.path!.split(PATH_DELIMITER);
      let tempObject: any = structuredClone(center);
      path.reduce((_: any, key: string, index: number) => {
        if (Array.isArray(tempObject[key])) {
          const tempItem = (tempObject[key] as any[]).find(
            (item) => item.projectId === projectId
          );
          if (!isNullOrUndefined(tempItem)) {
            tempObject = tempItem;
          }
        } else {
          tempObject = tempObject[key];
        }
        if (index === path.length - 1) {
          extendedCenterProps[key] = tempObject;
        }
        return;
      }, undefined);
    });
    return extendedCenterProps;
  }
}
