import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CENTER_DB_MAPPINGS } from '@shared/db-fields-mappings/CENTER_DB_MAPPINGS';
import { HCP_DB_MAPPINGS } from '@shared/db-fields-mappings/HCP_DB_MAPPINGS';
import { FilterOperator, TDataFilter } from '@shared/interfaces/TProject';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { TBaseSettingsTabParams } from '../project-settings.component';

export interface TSelectFilterItem {
  name: string;
  value: string;
}

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent {
  public params: TBaseSettingsTabParams;
  public availableHCPFilters: TSelectFilterItem[] = [
    ...Array.from(HCP_DB_MAPPINGS.values()).filter(
      (f) => f.isFilterField === true
    ),
  ].map((f) => {
    return {
      name: f.name,
      value: f.fieldId,
    };
  });
  public availableCentersFilters: TSelectFilterItem[] = [
    ...Array.from(CENTER_DB_MAPPINGS.values()).filter(
      (f) => f.isFilterField === true
    ),
  ].map((f) => {
    return {
      name: f.name,
      value: f.fieldId,
    };
  });

  constructor(
    public dialogRef: MatDialogRef<FiltersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TBaseSettingsTabParams
  ) {
    this.params = data;
    this.filterAvailableHCPFields();
    this.filterAvailableCenterFields();
  }

  public getCentersFilters(): TDataFilter[] {
    return this.params.selectedProject?.centerFilter ?? [];
  }

  public getHCPsFilters(): TDataFilter[] {
    return this.params.selectedProject?.hcpFilter ?? [];
  }

  public getHCPFieldName(id: string): string {
    return (
      [...Array.from(HCP_DB_MAPPINGS.values())].find((it) => it.fieldId === id)
        ?.name ?? ``
    );
  }

  public getCenterFieldName(id: string): string {
    return (
      [...Array.from(CENTER_DB_MAPPINGS.values())].find(
        (it) => it.fieldId === id
      )?.name ?? ``
    );
  }

  public addNewCenterFilter(value: string): void {
    const newFilter: TDataFilter = {
      operator: FilterOperator.Less,
      property: value,
      value: 0,
      type: 'regular',
    };
    if (
      !isNullOrUndefined(this.params.selectedProject) &&
      isNullOrUndefined(this.params.selectedProject.centerFilter)
    ) {
      this.params.selectedProject.centerFilter = [newFilter];
    } else if (!isNullOrUndefined(this.params.selectedProject)) {
      this.params.selectedProject.centerFilter.push(newFilter);
    }
    this.filterAvailableCenterFields();
  }

  public addNewHCPFilter(value: string): void {
    const type =
      HCP_DB_MAPPINGS.get(value)?.engagement === true
        ? `engagement`
        : `regular`;
    const newFilter: TDataFilter = {
      operator: FilterOperator.Less,
      property: value,
      value: 0,
      type: type,
    };
    if (
      !isNullOrUndefined(this.params.selectedProject) &&
      isNullOrUndefined(this.params.selectedProject.hcpFilter)
    ) {
      this.params.selectedProject.hcpFilter = [newFilter];
    } else if (!isNullOrUndefined(this.params.selectedProject)) {
      this.params.selectedProject.hcpFilter.push(newFilter);
    }
    this.filterAvailableHCPFields();
  }

  public deleteCenterItem(item: TDataFilter): void {
    if (
      !isNullOrUndefined(this.params.selectedProject) &&
      !isNullOrUndefined(this.params.selectedProject.centerFilter)
    ) {
      const itemIndex: number =
        this.params.selectedProject.centerFilter.findIndex(
          (it) => it.property === item.property
        );
      if (itemIndex > -1) {
        this.params.selectedProject.centerFilter.splice(itemIndex, 1);
        this.availableCentersFilters.push({
          name: this.getHCPFieldName(item.property),
          value: item.property,
        });
      }
    }
  }

  public deleteHCPItem(item: TDataFilter): void {
    if (
      !isNullOrUndefined(this.params.selectedProject) &&
      !isNullOrUndefined(this.params.selectedProject.hcpFilter)
    ) {
      const itemIndex: number = this.params.selectedProject.hcpFilter.findIndex(
        (it) => it.property === item.property
      );
      if (itemIndex > -1) {
        this.params.selectedProject.hcpFilter.splice(itemIndex, 1);
        this.availableHCPFilters.push({
          name: this.getHCPFieldName(item.property),
          value: item.property,
        });
      }
    }
  }

  private filterAvailableHCPFields(): void {
    const selectedFilterFields: string[] = (
      this.params.selectedProject?.hcpFilter ?? []
    ).map((it) => it.property);
    this.availableHCPFilters = this.availableHCPFilters.filter(
      (it) => !selectedFilterFields.includes(it.value)
    );
  }

  private filterAvailableCenterFields(): void {
    const selectedFilterFields: string[] = (
      this.params.selectedProject?.centerFilter ?? []
    ).map((it) => it.property);
    this.availableCentersFilters = this.availableCentersFilters.filter(
      (it) => !selectedFilterFields.includes(it.value)
    );
  }
}
