export class NumbersUtils {
  static isNumeric(value: unknown): boolean {
    return (
      typeof value === 'number' ||
      (typeof value === 'string' && value !== '' && !isNaN(Number(value)))
    );
  }
  static roundNumber(value: number): number {
    return Math.round((value + Number.EPSILON) * 10) / 10;
  }
}
