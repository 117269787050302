import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FieldType } from '@shared/Types/FieldType';
import { HCP_DB_MAPPINGS } from '@shared/db-fields-mappings/HCP_DB_MAPPINGS';
import {
  FilterOperator,
  TTagItem,
  filterOperatorsMap,
} from '@shared/interfaces/TProject';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { newGuid } from '@shared/utils/newGuild';
import { TReportDataFilter } from '../report-filters.component';

interface TFilterOperatorNamingItem {
  filter: FilterOperator;
  name: string;
}

@Component({
  selector: 'app-report-filters-item',
  templateUrl: './report-filters-item.component.html',
  styleUrls: ['./report-filters-item.component.scss'],
})
export class ReportFiltersItemComponent {
  @Input() public filterItem: TReportDataFilter = {
    operator: FilterOperator.Equal,
    property: ``,
    value: ``,
    id: newGuid(),
    type: 'regular',
  };
  @Input() public fieldName: string = ``;
  @Input() public availableTags: TTagItem[] = [];
  @Input() public fieldType: FieldType = `hcp`;
  @Input() public projectId: string = ``;
  @Input() public deleteFilter: (item: TReportDataFilter) => void = (
    item: TReportDataFilter
  ) => console.log(item);
  public tags: FormControl<TTagItem[] | null> = new FormControl<
    TTagItem[] | null
  >(null);
  public availableOperators: TFilterOperatorNamingItem[] = Array.from(
    filterOperatorsMap.entries()
  ).map((entry) => {
    return {
      filter: entry[0],
      name: entry[1],
    };
  });
  constructor() {}

  public isEngagement(): boolean {
    const item = HCP_DB_MAPPINGS.get(this.filterItem.property);
    if (!isNullOrUndefined(item)) {
      return item.engagement === true;
    }
    return false;
  }

  onDateChange(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      const date = event.value;
      const utcDate = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
      this.filterItem.date = utcDate;
    }
  }
}
