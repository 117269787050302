import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TCategory } from '@shared/interfaces/TCategory';
import { TProject } from '@shared/interfaces/TProject';
import { Subscription } from 'rxjs';
import { ROUTES_PATHS } from '../app-routing.module';
import { TProjectReviewParams } from '../project-review/project-review.component';
import { CategoriesService } from '../services/CategoriesService';

interface TVisibleProject extends TProject {
  categoryId: string;
}

@Component({
  selector: 'app-projects-review',
  templateUrl: './projects-review.component.html',
  styleUrls: ['./projects-review.component.scss'],
})
export class ProjectsReviewComponent implements OnInit, OnDestroy {
  public categories: TCategory[] = [];
  public projects: TVisibleProject[] = [];
  private subs: Subscription[] = [];
  public categoriesMap: Record<string, TCategory> = {};

  constructor(
    private _categoriesService: CategoriesService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this._categoriesService
        .getCategoriesObservable()
        .subscribe((categories) => {
          this.categoriesSubscriptionHandler(categories);
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  public handleUnpin(categoryId: string, projectId: string): void {
    const project: TProject | undefined =
      this._categoriesService.getProject(projectId);
    if (project !== undefined) {
      project.isPinned = false;
      this._categoriesService.updateProject(categoryId, project);
    }
  }

  public goToProject(categoryId: string, projectId: string): void {
    const projectReviewParams: TProjectReviewParams = {
      projectId: projectId,
      categoryId: categoryId,
    };
    this._router.navigate([`/${ROUTES_PATHS.project}`, projectReviewParams]);
  }

  private categoriesSubscriptionHandler(categories: TCategory[]): void {
    this.categories = categories.slice();
    this.categoriesMap = categories.reduce<Record<string, TCategory>>(
      (acc, category) => {
        acc[category.categoryId] = category;
        return acc;
      },
      {}
    );
    const tempProjects: TVisibleProject[] = [];
    categories.forEach((category) => {
      category.projects.forEach((project) => {
        if (project.isPinned) {
          tempProjects.push({
            categoryId: category.categoryId,
            ...project,
          });
        }
      });
    });
    this.projects = tempProjects;
  }
}
