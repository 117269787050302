import { Injectable } from '@angular/core';
import { THCP } from '@shared/interfaces/THCP';
import { TSettings } from '@shared/interfaces/TSettings';
import jsPDF from 'jspdf';
import { PAPER_NOTIFICATION_CONSTANTS } from 'src/constants/paperNotificationConstants';
import { SettingsService } from './SettingsService';

@Injectable()
export class PaperNotificationService {
  constructor(private _settingsService: SettingsService) {}

  public async createForHCPs(
    hcps: THCP[],
    callback: () => void
  ): Promise<void> {
    const settings: TSettings = this._settingsService.getAppSettings();
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [595, 842],
    });

    const pages: HTMLElement[] = [];

    hcps.forEach((hcp) => {
      let template = settings.optOutPaperTemplate;
      const replaceItems: string[][] = [
        [PAPER_NOTIFICATION_CONSTANTS.FIRST_NAME_REPLACER, hcp.first_name],
        [PAPER_NOTIFICATION_CONSTANTS.LAST_NAME_REPLACER, hcp.last_name],
        [
          PAPER_NOTIFICATION_CONSTANTS.COMPANY_NAME_REPLACER,
          settings.companyName,
        ],
        [PAPER_NOTIFICATION_CONSTANTS.TITLE_REPLACER, hcp.title],
        [PAPER_NOTIFICATION_CONSTANTS.CENTER_NAME, hcp.centerName],
        [PAPER_NOTIFICATION_CONSTANTS.STREET, `${hcp.street1} ${hcp.street2}`],
        [PAPER_NOTIFICATION_CONSTANTS.ZIP, hcp.zip],
        [PAPER_NOTIFICATION_CONSTANTS.CITY, hcp.city],
        [PAPER_NOTIFICATION_CONSTANTS.COUNTRY, hcp.country],
      ];
      replaceItems.forEach((it) => {
        template = template.replace(new RegExp(it[0], 'g'), it[1]);
      });

      const container = this._pdfPageContainer();

      // create logo
      const logoElement = document.createElement('img');
      logoElement.src = settings.logo;
      logoElement.style.height = '30px';

      // add logo and content to container
      const topDiv = `<div style="position: absolute; top: 10px; right: 10px;">${logoElement.outerHTML}</div>`;
      const contentDiv = `<div style="margin-left: 30px; margin-right: 30px;">${template}</div>`;
      container.innerHTML = `${topDiv}${contentDiv}`;

      // find all <p> elements and set their font size to 8px
      const pElements = container.getElementsByTagName('p');
      for (let i = 0; i < pElements.length; i++) {
        pElements[i].style.fontSize = '8px';
      }
      // remove consent hashtag
      this._removeElementByText(
        container,
        PAPER_NOTIFICATION_CONSTANTS.CONSENT_HASHTAG
      );

      pages.push(container);
    });
    const allPages = pages.map((p) => p.outerHTML).join('');
    pdf.html(allPages, {
      callback: function (pdf) {
        pdf.save('paper-notifications.pdf');
        callback();
      },
      margin: 0,
    });
  }

  private _pdfPageContainer(): HTMLElement {
    const container = document.createElement('div');
    container.style.display = 'block';
    container.style.fontFamily = 'helvetica';
    container.style.fontSize = '8px';
    container.style.width = '157.5mm';
    container.style.height = '222.7mm';
    container.style.padding = '20px 10px 0 10px';
    container.style.position = 'relative';
    // for tests
    // container.style.border = '1px solid #000';
    return container;
  }

  private _removeElementByText(container: HTMLElement, text: string): void {
    const elements = container.getElementsByTagName('p');
    for (let i = 0; i < elements.length; i++) {
      if (
        elements[i] &&
        elements[i].textContent &&
        elements[i].textContent!.includes(text)
      ) {
        if (elements[i].parentNode) {
          elements[i].parentNode!.removeChild(elements[i]);
        }
      }
    }
  }
}
