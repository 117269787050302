import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedConstants } from '@shared/Constants';
import { DEFAULT_APP_SETTINGS, TSettings } from '@shared/interfaces/TSettings';
import { Editor, Toolbar } from 'ngx-editor';
import { Subscription } from 'rxjs';
import { LoadingService } from 'src/app/services/LoadingService';
import { SettingsService } from 'src/app/services/SettingsService';
import { PAPER_NOTIFICATION_CONSTANTS } from 'src/constants/paperNotificationConstants';
import { Severity } from '../../note/note.component';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements OnInit, OnDestroy {
  public appSettings: TSettings = structuredClone(DEFAULT_APP_SETTINGS);
  public logoURL: string = ``;
  public faviconURL: string = ``;
  public optInEditor: Editor;
  public optOutEditor: Editor;
  public optInPaperEditor: Editor;
  public optOutPaperEditor: Editor;
  public templateDescriptionSeverity: Severity = Severity.Info;
  public toolbar: Toolbar = [
    // default value
    ['bold', 'italic'],
    ['underline', 'strike'],
    //['code', 'blockquote'],
    //['ordered_list', 'bullet_list'],
    //[{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
    //['horizontal_rule', 'format_clear'],
  ];
  private _subs: Subscription[] = [];
  public emailTemplateDescription = `Use ${SharedConstants.FIRST_NAME_REPLACER} ${SharedConstants.LAST_NAME_REPLACER} to place first name and last name, ${SharedConstants.TITLE_REPLACER} to use title, ${SharedConstants.COMPANY_NAME_REPLACER}, ${SharedConstants.CONSENT_HASHTAG} to use opt in/out hashtag, and ${SharedConstants.CONFIRM_LINK} to add consent link to opt in`;
  public paperTemplateDescription = `Use ${PAPER_NOTIFICATION_CONSTANTS.FIRST_NAME_REPLACER} ${PAPER_NOTIFICATION_CONSTANTS.LAST_NAME_REPLACER} to place first name and last name, ${PAPER_NOTIFICATION_CONSTANTS.TITLE_REPLACER} to use title, ${PAPER_NOTIFICATION_CONSTANTS.COMPANY_NAME_REPLACER}. Use ${PAPER_NOTIFICATION_CONSTANTS.CENTER_NAME}, ${PAPER_NOTIFICATION_CONSTANTS.STREET}, ${PAPER_NOTIFICATION_CONSTANTS.ZIP}, ${PAPER_NOTIFICATION_CONSTANTS.CITY}, ${PAPER_NOTIFICATION_CONSTANTS.COUNTRY} to place center name, street, zip, city, and country respectively`;

  constructor(
    private _settingsService: SettingsService,
    private _loadingService: LoadingService
  ) {
    this._subs.push(
      this._settingsService.getAppSettingsObservable().subscribe((settings) => {
        this.appSettings = settings;
        this.logoURL = settings.logo ?? ``;
        this.faviconURL = settings.favicon ?? ``;
      })
    );
  }

  public uploadLogo(e: Event): void {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    const files: FileList | null = target.files;
    const logoFile: File = files![0];
    if (logoFile.size === 0) {
      this._loadingService.triggerErrorMessage(`Invalid file`);
    } else if (logoFile.size > 2000000) {
      this._loadingService.triggerErrorMessage(`File to large (max 2 MB)`);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(files![0]);
      reader.addEventListener(`loadend`, () => {
        this.logoURL = reader.result?.toString() ?? ``;
      });
    }
  }

  public uploadFavicon(e: Event): void {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    const files: FileList | null = target.files;
    const faviconFile: File = files![0];
    if (faviconFile.size === 0) {
      this._loadingService.triggerErrorMessage(`Invalid file`);
    } else if (faviconFile.size > 2000000) {
      this._loadingService.triggerErrorMessage(`File to large (max 2 MB)`);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(files![0]);
      reader.addEventListener(`loadend`, () => {
        this.faviconURL = reader.result?.toString() ?? ``;
      });
    }
  }

  public ngOnInit(): void {
    this.optInEditor = new Editor();
    this.optOutEditor = new Editor();
    this.optInPaperEditor = new Editor();
    this.optOutPaperEditor = new Editor();
  }

  public ngOnDestroy(): void {
    this.optInEditor?.destroy();
    this.optOutEditor?.destroy();
  }

  public updateSettings(): void {
    const newSettings: TSettings = {
      ...this.appSettings,
      logo: this.logoURL,
      favicon: this.faviconURL,
    };
    this._settingsService.updateSettings(newSettings);
  }
}
