<mat-form-field appearance="fill">
  <mat-label>Region</mat-label>

  <mat-select
    #child
    [formControl]="selectedRegions"
    multiple
    class="select-with-filter"
    [compareWith]="compareRegions"
    (focus)="resetRegionFilter()"
  >
    <div class="search-input-container">
      <mat-icon matSuffix class="search-input-icon">search</mat-icon>
      <input
        matInput
        placeholder="Search"
        class="search-input"
        [formControl]="regionFilterInput"
        autocomplete="off"
      />
    </div>
    <ng-container *ngFor="let region of regionsFiltered">
      <div [ngClass]="hiddenClass(region)">
        <mat-option (click)="handleRegionClick(region)" [value]="region">
          {{ region.viewValue }}
        </mat-option>
      </div>
    </ng-container>
  </mat-select>
</mat-form-field>
