import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  EventMessageSource,
  EventMessageStatus,
  TBaseEventMessage,
} from '@shared/interfaces/TEventMessage';
import { newGuid } from '@shared/utils/newGuild';
import { BehaviorSubject, Observable } from 'rxjs';
//TODO stack loading requests
export interface TLoadingItem {
  id: string;
  message: string;
}

export interface TLogMessage extends TBaseEventMessage {
  date: Date;
}
@Injectable()
export class LoadingService {
  public loadingItems: BehaviorSubject<TLoadingItem[]> = new BehaviorSubject<
    TLoadingItem[]
  >([]);
  public fadeOut: boolean = true;
  //public logVisible: boolean = true;
  public logVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  public logMessages: BehaviorSubject<TLogMessage[]> = new BehaviorSubject<
    TLogMessage[]
  >([]);

  constructor(private _snackBar: MatSnackBar) {}

  public triggerOkMessage(message: string = ``, duration: number = 2000): void {
    if (message.length > 0) {
      this.addLogItem({
        date: new Date(),
        message: message,
        status: EventMessageStatus.Info,
        source: EventMessageSource.Frontend,
      });
    }
    this._snackBar.open(
      message.length > 0 ? message : `Loading completed`,
      ``,
      {
        duration: duration,
        panelClass: [`success`],
      }
    );
  }

  public triggerErrorMessage(
    message: string = ``,
    duration: number = 10000
  ): void {
    if (message.length > 0) {
      this.addLogItem({
        date: new Date(),
        message: message,
        status: EventMessageStatus.Error,
        source: EventMessageSource.Frontend,
      });
    }
    this._snackBar.open(message.length > 0 ? message : `Error`, ``, {
      duration: duration,
      panelClass: [`error`],
    });
  }

  public triggerInfoMessage(
    message: string = ``,
    duration: number = 5000,
    action: string = ``
  ): void {
    if (message.length > 0) {
      this.addLogItem({
        date: new Date(),
        message: message,
        status: EventMessageStatus.Info,
        source: EventMessageSource.Frontend,
      });
    }
    this._snackBar.open(message.length > 0 ? message : `Info`, action, {
      duration: duration,
      panelClass: [`info`],
    });
  }

  public onError(
    message: string = `Something went wrong please refresh the page and try again`
  ): void {
    this.triggerErrorMessage(message);
    this.loadingItems.next([]);
  }

  public getLogVisibleObservable(): Observable<boolean> {
    return this.logVisible.asObservable();
  }

  public setLogVisible(value: boolean): void {
    this.logVisible.next(value);
  }

  public getLogObservable(): Observable<TLogMessage[]> {
    return this.logMessages.asObservable();
  }

  public addLogItem(item: TLogMessage): void {
    if (item.status === EventMessageStatus.External) {
      item.message = `${item.message} [external]`;
    }
    this.logMessages.next(
      [...this.getLogItems(), item].sort((a, b) => {
        return b.date.getTime() - a.date.getTime();
      })
    );
  }

  public getLogItems(): TLogMessage[] {
    return this.logMessages.getValue();
  }

  public getLoadingItemsObservable(): Observable<TLoadingItem[]> {
    return this.loadingItems.asObservable();
  }

  public addLoadingItem(item: TLoadingItem): void {
    this.addLogItem({
      date: new Date(),
      message: item.message,
      status: EventMessageStatus.Info,
      source: EventMessageSource.Frontend,
    });
    this.loadingItems.next([...this.getLoadingItems(), item]);
  }

  public getLoadingItems(): TLoadingItem[] {
    return this.loadingItems.getValue();
  }

  public removeItem(item: TLoadingItem): void {
    const loadingItems: TLoadingItem[] = this.getLoadingItems().slice();
    const itemIndex: number = loadingItems.findIndex(
      (loadingItem) => loadingItem.id === item.id
    );
    if (itemIndex > -1) {
      loadingItems.splice(itemIndex, 1);
      this.loadingItems.next(loadingItems);
    }
  }

  public buildLoadingItem(message: string): TLoadingItem {
    return {
      id: newGuid(),
      message: message,
    };
  }
}
