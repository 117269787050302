<mat-toolbar class="toolbar">
  <div class="toolbar__item toolbar__item--logo-wrapper">
    <span class="cursor-pointer" (click)="handleHeaderClick()">
      <img class="toolbar__logo" [src]="settings?.logo ?? ''" />
    </span>
  </div>
  <div class="toolbar__item toolbar__item--full-width">
    <form [formGroup]="searchForm">
      <mat-form-field class="w-full">
        <mat-label>{{ this.searchLabel }}</mat-label>
        <input type="text" matInput formControlName="searchGroup" [matAutocomplete]="autoGroup"
          (blur)="onSearchBlur()" />
        <mat-autocomplete #autoGroup="matAutocomplete">
          <mat-optgroup *ngFor="let group of searchGroupOptions | async" [label]="group.area">
            <mat-option *ngFor="let result of group.results" [value]="result.name"
              (click)="handleSearchClick(group.area, result.id)">
              {{ result.name }}
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>
      </mat-form-field>
    </form>
  </div>
  <div class="toolbar__item">
    <app-region-select [regions]="regions" [regionsFiltered]="regionsFiltered" [selectedRegions]="selectedRegions"
      [handleRegionClick]="handleRegionClick.bind(this)"></app-region-select>
  </div>
  <div class="toolbar__item">
    <div class="toolbar__user-info">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon color="primary">person</mat-icon>
        <div class="user user--simple">
          <div class="user__avatar">
            <span>
              {{ userAvatar }}
            </span>
          </div>
        </div>
      </button>
      <mat-menu #menu="matMenu">
        <div class="toolbar__greetings-box">
          <div class="toolbar__greetings-box__message">Welcome!</div>
          <div class="toolbar__greetings-box__username">{{currentUser?.firstName}}</div>
        </div>
        <button mat-menu-item (click)="openSystemSettings()">
          <mat-icon>settings_suggest</mat-icon>
          <span>System settings</span>
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>
